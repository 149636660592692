import React from "react";
import PropTypes from "prop-types";

/**
 * SvgIcon component.
 *
 * Used by webpack to generate react components for svg icons.
 * Can be used as a component on its own. Accepts any props that are valid for an svg element.
 * `glyph` is the only required prop
 *
 * @param {object} props - Component props.
 * @param {object} props.glyph - The id of the svg icon to reference in the sprite.
 * @returns {React.Component} - The svg icon component.
 */
export default function SvgIcon(props) {
  const { glyph, ...restProps } = props;

  return (
    <svg {...restProps}>
      <use xlinkHref={`#${glyph}`} />
    </svg>
  );
}

SvgIcon.propTypes = {
  glyph: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SvgIcon.defaultProps = {
  className: undefined,
};
