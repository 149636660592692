export default [
  {
    value: "JOINT_TENANTS_WITH_RIGHT_OF_SURVIVORSHIP",
    label: "Joint Tenants with Rights of Survivorship",
  },
  { value: "TENANTS_IN_COMMON", label: "Tenants in Common" },
  { value: "COMMUNITY_PROPERTY", label: "Community Property" },
  {
    value: "COMMUNITY_PROPERTY_WITH_RIGHT_OF_SURVIVORSHIP",
    label: "Community Property with Rights of Survivorship",
  },
  { value: "TENANTS_BY_ENTIRETY", label: "Tenants by Entirety" },
  { value: "USUFRUCT", label: "Usufruct (Louisiana residents only)" },
  { value: "NOT_SURE", label: "Not Sure" },
];
