import React from "react";
import ReactDOM from "react-dom";
import Backbone from "backbone";

/**
 * This is a backbone wrapper for react components which makes
 * the integration of react into backbone views easier.
 *
    @example
    // renders a react component into `el`
    const view = new BackboneReactView({
      el: 'query',
      component: ReactComponent,
      componentProps: { props }
    });

    // updates and re-renders the component
    view.update({ newProps });

    // destroys the view and removes the view
    view.remove();
 */
export default Backbone.View.extend({
  /**
   * @param {ReactElement} component - The react component.
   * @param {Object} componentProps - The props for the react component.
   */
  initialize({ component, componentProps }) {
    this.component = component;
    this.componentProps = componentProps;
    this.render();
  },

  /**
   * Updates the react component with new data and re-renders the view.
   * @param {Object} componentProps - The props for the react component.
   * @param {ReactElement} component - The react component.
   * @param {string} el - The component id.
   */
  update(componentProps, component = false, el = false) {
    this.componentProps = Object.assign(
      {},
      this.componentProps,
      componentProps
    );
    if (component) {
      this.component = component;
    }
    if (el) {
      this.el = el;
    }
    this.render();
  },

  remove() {
    ReactDOM.unmountComponentAtNode(this.el);
    Backbone.View.prototype.remove.apply(this, arguments);
  },

  render() {
    ReactDOM.render(
      React.createElement(this.component, this.componentProps),
      this.el
    );
    return this;
  },
});
