/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";
import React from "react";
import { isEqual, uniqueId, isEmpty } from "underscore";
import { validate } from "revalidator";
import DefaultFormatter from "components/common/form/formatters/default";
import { CSSTransition } from "react-transition-group";
import IframeClient from "partner/iframeClient";

const iframeClient = IframeClient.getInstance();
export default class BaseInput extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      value: this.props.value == null ? "" : this.props.value,
      valid: true,
    };

    this.id = uniqueId();
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.validate = this.validate.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Using _.isEqual() for optimized deep comparison between two objects and/or values
    if (!isEqual(this.props.value, nextProps.value)) {
      this.setState(
        {
          value: nextProps.value,
        },
        () => {
          if (this.props.validator && this.state.validationStarted) {
            this.validate();
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (
      this.props.disabled !== prevProps.disabled ||
      !isEqual(this.props.validator, prevProps.validator)
    ) {
      if (this.props.validator && this.state.validationStarted) {
        this.validate();
      }
    }
  }

  /**
   * Used by the standard Input components to handle on change event
   *
   * @param {SyntheticEvent} event  Wrapped DOM event
   */
  handleChange(event) {
    const { validator, onChange } = this.props;
    let value;
    if (event.target.type === "checkbox") {
      if (!event.target.value) {
        value = event.target.checked;
      }
    } else if (isEmpty(this.props.formattingOptions)) {
      value = this.props.formatter.unformat(event.target.value);
    } else if (this.props.formattingOptions.useFormattedValue) {
      // To get formatted value while using formattingOptions in Input.
      value = event.target.value;
    } else {
      value = event.target.rawValue;
    }

    // The formatter may prevent some characters from being entered.
    // Detect that and don't relay the change via `onChange`.
    const isChanged = !isEqual(value, this.state.value);

    this.setState(
      {
        value,
        dirty: true,
      },
      () => {
        if (validator && this.state.validationStarted) {
          this.validate();
        }
      }
    );

    if (isChanged && onChange) {
      onChange(event, value); // Calls AbstractForm's handleInputChange()
    }
  }

  handleBlur(event) {
    const { validator, onBlur } = this.props;
    if (validator && this.state.dirty) {
      this.validate();
    }
    if (onBlur) {
      onBlur(event);
    }
  }

  /**
   * Validate this.state.value using props.name and props.validator
   *
   * @param {Function} callback   Optional callback function to run after setState() is done
   * @return {Object} The validation result
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  validate(callback) {
    const { name, disabled, validator, onAfterValidate } = this.props;
    let value = this.state.value;
    let validateResult;

    if (disabled) {
      validateResult = { valid: true, errors: null };
      this.setState(validateResult, callback);
      return validateResult;
    }

    // Do not attempt to run validation if an empty value is allowed
    if (
      validator.type === "string" &&
      value === "" &&
      validator.allowEmpty &&
      !validator.conform
    ) {
      this.setState({ valid: true, errors: [] });
      return { valid: true, errors: [] };
    }

    // Revalidator supports automatic casting via `{cast: true}` option. However, the casting
    // implementation doesn't work as expected as it casts an empty string `` to `0`.
    // That makes it impossible to use `required: true` validation rule (0 is treated as an existing value).
    // TODO patch revalidator
    if (
      validator &&
      (validator.type === "number" || validator.type === "integer") &&
      typeof value !== "number"
    ) {
      if (value === "") {
        value = undefined;
      } else {
        // attempt to cast
        const casted = Number(value);
        if (!isNaN(casted)) {
          value = casted;
        }
      }
    }

    validateResult = validate(
      { [name]: value },
      {
        properties: {
          [name]: validator,
        },
      }
    );

    // Revalidator does not allow an empty string to validate if the data type is `boolean`, even with `allowEmpty` set as `true`
    if (
      value === "" &&
      validator &&
      validator.type === "boolean" &&
      validator.allowEmpty
    ) {
      validateResult = { valid: true, errors: null };
    }

    if (onAfterValidate) {
      onAfterValidate(validateResult, this.ref);
    }

    this.setState(
      Object.assign({ validationStarted: true }, validateResult),
      callback
    );
    return validateResult;
  }

  /**
   * Get error block containing this.state.errors as content. By default it will return an invisible
   * label block so as to prevent the form elements from shifting downward when an error shows up.
   *
   * @param {Object} options  Key value pairs of error block options
   *        {String} options.className      The class name to insert into the label block
   *        {Boolean} options.placeholder   Pass true to get error block even when there are no errors
   * @returns {Object} The error block element
   */
  getErrorBlock(options = {}) {
    const className = options.className;
    const placeholder = options.placeholder;
    const error =
      this.state.errors && this.state.errors[0] && this.state.errors[0].message;
    const labelClassName = `${
      className ? className : ""
    } pc-help-block pc-help-block--small pc-help-block--error`;
    const id = this.props.id || this.id;
    let errorBlock;
    if (placeholder) {
      errorBlock = (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          htmlFor={id}
          className={`${labelClassName} ${
            this.state.valid ? "u-invisible" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: error || "&nbsp;" }}
          aria-label={`Error ${error || ""}`}
          aria-live="polite"
        />
      );
    } else {
      const transitionProps = {
        in: Boolean(error),
        unmountOnExit: true,
        classNames: "transition-height",
        timeout: 250,
      };

      if (IS_IFRAMED) {
        transitionProps.onEntered =
          iframeClient.triggerResize.bind(iframeClient);
        transitionProps.onExited =
          iframeClient.triggerResize.bind(iframeClient);
      }

      errorBlock = (
        <CSSTransition {...transitionProps}>
          <label
            id={`${id}-error`}
            htmlFor={id}
            className={labelClassName}
            aria-label={`Error ${error || ""}`}
            aria-live="polite"
            role="alert"
          >
            {error}
          </label>
        </CSSTransition>
      );
    }
    return errorBlock;
  }
}

/**
 * Important: Any `defaultProps` and `propTypes` declared by BaseInput's children
 * classes will override what's declared here
 */

BaseInput.defaultProps = {
  formatter: DefaultFormatter,
  formattingOptions: undefined,
};

BaseInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  validator: PropTypes.object,
  formattingOptions: PropTypes.object,
  formatter: PropTypes.shape({
    format: PropTypes.func,
    unformat: PropTypes.func,
  }),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onAfterValidate: PropTypes.func,
};
