import globals from "globals";
import Services from "libs/pcap/utils/service";

var cache = Services.cache({
  ignoreParameters: [
    "lastServerChangeId",
    "csrf",
    "apiClient",
    "FROMBARGRAPH",
    "incomeExpenseMode",
    "sort_cols",
    "sort_rev",
    "page",
    "rows_per_page",
    "search",
    "credit_debit",
    "component",
    "includeInCashManager",
    "transactionType",
    "accountType",
    "includeCashFlows",
    "includeCategoryCashFlows",
    "includeMerchantCashFlows",
    "includeMerchantCategories",
  ],
});

var accountServerChanges = [
  "USER_SITE_ADDED",
  "USER_SITE_UPDATED",
  "USER_SITE_REMOVED",
  "USER_PRODUCT_ADDED",
  "USER_PRODUCT_UPDATED",
  "USER_PRODUCT_REMOVED",
  "USER_ACCOUNT_ADDED",
  "USER_ACCOUNT_UPDATED",
  "USER_ACCOUNT_REMOVED",
  "USER_CACHE_REFRESHED",
  "USER_HOLDING_ADDED",
  "USER_HOLDING_UPDATED",
  "USER_HOLDING_REMOVED",
  "USER_ACCOUNT_CLOSED",
  "USER_ACCOUNT_CLOSED_DATE_MOVED",
  "USER_TRANSACTION_CATEGORY_UPDATED",
  "USER_TRANSACTION_CATEGORY_DELETED",
  "USER_ACCOUNT_REOPENED",
  "SHOW_INVESTMENT_INCOME_CHANGE",
];

var MainServices = new Services.Service()
  .use(Services.ensureParams())
  .use(cache)
  .use(Services.serverChanges(cache))
  .use(Services.csrfToken(globals.csrf))
  .use(Services.clientId())
  .use(Services.unauthRedirect()) // cspell:disable-line
  .map({
    Proposal: {
      get: "/api/proposal/getProposal",
      update: "/api/proposal/updateProposal",
      create: "/api/proposal/createProposal",
      delete: "/api/proposal/deleteProposal",
      publish: "/api/proposal/publishProposal",
      clone: "/api/proposal/cloneProposal",
      present: "/api/proposal/presentProposal",
      updateStats: "/api/proposal/updateProposalViewStats",
    },
    Meeting: {
      start: "/api/appointment/getMeetingLink",
    },
    Proposals: {
      get: "/api/proposal/getProposals",
    },
    AdvisoryFeeTransactions: {
      get: "/api/agent/getAdvisoryTransactions",
    },
    TargetPortfolio: {
      generate: "/api/agent/generateTargetPortfolio",
      revoke: "/api/agent/revokeStrategy",
    },
    Accounts: {
      get: {
        url: "/api/newaccount/getAccounts2",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    AccountTypes: {
      get: "/api/account/getAccountTypes2",
    },
    AddAccount: {
      createManual: "/api/newaccount/createAccounts2",
    },
    CustomProducts: {
      get: "/api/search/getCustomProducts",
    },
    Profile: {
      getUIPreferences: {
        url: "/api/profile/getUIPreferences ",
      },
      getFunnelAttributes: {
        url: "/api/profile/getFunnelAttributes",
      },
    },
    Person: {
      get: "/api/person/getPerson",
    },
    PersonEventHistories: {
      get: "/api/person/getPersonEventHistories",
    },
    Agent: {
      get: "/api/agent/getAgent",
    },
    UserCommunications: {
      get: "/api/milestone/getUserCommunications",
    },
    CallQueue: {
      getNextLead: "/api/cq/next-lead",
      getLeadInfo: "/api/cq/lead",
    },
    Transfer: {
      getStateForTransfer: {
        url: "/api/transfer/getAccountStateForTransfer",
        cache: true,
        serverChanges: [
          "USER_SITE_UPDATED",
          "USER_ACCOUNT_TRANSFER_STATE_UPDATED",
          "USER_ACCOUNT_CLOSED",
          "PCB_ENROLLMENT_UPDATED",
        ],
      },
    },
    UserResponseService: {
      getUserResponses: "/api/userResponseService/userResponses",
      getQuestions: "/api/userResponseService/questions",
    },
    ProspectIntoSalesforce: {
      /**Block merge for possible API location update */
      register: "/sfregistration/registerProspectIntoSalesforce",
    },
  });
export default MainServices;
