import PropTypes from "prop-types";
import React, { Component } from "react";
import noop from "utils/noop";

/**
 * Display current item indexes being shown and total number of items, with buttons to navigate through items.
 *
 *
 * ## Props
 *  - `className` {String}                    - ClassName applied to pagination nav.
 *  - `total` {Number}                        - Total number of items in the table across all pages.
 *  - `stepSize` {Number}                     - Number of items to show on each page.
 *  - `updatePage` {Function}  -    Updates Pagination Display and calls callback function.
 *                                  Receives the following arguments:
 *                                  1. `rangeStart` - 1 indexed location of page start
 *                                  2. `rangeEnd` - 1 indexed location of page end
 *                                  3. `total` - number of items across all pages
 *
 * @class Pagination
 * @extends {Component}
 */
export default class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangeStart: props.rangeStart,
    };
    this.updatePage = this.updatePage.bind(this);
    this.goToFirstPage = this.goToFirstPage.bind(this);
    this.goToLastPage = this.goToLastPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
    this.goToPrevPage = this.goToPrevPage.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    //eslint-disable-line camelcase
    let newState = {};
    if (this.state.rangeStart !== nextProps.rangeStart) {
      newState.rangeStart = nextProps.rangeStart;
    }
    this.setState(newState, () => {
      if (nextProps.total < this.state.rangeStart) {
        this.goToFirstPage(nextProps.total);
      }
    });
  }

  render() {
    const { className, total, stepSize } = this.props;
    const { rangeStart } = this.state;

    const isFirstPage = rangeStart <= 0;
    const isLastPage = rangeStart + stepSize >= total;
    const rangeEnd = Math.min(rangeStart + stepSize, total);

    return (
      <nav className={className || ""}>
        <button
          type="button"
          className="pc-pagination__button pc-pagination__button--skip-back"
          aria-label="Go to first page"
          disabled={isFirstPage}
          onClick={this.goToFirstPage}
        />
        <button
          type="button"
          className="pc-pagination__button pc-pagination__button--back"
          aria-label="Go to previous page"
          disabled={isFirstPage}
          onClick={this.goToPrevPage}
        />
        <span className="pc-pagination__text">
          <span className="pc-pagination__current-range-text">
            {rangeEnd > 0 ? rangeStart + 1 : 0} - {rangeEnd}{" "}
          </span>
          of {total}
        </span>
        <button
          type="button"
          className="pc-pagination__button pc-pagination__button--forward"
          aria-label="Go to next page"
          disabled={isLastPage}
          onClick={this.goToNextPage}
        />
        <button
          type="button"
          className="pc-pagination__button pc-pagination__button--skip-forward"
          aria-label="Go to last page"
          disabled={isLastPage}
          onClick={this.goToLastPage}
        />
      </nav>
    );
  }

  updatePage(rangeStart, total) {
    this.setState({
      rangeStart: rangeStart,
    });

    const rangeEnd = Math.min(rangeStart + this.props.stepSize, total) - 1;
    if (this.props.onPageChange) {
      this.props.onPageChange(rangeStart, rangeEnd, total);
    }
  }

  goToFirstPage(total = this.props.total) {
    const start = 0;
    this.updatePage(start, total);
  }

  goToLastPage() {
    const { total, stepSize } = this.props;
    const range = total % stepSize || stepSize;
    const start = this.props.total - range;
    this.updatePage(start, total);
  }

  goToNextPage() {
    const { total, stepSize } = this.props;
    const { rangeStart } = this.state;
    const start = rangeStart + stepSize;
    this.updatePage(start, total);
  }

  goToPrevPage() {
    const { total, stepSize } = this.props;
    const { rangeStart } = this.state;
    const start = rangeStart - stepSize;
    this.updatePage(start, total);
  }
}

Pagination.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  stepSize: PropTypes.number,
  onPageChange: PropTypes.func,
  rangeStart: PropTypes.number,
};

Pagination.defaultProps = {
  className: "",
  total: 0,
  stepSize: 100,
  rangeStart: 0,
  onPageChange: noop,
};
