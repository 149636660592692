function mapDurations(durations) {
  return durations.map((d) => ({ value: d, label: `${d} min` }));
}

export default function toClient(appointmentTypes) {
  return appointmentTypes.map((a) => {
    return {
      value: a.appointmentType,
      label: a.displayName,
      duration: a.defaultDuration,
      durations: mapDurations(a.optionalDurations),
    };
  });
}
