/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { trackClick } from "components/common/ComponentAnalytics";
import { FORMAT_TIME } from "components/scheduling/utils";

const FORMAT_DAY_OF_WEEK = "dddd";
const FORMAT_DAY_OF_MONTH = "Do";
const FORMAT_MONTH = "MMMM";

const REGEX_DAY_OF_MONTH = /(\d+)(\w+)/;

export default function AppointmentTiles({
  appointments,
  appointmentId,
  onSelect,
  eventTrackingSharedProperties,
  componentName,
}) {
  const handleClick = function (ev) {
    onSelect(appointments[parseInt(ev.currentTarget.value, 10)]);
    trackClick(
      ev,
      componentName,
      "Appointment Tile",
      eventTrackingSharedProperties
    );
  };

  return (
    <div className="l-spaced l-spaced--flush l-spaced--nowrap appointment-tiles appointment-tiles--responsive">
      {appointments.map((s, i) => {
        const dayOfMonth = s.startTime.format(FORMAT_DAY_OF_MONTH);
        const dayOfMonthTokens = REGEX_DAY_OF_MONTH.exec(dayOfMonth);
        const dayOfMonthDay = dayOfMonthTokens[1];
        const dayOfMonthSuffix = dayOfMonthTokens[2];
        const isSelected =
          s.appointmentId != null && s.appointmentId === appointmentId;
        return (
          <button
            key={String(s.startTime.valueOf()) + String(s.endTime.valueOf())}
            type="button"
            onClick={handleClick}
            name="availability"
            value={i}
            className={`pc-btn pc-btn--appointment-tile js-appointment-tile qa-appointment-tile qa-appointment-tile${i} ${
              isSelected ? "is-active" : ""
            } pc-btn--appointment-tile-empower`}
          >
            <div className="appointment-tile__day-month">
              <span className="appointment-tile__day qa-appointment-tile__day">
                {s.startTime.format(FORMAT_DAY_OF_WEEK)}
              </span>
              <span className="appointment-tile__month qa-appointment-tile__month">
                {s.startTime.format(FORMAT_MONTH)}
              </span>
              <span className="appointment-tile__day-of-month-container qa-appointment-tile__day-of-month-container">
                <span className="appointment-tile__day-of-month qa-appointment-tile__day-of-month">
                  {dayOfMonthDay}
                </span>
                <span className="appointment-tile__day-of-month-suffix">
                  {dayOfMonthSuffix}
                </span>
              </span>
            </div>
            <span className="appointment-tile__time qa-appointment-tile__time">
              {s.startTime.format(FORMAT_TIME)} –{" "}
              {s.endTime.format(FORMAT_TIME)}
            </span>
          </button>
        );
      })}
    </div>
  );
}

AppointmentTiles.propTypes = {
  appointments: PropTypes.array,
  onSelect: PropTypes.func,
  appointmentId: PropTypes.string,
  eventTrackingSharedProperties: PropTypes.object,
  componentName: PropTypes.string,
};

AppointmentTiles.defaultProps = {
  appointments: [],
};
