import PropTypes from "prop-types";
import React from "react";
import BaseInput from "../BaseInput";

/**
 * `Checkbox` component renders a checkbox and provides
 * the validation support.
 * Validator should be provided via `validator` attribute in the format
 * https://github.com/flatiron/revalidator#schema
 *
 * Example:
 * ```
    {
      required: true
    }
 * ```
 *
 * Unlike other form components, `value` prop is **static**. You want to make `checked` prop controlled.
 * ```
    <Checkbox
      name="isConsented"
      value={true}
      checked={this.state.model.isConsented}
      label="I, Christopher Hoffman, understand."
      validator={{
        type: "boolean",
        required: true
      }}
      onChange={this.handleInputChange}
      helpText="Please consent"
    />
 * ```
 *
 * @export Checkbox
 * @class Checkbox
 * @extends {React.Component}
 */
export default class Checkbox extends BaseInput {
  constructor() {
    super(...arguments);
    this.checkbox = true;

    const { value } = this.props;
    this.originalValue = value;
    this.state.value = this.props.checked ? value : undefined;
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      process.env.NODE_ENV !== "production" &&
      this.props.value !== nextProps.value
    ) {
      /*eslint-disable no-console*/
      console.warn(
        "Alert: Invalid usage of Checkbox has been detected. `value` prop should be static. Use `checked` prop to track the state of the checkbox.\nRefer to checkbox story in Storybook for the usage example."
      );
      /*eslint-enable no-console*/
    }
  }

  focus() {
    this.ref.focus();
  }

  handleChange(event) {
    const { validator, onChange } = this.props;
    let value = event.target.checked ? this.originalValue : undefined;

    this.setState(
      {
        value,
        dirty: true,
      },
      () => {
        if (validator && this.state.validationStarted) {
          this.validate();
        }
      }
    );

    if (onChange) {
      onChange(event, value);
    }
  }

  handleKeyDown(event) {
    const { checked, name } = this.props;
    // eslint-disable-next-line no-magic-numbers
    if (event.key === "Enter" || event.keyCode === 13) {
      this.handleChange({
        target: {
          name,
          type: "checkbox",
          checked: !checked,
          value: this.originalValue,
        },
      });
    }
  }

  render() {
    const {
      name,
      label,
      checked,
      disabled,
      className,
      checkboxClassName,
      labelClassName,
      labelTextClassName,
    } = this.props;
    const helpText = this.props.helpText;
    return (
      <div className={className}>
        <label className={labelClassName}>
          <input
            ref={(el) => {
              this.ref = el;
            }}
            className={checkboxClassName}
            type="checkbox"
            name={name}
            value={this.originalValue}
            checked={checked}
            disabled={disabled}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
          />{" "}
          {labelTextClassName ? (
            <span className={labelTextClassName}>{label}</span>
          ) : (
            label
          )}
        </label>
        {helpText && (
          <label className="pc-help-block pc-help-block--small u-text-left">
            {helpText}
          </label>
        )}
        {this.getErrorBlock()}
      </div>
    );
  }
}

Checkbox.propTypes = Object.assign({}, BaseInput.propTypes, {
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  helpText: PropTypes.string,
  labelTextClassName: PropTypes.string,
});

Checkbox.defaultProps = Object.assign({}, BaseInput.defaultProps, {
  checked: false,
  className: undefined,
  checkboxClassName: undefined,
  labelClassName: "pc-label",
  helpText: undefined,
  value: true,
  labelTextClassName: undefined,
});
