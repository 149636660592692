import _ from "underscore";
import { formatCurrency, formatNumber } from "libs/pcap/utils/format";

// as Handlebar adds an "implicit object" to the function call,
// we are filtering it out as a false value.
// All other values are interpreted to true/false.
function pure() {
  return _.map(_.toArray(arguments), function (value) {
    return _.isObject(value) ? false : Boolean(value);
  });
}

export default function dollarAndCentsAmount(
  number,
  dash,
  includeCurrencySymbol,
  isNegative,
  noCents
) {
  let argArray = pure(dash, includeCurrencySymbol, isNegative, noCents);

  dash = argArray[0];
  includeCurrencySymbol = argArray[1];
  isNegative = argArray[2];
  const precision = argArray[3] ? 0 : 2;

  if (typeof number === undefined || isNaN(number)) {
    return dash ? "-" : "N/A";
  }

  if (isNegative) {
    number = -1 * number;
  }

  return includeCurrencySymbol !== undefined && includeCurrencySymbol
    ? formatCurrency(number, precision)
    : formatNumber(number, precision);
}
