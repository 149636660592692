import BaseInput from "./BaseInput";

export default class CompositeInput extends BaseInput {
  constructor() {
    super(...arguments);
    this.handleChangeWithValue = this.handleChangeWithValue.bind(this);
  }

  /**
   * Used by custom, composite input components to directly set field value instead of getting
   * it from `event.target`. This is mainly because these components are made of more complex
   * input user interface, so we cannot deliver the composite input value via a single input.
   *
   * @param {any} value   The value to set the field to
   */
  handleChangeWithValue(value) {
    const { name, validator, onChange } = this.props;
    this.setState(
      {
        value: value,
        dirty: true,
      },
      () => {
        if (validator) {
          this.validate();
        }
      }
    );

    if (onChange) {
      const fakeEvent = { target: { name, value }, isFakeEvent: true };
      onChange(fakeEvent); // Calls AbstractForm's handleInputChange()
    }
  }
}

// `defaultProps` and `propTypes` are inherited from BaseInput
