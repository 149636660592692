/* eslint-disable no-invalid-this */
import { stack as stackD3, transition, scaleBand } from "d3";
import { flatten } from "underscore";

var CLASS_NAME_SERIES = "chart__series--bar";
var CLASS_NAME_STACK = "chart__stack--bar";
var CLASS_NAME_GROUP = "chart__group--bar";
var CLASS_NAME_LABEL = "chart__bar-value";
var BAR_VALUE_OFFSET_TOP = 20;
var BAR_VALUE_OFFSET_BOTTOM = 7;
var BAR_VALUE_OFFSET_BOTTOM_OVERFLOW = 15;
var DEFAULT_TEXT_HEIGHT = 24;

function generateStacks(stacks) {
  return function (d) {
    var stack = stackD3()
      .keys(stacks)
      .value(function (d, key) {
        // override the original value function to return `0` instead of `undefined`.
        return d[key] == null ? 0 : d[key];
      });
    return stack(d);
  };
}

function generateGroups(groups, x) {
  // From: [{ apple: 1, banana: 2, month: 1}, { apple: 3:, banana: 3, month: 2 }]
  // To: [ [{x: apple, y: 1}, {x: banana, y: 2}], [{x: apple, y: 3}, {x: banana, y: 3}] ] + `x` attribute on each 2nd level array
  return function (d) {
    return d.map((d) => {
      var result = Object.entries(d)
        .filter((e) => groups.includes(e[0]))
        .map((e) => ({ x: e[0], y: e[1] }));

      result.x = x(d);
      return result;
    });
  };
}

function generateGroupedStacks(groups, stacks) {
  return function (d) {
    var stack = stackD3()
      .keys(stacks)
      .value(function (d, key) {
        // override the original value function to return `0` instead of `undefined`.
        return d.y[key] == null ? 0 : d.y[key];
      });
    return stack(d);
  };
}

function labels(context, options) {
  var hasInheritedTransition = context instanceof transition;
  var selection = hasInheritedTransition ? context.selection() : context;
  var showLabels =
    options.showBarValue && !options.horizontalOrientation && !options.stacks;

  var xScale = options.xScale;
  var yScale = options.yScale;
  var x = options.x;
  var xScaledAccessor = function (d) {
    return xScale(x(d));
  };
  var y0 = 0;

  // The top line of the bar. Corresponds to the value.
  var y1 = options.y;
  var key = options.key;
  var keyFunction;
  if (key) {
    keyFunction = function (d) {
      return d ? key(d) : this.getAttribute("data-key");
    };
  }

  var text = selection.selectAll(`text.${CLASS_NAME_LABEL}`).data(function (d) {
    return d;
  }, keyFunction);

  var textExit = text.exit();

  if (showLabels) {
    var isBarTooSmall = function (d) {
      return Math.abs(yScale(y0) - yScale(y1(d))) < DEFAULT_TEXT_HEIGHT;
    };

    const data = flatten(selection.data());
    const hasPositiveValue = data.some((d) => y1(d) > 0);

    // Checks to see if the bar is large enough to contain the text label
    // If it's too small, position the text label outside the bar
    var calculateYPosition = function (d) {
      const dVal = y1(d);
      // shifts `0` value below X axis if there are negative values only
      if (dVal < 0 || (!hasPositiveValue && dVal === 0)) {
        const yVal = yScale(Math.min(y0, dVal));
        return isBarTooSmall(d)
          ? yVal + BAR_VALUE_OFFSET_BOTTOM_OVERFLOW
          : yVal - BAR_VALUE_OFFSET_BOTTOM;
      }
      const yVal = yScale(Math.max(y0, y1(d)));
      return isBarTooSmall(d)
        ? yVal - BAR_VALUE_OFFSET_BOTTOM
        : yVal + BAR_VALUE_OFFSET_TOP;
    };

    text
      .enter()
      .append("text")
      .attr("class", CLASS_NAME_LABEL)
      .classed(`${CLASS_NAME_LABEL}--inside`, function (d) {
        return !isBarTooSmall(d);
      })
      .attr("text-anchor", "middle")
      .attr("x", function (d) {
        return xScaledAccessor(d) + xScale.bandwidth() / 2;
      })
      .attr("y", yScale(0))
      .text(function (d) {
        return options.barValueFormat ? options.barValueFormat(y1(d)) : y1(d);
      })
      .style("opacity", 0)
      .transition(context.transition())
      .attr("y", calculateYPosition)
      .style("opacity", 1);

    text.classed(`${CLASS_NAME_LABEL}--inside`, function (d) {
      return !isBarTooSmall(d);
    });

    text
      .text(function (d) {
        return options.barValueFormat ? options.barValueFormat(y1(d)) : y1(d);
      })
      .transition(context)
      .attr("x", function (d) {
        return xScaledAccessor(d) + xScale.bandwidth() / 2;
      })
      .attr("y", calculateYPosition);

    textExit
      .transition(context)
      .style("opacity", 0)
      .attr("y", yScale(0))
      .remove();
  } else {
    text.remove();
  }
}

function bars(context, options) {
  var hasInheritedTransition = context instanceof transition;
  var selection = hasInheritedTransition ? context.selection() : context;

  var xScale = options.xScale;
  var yScale = options.yScale;
  var x = options.x;
  var xScaledAccessor = function (d) {
    d = options.stacks ? d.data : d;
    return xScale(x(d));
  };

  // The base line of the bar. Corresponds to the 0 coordinate
  // or the top of the previous stack on the stackable bar graph.
  var y0 = function (d) {
    return options.stacks ? d[0] : 0;
  };
  // The top line of the bar. Corresponds to the value.
  var y1 = options.y;
  if (options.stacks) {
    y1 = function (d) {
      return d[1];
    };
  }
  var key = options.key;
  const { barClassName } = options;
  var keyFunction;
  if (key) {
    keyFunction = function (d) {
      return d ? key(d) : this.getAttribute("data-key");
    };
  }

  var bars = selection.selectAll("rect").data(function (d) {
    return d;
  }, keyFunction);

  var barsExit = bars.exit();

  bars
    .enter()
    .append("rect")
    .attr("data-key", function (d) {
      return key ? key(d) : undefined;
    })
    .attr("class", function (d) {
      var className =
        "chart__bar js-chart-bar chart__bar--" +
        (y1(d) < 0 ? "negative" : "positive");
      if (key) {
        className += " chart__bar--" + key(d);
      }

      if (barClassName) {
        className += ` ${barClassName(d)}`;
      }

      return className;
    })
    .attr("x", xScaledAccessor)
    .attr("y", yScale(0))
    .attr("width", xScale.bandwidth())
    .attr("height", 0)
    // delay till the chart canvas finished animating
    .transition(context.transition())
    .attr("y", function (d) {
      return yScale(Math.max(y0(d), y1(d)));
    })
    .attr("height", function (d) {
      return Math.abs(yScale(y0(d)) - yScale(y1(d)));
    });

  bars
    .classed("chart__bar--positive", function (d) {
      return y1(d) >= 0;
    })
    .classed("chart__bar--negative", function (d) {
      return y1(d) < 0;
    });

  if (hasInheritedTransition) {
    bars = bars.transition(context);
    barsExit = barsExit.transition(context);
  }

  barsExit.attr("height", 0).attr("y", yScale(0)).style("opacity", 0).remove();

  // Schedule the update transition after exit, so that the removed bar have time
  // to animate to 0 before other bars take up the extra space.
  if (options.waitExit) {
    var hasRemovedBars = !barsExit.empty();
    if (hasRemovedBars) {
      bars = bars.transition(context.transition());
    }
  }

  bars
    .attr("x", xScaledAccessor)
    .attr("y", function (d) {
      return yScale(Math.max(y0(d), y1(d)));
    })
    .attr("height", function (d) {
      return Math.abs(yScale(y0(d)) - yScale(y1(d)));
    })
    .attr("width", xScale.bandwidth());

  context.call(labels, options);
}

function horizontalBars(context, options) {
  var hasInheritedTransition = context instanceof transition;
  var selection = hasInheritedTransition ? context.selection() : context;

  var xScale = options.xScale;
  var yScale = options.yScale;
  var y = options.y;
  var yScaledAccessor = function (d) {
    d = options.stacks ? d.data : d;
    return yScale(y(d));
  };

  // The base line of the bar. Corresponds to the 0 coordinate
  // or the top of the previous stack on the stackable bar graph.
  var x0 = function (d) {
    return options.stacks ? d[0] : 0;
  };
  // The top line of the bar. Corresponds to the value.
  var x1 = options.x;
  if (options.stacks) {
    x1 = function (d) {
      return d[1];
    };
  }

  var key = options.key;
  var keyFunction;
  if (key) {
    keyFunction = function (d) {
      return d ? key(d) : this.getAttribute("data-key");
    };
  }

  var bars = selection.selectAll("rect").data(function (d) {
    return d;
  }, keyFunction);

  var barsExit = bars.exit();

  bars
    .enter()
    .append("rect")
    .attr("data-key", function (d) {
      return key ? key(d) : undefined;
    })
    .attr("class", function (d) {
      return (
        "chart__bar js-chart-bar chart__bar--" +
        (x1(d) < 0 ? "negative" : "positive")
      );
    })
    .attr("x", xScale(0))
    .attr("y", yScaledAccessor)
    .attr("width", 0)
    .attr("height", yScale.bandwidth())
    // delay till the chart canvas finished animating
    .transition(context.transition())
    .attr("x", function (d) {
      return xScale(Math.min(x0(d), x1(d)));
    })
    .attr("width", function (d) {
      return Math.abs(xScale(x0(d)) - xScale(x1(d)));
    });

  bars
    .classed("chart__bar--positive", function (d) {
      return x1(d) >= 0;
    })
    .classed("chart__bar--negative", function (d) {
      return x1(d) < 0;
    });

  if (hasInheritedTransition) {
    bars = bars.transition(context);
    barsExit = barsExit.transition(context);
  }

  barsExit.attr("width", 0).attr("x", xScale(0)).style("opacity", 0).remove();

  bars
    .attr("x", function (d) {
      return xScale(Math.min(x0(d), x1(d)));
    })
    .attr("y", yScaledAccessor)
    .attr("width", function (d) {
      return Math.abs(xScale(x0(d)) - xScale(x1(d)));
    })
    .attr("height", yScale.bandwidth());
}

function stacks(context, options) {
  var hasInheritedTransition = context instanceof transition;
  var selection = hasInheritedTransition ? context.selection() : context;
  var barChart = options.horizontalOrientation ? horizontalBars : bars;

  var stacks;
  if (options.group) {
    stacks = selection
      .selectAll(".js-chart-bar-stack")
      .data(generateGroupedStacks(options.group, options.stacks));
  } else {
    stacks = selection
      .selectAll(".js-chart-bar-stack")
      .data(generateStacks(options.stacks));
  }

  var stacksExit = stacks.exit();

  stacks
    .enter()
    .append("g")
    .attr("class", function (datum, index) {
      return (
        "js-chart-bar-stack chart__stack " +
        CLASS_NAME_STACK +
        " " +
        CLASS_NAME_STACK +
        "-" +
        ++index +
        " " +
        CLASS_NAME_STACK +
        "-" +
        datum.key
      );
    })
    .transition(context)
    .call(barChart, options);

  if (hasInheritedTransition) {
    stacks = stacks.transition(context);
  }

  stacksExit.remove();

  stacks.call(barChart, options);
}

function groups(context, options) {
  var hasInheritedTransition = context instanceof transition;
  var selection = hasInheritedTransition ? context.selection() : context;
  var barChart;
  if (options.stacks) {
    barChart = stacks;
  } else {
    barChart = options.horizontalOrientation ? horizontalBars : bars;
  }
  var xScale = options.xScale;

  // x scale within a group
  var xScaleGroup = scaleBand()
    .domain(options.group)
    .range([0, options.xScale.bandwidth()]);

  if (options.groupPaddingInner) {
    xScaleGroup.paddingInner(options.groupPaddingInner);
  }

  var optionsGroup = Object.assign({}, options, {
    xScale: xScaleGroup,
    x: (d) => d.x,
    y: (d) => d.y,
    // key is used to uniquely identify bars within a group
    key: (d) => d.x,
  });

  var group = selection
    .selectAll(".js-chart-bar-group")
    .data(generateGroups(options.group, options.x), (d) => d.x);

  group
    .enter()
    .append("g")
    .attr("class", function (datum, index) {
      return (
        "js-chart-bar-group chart__group " +
        CLASS_NAME_GROUP +
        " " +
        CLASS_NAME_GROUP +
        "-" +
        ++index
      );
    })
    .attr("transform", function (d) {
      return "translate(" + xScale(d.x) + ",0)";
    })
    .transition(context)
    .call(barChart, optionsGroup);

  var groupExit = group.exit();
  if (hasInheritedTransition) {
    // animate the bars to 0 value and remove the wrapper group element after that
    groupExit
      .selectAll(".js-chart-bar")
      .transition(context)
      .attr("height", 0)
      .attr("y", options.yScale(0))
      .style("opacity", 0);

    groupExit.transition(context).remove();

    group = group.transition(context);
  } else {
    groupExit.remove();
  }

  group
    .attr("transform", function (d) {
      return "translate(" + xScale(d.x) + ",0)";
    })
    .call(barChart, optionsGroup);
}

/**
 * Draws bar shapes.
 *
 * @param {Object}          options           Initialization options.
 * @param {Function|Number} options.x         If `x` is specified, sets the x accessor to the specified function or number.
 * @param {Function|Number} options.y         If `y` is specified, sets the y accessor to the specified function or number.
 * @param {Function}        options.xScale    The X scale.
 * @param {Function}        options.yScale    The Y scale.
 * @param {Function}        [options.key]     A key function may be specified to control which datum is assigned to
 *                                            which element, replacing the default join-by-index.
 *                                            This is done to maintain the object constancy, where a graphical element
 *                                            that represents a particular data point can be tracked visually through
 *                                            the transition.
 *                                            Please read [Joining Data]{@link https://github.com/d3/d3-selection/blob/master/README.md#joining-data} for more details.
 * @param {Function|Array}  [options.stacks]  Defines the datum keys to be used for building stacks for stackable bar graph.
 *                                            `stacks` is an array of keys which map data points to particular stacks.
 *                                            Stack keys are typically strings, but they may be arbitrary values.
 *                                            If `stacks` is a function, it must return an array of stack keys.
 * @param {Array}           [options.group]   Defines the keys to be included in the group of bars.
 * @param {Boolean}   [options.horizontalOrientation]  Renders the chart in horizontal direction. Default `false`.
 * @param {Boolean}   options.showBarValue    If true, this renders the value for each bar at the top of the bar. This is disabled when horizontalOrientation is true.
 * @param {Function}  options.barValueFormat  Function which specifies how the bar values should be formatted. Must take a single value and return a single formatted value. Optional.
 *
 * @returns {Function}                        A new bar series.
 */
export default function factory(options) {
  options = options || {};

  var shape;
  if (options.stacks && options.group) {
    shape = groups;
  } else if (options.stacks) {
    shape = stacks;
  } else if (options.horizontalOrientation) {
    shape = horizontalBars;
  } else if (options.group) {
    shape = groups;
    options.waitExit = true;
  } else {
    shape = bars;
  }

  function series(context, opts) {
    options = Object.assign(options, opts);
    var hasInheritedTransition = context instanceof transition;
    var selection = hasInheritedTransition ? context.selection() : context;

    var series = selection.selectAll(".js-chart-series").data(function (d) {
      return d;
    });

    series
      .enter()
      .append("g")
      .attr("class", function (datum, index) {
        return (
          "js-chart-series chart__series " +
          CLASS_NAME_SERIES +
          " " +
          CLASS_NAME_SERIES +
          "-" +
          ++index
        );
      })
      .transition(context)
      .call(shape, options);

    series.exit().remove();

    if (hasInheritedTransition) {
      series = series.transition(context);
    }
    series.call(shape, options);
  }

  //------------------------------------------------------------
  //  PUBLIC
  //------------------------------------------------------------

  /**
   * Set/get x value accessor.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {series|Function}      If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  series.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    return series;
  };

  /**
   * Set/get y value accessor.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {series|Function}      If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  series.y = function (accessor) {
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    return series;
  };

  /**
   * Set/get the scale of Y axis.
   *
   * @param  {Array} [scale=null]  the scale.
   * @return {series|Function}      If `scale` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `xScale` value.
   */
  series.xScale = function (scale) {
    if (!arguments.length) {
      return options.xScale;
    }

    options.xScale = scale;
    return series;
  };

  /**
   * Set/get the scale of Y axis.
   *
   * @param  {Array} [scale=null]   the Y scale
   * @return {series|Function}      If `scale` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `yScale` value.
   */
  series.yScale = function (scale) {
    if (!arguments.length) {
      return options.yScale;
    }

    options.yScale = scale;
    return series;
  };

  /**
   * Set/get the key function.
   *
   * @param  {Function} [key=null]  The key function.
   * @return {series|Function}      If `key` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `key` value.
   */
  series.key = function (key) {
    if (!arguments.length) {
      return options.key;
    }

    options.key = key;
    return series;
  };

  /**
   * Set/get the stacks for bar chart.
   *
   * @param  {Array|Function} [stacks]  The `stacks` accessor function or an array.
   * @return {chart|Function}           If `stacks` is specified, returns `this` reference for chaining,
   *                                    otherwise returns the current `stacks` value.
   */
  series.stacks = function (stacks) {
    if (!arguments.length) {
      return options.stacks;
    }

    options.stacks = stacks;
    return series;
  };

  /** Specifies the horizontal orientation for chart axes.
   *
   *
   * @param  {Boolean} [flag=true]  the flag controlling the horizontal orientation.
   * @return {chart|Boolean}        If `flag` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `flag` value.
   */
  series.horizontalOrientation = function (flag) {
    if (!arguments.length) {
      return options.horizontalOrientation;
    }

    options.horizontalOrientation = flag;
    return series;
  };

  return series;
}
