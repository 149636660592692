import PropTypes from "prop-types";
import React from "react";
import Row from "libs/pcap/table/Row";
import HeaderRow from "libs/pcap/table/HeaderRow";
import SummaryRow from "libs/pcap/table/SummaryRow";
import GroupHeaderRow from "libs/pcap/table/GroupHeaderRow";
import { groupBy as underscoreGroupBy, isString, isFunction } from "underscore";

const MAIN_NAV_HEIGHT = 60;
const ADVISOR_NAV_HEIGHT = 100;
const EMPOWER_NAV_HEIGHT = 0;
/**
 * Basic table component for displaying tabular data. It only capable of displaying the data and provides callbacks
 * to implement sorting and other features.
 *
 * Look at `TableClient` for client-side sorting capabilities.
 *
 * Usage example:
 ```
 <Table columns={Array} data={Array} onRowClick={Function} onRequestSort={Function} />
 ```
 *
 *
 * ## Styles
 * Table component is implemented using flex-box. Because of that, a desired `flex-grow` (usually `flex-grow: 1`)
 * value should be provided for the flexible columns which don't have the specific width value.
 *
 *
 * @param {Object} props - consists of the following:
 *
 *  - `columns` {Array} - Array of columns definitions.
 *    Format:
 ```
 {
   header: String,
   accessor: Function,
   headerClassName: String,        // optional, if not provided, we fall back to className || ''
   className: String,              // optional
   summaryClassName: String,        // optional, if not provided, we fall back to className || ''
   isSortable: Boolean,            // optional
   sortOrder: String ('asc|desc'), // optional
   formatter: Function,            // optional
   summaryAccessor: Function,       // optional
   summaryFormatter: Function,      // optional
   groupRowAccessor: Function,       // optional
   groupRowFormatter: Function,      // optional
 }
 ```
 *      - `header`      - Column header
 *
 *      - `accessor`    - Accessor function which will be called on each row to retrieve
 *                        the data point for displaying in the cell.
 *                        The accessor receives the row object as the argument.
 *        Example:
 *        ```
 *         d => d.firstName
 *        ```
 *
 *      - `formatter`   - Formatter function which receives the value associated with the column and returns the
 *                        formatted representation. For example, to format a currency value.
 *                        Supported formatters:
 *                        - Regular function returning text.
 *                        - React component.
 *      - `summaryAccessor` - Accessor function which will be called on the summary row to retrieve
 *                        the data to display in the summary cell.
 *                        The accessor receives the entire dataset as the argument.
 *        Example:
 *        ```
 *         dataSet => dataSet.reduce((total, dataRow) => total + dataRow.income, 0)
 *        ```
 *
 *      - `summaryFormatter` - Formatter function which receives the value associated with the column and returns the
 *                        formatted representation. This formatter executes when rendering the specific summary row cell of this column.
 *                        For example, to format a currency value. Supported formatters:
 *                        - Regular function returning text.
 *                        - React component.
 *
 *       - `groupRowAccessor` - Accessor function which will be called on the group row to retrieve
 *                        the data to display in the group row cell for the column. Used only
 *                        when you want to override the default group row layout
 *
 *       - `groupRowFormatter` - Formatter function which receives the value for the group row summary
 *                        and returns the formatted output. Used only when you have supplied a groupRowAccessor for the column.
 *
 *      - `headerClassName`- Class name string to be set on the header cell of this column.*
 *
 *      - `className`   - Class name string to be set on the cell element.
 *
 *      - `summaryClassName`- Class name string to be set on the summary cell of this column.
 *
 *      - `style`       - Styles to be set on the cell element.
 *
 *      - `isSortable`  - Boolean flag indicating that the cell is sortable. It enables the visual indication of the sort
 *                        order in the header and enables sort click handler.
 *                        NOTE: Setting the flag doesn't enable the sort by itself. The client must sort `data` array in
 *                              the parent container in response to `onRequestSort` callback.
 *
 *      - `sortOrder`   - The default sort order for a sortable column. Possible values: 'asc', 'desc'.
 *                        The sort order must be provided when at least one column is set to be sortable.
 *                        Only one column can have a default sort order.
 *
 *  - `groupBy` {String|Function} - If passed the row groups will have a header with the group name and the number of rows grouped under that group.
 *                                  - {String} shallow attribute on the iteratee object to groupBy from
 *                                  - {Function} function which returns a value to group for
 *
 *  - `groupByClassName` (String|Function) - If passed in, applies a class to each group by header row
 *                                  - {String} single classname to apply to all groupBy rows
 *                                  - {Function} function which returns a className based on the group name
 *
 *  - `getGroupByValue` {Function} - A function to format the value in the groupBy row. Default `(ROW COUNT FOR GROUP)`
 *  - `rowClassName` {String|Function}
 *                                  - {String} className to be given to every row
 *                                  - {Function} takes (datum, index), run for each row, returns string className
 *  - `headerRowClassName` {String} - className to be given to the header row
 *
 *  - `summaryRowClassName` {String} - className to be given to the summary row
 *
 *  - `summaryRowPosition` {String} - Position of the summary row. Defaults to 'BOTTOM'. 'TOP' will place it immediately beneath the main header row. If no summaryAccessors are
 *                                    defined, this will be ignored.
 *
 *  - `rowDataAttributes` {Object|Function}
 *                                  - {Object} object to be given to every row
 *                                  - {Function} takes (datum, index), run for each row, returns object to be given to the row
 *  - `data` {Array}                - Array of objects in arbitrary format which will be mapped to table rows.
 *                                    Column accessor function retrieves the data points for displaying in the cells.
 *  - `onRowClick` {Function}       - Row click callback.
 *                                    Receives the following arguments:
 *                                    1. `ev` - original event
 *                                    2. `data` - data associated with the row
 *                                    3. `index` - zero-based row index
 *  - `onRequestSort` {Function}    - Request sort callback. The original `data` array must be sorted in the handler.
 *                                    Receives the following arguments:
 *                                    1. `column` - column definition associated with the clicked column
 *                                    2. `order` - requested sort order (`asc`, `desc`)
 *  - `hasStickyHeader` {Boolean}   - When true, the table header will be fixed to the top of the page.
 *  - `stickyHeaderOffset` {Number|String} - Determines the header's offset from the top of the screen. Use a string to specify a percent. When `hasStickyHeader` is false, this parameter has no effect.
 *  - `stickyHeaderWidth` {Number|String}  - Determines fixed sticky header width. Use a string to specify a percent. When `hasStickyHeader` is false, this parameter has no effect.
 *  - `Row` {React|Function}               - A custom row implementation. Can be used to support edit mode.
 *                                         Receives `props` object as an argument:
 *                                           - `className`
 *                                           - `columns`
 *                                           - `data`
 *                                           - `index`
 *                                           - `onClick`
 *                                           - `dataAttributes`
 *  - `keyAccessor` {Function}      - Accessor function to determine the unique id that will be used as a row key for the data provided.
 *                                  eg: keyAccessor(d) => d.userTransactionId for transaction data set.
 * @returns {Object} React Component
 */

function Table(props) {
  const {
    groupBy,
    groupByClassName,
    getGroupByValue,
    groupByOrder,
    columns,
    data,
    onRowClick,
    onRequestSort,
    rowClassName,
    rowTitle,
    headerRowClassName,
    summaryRowClassName,
    rowDataAttributes,
    Row,
    hasStickyHeader,
    stickyHeaderOffset,
    stickyHeaderWidth,
    keyAccessor,
    summaryRowPosition,
    onColumnFilterOpen,
    onColumnFilterChange,
    zeroState,
  } = props;
  const className = props.className || "";
  const rowRenderer = (data) => {
    return data.map((d, i) => {
      let stringClassName;
      if (typeof rowClassName === "function") {
        stringClassName = rowClassName(d, i);
      } else {
        stringClassName = rowClassName;
      }
      let title;
      if (typeof rowTitle === "function") {
        title = rowTitle(d, i);
      } else {
        title = rowTitle;
      }

      let dataAttributes;
      if (typeof rowDataAttributes === "function") {
        dataAttributes = rowDataAttributes(d, i);
      } else {
        dataAttributes = rowDataAttributes;
      }
      const rowProps = {
        key: typeof keyAccessor === "function" ? keyAccessor(d) : i,
        columns,
        onClick: onRowClick,
        data: d,
        index: i,
        className: stringClassName,
        dataAttributes,
        title,
      };

      return <Row {...rowProps} />; /* key is defined */ // eslint-disable-line react/jsx-key
    });
  };

  const groupRenderer = (data, tableColumns, groupByOrder) => {
    let rows = [];
    let groups = underscoreGroupBy(data, groupBy);
    groups = groupByOrder ? groupByOrder(groups) : groups;
    let headerRowClassName = "";

    for (let [groupName, accountsInGroup] of Object.entries(groups)) {
      if (typeof groupByClassName === "function") {
        headerRowClassName = groupByClassName(accountsInGroup);
      } else if (typeof groupByClassName === "string") {
        headerRowClassName = groupByClassName;
      }

      const hasGroupColumns = tableColumns.some((c) => c.groupRowAccessor);

      const groupRowProps = {
        index: rows.length,
        className: headerRowClassName,
        text: groupName,
      };

      if (hasGroupColumns) {
        // Per-column group summaries have been defined as part of the table instance
        groupRowProps.data = accountsInGroup;
        groupRowProps.columns = columns;
        groupRowProps.hasCustomColumns = true;
      } else {
        // Default treatment, Show a text label and one summary value
        groupRowProps.text = groupName;
        groupRowProps.value = getGroupByValue(accountsInGroup);
      }

      rows.push(<GroupHeaderRow key={rows.length} {...groupRowProps} />);
      rows.push(rowRenderer(accountsInGroup));
    }
    return rows;
  };

  let zeroStateContent = null;
  if (!(data && data.length)) {
    if (typeof zeroState === "string") {
      zeroStateContent = <strong>{zeroState}</strong>;
    } else if (typeof zeroState === "function") {
      zeroStateContent = zeroState(props);
    } else {
      zeroStateContent = zeroState;
    }
  }
  const getTableBody = (isSummaryRowAtBottom) => {
    if (data && data.length) {
      return (
        <>
          <div role="rowgroup" className="table__body qa-datagrid-rows">
            {isFunction(groupBy) || isString(groupBy)
              ? groupRenderer(data, columns, groupByOrder)
              : rowRenderer(data)}

            {isSummaryRowAtBottom && (
              <SummaryRow
                columns={columns}
                className={summaryRowClassName}
                data={data}
              />
            )}
          </div>
        </>
      );
    }
    return (
      <div
        role="rowgroup"
        className="pc-u-mt pc-block--center qa-table-zero-state"
      >
        <div role="row">
          <div
            role="gridcell"
            aria-colspan={columns.length}
            colSpan={columns.length}
          >
            {zeroStateContent}
          </div>
        </div>
      </div>
    );
  };

  const getOffSet = () => {
    if (window.isAdvisorApp) {
      return ADVISOR_NAV_HEIGHT;
    }

    if (IS_EMPOWER) {
      return EMPOWER_NAV_HEIGHT;
    }

    return MAIN_NAV_HEIGHT;
  };
  const hasSummaryRow = columns.some((col) => col.summaryAccessor);
  const isSummaryRowAtTop = hasSummaryRow && summaryRowPosition === "TOP";
  const isSummaryRowAtBottom = hasSummaryRow && summaryRowPosition === "BOTTOM";
  const offset = stickyHeaderOffset || getOffSet();
  // header row, data legnth ot zero state row and optional summary row are considered in the row count calculation
  let ariaRowCount;
  if (data && data.length) {
    ariaRowCount = data.length + 1 + (hasSummaryRow ? 1 : 0);
  } else {
    ariaRowCount = 2;
  }

  return (
    <div
      role="grid"
      aria-rowcount={ariaRowCount}
      className={`table table--hoverable${
        hasStickyHeader ? " table--overflow-visible" : ""
      } ${className}`}
    >
      <HeaderRow
        columns={columns}
        className={headerRowClassName}
        onCellClick={onRequestSort}
        onColumnFilterOpen={onColumnFilterOpen}
        onColumnFilterChange={onColumnFilterChange}
        isSticky={hasStickyHeader}
        offset={offset}
        width={stickyHeaderWidth}
      />
      {isSummaryRowAtTop && (
        <SummaryRow
          columns={columns}
          className={summaryRowClassName}
          data={data}
        />
      )}
      {getTableBody(isSummaryRowAtBottom)}
    </div>
  );
}

Table.propTypes = {
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowDataAttributes: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  groupBy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  groupByClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  getGroupByValue: PropTypes.func,
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  hasStickyHeader: PropTypes.bool,
  stickyHeaderOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stickyHeaderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  summaryRowPosition: PropTypes.oneOf(["BOTTOM", "TOP"]),
  onRowClick: PropTypes.func,
  onColumnFilterOpen: PropTypes.func,
  onColumnFilterChange: PropTypes.func,
  onRequestSort: PropTypes.func,
  Row: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  headerRowClassName: PropTypes.string,
  summaryRowClassName: PropTypes.string,
  keyAccessor: PropTypes.func,
  groupByOrder: PropTypes.func,
  zeroState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
};

Table.defaultProps = {
  rowClassName: "",
  rowTitle: undefined,
  hasStickyHeader: false,
  rowDataAttributes: undefined,
  groupBy: undefined,
  groupByClassName: "",
  data: undefined,
  stickyHeaderOffset: undefined,
  stickyHeaderWidth: undefined,
  onRowClick: undefined,
  onRequestSort: undefined,
  onColumnFilterOpen: undefined,
  onColumnFilterChange: undefined,
  className: "",
  headerRowClassName: "",
  summaryRowClassName: "",
  keyAccessor: undefined,
  groupByOrder: undefined,
  Row: Row,
  getGroupByValue: (group) => `(${group.length})`,
  summaryRowPosition: "BOTTOM",
  zeroState: "No data rows match the current criteria.",
};

export default Table;
