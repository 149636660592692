/* eslint-disable no-magic-numbers, no-extra-parens */
import { COLOR_SCALE_OLD } from "libs/pcap/utils/colors";

let confs, ctx, width, height;

const SIZE = 1;

function Confetti() {
  const colours = [
    COLOR_SCALE_OLD.CURIOUS_BLUE,
    COLOR_SCALE_OLD.TANGERINE_YELLOW,
    COLOR_SCALE_OLD.BERMUDA,
  ];

  this.x = Math.round(Math.random() * width);
  this.y = Math.round(Math.random() * height) - height / 2;
  this.rotation = Math.random() * 360;

  const size = Math.random() * (width / 90);
  this.size = size < SIZE ? SIZE : size;

  this.color = colours[Math.floor(colours.length * Math.random())];
  this.speed = this.size / 5;
  this.opacity = Math.random();
  this.shiftDirection = Math.random() > 0.5 ? 1 : -1;
}

Confetti.prototype.border = function () {
  if (this.y >= height) {
    this.y = height;
  }
};

Confetti.prototype.update = function () {
  this.y += this.speed;

  if (this.y <= height) {
    this.x += this.shiftDirection / 5;
    this.rotation += (this.shiftDirection * this.speed) / 100;
  }

  this.border();
};

Confetti.prototype.draw = function () {
  ctx.beginPath();
  ctx.arc(
    this.x,
    this.y,
    this.size,
    this.rotation,
    this.rotation + Math.PI / 2
  );
  ctx.lineTo(this.x, this.y);
  ctx.closePath();
  ctx.globalAlpha = this.opacity;
  ctx.fillStyle = this.color;
  ctx.fill();
};

function loop() {
  requestAnimationFrame(loop);
  ctx.clearRect(0, 0, width, height);

  confs.forEach((conf) => {
    conf.update();
    conf.draw();
  });
}

const FallingConfetti = ({
  canvasWidth = 822,
  canvasHeight = 153,
  selector = "#FallingConfettiCanvas",
} = {}) => {
  const canvasEl = document.querySelector(selector);

  width = canvasEl.width = canvasWidth;
  height = canvasEl.height = canvasHeight * 2;
  ctx = canvasEl.getContext("2d");
  const confNum = Math.floor(width);

  confs = new Array(confNum).fill().map(() => new Confetti());

  loop();
};

export default FallingConfetti;
