var TRILLION = 1e12;
var BILLION = 1e9;
var MILLION = 1e6;
var THOUSAND = 1e3;

var FRACTION_PRECISION = 2;

function formatTrillion(value) {
  return value / TRILLION + "T";
}

function formatBillion(value) {
  return value / BILLION + "B";
}

function formatMillion(value) {
  return value / MILLION + "M";
}

function formatThousand(value) {
  return value / THOUSAND + "K";
}

export default function (value) {
  value = parseFloat(value.toFixed(FRACTION_PRECISION));
  var sign = value < 0 ? "-" : "";
  value = Math.abs(value);
  var format = function (value) {
    return value;
  };

  if (value >= TRILLION) {
    format = formatTrillion;
  } else if (value >= BILLION) {
    format = formatBillion;
  } else if (value >= MILLION) {
    format = formatMillion;
  } else if (value >= THOUSAND) {
    format = formatThousand;
  }

  value = format(value);
  return sign + "$" + value;
}
