/* eslint-disable react/require-default-props */
import React, { Component } from "react";
import PropTypes from "prop-types";
import eventBus from "eventBus";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import { US_TIMES } from "enums/usTimes";
import { convertAppointmentsToTimezone, guessUserTimezone } from "../utils";
import { isEmpty } from "underscore";
import AppointmentTiles from "components/scheduling/AppointmentTiles";
import { trackEvent } from "components/common/ComponentAnalytics";
import {
  AB_TEST_GROUP_A,
  AB_TEST_GROUP_B,
} from "libs/pcap/utils/getABTestGroupForCurrentUser";

const AVAILABILITIES_TO_SHOW = 3;

function getAvailabilitiesForTimezone(availabilities, timezone) {
  if (timezone) {
    return convertAppointmentsToTimezone(availabilities, timezone);
  }
}

// Convert availabilities to user's timezone.
function getDerivedStateFromAvailabilities(props, state = {}) {
  const { availabilities } = props;
  if (availabilities !== state.prevPropsAvailabilities) {
    const availabilitiesInTz = getAvailabilitiesForTimezone(
      availabilities,
      state.timezone
    );
    return {
      availabilities: availabilitiesInTz,
      prevPropsAvailabilities: availabilities,
    };
  }
  return null;
}

class ThreeTimeSlotsAppointmentSelector extends Component {
  static getDerivedStateFromProps(props, state) {
    const derivedState = {};

    if (props.appointmentId !== state.appointmentId) {
      derivedState.appointmentId = props.appointmentId;
    }

    Object.assign(
      derivedState,
      getDerivedStateFromAvailabilities(props, state)
    );

    if (isEmpty(derivedState)) {
      return null;
    }
    return derivedState;
  }

  constructor(props) {
    super(props);

    this.state = {
      timezone: props.timezone || guessUserTimezone(),
      availabilities: props.availabilities,
      appointmentId: "",
    };

    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleTimezoneChange({ target }) {
    const { availabilities, eventTrackingSharedProperties, componentName } =
      this.props;
    const timezone = target.value;
    const convertedAvailabilities = convertAppointmentsToTimezone(
      availabilities,
      timezone
    );
    this.setState({
      timezone,
      availabilities: convertedAvailabilities,
    });
    trackEvent(
      componentName,
      "Select Time Zone",
      Object.assign({}, eventTrackingSharedProperties, {
        timeZone: target.value,
      })
    );

    this.triggerChange({ appointmentId: null, timezone });
  }

  triggerChange(appointment) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(appointment);
    }
  }

  handleSelect(appt) {
    const { onChange } = this.props;

    this.setState({
      appointmentId: appt.appointmentId,
    });

    appt.timezone = this.state.timezone;

    if (onChange) {
      onChange(appt);
    }
  }

  componentDidMount() {
    const { availabilities, appointmentId } = this.state;
    const { hasOnboardingAppointmentPage, referralOnboardingTest } = this.props;

    if (
      (hasOnboardingAppointmentPage || referralOnboardingTest) &&
      availabilities?.length > 2 &&
      !appointmentId
    ) {
      // the middle slot is selected by default
      this.handleSelect(availabilities[1]);
    }
    eventBus.on("firstUseSignOut:clicked", () => {
      if (window.referralOnboardingTest === AB_TEST_GROUP_A) {
        trackEvent("Appointment Scheduler Modal Ref_On", "Sign Out");
      } else if (window.referralOnboardingTest === AB_TEST_GROUP_B) {
        trackEvent("Appointment Scheduler Modal Ref_Ont", "Sign Out");
      } else {
        trackEvent("Appointment Scheduler Modal", "Sign Out");
      }
    });
  }

  componentWillUnmount() {
    eventBus.off("firstUseSignOut:clicked");
  }

  render() {
    const { timezone, availabilities, appointmentId } = this.state;

    const firstAvailabilities = isEmpty(availabilities)
      ? availabilities
      : availabilities.slice(0, AVAILABILITIES_TO_SHOW);

    const zeroState =
      this.props.loading || !timezone ? null : (
        <div className="three-time-slots-appointment-selector__tile-container qa-three-time-slots-appointment-selector__tile-container u-text-center pc-u-mt++">
          <h2>No time slots available</h2>
        </div>
      );

    return (
      <div className="three-time-slots-appointment-selector qa-three-time-slots-appointment-selector">
        <div className="three-time-slots-appointment-selector__timezone-select pc-u-1/6">
          <FancySelect
            name="timezone"
            placeholder="Select your timezone..."
            ariaLabel="Select your timezone..."
            value={timezone}
            options={US_TIMES}
            maxMenuHeight={300}
            onChange={this.handleTimezoneChange}
            className="pc-u-mb- Select--round-bordered Select--mini qa-three-time-slots-appointment-selector__timezone-select"
          />
        </div>
        {isEmpty(firstAvailabilities) || !timezone ? (
          zeroState
        ) : (
          <div className="three-time-slots-appointment-selector__tile-container three-time-slots-appointment-selector__tile-container--responsive qa-three-time-slots-appointment-selector__tile-container">
            <AppointmentTiles
              onSelect={this.handleSelect}
              appointmentId={appointmentId}
              appointments={firstAvailabilities}
              eventTrackingSharedProperties={
                this.props.eventTrackingSharedProperties
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default ThreeTimeSlotsAppointmentSelector;

ThreeTimeSlotsAppointmentSelector.propTypes = {
  onChange: PropTypes.func,
  availabilities: PropTypes.array,
  timezone: PropTypes.string,
  appointmentId: PropTypes.string,
  eventTrackingSharedProperties: PropTypes.object,
  componentName: PropTypes.string,
  loading: PropTypes.bool,
  hasOnboardingAppointmentPage: PropTypes.bool,
  referralOnboardingTest: PropTypes.string,
};

ThreeTimeSlotsAppointmentSelector.defaultProps = {
  availabilities: [],
  hasOnboardingAppointmentPage: false,
  referralOnboardingTest: "",
};
