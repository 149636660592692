import { pluck, isEmpty } from "underscore";

export const ERROR_GENERIC =
  "There seems to be a problem. Our engineering team has been alerted and will be working to fix this soon.";

/**
 * Parse the response of the Services call
 * @param {object} err - the Error from the HttpRequest
 * @param {object} response - The response object.
 * @returns {array|null} an array that has a severity and messages property
 */
export default function parseResponseErrors(err, response) {
  // HTTP errors
  if (err || !response) {
    return [ERROR_GENERIC];
  }

  // The response was marked as unsuccessful by the server without providing the errors.
  if (
    response.spHeader &&
    response.spHeader.success === false &&
    isEmpty(response.spHeader.errors)
  ) {
    return [ERROR_GENERIC];
  }

  if (!(response.spHeader && response.spHeader.errors)) {
    return null;
  }
  const errorMessages = pluck(response.spHeader.errors, "message");
  if (errorMessages.length) {
    // Backend server responds with error messages
    return errorMessages;
  }
  return null;
}

export function findErrorByCode(response, errorCode) {
  if (!response || !(response.spHeader && response.spHeader.errors)) {
    return;
  }
  return response.spHeader.errors.find((error) => {
    return error.code === errorCode;
  });
}
