import _ from "underscore";
import PropTypes from "prop-types";
import React from "react";
import { select } from "d3";
import PcapChart from "libs/pcap/chart/chart";

const propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  ariaDesc: PropTypes.string,
  tabIndex: PropTypes.number,
  disableAnimations: PropTypes.bool,
  keyFunc: PropTypes.func,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  tabpanel: PropTypes.bool,
};

function getChartOptions(options) {
  let props = _.omit(options, Object.keys(propTypes));
  props.key = options.keyFunc;
  return props;
}

/**
 * @param {object} options - component props
 * @returns {PcapChart} chart instance
 */
function initializeChart(options) {
  return PcapChart(getChartOptions(options));
}

/**
 * React wrapper for D3 chart library `scripts/libs/pcap/chart/chart.js`
 *
 * @class ChartReact
 * @extends {React.Component}
 */
export default class ChartReact extends React.Component {
  /**
   * Post-initialization and render operations to set up d3 graph
   */
  componentDidMount() {
    this.chart = initializeChart(this.props);
    this.renderChart();
  }

  /**
   * Post-update operations to re-do up d3 graph
   */
  componentDidUpdate() {
    this.updateChart();
  }

  /**
   * Destroy chart
   */
  componentWillUnmount() {
    this.chart.destroy();
  }

  /**
   * Render the D3 chart
   */
  renderChart() {
    const { data, disableAnimations } = this.props;
    let selection = select(this.container).datum(data);

    if (disableAnimations) {
      selection = selection.transition().duration(0);
    }
    selection.call(this.chart);
  }

  /**
   * Update the D3 chart
   */
  updateChart() {
    this.chart.option(getChartOptions(this.props));
    this.renderChart();
  }

  render() {
    let className = "svg-chart " + (this.props.className || "");
    const { onClick, onKeyDown, ariaLabel, ariaDesc, tabIndex, id } =
      this.props;
    const ariaTitleID = `chart-${id}-title`;
    const ariaDescID = `chart-${id}-desc`;
    const conditionalProps = {};
    if (id) conditionalProps.id = id;
    if (ariaLabel) conditionalProps["aria-labelledby"] = ariaTitleID;
    if (ariaDesc) conditionalProps["aria-describedby"] = ariaDescID;
    if (this.props.tabpanel) conditionalProps.role = "tabpanel";

    return (
      <svg
        width="100%"
        height="100%"
        onClick={onClick}
        onKeyDown={onKeyDown}
        alt={ariaLabel}
        tabIndex={tabIndex}
        className={className}
        ref={(container) => {
          this.container = container;
        }}
        {...conditionalProps}
      >
        {ariaLabel && <title id={ariaTitleID}>{ariaLabel}</title>}
        {ariaDesc && <desc id={ariaDescID}>{ariaDesc}</desc>}
      </svg>
    );
  }
}

ChartReact.propTypes = propTypes;

ChartReact.defaultProps = {
  data: [],
  className: "",
  onClick: _.noop,
  ariaLabel: "",
  ariaDesc: "",
  tabIndex: undefined,
  disableAnimations: null,
  keyFunc: _.noop,
  id: "",
  tabpanel: undefined,
  onKeyDown: _.noop,
};
