/* eslint-disable camelcase, sonarjs/cognitive-complexity */
/* cSpell:disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  isEmpty,
  sortBy,
  isUndefined,
  isObject,
  noop,
  first,
} from "underscore";
import AppointmentWizard from "../AppointmentWizard";
import Services from "services";
import LoadingOverlay from "components/common/LoadingOverlay";
import { promisify } from "utils/service";
import moment from "moment";
import { API_FORMAT } from "libs/pcap/utils/date2";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";
import appointmentTypesToClient from "accessors/appointmentTypes/mappers/toClient";
import appointmentsToClient from "accessors/appointments/mappers/toClient";
import appointmentToServer from "accessors/appointments/mappers/toServer";
import availabilitiesToClient from "accessors/availabilities/mappers/toClient";
import { trackClick, trackEvent } from "components/common/ComponentAnalytics";
import eventBus from "eventBus";
import { setSource, clear } from "components/common/attributionStore";
import fireGoogleTagManagerEvent from "libs/pcap/utils/fireGoogleTagManagerEvent";
import {
  newAppointment,
  isNewAppointment,
  getAppointmentTime,
  getEndOfMonth,
  getImageUrl,
  getAdvisor,
  getAppointmentSource,
} from "utils/appointmentUtils";
import {
  getFromSessionStorage,
  getAndRemoveFromSessionStorage,
  isPersonCanadian,
  getNextWeeksAppointments,
  getPrefetchedAvailabilities,
  FIRST_USE_APPT_TYPES,
} from "libs/pcap/utils/customAppointmentSchedulerUtils";
import LimitedAppHeader from "components/common/LimitedAppHeader";
import {
  FORMAT_DAY,
  FORMAT_TIME,
  FORMAT_TIME_TZ,
} from "components/scheduling/utils";
import { isMobileDevice } from "../../../libs/pcap/utils/isMobileDevice";

const DEFAULT_COMPONENT_NAME = "Appointment Scheduler Modal";
const START = moment().add(1, "days");
const PROSPECT_MAX_DAYS = 15;
const PROSPECT_END_DATE = START.clone().add(PROSPECT_MAX_DAYS, "days");
const STEP_INDEX_CONFIRMATION = 2;
const FINANCIAL_PLANNING_TYPE = "FINANCIAL_PLANNING";
const MAX_GET_AVAILABILITIES_RETRIES = 2;

const MessageForCanadians = () => (
  <p className="js-message-for-canadians pc-u-mt+ pc-u-mb+ pc-u-ml+ pc-u-mr+">
    Our Advisors are licensed in the US only. Please update your phone number to
    a valid US phone number <a href="#/settings">here</a> before scheduling a
    call.
  </p>
);
const NUM_PROSPECT_SLOTS = 3;

const AUTH_API = {
  fetchPerson: promisify(Services.Person.get),
  fetchAvailabilities: promisify(Services.Appointment.getAvailabilities),
  fetchAppointmentTypes: promisify(Services.Appointment.getEligibleMeetings),
  fetchAppointments: promisify(Services.Appointment.get),
  createAppointment: promisify(Services.Appointment.create),
  updateAppointment: promisify(Services.Appointment.update),
  cancelAppointment: promisify(Services.Appointment.cancel),
  fetchAdvisor: promisify(Services.Advisor.get),
  fetchFPTopics: promisify(Services.Appointment.getFpTopics),
};

export const LIMITED_API = {
  fetchPerson: promisify(Services.Limited.getProfile),
  fetchAvailabilities: promisify(Services.Limited.getAvailabilities),
  fetchAppointmentTypes: promisify(Services.Limited.getEligibleMeetings),
  fetchAppointments: promisify(Services.Limited.getAppointments),
  createAppointment: promisify(Services.Limited.createAppointment),
  updateAppointment: promisify(Services.Limited.updateAppointment),
  cancelAppointment: promisify(Services.Limited.cancelAppointment),
  fetchAdvisor: promisify(Services.Limited.getAdvisor),
  fetchFPTopics: Promise.resolve({ topics: [] }),
};

export let API = AUTH_API;

export default class AppointmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appointment: newAppointment(),
      appointmentTypes: [],
      availabilitiesStartDate: START,
      availabilitiesEndDate: props.isClient
        ? getEndOfMonth(START)
        : PROSPECT_END_DATE,
      step: 0,
      loading: true,
      initialized: false,
      isThreeTimeSlotsMode: false,
      numGetAvailabilitiesRetries: 0,
      eventTrackingSharedProperties: {},
      calendarMonth: START,
      cachedAvailabilities: new Map(),
    };

    this.isAppointmentSaving = false;
    if (props.isLimited) {
      API = LIMITED_API;
    } else {
      API = AUTH_API;
    }

    this.handleAppointmentTypeChange =
      this.handleAppointmentTypeChange.bind(this);
    this.handleAppointmentChange = this.handleAppointmentChange.bind(this);
    this.handleAppointmentSave = this.handleAppointmentSave.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleAppointmentCancel = this.handleAppointmentCancel.bind(this);
    this.handleAppointmentReschedule =
      this.handleAppointmentReschedule.bind(this);
    this.handleAppointmentKeep = this.handleAppointmentKeep.bind(this);
    this.handleAppointmentMoreTimes =
      this.handleAppointmentMoreTimes.bind(this);
    this.handleFPTopicSelection = this.handleFPTopicSelection.bind(this);
    this.handleStartOver = this.handleStartOver.bind(this);
    this.getCustomContentData = this.getCustomContentData.bind(this);
    this.onFetchAll = this.onFetchAll.bind(this);
    this.handleFirstUseAppointment = this.handleFirstUseAppointment.bind(this);
    this.saveAppointmentDataInSessionStorage =
      this.saveAppointmentDataInSessionStorage.bind(this);
  }

  componentDidMount() {
    const { advisorData } = this.props;
    if (this.handleFirstUseAppointment()) {
      return;
    }

    let requests = [API.fetchPerson(), API.fetchAppointmentTypes()];
    // Don't call fetchAppointments unless the user has an advisor (throws an error)
    if (!["AWAITING_ASSIGNMENT", "NOT_ASSIGNED"].includes(advisorData.status)) {
      requests.push(API.fetchAppointments());
    }
    this.fetchAll = makeCancelablePromise(Promise.all(requests));

    this.fetchAll.promise.then(this.onFetchAll, (errors) => {
      if (this.fetchAll.isCanceled()) {
        return;
      }

      this.setState({
        loading: false,
        initialized: true,
        errors: errors,
      });
    });
  }

  onFetchAll([person, { appointmentTypes }, appointmentsResponse]) {
    const { isClient, advisorData, source, isLimited } = this.props;
    const {
      showDatePicker,
      isFirstUseAppointmentInterjection,
      isMadLibsInterjectionReviewModal,
      eventTrackingSharedProperties,
    } = this.getCustomContentData();

    let { step } = this.state;
    let loading = false;
    let appointment = newAppointment(
      person,
      isLimited,
      this.props.type,
      this.props.duration,
      this.props.subtypes
    );
    const clientApptTypes = appointmentTypesToClient(appointmentTypes);
    const isCanadian = isPersonCanadian(person);
    const appendToState = {};
    if (this.props.type && this.props.duration) {
      loading = true;
      this.handleAppointmentTypeChange({
        type: this.props.type,
        duration: this.props.duration,
      });
    }

    if (appointmentsResponse && !isEmpty(appointmentsResponse.appointments)) {
      step = STEP_INDEX_CONFIRMATION;
      appointment = Object.assign(
        appointment,
        appointmentsToClient(appointmentsResponse.appointments)[0]
      );
    } else if (!isClient && !isCanadian) {
      appointment.appointmentType =
        appointment.appointmentType || clientApptTypes[0].value;
      appointment.duration =
        appointment.duration || clientApptTypes[0].duration;

      const {
        prefetchedFirstUseAvailabilities,
        prefetchedNextWeeksAvailabilities,
      } = getPrefetchedAvailabilities();

      if (
        isFirstUseAppointmentInterjection &&
        prefetchedFirstUseAvailabilities &&
        prefetchedFirstUseAvailabilities.length
      ) {
        trackEvent(
          this.props.componentName,
          "onFetchAll: using prefetchedFirstUseAvailabilities",
          {
            prefetchedFirstUseAvailabilities,
          }
        );
        appendToState.availabilities = availabilitiesToClient(
          sortBy(prefetchedFirstUseAvailabilities, "fromTime")
        );
      } else if (
        isMadLibsInterjectionReviewModal &&
        prefetchedNextWeeksAvailabilities &&
        prefetchedNextWeeksAvailabilities.length
      ) {
        trackEvent(
          this.props.componentName,
          "onFetchAll: using prefetchedNextWeeksAvailabilities",
          {
            prefetchedNextWeeksAvailabilities,
          }
        );
        appendToState.availabilities = availabilitiesToClient(
          sortBy(prefetchedNextWeeksAvailabilities, "fromTime")
        );
      } else {
        loading = true;
        this.fetchAvailabilities({
          appointmentType: appointment.appointmentType,
          duration: appointment.duration,
          limit: 3,
        });
      }
    } else {
      trackEvent(
        this.props.componentName,
        "onFetchAll else: not requesting availabilities",
        {
          isClient,
          isCanadian,
          appointmentsResponse,
        }
      );
    }
    appointment.source = source || getAppointmentSource();
    eventTrackingSharedProperties.source = appointment.source;

    this.setState(
      Object.assign(
        {
          step,
          person,
          appointment,
          loading,
          initialized: true,
          isThreeTimeSlotsMode: !isClient && !showDatePicker,
          advisor: getAdvisor(advisorData),
          appointmentTypes: clientApptTypes,
          isCanadian,
          eventTrackingSharedProperties,
        },
        appendToState
      )
    );
  }

  fetchAvailabilities({
    appointmentType,
    duration,
    availabilitiesStartDate,
    availabilitiesEndDate,
    limit,
  }) {
    this.setState({ loading: true });
    const { cachedAvailabilities } = this.state;
    const originalRequestObject = {
      type: appointmentType,
      duration,
      startDate: availabilitiesStartDate?.format(API_FORMAT),
      endDate: availabilitiesEndDate?.format(API_FORMAT),
      limit,
    };
    const requestObjectToCache = { ...originalRequestObject };
    const pastResponseFromCache = cachedAvailabilities.get(
      JSON.stringify(requestObjectToCache)
    );
    const currentTime = new Date();

    if (
      pastResponseFromCache &&
      currentTime < pastResponseFromCache.cachedRequestExpiration
    ) {
      this.setState({
        availabilities: pastResponseFromCache.cachedRequestAvailabilities,
        loading: false,
      });
    } else {
      API.fetchAvailabilities(originalRequestObject).then(
        ({ appointments = [] }) => {
          appointments = appointments.filter(
            (a) => a.appointmentStatus === "FREE"
          );
          if (isEmpty(appointments) && this.props.isClient) {
            const { numGetAvailabilitiesRetries } = this.state;

            if (numGetAvailabilitiesRetries < MAX_GET_AVAILABILITIES_RETRIES) {
              // change the calendar one month forward if there are no availabilities in the current month
              this.handleMonthChange(
                availabilitiesStartDate.clone().add(1, "month").startOf("month")
              );
              this.setState({
                numGetAvailabilitiesRetries: numGetAvailabilitiesRetries + 1,
              });

              trackEvent(
                this.props.componentName,
                "Client: No availabilities found for current month - Advancing",
                {
                  startDate: availabilitiesStartDate.format(API_FORMAT),
                  endDate: availabilitiesEndDate.format(API_FORMAT),
                  duration,
                  appointmentType,
                }
              );
            } else {
              trackEvent(
                this.props.componentName,
                "Client: No availabilities found for current month - Retry limit reached",
                {
                  startDate: availabilitiesStartDate.format(API_FORMAT),
                  endDate: availabilitiesEndDate.format(API_FORMAT),
                  duration,
                  appointmentType,
                }
              );
              this.setState({
                availabilities: [],
                loading: false,
              });
            }
          } else {
            if (isEmpty(appointments) && !this.props.isClient) {
              trackEvent(
                this.props.componentName,
                "Prospect: No availabilities found",
                {
                  startDate: availabilitiesStartDate?.format(API_FORMAT),
                  endDate: availabilitiesEndDate?.format(API_FORMAT),
                  duration,
                  appointmentType,
                }
              );
            }
            if (
              !this.props.isClient &&
              appointments.length < NUM_PROSPECT_SLOTS
            ) {
              trackEvent(
                this.props.componentName,
                "Prospect: Less than three time slots found",
                {
                  startDate: availabilitiesStartDate?.format(API_FORMAT),
                  endDate: availabilitiesEndDate?.format(API_FORMAT),
                  duration,
                  appointmentType,
                }
              );
            }
            appointments = availabilitiesToClient(
              sortBy(appointments, "fromTime")
            );
            let newState = { availabilities: appointments, loading: false };
            const earliestAppt = first(appointments);
            if (
              earliestAppt &&
              earliestAppt.startTime &&
              earliestAppt.startTime.month() >
                this.state.availabilitiesStartDate.month()
            ) {
              newState.calendarMonth = earliestAppt.startTime;
            }
            let oneMinuteExpirationTimestamp = new Date();
            oneMinuteExpirationTimestamp.setTime(
              // eslint-disable-next-line no-magic-numbers
              oneMinuteExpirationTimestamp.getTime() + 60 * 1000
            );
            const pendingCachedRequestWithExpiration = {
              cachedRequestExpiration: oneMinuteExpirationTimestamp,
              cachedRequestAvailabilities: appointments,
            };
            cachedAvailabilities.set(
              JSON.stringify(requestObjectToCache),
              pendingCachedRequestWithExpiration
            );
            newState.cachedAvailabilities = cachedAvailabilities;
            this.setState(newState);
          }
        },
        (errors) => {
          this.setState({ errors, loading: false });
          trackEvent(this.props.componentName, "fetchAvailabilities: error", {
            errors,
          });
        }
      );
    }
  }

  fetchFPTopics() {
    this.setState({ loading: true });
    API.fetchFPTopics({}).then(
      ({ topics }) => {
        this.setState({
          loading: false,
          fpTopics: isEmpty(topics)
            ? {}
            : topics.reduce((result, cur) => {
                result[cur] = false;
                return result;
              }, {}),
        });
      },
      (errors) => {
        this.setState({ errors, loading: false });
      }
    );
  }

  componentWillUnmount() {
    if (this.fetchAll) {
      this.fetchAll.cancel();
    }
    clear();
  }

  handleFirstUseAppointment() {
    const {
      isFirstUseAppointmentInterjection,
      isMadLibsInterjectionReviewModal,
    } = this.props.customContent || {};
    if (isFirstUseAppointmentInterjection) {
      const person = getFromSessionStorage("firstUsePersonData");
      this.onFetchAll([person, { appointmentTypes: FIRST_USE_APPT_TYPES }]);
      return true;
    }
    const nextWeeksAppts = getNextWeeksAppointments();
    if (isMadLibsInterjectionReviewModal && nextWeeksAppts) {
      const person = getAndRemoveFromSessionStorage("firstUsePersonData");
      window.sessionStorage.setItem(
        "firstUseNextWeeksAvailabilitiesResponse",
        JSON.stringify(nextWeeksAppts)
      );
      this.onFetchAll([person, { appointmentTypes: FIRST_USE_APPT_TYPES }]);
      return true;
    }
  }

  getCustomContentData() {
    const { customContent, componentName } = this.props;
    const {
      isFirstUseAppointmentInterjection,
      data: customContentData,
      isMadLibsInterjectionReviewModal,
    } = customContent;
    const eventTrackingSharedProperties = { component: componentName };
    let showDatePicker = false;

    if (customContent.viewMoreTimesCustomLabel) {
      eventTrackingSharedProperties.viewMoreTimesCustomLabel =
        customContent.viewMoreTimesCustomLabel;
    }

    if (
      !isEmpty(customContentData) &&
      ((isFirstUseAppointmentInterjection && customContentData.show) ||
        !isFirstUseAppointmentInterjection)
    ) {
      eventTrackingSharedProperties.is_mad_libs_qq_interjection =
        isFirstUseAppointmentInterjection;
      eventTrackingSharedProperties.view_template =
        customContentData.attributionSource;
      if (!isUndefined(customContentData.startWithDatePicker)) {
        eventTrackingSharedProperties.start_with_date_picker =
          customContentData.startWithDatePicker;
        showDatePicker = customContentData.startWithDatePicker;
      }
      if (isObject(customContentData.mixpanelEventProps)) {
        Object.assign(
          eventTrackingSharedProperties,
          customContentData.mixpanelEventProps
        );
      }
      if (customContentData.attributionSource) {
        setSource(customContentData.attributionSource);
      }
      if (!this.hasFiredGtmEvent) {
        fireGoogleTagManagerEvent("View QQ Mad Libs Interjection");
        this.hasFiredGtmEvent = true;
      }

      if (
        isFirstUseAppointmentInterjection &&
        !isMadLibsInterjectionReviewModal
      ) {
        window.sessionStorage.setItem(
          "showMadLibsReviewModalOnMadLibsModalClose",
          "1"
        );
      }
    }

    eventBus.trigger(
      // Pass important event properties to AppointmentModal for use by the close modal tracking event
      "eventTrackingSharedProperties",
      eventTrackingSharedProperties
    );

    return {
      showDatePicker,
      isFirstUseAppointmentInterjection,
      isMadLibsInterjectionReviewModal,
      eventTrackingSharedProperties,
    };
  }

  handleAppointmentTypeChange({ type: appointmentType, duration }) {
    // It's not required to update the appointment object with type and duration here.
    // Do that only to be able to access the previously selected appt type and duration
    // in `handleMonthChange`.
    this.updateAppointment({ appointmentType, duration });

    // This clears the current time slot selection when changing appointment types
    // (prevents an error when going back after selecting an appointment slot)
    let { appointment, cachedAvailabilities } = this.state;
    delete appointment.appointmentId;
    this.setState({
      appointment,
    });

    if (appointmentType && duration) {
      cachedAvailabilities.clear();
      this.setState({ cachedAvailabilities });
      const { availabilitiesStartDate, availabilitiesEndDate } = this.state;
      this.fetchAvailabilities({
        appointmentType,
        duration,
        availabilitiesStartDate,
        availabilitiesEndDate,
      });
    } else {
      trackEvent(
        this.props.componentName,
        "handleAppointmentTypeChange: not calling fetchAvailabilities",
        {
          appointmentType,
          duration,
        }
      );
    }
    if (appointmentType === FINANCIAL_PLANNING_TYPE) {
      this.fetchFPTopics();
    }
  }

  handleFPTopicSelection(topics) {
    let { appointment } = this.state;
    appointment.selectedFpTopics = isEmpty(topics)
      ? []
      : Object.keys(topics).filter((key) => topics[key]);
    this.setState({ appointment });
  }

  handleMonthChange(date) {
    const { isClient, isLimited } = this.props;
    const monthStart = moment.isDate(date) ? moment(date) : date;
    let availabilitiesStartDate = monthStart.isBefore(START)
      ? START
      : monthStart;
    let availabilitiesEndDate = getEndOfMonth(availabilitiesStartDate);

    if (!isClient) {
      availabilitiesEndDate = PROSPECT_END_DATE;
    }

    this.setState({
      availabilitiesStartDate,
      availabilitiesEndDate,
      calendarMonth: availabilitiesStartDate,
    });
    const { appointment, person, reschedule } = this.state;
    const { appointmentType, duration, selectedFpTopics } = appointment;

    // reset the currently selected time slot
    if (!reschedule) {
      const newAppt = Object.assign(
        newAppointment(
          person,
          isLimited,
          appointmentType,
          duration,
          selectedFpTopics
        ),
        {
          appointmentType,
        }
      );
      this.setState({ appointment: newAppt });
    }

    // At this point, type and duration should never be undefined because
    // the user has to select the duration in order to enable the calendar.
    // Keeping this check here just to avoid anything unexpected.
    if (appointmentType && duration) {
      this.fetchAvailabilities({
        appointmentType,
        duration,
        availabilitiesStartDate,
        availabilitiesEndDate,
      });
    } else {
      trackEvent(
        this.props.componentName,
        "handleMonthChange: not calling fetchAvailabilities",
        {
          appointmentType,
          duration,
        }
      );
    }
  }

  updateAppointment(appointment) {
    this.setState({
      appointment: Object.assign(this.state.appointment, appointment),
    });
  }

  handleAppointmentChange(appointment) {
    // to reschedule an appointment we need to preserve the original `appointmentId`
    // and set the new id as `newAppointmentId`
    if (this.state.reschedule) {
      appointment.newAppointmentId = appointment.appointmentId;
      delete appointment.appointmentId;
    }
    this.updateAppointment(appointment);
  }

  handleAppointmentSave(appointment, done) {
    const { componentName, customContent, onDone } = this.props;

    const { eventTrackingSharedProperties } = this.state;
    if (this.isAppointmentSaving) {
      return;
    }
    appointment = Object.assign(this.state.appointment, appointment);
    if (this.state.reschedule) {
      appointment.isReschedule = true;
    }
    this.isAppointmentSaving = true;
    this.setState({ appointment, loading: true });
    const isNewAppt = isNewAppointment(appointment);
    const createUpdate = isNewAppt
      ? API.createAppointment
      : API.updateAppointment;
    createUpdate(appointmentToServer(appointment)).then(
      ({ appointments }) => {
        const updatedAppointment = appointmentsToClient(appointments)[0];
        // `newAppointmentId` is set in `handleAppointmentChange()`
        // need to reset it when we're done with the reschedule flow
        if (this.state.reschedule) {
          updatedAppointment.newAppointmentId = null;
        }
        this.isAppointmentSaving = false;
        this.setState({
          errors: null,
          loading: false,
          reschedule: false,
        });
        this.updateAppointment(updatedAppointment);

        trackEvent(
          componentName,
          `Appointment ${isNewAppt ? "Added" : "Updated"}`,
          Object.assign(
            appointment.source ? { source: appointment.source } : {},
            eventTrackingSharedProperties
          )
        );

        // No need to show MadLibsInterjection review modal if user has set an appointment
        window.sessionStorage.removeItem(
          "showMadLibsReviewModalOnMadLibsModalClose"
        );

        window.localStorage.removeItem("onInformationGatheringPage");

        if (done) {
          if (
            customContent.isFirstUseAppointmentInterjection ||
            customContent.isMadLibsInterjectionReviewModal
          ) {
            this.saveAppointmentDataInSessionStorage(updatedAppointment);
            if (isMobileDevice()) {
              trackEvent(
                "First Use",
                "Redirect Mobile Page",
                {},
                {
                  // eslint-disable-next-line camelcase
                  send_immediately: true,
                  callback: () => {
                    window.location.replace("/static/html/redirectmobile/");
                  },
                }
              );
            } else onDone();
          } else {
            done();
          }
        }
      },
      (errors) => {
        this.setState({ errors, loading: false });
      }
    );
  }

  handleAppointmentCancel(e) {
    const { appointmentId, eventTrackingSharedProperties } =
      this.state.appointment;
    const { appointmentTypes, person, isLimited } = this.state;

    this.setState({ loading: true });
    API.cancelAppointment({ appointmentId }).then(
      () => {
        let updatedAppointment = Object.assign(
          newAppointment(person, isLimited),
          {
            // NOTE, there is no `CANCELED` status on the server.
            // This is a client-side only status to display the cancel confirmation message.
            appointmentStatus: "CANCELED",
          }
        );

        if (
          !this.props.isClient &&
          appointmentTypes &&
          appointmentTypes.length
        ) {
          updatedAppointment.appointmentType = appointmentTypes[0].value;
          updatedAppointment.duration = appointmentTypes[0].duration;
        }
        this.setState({
          appointment: updatedAppointment,
          availabilitiesStartDate: START,
          availabilitiesEndDate: this.props.isClient
            ? getEndOfMonth(START)
            : PROSPECT_END_DATE,
          isThreeTimeSlotsMode: !this.props.isClient,
          errors: null,
          loading: false,
        });
      },
      (errors) => {
        this.setState({ errors, loading: false });
      }
    );
    trackClick(
      e,
      this.props.componentName,
      "Cancel Appointment",
      eventTrackingSharedProperties
    );
  }

  handleAppointmentReschedule() {
    this.setState({ reschedule: true });
    const {
      availabilitiesStartDate,
      availabilitiesEndDate,
      isThreeTimeSlotsMode,
      appointment: { appointmentType, duration },
    } = this.state;

    if (isThreeTimeSlotsMode) {
      this.fetchAvailabilities({
        appointmentType,
        duration,
        limit: 3,
      });
    } else {
      this.fetchAvailabilities({
        appointmentType,
        duration,
        availabilitiesStartDate,
        availabilitiesEndDate,
      });
    }
  }

  handleStartOver() {
    const { person } = this.state;
    const appointment = newAppointment(
      person,
      this.props.isLimited,
      this.props.type,
      this.props.duration,
      this.props.subtypes
    );
    const { appointmentType, duration } = appointment;
    this.updateAppointment(appointment);

    if (!this.props.isClient) {
      this.fetchAvailabilities({
        appointmentType,
        duration,
        limit: 3,
      });
    }
  }

  handleAppointmentMoreTimes(e) {
    this.setState({ isThreeTimeSlotsMode: false });
    const {
      availabilitiesStartDate,
      availabilitiesEndDate,
      appointment: { appointmentType, duration },
    } = this.state;

    this.fetchAvailabilities({
      appointmentType,
      duration,
      availabilitiesStartDate,
      availabilitiesEndDate,
    });
    trackClick(
      e,
      this.props.componentName,
      "More Times",
      this.state.eventTrackingSharedProperties
    );
  }

  handleAppointmentKeep() {
    this.setState({ reschedule: false });
  }

  renderLimitedHeader() {
    return (
      <>
        <LimitedAppHeader
          cmsUrl={window.cmsUrl}
          baseUrl={window.baseUrl}
          loginUrl={`${window.baseUrl}page/login/switchUser`}
          noMargin={true}
        />
        <div className="limited-appointment__top-banner pc-u-mb qa-limited-appointment-top-banner">
          Talk to an Advisor
        </div>
      </>
    );
  }
  saveAppointmentDataInSessionStorage(appointment) {
    let timezone = appointment.timezone;
    if (timezone == null || timezone === "") {
      timezone = moment.tz.guess();
    }
    const startTime = appointment.startTime.clone().tz(timezone);
    const endTime = appointment.endTime.clone().tz(timezone);
    window.sessionStorage.setItem(
      "appointmentConfirmationData",
      JSON.stringify({
        firstName: appointment.firstName,
        advisorName: appointment.advisorName,
        gcalURL: appointment.gmail,
        icalURL: appointment.ical,
        outlookURL: appointment.outlook,
        appointmentDay: startTime.format(FORMAT_DAY),
        appointmentStartTime: startTime.format(FORMAT_TIME),
        appointmentEndTime: endTime.format(FORMAT_TIME_TZ),
      })
    );
  }

  render() {
    const {
      appointmentTypes,
      appointment,
      advisor,
      availabilities,
      step,
      errors,
      loading,
      initialized,
      calendarMonth,
      reschedule,
      fpTopics,
      isThreeTimeSlotsMode,
      eventTrackingSharedProperties,
      isCanadian,
    } = this.state;
    const {
      customContent,
      setModalTitle,
      isLimited,
      type,
      subtypes,
      isTopicPreselected,
      is401kEnrollment,
      onDone,
      hasOnboardingAppointmentPage,
      referralOnboardingTest,
    } = this.props;
    if (isCanadian) {
      setModalTitle(EMPOWER_PERSONAL_DASHBOARD);
      return <MessageForCanadians />;
    }

    if (!initialized) {
      return (
        <div className="appointment">
          <LoadingOverlay active={true} />
        </div>
      );
    }

    // in UTC
    let appointmentStartTime,
      appointmentEndTime,
      oldAppointmentStartTime,
      oldAppointmentEndTime;
    if (reschedule) {
      ({
        appointmentStartTime: oldAppointmentStartTime,
        appointmentEndTime: oldAppointmentEndTime,
      } = getAppointmentTime(appointment, availabilities));
      ({ appointmentStartTime, appointmentEndTime } = getAppointmentTime(
        appointment,
        availabilities,
        reschedule
      ));
    } else {
      ({ appointmentStartTime, appointmentEndTime } = getAppointmentTime(
        appointment,
        availabilities
      ));
    }

    return (
      <>
        <LoadingOverlay active={loading} />
        {isLimited ? this.renderLimitedHeader() : null}
        <AppointmentWizard
          pageIndex={step}
          availabilities={availabilities}
          appointmentTypes={appointmentTypes}
          advisorImgURL={advisor && advisor.imgURL}
          advisorName={advisor && advisor.name}
          userFirstName={appointment.firstName}
          userEmail={appointment.userEmail}
          userPhoneNumber={appointment.userPhoneNumber}
          invitees={appointment.invitees}
          fpTopics={fpTopics}
          clientNotesToAdvisor={appointment.clientNotesToAdvisor}
          appointmentType={appointment.appointmentType}
          duration={appointment.duration}
          appointmentId={reschedule ? null : appointment.appointmentId}
          appointmentStatus={appointment.appointmentStatus}
          appointmentStartTime={appointmentStartTime}
          appointmentEndTime={appointmentEndTime}
          oldAppointmentStartTime={oldAppointmentStartTime}
          oldAppointmentEndTime={oldAppointmentEndTime}
          timezone={appointment.timezone}
          gcalURL={appointment.gmail}
          icalURL={appointment.ical}
          outlookURL={appointment.outlook}
          errors={errors}
          month={calendarMonth}
          reschedule={reschedule}
          isThreeTimeSlotsMode={isThreeTimeSlotsMode}
          isClient={this.props.isClient}
          onMonthChange={this.handleMonthChange}
          onAppointmentTypeChange={this.handleAppointmentTypeChange}
          onAppointmentChange={this.handleAppointmentChange}
          onAppointmentSave={this.handleAppointmentSave}
          onAppointmentCancel={this.handleAppointmentCancel}
          onAppointmentReschedule={this.handleAppointmentReschedule}
          onAppointmentKeep={this.handleAppointmentKeep}
          onAppointmentMoreTimes={this.handleAppointmentMoreTimes}
          onAppointmentFPTopicSelection={this.handleFPTopicSelection}
          onStartOver={this.handleStartOver}
          source={appointment.source}
          apptAdvisorName={appointment.advisorName}
          apptAdvisorImgURL={getImageUrl(appointment.advisorId)}
          eventTrackingSharedProperties={eventTrackingSharedProperties}
          componentName={this.props.componentName}
          customContent={customContent}
          loading={loading}
          isLimited={isLimited}
          type={type}
          subtypes={subtypes}
          isTopicPreselected={isTopicPreselected}
          is401kEnrollment={is401kEnrollment}
          onDone={onDone}
          hasOnboardingAppointmentPage={hasOnboardingAppointmentPage}
          referralOnboardingTest={referralOnboardingTest}
        />
      </>
    );
  }
}

AppointmentContainer.propTypes = {
  is401kEnrollment: PropTypes.bool,
  isClient: PropTypes.bool,
  customContent: PropTypes.object,
  setModalTitle: PropTypes.func,
  componentName: PropTypes.string,
  advisorData: PropTypes.object,
  source: PropTypes.string,
  isLimited: PropTypes.bool,
  type: PropTypes.string,
  subtypes: PropTypes.array,
  isTopicPreselected: PropTypes.bool,
  duration: PropTypes.number,
  onDone: PropTypes.func,
  hasOnboardingAppointmentPage: PropTypes.bool,
  referralOnboardingTest: PropTypes.string,
};

AppointmentContainer.defaultProps = {
  is401kEnrollment: false,
  isClient: false,
  advisorData: {},
  customContent: {},
  setModalTitle: noop,
  componentName: DEFAULT_COMPONENT_NAME,
  source: undefined,
  isLimited: false,
  type: undefined,
  subtypes: undefined,
  duration: undefined,
  isTopicPreselected: false,
  onDone: noop,
  hasOnboardingAppointmentPage: false,
  referralOnboardingTest: "",
};
