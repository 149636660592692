import moment from "moment";

export default function toClient(availabilities) {
  return availabilities.map((a) => {
    return {
      advisorId: a.advisorId,
      advisorName: a.advisorName,
      advisorIsCFP: a.advisorIsCFP,
      appointmentId: a.appointmentId,
      appointmentStatus: a.appointmentStatus,
      meetingType: a.meetingType,
      startTime: moment.utc(a.fromTime),
      endTime: moment.utc(a.toTime),
    };
  });
}
