import Backbone from "backbone";
import Person from "models/enrollment/person";
import fullNameTemplate from "templates/modules/enrollmentNew/partials/fullName.html";

export default Backbone.Collection.extend({
  model: Person,

  toSelectOptionsArray: function () {
    return this.map(function (person) {
      return {
        value: String(person.id),
        label: fullNameTemplate(person.getDisplayName()) || "Name not provided",
      };
    });
  },
});
