/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { noop } from "underscore";

/**
 * Return the singular or plural filter name, for use in strings such as `5 Categories Filtered`.
 *
 * @param {number} filterCount The count of currently active filters.
 * @param {string} headerName The base name for the filter.
 * @param {string} pluralityOverrides Object containing overrides for single and/or plural forms of the header.
 * @returns {string} Singular or plural filter name, eg `categories`.
 */
export function getSingleOrPluralFilterName(
  filterCount,
  headerName,
  pluralityOverrides
) {
  if (typeof filterCount !== "number") {
    console.error("WARNING: Must pass an integer as `filterCount`"); // eslint-disable-line no-console

    return "value";
  }

  // Destructure from empty object if no pluralityOverrides, so `singularOverride` and `pluralOverride` can exist as undefined;
  const { singular: singularOverride, plural: pluralOverride } =
    pluralityOverrides || {};

  // Singular
  if (filterCount < 2) {
    switch (true) {
      case Boolean(singularOverride):
        // Some headers (eg `Tags`) have headers that are already pluralized, and need to be overridden.
        return singularOverride;
      case Boolean(headerName):
        // Simple case.
        return headerName;
      default:
        // Not all table columns have headers, but we can add filtering to those too.
        return "Value";
    }
  }

  // Plural
  switch (true) {
    case Boolean(pluralOverride):
      // Some headers (eg 'Category') have headers with more complicated pluralizing rules than just adding `s`.
      return pluralOverride;
    case Boolean(singularOverride):
      // Don't make consumer specify both a singular and a plural for overrides, if `singularOverride` can be simply pluralized.
      return singularOverride + "s";
    case Boolean(headerName):
      // Simple case.
      return headerName + "s";
    default:
      // Not all table columns have headers, but we can add filtering to those too.
      return "Values";
  }
}

/**
 * Expand and collapse to show and hide an element.
 *
 * @class ColumnFilterToggle
 */
export default class ColumnFilterToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGAEvent = this.handleGAEvent.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isExpanded !== state.isExpanded) {
      return {
        ...state,
        isExpanded: props.isExpanded,
      };
    }

    return null;
  }

  // Dispatch GA event
  handleGAEvent() {
    const AMPLITUDE_EVENTS = window.integratedSharedData?.AMPLITUDE_EVENTS;
    const eventBus = window.dashboardUtils?.eventBus;
    const selection = "budget_page.filter_transactions_button.click";
    eventBus?.dispatch(selection);
    eventBus?.dispatchAmplitude({
      event_type: AMPLITUDE_EVENTS?.SELECT_BUTTON ?? "select_button",
      event_properties: {
        selection,
      },
    });
  }

  handleKeyDown(event) {
    // eslint-disable-next-line no-magic-numbers
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      const isExpanded = !this.state.isExpanded;
      this.setState({ isExpanded }, () => this.props.onChange(isExpanded));

      if (isExpanded === true && IS_EMPOWER) {
        this.handleGAEvent();
      }
    }
  }

  handleChange(event) {
    event.preventDefault();
    event.stopPropagation();
    const isExpanded = !this.state.isExpanded;
    this.setState({ isExpanded }, () => this.props.onChange(isExpanded));
    if (isExpanded === true && IS_EMPOWER) {
      this.handleGAEvent();
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      isDisabled,
      disableMessage,
      activeFilterCount,
      onClear,
      columnHeader,
      columnHeaderPluralityOverrides,
    } = this.props;
    const { isExpanded } = this.state;

    const columnName = columnHeader || "column";
    const pluralizedFilterName = getSingleOrPluralFilterName(
      activeFilterCount,
      columnHeader,
      columnHeaderPluralityOverrides
    );
    const activeFilterText = isDisabled
      ? `${activeFilterCount} ${pluralizedFilterName} Filtered. ${disableMessage}`
      : `${activeFilterCount} ${pluralizedFilterName} Filtered`;
    return (
      <>
        {activeFilterCount > 0 ? (
          <button
            className={`table__column-filter-count pc-u-ph-- centi qa-table-column-filter-count${
              isDisabled ? " table__column-filter-toggler--disabled" : ""
            }`}
            onClick={isDisabled ? noop : this.handleChange}
            onKeyDown={isDisabled ? noop : this.handleKeyDown}
            title={activeFilterText}
            aria-label={activeFilterText}
            aria-expanded={isExpanded}
            aria-disabled={isDisabled}
          >
            {activeFilterCount}
          </button>
        ) : (
          <button
            className={`table__column-filter-toggler qa-table-column-filter-toggler${
              isExpanded ? " table__column-filter-toggler--active" : ""
            }${isDisabled ? " table__column-filter-toggler--disabled" : ""}`}
            onClick={isDisabled ? noop : this.handleChange}
            onKeyDown={isDisabled ? noop : this.handleKeyDown}
            title={isDisabled ? disableMessage : `Filter by ${columnName}`}
            aria-label={
              isDisabled ? disableMessage : `Filter by ${columnName} data`
            }
            aria-expanded={isExpanded}
            aria-disabled={isDisabled}
          >
            <svg
              className={`table__column-filter-icon ${
                isExpanded ? "table__column-filter-icon--active" : ""
              }`}
              onClick={isDisabled ? noop : this.handleChange}
              viewBox="0 1 13 13"
              aria-hidden
              alt=""
            >
              <use xlinkHref="#pc-filter" />
            </svg>
          </button>
        )}

        {isExpanded && activeFilterCount > 0 && (
          <button
            className="pc-btn pc-btn--tiny pc-btn--danger pc-u-ph-- table__column-filter-clear-btn qa-table-column-filter-clear"
            onClick={onClear}
            title={"Clear filters"}
            aria-label={`Clear ${columnName} Filters`}
          >
            X
          </button>
        )}
      </>
    );
  }
}

ColumnFilterToggle.propTypes = {
  isExpanded: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disableMessage: PropTypes.string,
  activeFilterCount: PropTypes.number,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  columnHeader: PropTypes.string,
  columnHeaderPluralityOverrides: PropTypes.object,
};

ColumnFilterToggle.defaultProps = {
  isExpanded: false,
  isDisabled: false,
  disableMessage: "Cannot use filter while editing a transaction.",
  activeFilterCount: 0,
  onChange: noop,
  onClear: noop,
  columnHeader: undefined,
  columnHeaderPluralityOverrides: undefined,
};
