import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import {
  isPcapCashPostLoginAction,
  getPcapCashLiveDataPromise,
} from "views/components/postLoginActions/pcapCashPlaUtils";
import objectPath from "object-path";

const ORIGINAL_DISCLAIMER = (
  <>
    <p>
      Notifications are only as accurate as the data provided by you and what we
      are able to retrieve from the financial institutions you have linked and
      may be incomplete, inaccurate or misinterpreted.
    </p>
    <p>
      Recommendations are based on your current portfolio and your responses to
      the investment profile questions that set your alternative allocation. You
      can update your investment profile from the profile menu under your login
      name on the main menu. Notifications are based on the historical
      performance for your current portfolio holdings compared to your target
      portfolio. Projected returns are not intended as a promise of future
      results and are not representative of the projected performance of a
      single alternative allocation. Investing in securities includes the risk
      of loss.
    </p>
    <p>
      Any reference to the advisory services refers to Empower Advisory Group,
      LLC. Empower Advisory Group, LLC is an investment adviser registered with
      the Securities and Exchange Commission. Registration does not imply a
      certain level of skill or training nor does it imply endorsement or
      sponsorship by the Commission. Past performance is not an indicator of
      future results. All investments involve some form of risk. Diversification
      alone cannot guarantee against losses in a declining market. Empower
      Advisory Group, LLC.&apos;s current FORM ADV Part 2, which describes the
      services offered, fees charged and any conflicts of interest, among other
      things, is available upon request free of charge.
    </p>
  </>
);

const AdviceDisclaimerModal = (props) => {
  const {
    componentName,
    isOpen,
    onClosed,
    customDisclaimerData,
    title,
    userMessage,
    disclaimerClassName,
  } = props;

  const modalComponentName = `${componentName || ""}${
    componentName ? " " : ""
  }Advice disclaimer`;
  const hasCustomDisclaimerContent = Boolean(
    customDisclaimerData && customDisclaimerData.htmlContent
  );
  const isPcapCashPLA = isPcapCashPostLoginAction(userMessage);

  const className = disclaimerClassName
    ? disclaimerClassName
    : "pc-modal--huge";

  const [latestDisclaimer, setLatestDisclaimer] = useState(
    <div>Loading...</div>
  );

  useEffect(() => {
    if (isPcapCashPLA && !hasCustomDisclaimerContent) {
      getPcapCashLiveDataPromise().then((data) => {
        setLatestDisclaimer(objectPath.get(data, "disclosures.content_html"));
      });
    }
  }, []); // Run only once
  return (
    <Modal
      componentName={modalComponentName}
      contentLabel={modalComponentName}
      className={className}
      isOpen={isOpen}
      onClosed={onClosed}
      title={title || "Advice disclaimer"}
    >
      {hasCustomDisclaimerContent && Boolean(customDisclaimerData.htmlContent) && (
        <div className="custom-disclaimer-content">
          <div
            dangerouslySetInnerHTML={{
              __html: customDisclaimerData.htmlContent,
            }}
          />
        </div>
      )}
      {!hasCustomDisclaimerContent && isPcapCashPLA && (
        <div
          dangerouslySetInnerHTML={{
            __html: latestDisclaimer,
          }}
        />
      )}
      {!hasCustomDisclaimerContent && !isPcapCashPLA && ORIGINAL_DISCLAIMER}
      <div className="u-text-right">
        <button className="pc-btn pc-btn--primary" onClick={onClosed}>
          Close
        </button>
      </div>
    </Modal>
  );
};

AdviceDisclaimerModal.defaultProps = {
  componentName: "",
  customDisclaimerData: undefined,
  userMessage: undefined,
  isOpen: false,
  title: undefined,
  onClosed: undefined,
  disclaimerClassName: undefined,
};

AdviceDisclaimerModal.propTypes = {
  componentName: PropTypes.string,
  customDisclaimerData: PropTypes.object,
  userMessage: PropTypes.object,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClosed: PropTypes.func,
  disclaimerClassName: PropTypes.string,
};

export default AdviceDisclaimerModal;
