import PropTypes from "prop-types";
import React from "react";
import Loading from "components/common/loading/Loading";

const LoadingOverlay = (props) => {
  return (
    <div
      className={`pc-overlay qa-loading-overlay ${
        props.active ? "pc-overlay--active" : ""
      } ${props.className}`}
    >
      <Loading modifier={props.modifier} />
    </div>
  );
};

LoadingOverlay.defaultProps = {
  className: "",
  modifier: "",
  active: false,
};

LoadingOverlay.propTypes = {
  className: PropTypes.string,
  modifier: PropTypes.string,
  active: PropTypes.bool,
};

export default LoadingOverlay;
