import PropTypes from "prop-types";
import React from "react";
import { select } from "d3";
import pieSeries from "libs/pcap/chart/series/pie";

export const getArcClasses = (datum) => {
  let classesToAdd = [];
  if (datum.data && datum.data.id) {
    classesToAdd.push(`chart__series--arc--${datum.data.id}`);
  }
  return { classesToAdd };
};

export default class PieChart extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.initializeChart();
    this.renderPieChart();
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  updateChart() {
    select(this.container).datum(this.props.datum).call(this.pieChart);
    this.renderPieChart();
  }

  initializeChart() {
    const { innerRadius, padAngle } = this.props;
    this.pieChart = pieSeries({
      sort: null,
      value: (d) => d.value,
      innerRadius: innerRadius,
      padAngle: padAngle,
    });
  }

  renderPieChart() {
    const { getArcClasses, handleMouseOver, handleMouseOut } = this.props;
    let arcs = select(this.container).selectAll(".js-chart-series");
    if (getArcClasses) {
      arcs.each(function (datum) {
        const { classToRemove, classesToAdd } = getArcClasses(datum);
        classesToAdd.forEach((className) => {
          // eslint-disable-next-line no-invalid-this
          select(this).classed(className, true);
        });

        if (classToRemove) {
          // eslint-disable-next-line no-invalid-this
          select(this).classed(classToRemove, false);
        }
      });
    }

    this.bindMouseEvents(arcs, handleMouseOver, handleMouseOut);
  }

  bindMouseEvents(arcs, handleMouseOver, handleMouseOut) {
    if (handleMouseOver && arcs) {
      arcs.on("mouseover", handleMouseOver);
    }

    if (handleMouseOut && arcs) {
      arcs.on("mouseout", handleMouseOut);
    }
  }

  render() {
    const { height, width, chartClassName, title } = this.props;
    return (
      <svg
        role="img"
        aria-label={title}
        ref={(container) => {
          this.container = container;
        }}
        height={height}
        width={width}
        className={chartClassName}
      />
    );
  }
}

PieChart.defaultProps = {
  getArcClasses: undefined,
  handleMouseOver: undefined,
  handleMouseOut: undefined,
  title: "Pie Chart",
};

PieChart.propTypes = {
  datum: PropTypes.array.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  innerRadius: PropTypes.number.isRequired,
  chartClassName: PropTypes.string.isRequired,
  padAngle: PropTypes.number.isRequired,
  getArcClasses: PropTypes.func,
  handleMouseOver: PropTypes.func,
  handleMouseOut: PropTypes.func,
  title: PropTypes.string,
};
