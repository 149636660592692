import React from "react";
import PropTypes from "prop-types";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import ColumnFilterToggle from "components/common/TableColumnFilter/ColumnFilterToggle";
import { isEqual, noop } from "underscore";

/**
 * When selection items are passed which do not match an option, remove them.
 *
 * This can happen because the selection was initially bad, or because
 * new options have been passed, which no longer include the selection.
 *
 * @param {array} options which are contained in the dropdown.
 * @param {array} values which are currently selected.
 *
 * @return {array} values for which matching options exist
 */
function getValidSelectionValues(options, values) {
  let validValues = [];

  values.forEach((value) => {
    if (options.find((option) => option.value === value)) {
      validValues.push(value);
    }
  });

  return validValues;
}

/**
 * List all the unique items in a table column dataset, and allow
 * the user to select them as filters for the column data.
 *
 * @export TableColumnFilter
 * @class TableColumnFilter
 */
export default class TableColumnFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: props.isExpanded,
      prevOptionsProp: props.options,
      prevValueProp: props.value,
      value: getValidSelectionValues(props.options, props.value),
    };

    this.handleExpandToggle = this.handleExpandToggle.bind(this);
    this.handleFilterSelect = this.handleFilterSelect.bind(this);
    this.handleFilterClear = this.handleFilterClear.bind(this);
    this.getClearIndicatorOverride = this.getClearIndicatorOverride.bind(this);
    this.handleSelectBlur = this.handleSelectBlur.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    // both options and values have changed
    if (
      !isEqual(prevState.prevOptionsProp, nextProps.options) &&
      !isEqual(prevState.prevValueProp, nextProps.value)
    ) {
      newState.prevOptionsProp = nextProps.options;
      newState.prevValueProp = nextProps.value;

      newState.value = getValidSelectionValues(
        nextProps.options,
        nextProps.value
      );

      // only options have changed
    } else if (!isEqual(prevState.prevOptionsProp, nextProps.options)) {
      newState.prevOptionsProp = nextProps.options;

      newState.value = getValidSelectionValues(
        nextProps.options,
        prevState.value
      );

      // only values have changed
    } else if (!isEqual(prevState.prevValueProp, nextProps.value)) {
      newState.prevValueProp = nextProps.value;

      newState.value = getValidSelectionValues(
        prevState.prevOptionsProp,
        nextProps.value
      );
    }

    return newState;
  }

  /**
   * `ReactSelect` is a modular package, pieces of which we can override or remove
   * via its props. This function creates an override to the `ClearIndicator`
   * button, with a new class and event attached to it.
   *
   * @returns {*} an override `ClearIndicator` component.
   */
  getClearIndicatorOverride() {
    return (
      <button
        className={
          "Select__clear-indicator Select__clear-indicator-with-no-dropdown-indicator Select__clear-indicator-override-button"
        }
        onClick={this.handleFilterClear}
        title={`Clear filters`}
        aria-label={`Clear filters`}
      >
        X
      </button>
    );
  }

  handleExpandToggle(isExpanded) {
    this.setState(
      {
        isExpanded,
      },
      () => {
        if (isExpanded && this.props.onExpand) {
          this.props.onExpand();
        }
      }
    );
  }

  handleSelectBlur(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.isExpanded) {
      this.setState({ isExpanded: false });
    }
  }

  static handleContainerClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleFilterSelect({ target: { value } }) {
    this.setState({ value }, () => {
      this.props.onChange(this.state.value);
    });
  }

  handleFilterClear() {
    this.setState({ value: [] });
    this.props.onChange([]);
  }

  render() {
    const {
      options,
      isFilterAlignedToTheRight,
      columnHeader,
      disableTransactionsFilter,
      columnHeaderPluralityOverrides,
    } = this.props;
    const { isExpanded, value } = this.state;
    const fancySelectContainerClassName = `table__column-filter-select${
      isFilterAlignedToTheRight ? " table__column-filter-select--right" : ""
    }${
      columnHeader
        ? ` qa-table__column-filter--${columnHeader.toLowerCase()}`
        : ""
    }`;
    const columnFilterId = `column-filter-${columnHeader.toLowerCase()}`;
    const selectLabel = `Filter by ${columnHeader.toLowerCase()}`;

    return (
      // eslint-disable-next-line
      <div
        className="table__column-filter-container"
        // Prevent `onClick` and `onKeyDown` propagation from FancySelect
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onKeyDown={(event) => {
          // eslint-disable-next-line no-magic-numbers
          if (event.key === "Enter" || event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      >
        <ColumnFilterToggle
          activeFilterCount={value.length}
          columnHeader={columnHeader}
          columnHeaderPluralityOverrides={columnHeaderPluralityOverrides}
          onChange={this.handleExpandToggle}
          onClear={this.handleFilterClear}
          isDisabled={disableTransactionsFilter}
          isExpanded={isExpanded}
        />
        {isExpanded && (
          <FancySelect
            {...this.props}
            id={columnFilterId}
            containerClassName={fancySelectContainerClassName}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            name="filter"
            placeholder=""
            selectLabel={selectLabel}
            ariaLabel={selectLabel}
            value={value}
            isMulti={true}
            menuIsOpen={true}
            isClearable={true}
            isSearchable={true}
            options={options}
            onChange={this.handleFilterSelect}
            components={{
              ClearIndicator: this.getClearIndicatorOverride,
              DropdownIndicator: () => null,
            }}
            onBlur={this.handleSelectBlur}
          />
        )}
      </div>
    );
  }
}

TableColumnFilter.propTypes = {
  onChange: PropTypes.func,
  isExpanded: PropTypes.bool,
  onExpand: PropTypes.func,
  options: PropTypes.array.isRequired,
  isFilterAlignedToTheRight: PropTypes.bool,
  disableTransactionsFilter: PropTypes.bool,
  value: PropTypes.array,
  columnHeader: PropTypes.string,
  columnHeaderPluralityOverrides: PropTypes.object,
};

TableColumnFilter.defaultProps = {
  isExpanded: false,
  onExpand: undefined,
  onChange: noop,
  isFilterAlignedToTheRight: false,
  disableTransactionsFilter: false,
  value: [],
  columnHeader: "",
  columnHeaderPluralityOverrides: undefined,
};
