import _ from "underscore";
import Backbone from "backbone";
var State = Backbone.Model.extend({
  getTransactionUserAccountIds: function () {
    if (!this.has("transUserAccountIds")) {
      return { type: "all" };
    }
    return this.get("transUserAccountIds");
  },

  setTransactionUserAccountIds: function (type, value) {
    if (!type) throw new Error("setTransactionUserAccountIds requires a type");
    type = type.toLowerCase();

    switch (type) {
      case "single":
        if (isNaN(value)) {
          throw new Error(
            "attempt to set a non number to setTransactionUserAccountIds"
          );
        } else {
          value = parseInt(value, 10);
        }

        if (value) {
          this.set("transUserAccountIds", { type: type, value: value });
        } else {
          this.set("transUserAccountIds", { type: "all" });
        }
        break;

      case "multi":
        if (!_.isArray(value)) {
          throw new Error(
            "attempt to pass a non_array to setTransactionUserAccountIds"
          );
        }
        value = _.compact(value);
        if (value) {
          this.set("transUserAccountIds", { type: type, value: value });
        } else {
          this.set("transUserAccountIds", { type: "all" });
        }
        break;

      case "all":
        this.set("transUserAccountIds", { type: "all" });
        break;

      default:
        throw new Error(
          "unknown type passed to setTransactionUserAccountIds: ",
          Number(type)
        );
    }
  },
});

export default new State({});
