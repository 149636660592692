import getUrlParameterByName from "./getUrlParameterByName";

/**
 * Derives `source` from either utm or source query params.
 *
 * @return {String}           the `source`.
 *
 */
export default function getSourceFromUrl() {
  const source = getUrlParameterByName("source");
  if (source) {
    return source;
  }

  const medium = getUrlParameterByName("utm_medium");
  const content = getUrlParameterByName("utm_content");
  const sourceFromUTMParams = [];

  if (medium) {
    sourceFromUTMParams.push(medium);
  }
  if (content) {
    sourceFromUTMParams.push(content);
  }
  return sourceFromUTMParams.join("_");
}
