import PropTypes from "prop-types";
import React from "react";

const Loading = (props) => {
  return (
    <div
      data-testid="loader"
      className={`component-loading qa-component-loading ${props.className}`}
    >
      <div
        className={`loader__container${
          props.small ? " loader__container--small" : ""
        }${props.modifier ? " loader__container--" + props.modifier : ""}`}
      >
        <div
          className={`loader__circle${
            props.small
              ? " loader__circle--small loader__full-circle--small"
              : ""
          } loader__full-circle`}
        />
        <div
          className={`loader__circle${
            props.small
              ? " loader__circle--small loader__spinning-circle--small"
              : ""
          } loader__spinning-circle`}
        />
      </div>
    </div>
  );
};

Loading.defaultProps = {
  modifier: "",
  small: false,
  className: "u-full-center--abs",
};

Loading.propTypes = {
  small: PropTypes.bool,
  modifier: PropTypes.string,
  className: PropTypes.string,
};

export default Loading;
