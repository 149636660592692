import * as Sentry from "@sentry/browser";

let isSentryInitialized = false;

function initSentry() {
  if (!window.isErrorTrackingEnabled || process.env.NODE_ENV !== "production") {
    return;
  }

  const version = process.env.SENTRY_VERSION;
  const subDomain = window.location.hostname.split(".")[0];
  let env = subDomain;
  // Value is set in GTM in constants tag.
  let tracesSampleRate = window.PCAP?.sentryConstants?.tracesSampleRate || 0;

  if (subDomain === "home") {
    env = "production";
  }

  Sentry.init({
    dsn: "https://6736d493ffe349af9dd736171a7d7b22:93925810986f44a4a8092da4ddead2b6@sentry.io/1492208",
    release: version,
    environment: env,
    allowUrls: [
      "https://pcsvc.dt.pcapqa.app",
      "https://devtrunk.pcapcloud.com",
      "https://qastaging.pcapcloud.com",
      "https://qatrunk.pcapcloud.com",
      "https://home.personalcapital.com",
    ],
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate,
  });

  Sentry.configureScope((scope) => {
    scope.setUser({ id: window.userGuid });
    scope.setTag("location_hash", window.location.hash);
  });

  window.addEventListener("hashchange", () => {
    Sentry.setTag("location_hash", window.location.hash);
  });

  isSentryInitialized = true;
}

/*  Proxy to watch events pushed to the google tag manager dataLayer. When OneTrust
    is initialized it will push an event to dataLayer that contains the opted-in categories
    of cookies, based off of the user's settings. If performance is enabled (C0002) then
    initialize Sentry. If Sentry was initialized and performance becomes disabled close it. */
window.dataLayer = window.dataLayer ?? [];
window.dataLayer = new Proxy(window.dataLayer, {
  set(_dataLayer, _prop, value) {
    if (
      !isSentryInitialized &&
      value?.OnetrustActiveGroups &&
      value.OnetrustActiveGroups?.includes("C0002")
    ) {
      initSentry();
    } else if (
      isSentryInitialized &&
      value?.OnetrustActiveGroups &&
      !value.OnetrustActiveGroups.includes("C0002")
    ) {
      Sentry.close();
      isSentryInitialized = false;
    }
    return Reflect.set(...arguments);
  },
});

function oneTrustPerformanceEnabled() {
  return window.dataLayer.some((e) => {
    return e.OnetrustActiveGroups?.includes("C0002");
  });
}

/*  Though we're proxying dataLayer for OT events there are cases where all of the updates to dataLayer
    have occured before logger is loaded, so for these cases we need to check if performance is enabled
    before calling the sentry method */
function error(err) {
  if (isSentryInitialized) {
    return Sentry.captureException(err);
  }

  /*  Necessary check when OneTrust is initialized before this file is loaded */
  if (!isSentryInitialized && oneTrustPerformanceEnabled()) {
    initSentry();
    if (isSentryInitialized) Sentry.captureException(err);
  }
}

/** Records a new breadcrumb which will be attached to future events.
 * Breadcrumbs will be added to subsequent events to provide more context on user's actions prior to an error or crash.
 * @param {Sentry.Breadcrumb} breadcrumb — The breadcrumb to record. */

function breadcrumb(breadcrumb) {
  if (isSentryInitialized) {
    return Sentry.addBreadcrumb(breadcrumb);
  }

  /*  Necessary check when OneTrust is initialized before this file is loaded */
  if (!isSentryInitialized && oneTrustPerformanceEnabled()) {
    initSentry();
    if (isSentryInitialized) Sentry.addBreadcrumb(breadcrumb);
  }
}

export { error, breadcrumb };
