import _ from "underscore";
import Backbone from "backbone";
import Services from "enrollmentServices";
import NameSuffixes from "enums/nameSuffixes";
/**
 * Name suffixes indexed by `value`.
 * @type {Object}
 */
var INDEXED_NAME_SUFFIXES = _.indexBy(NameSuffixes, "value");

function getDisplayName(model) {
  var name;
  if (model instanceof Backbone.Model) {
    name = _.clone(model.get("name"));
  } else {
    name = model.name;
  }
  if (name && INDEXED_NAME_SUFFIXES[name.suffix]) {
    name.suffixLabel = INDEXED_NAME_SUFFIXES[name.suffix].label;
  }
  return name;
}

export default Backbone.Model.extend(
  {
    /**
     * Returns a copy of the current person's `name` object
     * populating `suffixLabel` property with the label matching
     * `name.suffix` value.
     *
     * @return {Object} The object in the form of:
     *                  ```
     *                  {
     *                    firstName: '',
     *                    middleName: '',
     *                    lastName: '',
     *                    suffix: '',
     *                    suffixLabel, ''
     *                  }
     *                  ```
     */
    getDisplayName: function () {
      return getDisplayName(this);
    },

    /**
     * Verifies that the model has the first and the last name provided.
     * @return {Boolean}      The boolean flag indicating that the name is complete.
     */
    hasFullName: function () {
      var name = this.get("name");
      return Boolean(name && name.firstName && name.lastName);
    },

    /**
     * Overrides the default implementation to provide custom persistance strategy.
     * @param  {String}         method  the CRUD method ("create", "read", "update", or "delete")
     * @param  {Backbone.Model} model   the model to be saved
     * @param  {Object}         options success and error callbacks, and all other jQuery request options
     * @returns {$.Deferred}    Request deferred.
     */
    sync: function (method, model, options) {
      // mimics jQuery XHR object to make sure we follow `sync()` and `save()` APIs
      var request = $.Deferred();
      var personJson = model.toJSON();

      // don't send spouse details as part of the person model as we don't operate on it
      // when editing the person and the request oftentimes overwrites the server model
      // with the stale state
      delete personJson.spouseDetails;
      Services.Person[method](
        {
          // TODO Remove `personId`. Seems redundant as it is in `person` object as well. SS-4451 may address this.
          personId: model.id,
          // TODO Remove `personRole`. It is used only in `create` api. `personRole` possible values
          // are `OTHER` and `SELF`. Since we never create `SELF` from UI, it is always `OTHER`.
          // Moreover, the role can be derived from the `person.relationship` field.
          // I'm hardcoding the value for now, but it's better to remove it all together.
          // SS-4451 may address this.
          personRole: "OTHER",
          person: JSON.stringify(personJson),
          source: "Enrollment",
        },
        function (error, data) {
          // TODO handle server exception.
          // `error` is the textual portion of the HTTP status, such as "Not Found" or "Internal Server Error."

          if (error || (data && data.spHeader && data.spHeader.errors)) {
            options.error(data);
            request.reject(data);
          } else {
            options.success(data);
            request.resolve(data);
          }
        },
        this
      );
      return request.promise();
    },

    parse: function (response) {
      return response && response.spData;
    },
  },
  {
    /**
     * Returns a copy of the current person's `name` object
     * populating `suffixLabel` property with the label matching
     * `name.suffix` value.
     * @param   {Person|Object} model (or a plain object) to verify
     * @return {Object} The object in the form of:
     *                  ```
     *                  {
     *                    firstName: '',
     *                    middleName: '',
     *                    lastName: '',
     *                    suffix: '',
     *                    suffixLabel, ''
     *                  }
     *                  ```
     */
    getDisplayName: getDisplayName,
  }
);
