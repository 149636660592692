/* eslint-disable no-prototype-builtins */
import $ from "jquery"; // END AMD WRAPPER
import _ from "underscore";
import Backbone from "backbone";
import Mixpanel from "mixpanel";
import analytics from "analytics";
import EventBus from "eventBus";
import { ERROR_GENERIC } from "libs/pcap/utils/response";
import { error } from "logger";
import "globals";
import { invalidateSession } from "empower/utils/session";

var exports = {};
var noop = function () {}; //eslint-disable-line no-empty-function
var clone = function (data) {
  // ensuring data is of type object before cloning it
  if (typeof data != "object") {
    return data;
  }
  if (typeof JSON != "undefined") {
    return JSON.parse(JSON.stringify(data));
  }
  return $.extend(true, {}, data);
};

/* eslint-disable camelcase */
function mixpanelFiAggSuccess(postData) {
  const { accountsSubmitted } = window.PCAP || {};
  if (accountsSubmitted) {
    if (accountsSubmitted.isFirstUseScreen) {
      Mixpanel.trackEvent("First Acct Agg Success", {
        component: accountsSubmitted.component,
        fi_name: accountsSubmitted.firmName,
        product_type: accountsSubmitted.productType,
        is_first_use: accountsSubmitted.isFirstUseScreen,
        is_OAuth: accountsSubmitted.isOAuth,
        time_to_success: postData,
      });
    }
    Mixpanel.trackEvent("FI Agg Success", {
      component: accountsSubmitted.component,
      fi_name: accountsSubmitted.firmName,
      product_type: accountsSubmitted.productType,
      is_first_use: accountsSubmitted.isFirstUseScreen,
      is_OAuth: accountsSubmitted.isOAuth,
      time_to_success: postData,
    });
  }
}

// getAccounts request
var checkForNewlyAggregatedAccounts = function (response) {
  try {
    var accounts =
        (response && response.spData && response.spData.accounts) || [],
      accountsSubmitted = (window.PCAP && window.PCAP.accountsSubmitted) || {};
    /*
    TODO: currently this code has a bug. if the user adds a FI, logs out or refreshes the page or takes a long time to aggregrate (causing the user to logout/refres),
    the global variable is reset and we will not trigger any success event.
    */

    // if there are no newly added user products, return.
    if (_.isEmpty(accountsSubmitted)) {
      return;
    }
    _.each(accounts, function (account) {
      var accountSubmitted = accountsSubmitted[account.siteId];
      if (!_.isUndefined(accountSubmitted)) {
        var postData = { productName: accountSubmitted.productName };
        var eventData = {
          site_id: account.siteId, // eslint-disable-line camelcase
          user_product_id: account.userProductId, // eslint-disable-line camelcase
          // eslint-disable-next-line camelcase
          number_of_accounts: _.where(accounts, function (acct) {
            return acct.siteId === account.siteId;
          }).length,
          fi_name: account.originalFirmName, // eslint-disable-line camelcase
          last_refeshed: account.lastRefreshed, // eslint-disable-line camelcase
          next_action: account.nextAction && account.nextAction.action, // eslint-disable-line camelcase
        };

        if (
          account.nextAction.action === "NONE" ||
          (account.nextAction.action === "MORE_INFO" &&
            account.nextAction.aggregationErrorType === "NO_ERROR")
        ) {
          var submittedTS = accountSubmitted.timestamp,
            aggregratedTS = Math.round(new Date().getTime() / 1000); //eslint-disable-line no-magic-numbers

          postData.timeToSuccess = aggregratedTS - submittedTS;
          mixpanelFiAggSuccess(postData);
          // delete the account from submitted account collection
          delete accountsSubmitted[account.siteId];
        } else if (account.nextAction.action !== "WAIT") {
          // eslint-disable-next-line camelcase
          eventData.aggregation_error_type =
            account.nextAction.aggregationErrorType;
          Mixpanel.trackEvent("FI Agg Fail", eventData);
          // delete the account from submitted account collection
          delete accountsSubmitted[account.siteId];
        }
      }
    });
  } catch (e) {
    // log exception to splunk
    analytics.sendEngineeringEvent(
      "Error",
      "`checkForNewlyAggregatedAccounts()` " + e.message
    );
  }
};

function setAttributionSource(response) {
  var messages =
    (response && response.spData && response.spData.userMessages) || [];
  _.each(messages, function (message) {
    var actions = message.action;
    _.each(actions, function (action) {
      action.attributionSource = message.template + "--" + action.key;
    });
  });
}

var Service = function () {
  this.stack = [];
  this.namespaces = {};
  this.requestQueue = {};
};

var generateFingerprint = function (req) {
  // get any ignoredParameters
  var ignoredParameters = _.isUndefined(req.ignoredParameters)
    ? []
    : req.ignoredParameters;

  // simple fingerprinting of a request and it's parameters
  var print = req.url + "?";
  var paramKeys = _.keys(req.params);
  // sort the req param keys, so we always generate the same fingerprint irrespective of the order of params sent
  var sortedParamKeys = _.sortBy(paramKeys, function (name) {
    return name;
  });
  _.each(sortedParamKeys, function (key) {
    if (!_.include(ignoredParameters, key)) {
      print += "|" + key + "=" + req.params[key];
    }
  });
  return print;
};

Service.prototype.createRequest = function (url, params) {
  return {
    url: url,
    params: params,
  };
};

Service.prototype.createResponse = function (data, jqXHR, textStatus, error) {
  return {
    status: textStatus,
    jqXHR: jqXHR,
    data: data,
    error: error,
  };
};

Service.prototype.processResponse = function (req, res, config, callbacks) {
  // if req is for getAccounts, process the response and check for any newly aggregrated accounts
  if (req.url === "/api/newaccount/getAccounts2") {
    checkForNewlyAggregatedAccounts(res.data);
  }
  if (req.url === "/api/message/getUserMessages") {
    setAttributionSource(res.data);
  }
  for (var i = 0; i < this.stack.length; i++) {
    if (typeof this.stack[i].response == "function") {
      if (false === this.stack[i].response(req, res, config, callbacks, this)) {
        return this;
      }
    }
  }
  let error = res.error;
  if (!error && res.status === "error") {
    error = ERROR_GENERIC;
  }
  _.each(callbacks, function (callback) {
    callback(error, res ? res.data : null);
  });
  return this;
};

Service.prototype.send = function (req, config, callback) {
  var self = this;

  // generate a fingerprint
  var reqFingerprint = (req.fingerprint = generateFingerprint(req));

  // check if a request with the same fingerprint is currently in progress
  function generateSuccessHandler(req, config) {
    return function (response, textStatus, jqXHR) {
      response = self.createResponse(response, jqXHR, textStatus);
      // make a copy of the callbacks to be processed
      var reqCallbacks = _.clone(self.requestQueue[reqFingerprint]);
      //console.log('request queue for', reqFingerprint, reqCallbacks.length);
      // remove the request from queue
      delete self.requestQueue[reqFingerprint];
      // process the response and pass it on to callbacks
      self.processResponse(req, response, config, reqCallbacks);
    };
  }

  function generateErrorHandler(req, config) {
    return function (jqXHR, textStatus, errorThrown) {
      var response = self.createResponse(null, jqXHR, textStatus, errorThrown);
      // make a copy of the callbacks to be processed
      var reqCallbacks = _.clone(self.requestQueue[reqFingerprint]);
      // remove the request from queue
      delete self.requestQueue[reqFingerprint];

      // Status code 0 could be anything from disconnect to cors.
      if (textStatus === "error" && jqXHR.status === 0) {
        error(new Error("Unexpected network error"));
      }

      // process the response and pass it on to callbacks
      self.processResponse(req, response, config, reqCallbacks);
    };
  }

  if (typeof this.requestQueue[reqFingerprint] == "undefined") {
    // if no, make the request and add the request and its callback to the queue
    this.requestQueue[reqFingerprint] = [callback];

    // WHY are we doing this?
    // Relevant commits:
    // https://github.com/personalcapital/pcap-webui/commit/a47cf5aa592c79443874efb9aa8994f9d18f7c2f
    // https://github.com/personalcapital/pcap-webui/commit/2415e40c3fbf5bb621944d845526fdb7439ec7e3
    // https://github.com/personalcapital/pcap-webui/commit/5d7daea79cc8d83b31fd716070dabe4365f229b0
    //
    // TODO remove this code, it prevents sinon from faking the server APIs.
    //
    // if the request url does not start with http, append baseUrl to the requestUrl
    // most urls do not contain http with the only exception being dailycapital feed url
    var reqUrl = req.url;
    var httpUrl = /^(http|https):\/\//;
    var strippedBaseUrl;
    if (!httpUrl.test(reqUrl)) {
      // if baseUrl ends with a backslash, remove it
      if (/\/$/.test(baseUrl)) {
        strippedBaseUrl = baseUrl.substr(0, baseUrl.length - 1);
      } else {
        strippedBaseUrl = baseUrl;
      }
      // prefix request url with base url
      reqUrl = strippedBaseUrl + reqUrl;
    }

    // make the request
    $.ajax({
      url: reqUrl,
      // Used to persist sign-in from Empower host
      xhrFields: IS_EMPOWER ? { withCredentials: true } : undefined,
      data: req.params,
      type: "POST",
      dataType: "json",
      error: generateErrorHandler(req, config, callback),
      success: generateSuccessHandler(req, config, callback),
    });
  } else {
    // if yes, add the callback to the request in queue
    this.requestQueue[reqFingerprint].push(callback);
  }
  return self;
};

Service.prototype.map = function (api) {
  var self = this;

  function generateEndpoint(config) {
    return function (params, callback, scope) {
      var wrappedCallback = noop;
      if (typeof params == "function") {
        scope = callback;
        callback = params;
        params = null;
      }
      if (callback) {
        scope = scope || window;
        wrappedCallback = function () {
          callback.apply(scope, arguments);
        };
      }
      var req = self.createRequest(config.url, params);
      self.exec(req, config, wrappedCallback);
    };
  }

  for (var namespace in api) {
    if (api.hasOwnProperty(namespace)) {
      this.namespaces[namespace] = true;
      if (typeof this[namespace] == "undefined") {
        this[namespace] = {};
      }
      // eslint-disable-next-line guard-for-in
      for (var method in api[namespace]) {
        if (typeof api[namespace][method] == "string") {
          api[namespace][method] = { url: api[namespace][method] };
        }
        var url = api[namespace][method].url;
        this[namespace][method] = generateEndpoint(api[namespace][method]);
        this[namespace][method].config = api[namespace][method];
        this[namespace][method].config.url = url;
      }
    }
  }

  for (var i = 0; i < this.stack.length; i++) {
    if (typeof this.stack[i].map == "function") {
      this.stack[i].map(this);
    }
  }

  return self;
};

Service.prototype.use = function (handlers) {
  if (handlers) {
    if (typeof handlers.init == "function") {
      handlers.init(this);
    }
    this.stack.push(handlers);
  }
  return this;
};

Service.prototype.exec = function (req, config, callback) {
  var self = this,
    stack = self.stack;
  // call down through the stack
  for (var i = 0; i < stack.length; i++) {
    if (typeof stack[i].request == "function") {
      if (false === stack[i].request(req, config, callback, self)) {
        // return if a handler cancels the request
        return self;
      }
    }
  }
  self.send(req, config, callback);
  return self;
};

// client code
var csrfToken = function (startingValue) {
  return {
    value: startingValue || "",
    request: function (req) {
      req.params.csrf = req.params.csrf || this.value;
    },
    response: function (req, res) {
      if (res && res.data && res.data.spHeader && res.data.spHeader.csrf) {
        this.value = res.data.spHeader.csrf;
      }
    },
  };
};

var clientId = function (identifier) {
  return {
    value: identifier || "WEB",
    request: function (req) {
      req.params.apiClient = req.params.apiClient || this.value;
    },
  };
};

var ensureParams = function () {
  return {
    request: function (req) {
      req.params = req.params || {};
    },
  };
};

var redirectToMarketingContent = function (requestUrl) {
  var doRedirect = false;
  // Only for users clicking on sign out
  if (requestUrl === "/api/login/switchUser") {
    doRedirect = true;
  }
  return doRedirect;
};

var IS_SEMI_AUTHENTICATED = window.location.href.indexOf("/page/limited") > -1;
var allowedAuthLevel = IS_SEMI_AUTHENTICATED
  ? "USER_IDENTIFIED"
  : "SESSION_AUTHENTICATED";

var unauthRedirect = function () {
  return {
    response: function (req, res) {
      if (
        res.data &&
        res.data.spHeader &&
        res.data.spHeader.authLevel !== allowedAuthLevel
      ) {
        if (IS_EMPOWER) {
          invalidateSession();
          return;
        }

        try {
          this.redirect(req.url);
        } catch (e) {
          return false;
        }
        return false;
      }
    },
    redirect: function (requestUrl) {
      EventBus.trigger("logout");
      if (redirectToMarketingContent(requestUrl)) {
        window.location.href = cmsUrl + "pages/signout";
      } else {
        window.location.href = baseUrl;
      }
    },
  };
};

var cache = function (config) {
  return {
    cache: {},
    watchers: {},
    watchHash: {},
    watchCount: 0,
    ignoredParameters:
      config && config.ignoreParameters ? config.ignoreParameters : [],
    noop: function () {}, //eslint-disable-line no-empty-function
    clear: function (url) {
      // clears the cached value for a url
      var outdatedEndpoints = [];
      var matcher = new RegExp("^" + url);
      for (var key in this.cache) {
        // if cached value's key matches what we're supposed to clear
        if (key.match(matcher) != null) {
          delete this.cache[key];
          // when we clear, if there are watchers, refire the request
          for (var watcherKey in this.watchers) {
            if (key === watcherKey) {
              outdatedEndpoints.push(this.watchers[watcherKey]);
            }
          }
        }
      }
      // process and fire off new requests
      _.each(
        outdatedEndpoints,
        function (watch) {
          this.service.exec(
            this.service.createRequest(watch.config.url, watch.params),
            watch.config,
            this.noop
          );
        }.bind(this)
      );
    },
    fingerprint: function (req) {
      // store ignoredParameters in req object for further use
      req.ignoredParameters = this.ignoredParameters;

      // generate fingerprint
      return generateFingerprint(req);
    },
    init: function (service) {
      this.service = service;
    },
    map: function (service) {
      // implements the #watch functionality
      var self = this;
      for (var namespace in service.namespaces) {
        if (service.namespaces.hasOwnProperty(namespace)) {
          for (var method in service[namespace]) {
            if (service[namespace].hasOwnProperty(method)) {
              // add the watch method to each of the methods
              service[namespace][method].watch = (function (endpoint) {
                return function (params, callback, scope) {
                  // save a reference to the client watcher callback
                  if (typeof params == "function") {
                    scope = callback;
                    callback = params;
                    params = {};
                  }
                  var print = self.fingerprint({
                    url: endpoint.config.url,
                    params: params,
                  });
                  if (typeof self.watchers[print] == "undefined") {
                    self.watchers[print] = {
                      config: endpoint.config,
                      params: params,
                      callbacks: [{ callback: callback, scope: scope }],
                    };
                  } else {
                    self.watchers[print].callbacks.push({
                      callback: callback,
                      scope: scope,
                    });
                  }
                  // TODO: while passing in the original callback here ensures
                  // the callback is called even if the data is already cached,
                  // it might cause the callback to get called twice, once as
                  // the original callback, and once when the cache is updated
                  // during the response cycle - both times would be for the same
                  // server response
                  if (self.cache[print]) {
                    // if we have the desired response cached,
                    // return it to the requester here
                    if (typeof callback == "function") {
                      callback.apply(scope, [null, clone(self.cache[print])]);
                    }
                  } else {
                    // if it's not already cached, trigger
                    // a call to the server. The response
                    // will be passed to the requestor via
                    // the normal watch mechanism
                    endpoint(params, noop, scope);
                  }
                  // for unwatch
                  var watchID = {
                    print: print,
                    id: self.watchCount++,
                  };
                  self.watchHash[watchID.id] = callback;
                  return watchID;
                };
              })(service[namespace][method]);
              // add the unwatch method to each of the methods
              service[namespace][method].unwatch = (function () {
                return function (watchID) {
                  if (!watchID) {
                    return;
                  }
                  var watchers = self.watchers[watchID.print]
                    ? self.watchers[watchID.print].callbacks
                    : [];
                  if (watchers) {
                    for (var i = 0; i < watchers.length; i++) {
                      if (watchers[i].callback === self.watchHash[watchID.id]) {
                        self.watchers[watchID.print].callbacks.splice(i, 1);
                        // if there are no remaining callbacks delete the watch item as well.
                        if (
                          self.watchers[watchID.print].callbacks.length === 0
                        ) {
                          delete self.watchers[watchID.print];
                        }
                        break;
                      }
                    }
                  }
                };
              })(service[namespace][method]);
            }
          }
        }
      }
    },
    request: function (req, config, cb) {
      var fingerprint = this.fingerprint(req);
      if (
        config &&
        config.cache === true &&
        typeof this.cache[fingerprint] != "undefined" &&
        this.cache[fingerprint] != null
      ) {
        if (typeof cb == "function") {
          cb(null, clone(this.cache[fingerprint]));
        }
        return false;
      }
    },
    response: function (req, res, config) {
      if (
        config.cache === true &&
        (typeof res.error == "undefined" || res.error == null)
      ) {
        var fingerprint = this.fingerprint(req);
        this.cache[fingerprint] = res.data;
        // when we update the cache, notify watchers
        for (var watcherKey in this.watchers) {
          if (fingerprint === watcherKey) {
            const watcherKeyString = String(watcherKey);
            _.each(this.watchers[watcherKey].callbacks, (packagedCallback) => {
              if (
                packagedCallback &&
                typeof packagedCallback.callback === "function"
              ) {
                packagedCallback.callback.apply(packagedCallback.scope, [
                  null,
                  clone(res.data),
                ]);
              } else {
                // To debug why callback is not defined
                // Adding this event
                Mixpanel.trackEvent("Watcher callback not setup", {
                  watcherKey: watcherKeyString,
                });
              }
            });
          }
        }
      }
    },
  };
};

var serverChanges = function (cache) {
  return {
    lastSPHeaderVersion: -1,
    cache: cache,
    watchedChanges: {},
    eventBus: _.extend({}, Backbone.Events),
    init: function () {
      // TODO remove this event listener and fix the test that depend on it
      this.eventBus.on("spHeaderUpdated", this.onHeaderUpdated, this);
    },
    map: function (service) {
      var change, config;
      this.watchedChanges = {};
      // create a dictionary of server changes to methods
      for (var namespace in service.namespaces) {
        if (service.namespaces.hasOwnProperty(namespace)) {
          // search methods in a namespace
          for (var method in service[namespace]) {
            if (service[namespace].hasOwnProperty(method)) {
              // check the config for the method
              config = service[namespace][method].config;
              if (
                config &&
                typeof config.serverChanges == "object" /* array */
              ) {
                // map server changes
                for (var i = 0; i < config.serverChanges.length; i++) {
                  change = config.serverChanges[i];
                  if (typeof this.watchedChanges[change] == "undefined") {
                    this.watchedChanges[change] = [];
                  }
                  this.watchedChanges[change].push(config.url);
                }
              }
            }
          }
        }
      }
    },
    request: function (req) {
      // PFM-3475 Make sure we always send lastServerChangeId parameter in the header
      // so server doesn't pull it from redis.
      req.params.lastServerChangeId = this.lastSPHeaderVersion;
    },
    response: function (req, res) {
      if (res && res.data && res.data.spHeader) {
        this.onHeaderUpdated(res.data.spHeader);
      }
    },

    /**
     * Triggers a global `SERVER_CHANGE:XXX` event.
     * Global event name is constructed by prefixing the original
     * event name with `SERVER_CHANGE:`.
     *
     * Subscribed callbacks recieve two arguments:
     *   - original server event name
     *   - server event data
     *
     * @param  {String} change        Event name.
     * @param  {Object} changeDetails Event data.
     */
    processServerChange: function (change, changeDetails) {
      if (EventBus) {
        EventBus.trigger("SERVER_CHANGE:" + change, change, changeDetails);
      }
      // search watched changes
      if (typeof this.watchedChanges[change] == "object" /* array */) {
        for (var i = 0; i < this.watchedChanges[change].length; i++) {
          this.cache.clear(this.watchedChanges[change][i]);
        }
      }
    },
    processServerChanges: function (res) {
      var change,
        changes =
          res.data.spHeader && res.data.spHeader.SP_DATA_CHANGES
            ? res.data.spHeader.SP_DATA_CHANGES
            : [],
        i;

      // process the changes
      for (i = 0; i < changes.length; i++) {
        change = changes[i];
        var changeDetails = _.isUndefined(change.details) ? {} : change.details;
        this.processServerChange(change.eventType, changeDetails);
      }
    },
    onHeaderUpdated: function (spHeader) {
      if (spHeader.SP_HEADER_VERSION > this.lastSPHeaderVersion) {
        this.lastSPHeaderVersion = spHeader.SP_HEADER_VERSION;
        this.processServerChanges({
          data: {
            spHeader: spHeader,
          },
        });
      }
    },
  };
};

exports.Service = Service;
exports.csrfToken = csrfToken;
exports.clientId = clientId;
exports.ensureParams = ensureParams;
exports.unauthRedirect = unauthRedirect;
exports.cache = cache;
exports.serverChanges = serverChanges;

export default exports;
