/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";

const Header = ({ advisorImgURL, advisorName, body }) => {
  return (
    <div className="scheduling-header">
      <div className="pc-flag pc-flag--large">
        {advisorImgURL && (
          <div className="pc-flag__img">
            <img
              className="scheduling-header__img js-scheduling-header-img"
              src={advisorImgURL}
              alt=""
              width="90"
              height="90"
            />
            <div className="scheduling-header__advisor-name js-scheduling-header-adv-name">
              {advisorName}
            </div>
          </div>
        )}
        <div className="pc-flag__body">
          <div
            className={`scheduling-header__body qa-scheduling-header__body ${
              advisorImgURL ? "scheduling-header__body--with-advisor" : ""
            } js-scheduling-header-body`}
          >
            {body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  advisorName: PropTypes.string,
  advisorImgURL: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Header.defaultProps = {
  body: "Combine smart tools with the personalized touch of a Financial Advisor. Pick a time below to schedule a complimentary call.",
};
