import { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "underscore";

/**
 * Wizard component manages the navigation between the pages in an orderly fashion.
 * Extend the component to inherit the next and the back handlers and provide
 * the list of the pages to traverse.
 *
 * The initial list of pages can be updated at any time to add/remove steps from
 * the wizard flow.
 *
 * NOTE: `this.state.pages` can be updated on the runtime to dynamically add and remove steps
 */
export default class Wizard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.setPageLocation = this.setPageLocation.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    if (nextProps.pageIndex !== prevState.prevPropsPageIndex) {
      newState.pageIndex = nextProps.pageIndex;
      newState.prevPropsPageIndex = nextProps.pageIndex;
    }

    if (nextProps.pages !== prevState.prevPropsPages) {
      newState.pages = nextProps.pages;
      newState.prevPropsPages = nextProps.pages;
    }

    if (!isEmpty(newState)) {
      return newState;
    }

    return null;
  }

  handleNext(callback) {
    const { pageIndex, pages } = this.state;
    const newIndex = pageIndex + 1;
    this.setState(
      { pageIndex: newIndex },
      typeof callback === "function" ? callback : undefined
    );

    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(newIndex, pages[newIndex]);
    }
  }

  handleBack(callback) {
    const { pageIndex } = this.state;
    const newIndex = pageIndex - 1;
    this.setState(
      { pageIndex: newIndex },
      typeof callback === "function" ? callback : undefined
    );

    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(newIndex);
    }
  }

  /**
   * Update the current page location index
   * @param {number} newPageIndex The index of the page where the function should take me
   * @param {function} callback A function to execute after update is complete
   */
  setPageLocation(newPageIndex, callback) {
    this.setState(
      { pageIndex: newPageIndex },
      typeof callback === "function" ? callback : undefined
    );

    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(newPageIndex);
    }
  }

  getCurrentPageName() {
    const { pageIndex, pages } = this.state;
    return pages[pageIndex] || pages[pages.length - 1];
  }

  getPageIndexByName(pageName) {
    return this.state.pages.indexOf(pageName);
  }

  render() {
    return null;
  }
}

Wizard.propTypes = {
  pageIndex: PropTypes.number,
  pages: PropTypes.array,
  onPageChange: PropTypes.func,
};

Wizard.defaultProps = {
  pageIndex: 0,
  pages: [],
  onPageChange: undefined,
};

export function getPageIndexByName(pages, pageName) {
  return pages.indexOf(pageName);
}
