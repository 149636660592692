import PropTypes from "prop-types";
import React from "react";
import BaseInput from "../BaseInput";

function selectOptions(options) {
  return options.map((option) => {
    return (
      <option
        key={option.value}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </option>
    );
  });
}

/**
 * `select` element wrapper with the validation support.
 * Validator should be provided via `validator` attribute in the format
 * https://github.com/flatiron/revalidator#schema
 *
 * Example:
 * ```
    {
      allowEmpty: false
    }
 * ```
 *
 * Select options can be defined in two ways:
 * 1. As children tags.
 * ```
    <Select>
      <option value="example_value">Example Option</option>
      ...
    </Select>
 * ```
 * 2. As an array of objects via `options` prop.
 * ```
    <Select options={[ {value: 'example_value', label: 'Example Option'}, ... ]}>
    </Select>
 * ```
 * 3. Combination of the two.
    <Select options={[ {value: 'example_value', label: 'Example Option'}, ... ]}>
      <option></option>
    </Select>
 *
 * @export Select
 * @class Select
 * @extends {React.Component}
 */
export default class Select extends BaseInput {
  componentWillUnmount() {
    this.unmounted = true;
  }

  focus() {
    this.ref.focus();
  }

  render() {
    const helpText = this.props.helpText;
    const className = `input ${this.props.className || ""} ${
      this.state.valid ? "" : "input--error"
    }`;
    const disabled = this.props.disabled || false;

    return (
      <div>
        <select
          ref={(el) => {
            this.ref = el;
          }}
          id={this.props.id || this.id}
          className={className}
          disabled={disabled}
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        >
          {this.props.children}
          {this.props.options && selectOptions(this.props.options)}
        </select>
        {helpText && (
          <label className="pc-help-block pc-help-block--tiny u-text-left">
            {helpText}
          </label>
        )}
        {this.getErrorBlock({ placeholder: this.props.errorPlaceholder })}
      </div>
    );
  }
}

Select.propTypes = Object.assign({}, BaseInput.propTypes, {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  errorPlaceholder: PropTypes.bool,
});
