import React from "react";
import PropTypes from "prop-types";
import Column from "libs/pcap/table/Column";

const SummaryRow = (props) => {
  return (
    <div
      role="row"
      className={`table__row table__row--summary ${props.className}`}
    >
      {props.columns.map((col, i) => {
        return (
          <Column
            key={"fot_col" + i}
            data={props.data}
            accessor={col.summaryAccessor}
            formatter={col.summaryFormatter}
            className={col.summaryClassName || col.className || ""}
            style={col.style}
          />
        );
      })}
    </div>
  );
};

SummaryRow.propTypes = {
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

SummaryRow.defaultProps = {
  className: "",
};

export default SummaryRow;
