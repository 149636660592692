import Backbone from "backbone";
import _ from "underscore";
import Relationships from "enums/personRelationships";
/*------------------------------------*\
    #RELATIONSHIPS PROVIDER
\*------------------------------------*/

/**
 * An array of relationships available for selection only once.
 * @type {Array}
 */
var RELATIONSHIPS_UNIQUE = ["SELF", "SPOUSE"];

export default function getAvailableRelationships(people, include) {
  let relationships;
  if (people instanceof Backbone.Collection) {
    relationships = people.pluck("relationship");
  } else {
    relationships = _.pluck(people, "relationship");
  }
  var used = _.chain(relationships).uniq().compact().value();

  const person = people.find((p) => p.relationship === "SELF");
  if (person && person.maritalStatus === "SINGLE") used.push("SPOUSE");

  var usedUnique = _.intersection(
    used,
    _.without(RELATIONSHIPS_UNIQUE, include)
  );

  if (_.isEmpty(usedUnique)) {
    return Relationships;
  }

  return _.reject(Relationships, function (relationship) {
    return _.contains(usedUnique, relationship.value);
  });
}
