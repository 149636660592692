// eslint-disable-next-line no-empty-function
var Log = function () {};
Log.prototype.record = function (message) {
  if (typeof console != "undefined") {
    console.log(message);
  }
  if (typeof this.buffer == "undefined") {
    this.buffer = [];
  }
  this.buffer.push(message);
};
var log = new Log();
export default log.record.bind(log);
