import React from "react";
import PropTypes from "prop-types";
import Wizard from "common/Wizard";
import AppointmentTime from "../pages/AppointmentTime";
import AppointmentReview from "../pages/AppointmentReview";
import AppointmentConfirmation from "../pages/AppointmentConfirmation";
import AppointmentFPTopics from "../pages/AppointmentFPTopics";
import objectPath from "object-path";
import { first, isEmpty, isArray } from "underscore";
import { trackClick } from "../../common/ComponentAnalytics";
import { isMobileDevice } from "react-select/lib/utils";

export const STEP_APPOINTMENT_TIME = "appt_time";
export const STEP_APPOINTMENT_REVIEW = "appt_review";
export const STEP_APPOINTMENT_CONFIRMATION = "appt_confirm";
export const STEP_APPOINTMENT_FP_TOPICS = "appt_fp_topics";

export const FINANCIAL_PLANNING_TYPE = "FINANCIAL_PLANNING";

export default class AppointmentWizard extends Wizard {
  constructor(props) {
    super(props);
    this.handleAppointmentSave = this.handleAppointmentSave.bind(this);
    this.handleAppointmentReschedule =
      this.handleAppointmentReschedule.bind(this);
    this.handleAppointmentKeep = this.handleAppointmentKeep.bind(this);
    this.handleTopicSelection = this.handleTopicSelection.bind(this);
    this.handleStartOver = this.handleStartOver.bind(this);
  }

  handleNext(appointment) {
    const { pages, pageIndex } = this.state;
    this.props.onAppointmentChange(appointment);

    // If the user selects Financial Planning, the next step should be topic selection
    if (
      appointment.appointmentType === FINANCIAL_PLANNING_TYPE &&
      !isEmpty(this.props.fpTopics) &&
      !this.props.isTopicPreselected
    ) {
      let newPages = pages.slice();
      newPages.splice(pageIndex + 1, 0, STEP_APPOINTMENT_FP_TOPICS);
      this.setState({ pages: newPages });
    }

    super.handleNext();
  }

  handleBack() {
    super.handleBack();
  }

  handleAppointmentSave(appointment) {
    this.props.onAppointmentSave(appointment, () => {
      // proceed to the next step after the successful API call
      this.setState({ pageIndex: this.state.pageIndex + 1 });
    });
  }

  handleTopicSelection(topics) {
    // Pass topics up to container to retrieve questions
    // Skipping to the next step until question forms are in place
    this.setState({ pageIndex: this.state.pageIndex + 1 });
    this.props.onAppointmentFPTopicSelection(topics);
  }

  handleAppointmentReschedule(e) {
    const { componentName, eventTrackingSharedProperties } = this.props;
    this.setState({ pageIndex: 0 });
    this.props.onAppointmentReschedule();
    trackClick(
      e,
      componentName,
      "Reschedule Appointment",
      eventTrackingSharedProperties
    );
  }

  handleStartOver(e) {
    const { componentName, eventTrackingSharedProperties } = this.props;
    this.setState({ pageIndex: 0 });
    this.props.onStartOver();
    trackClick(
      e,
      componentName,
      "Set New Appointment",
      eventTrackingSharedProperties
    );
  }

  handleAppointmentKeep(e) {
    const { componentName, onAppointmentKeep, eventTrackingSharedProperties } =
      this.props;
    this.setState({ pageIndex: this.state.pages.length - 1 });
    onAppointmentKeep();
    trackClick(
      e,
      componentName,
      "Keep Appointment",
      eventTrackingSharedProperties
    );
  }

  componentWillUnmount() {
    let hjContainer = document.getElementById("_hj_feedback_container");

    if (hjContainer) {
      hjContainer.parentNode.removeChild(hjContainer);
    }
  }

  componentDidMount() {
    if (typeof window.hj !== "undefined") {
      window.hj("trigger", "schedulingFeedback");
    }

    // MC-1552 mobile responsive onboarding
    if (isMobileDevice()) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  componentDidUpdate() {
    let curPageName = this.getCurrentPageName();
    const tollGateHeader = $(".toll_gate__container .accordion");
    const tollGateOffRampHeader = $(
      ".toll_gate_off_ramp__container .toll_gate_off_ramp__header"
    );
    const tollGateOffRampSubheader = $(
      ".toll_gate_off_ramp__container .toll_gate_off_ramp__subheader"
    );

    if (
      (curPageName === "appt_time" && !this.props.isThreeTimeSlotsMode) ||
      curPageName === "appt_review"
    ) {
      tollGateHeader.hide();
      tollGateOffRampHeader.hide();
      tollGateOffRampSubheader.hide();
    } else {
      tollGateHeader.show();
      tollGateOffRampHeader.show();
      tollGateOffRampSubheader.show();
    }

    // MC-1552 mobile responsive onboarding
    if (isMobileDevice()) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  getPage() {
    const {
      errors,
      advisorImgURL,
      advisorName,
      userFirstName,
      userEmail,
      userPhoneNumber,
      invitees,
      appointmentTypes,
      availabilities,
      appointmentType,
      appointmentStatus,
      duration,
      timezone,
      appointmentId,
      appointmentStartTime,
      appointmentEndTime,
      oldAppointmentStartTime,
      oldAppointmentEndTime,
      gcalURL,
      icalURL,
      outlookURL,
      month,
      reschedule,
      onMonthChange,
      onAppointmentTypeChange,
      onAppointmentCancel,
      clientNotesToAdvisor,
      fpTopics,
      isThreeTimeSlotsMode,
      onAppointmentMoreTimes,
      isClient,
      source,
      customContent,
      apptAdvisorName,
      apptAdvisorImgURL,
      eventTrackingSharedProperties,
      componentName,
      loading,
      isLimited,
      type,
      isTopicPreselected,
      is401kEnrollment,
      hasOnboardingAppointmentPage,
      referralOnboardingTest,
    } = this.props;
    const guest = first(invitees);
    const pageName = this.getCurrentPageName();
    let page;
    switch (pageName) {
      case STEP_APPOINTMENT_TIME:
        page = (
          <AppointmentTime
            errors={errors}
            appointmentType={appointmentType}
            timezone={timezone}
            duration={duration}
            appointmentId={appointmentId}
            advisorImgURL={apptAdvisorImgURL || advisorImgURL}
            advisorName={apptAdvisorName || advisorName}
            appointmentTypes={appointmentTypes}
            oldAppointmentStartTime={oldAppointmentStartTime} // used for reschedule only
            oldAppointmentEndTime={oldAppointmentEndTime} // used for reschedule only
            availabilities={availabilities}
            month={month}
            reschedule={reschedule}
            isThreeTimeSlotsMode={isThreeTimeSlotsMode}
            isClient={isClient}
            onAppointmentTypeChange={onAppointmentTypeChange}
            onKeep={this.handleAppointmentKeep}
            onNext={this.handleNext}
            onShowMoreTimes={onAppointmentMoreTimes}
            onMonthChange={onMonthChange}
            customContent={customContent}
            loading={loading}
            eventTrackingSharedProperties={eventTrackingSharedProperties}
            componentName={componentName}
            type={type}
            isTopicPreselected={isTopicPreselected}
            is401kEnrollment={is401kEnrollment}
            hasOnboardingAppointmentPage={hasOnboardingAppointmentPage}
            referralOnboardingTest={referralOnboardingTest}
          />
        );
        break;
      case STEP_APPOINTMENT_REVIEW:
        page = (
          <AppointmentReview
            errors={errors}
            model={{
              userEmail,
              firstName: userFirstName,
              userPhoneNumber,
              invitees,
              clientNotesToAdvisor,
              source,
            }}
            reschedule={reschedule}
            advisorName={apptAdvisorName || advisorName}
            timezone={timezone}
            appointmentStartTime={appointmentStartTime}
            appointmentEndTime={appointmentEndTime}
            onConfirm={this.handleAppointmentSave}
            onBack={this.handleBack}
            eventTrackingSharedProperties={eventTrackingSharedProperties}
            componentName={componentName}
            isLimited={isLimited}
          />
        );
        break;
      case STEP_APPOINTMENT_CONFIRMATION:
        page = (
          <AppointmentConfirmation
            errors={errors}
            appointmentStartTime={appointmentStartTime}
            appointmentEndTime={appointmentEndTime}
            appointmentCanceled={appointmentStatus === "CANCELED"}
            timezone={timezone}
            advisorImgURL={apptAdvisorImgURL || advisorImgURL}
            advisorName={apptAdvisorName || advisorName}
            userFirstName={userFirstName}
            userEmail={userEmail}
            guestEmail={guest && guest.email}
            gcalURL={gcalURL}
            icalURL={icalURL}
            outlookURL={outlookURL}
            onCancel={onAppointmentCancel}
            onReschedule={this.handleAppointmentReschedule}
            onStartOver={this.handleStartOver}
            eventTrackingSharedProperties={eventTrackingSharedProperties}
            customContent={customContent}
            componentName={componentName}
          />
        );
        break;
      case STEP_APPOINTMENT_FP_TOPICS:
        page = (
          <AppointmentFPTopics
            model={fpTopics}
            onContinue={this.handleTopicSelection}
            onBack={this.handleBack}
          />
        );
        break;
      default:
        break;
    }

    return page;
  }

  showIgShortFormInsteadOfConfirmPage() {
    const {
      customContent,
      apptAdvisorName,
      advisorName,
      apptAdvisorImgURL,
      advisorImgURL,
      appointmentStartTime,
      appointmentEndTime,
      timezone,
    } = this.props;
    const advisorNameToUse = apptAdvisorName || advisorName;
    const advisorImgUrlToUse = apptAdvisorImgURL || advisorImgURL;
    const confirmAppointmentOnIgShortFormForAdvisors = objectPath.get(
      customContent,
      "data.confirmAppointmentOnIgShortFormForAdvisors"
    );
    const showIgShortForm =
      isArray(confirmAppointmentOnIgShortFormForAdvisors) &&
      (confirmAppointmentOnIgShortFormForAdvisors.includes(advisorNameToUse) ||
        confirmAppointmentOnIgShortFormForAdvisors.includes("ALL_ADVISORS"));
    if (
      showIgShortForm &&
      this.getCurrentPageName() === STEP_APPOINTMENT_CONFIRMATION
    ) {
      window.sessionStorage.setItem(
        "appointmentConfirmationData",
        JSON.stringify({
          advisorName: advisorNameToUse,
          advisorImgUrl: advisorImgUrlToUse,
          appointmentStartTime,
          appointmentEndTime,
          timezone,
        })
      );
      window.AppRouter.navigate("#/dashboard");
      return true;
    }
    return false;
  }

  render() {
    if (this.showIgShortFormInsteadOfConfirmPage()) {
      return null;
    }
    return <div className="appointment-wizard">{this.getPage()}</div>;
  }
}

AppointmentWizard.propTypes = {
  is401kEnrollment: PropTypes.bool,
  errors: PropTypes.array,
  appointmentId: PropTypes.string,
  pageIndex: PropTypes.number,
  advisorImgURL: PropTypes.string,
  advisorName: PropTypes.string,
  apptAdvisorName: PropTypes.string,
  apptAdvisorImgURL: PropTypes.string,
  userFirstName: PropTypes.string,
  userEmail: PropTypes.string,
  userPhoneNumber: PropTypes.string,
  clientNotesToAdvisor: PropTypes.string,
  invitees: PropTypes.array,
  appointmentTypes: PropTypes.array,
  availabilities: PropTypes.array,
  appointmentType: PropTypes.string,
  appointmentStatus: PropTypes.string,
  appointmentStartTime: PropTypes.object,
  appointmentEndTime: PropTypes.object,
  oldAppointmentStartTime: PropTypes.object,
  oldAppointmentEndTime: PropTypes.object,
  timezone: PropTypes.string,
  duration: PropTypes.number,
  month: PropTypes.object,
  gcalURL: PropTypes.string,
  icalURL: PropTypes.string,
  outlookURL: PropTypes.string,
  reschedule: PropTypes.bool,
  isThreeTimeSlotsMode: PropTypes.bool,
  fpTopics: PropTypes.object,
  isClient: PropTypes.bool,
  onAppointmentTypeChange: PropTypes.func,
  onAppointmentCancel: PropTypes.func,
  onAppointmentReschedule: PropTypes.func,
  onAppointmentChange: PropTypes.func.isRequired,
  onAppointmentSave: PropTypes.func.isRequired,
  onAppointmentKeep: PropTypes.func,
  onAppointmentMoreTimes: PropTypes.func,
  onAppointmentFPTopicSelection: PropTypes.func,
  onMonthChange: PropTypes.func.isRequired,
  onStartOver: PropTypes.func.isRequired,
  eventTrackingSharedProperties: PropTypes.object,
  customContent: PropTypes.object,
  loading: PropTypes.bool,
  componentName: PropTypes.string,
  isLimited: PropTypes.bool,
  type: PropTypes.string,
  subtypes: PropTypes.array,
  isTopicPreselected: PropTypes.bool,
  hasOnboardingAppointmentPage: PropTypes.bool,
  referralOnboardingTest: PropTypes.string,
};

AppointmentWizard.defaultProps = Object.assign({}, Wizard.defaultProps, {
  pages: [
    STEP_APPOINTMENT_TIME,
    STEP_APPOINTMENT_REVIEW,
    STEP_APPOINTMENT_CONFIRMATION,
  ],
  is401kEnrollment: false,
  type: undefined,
  subtypes: undefined,
  isTopicPreselected: false,
  hasOnboardingAppointmentPage: false,
  referralOnboardingTest: "",
});
