import { pie, arc, transition } from "d3";

const DEFAULT_TRANSITION_DURATION_SERIES = 1000;
const CLASS_NAME_SERIES = "chart__pie";
const CLASS_NAME_SHAPE = "chart__series--arc";
const HALF = 2;

function shape(context, options) {
  const hasInheritedTransition = context instanceof transition;
  const selection = hasInheritedTransition ? context.selection() : context;
  const arcShape = arc();

  arcShape.outerRadius(options.outerRadius).innerRadius(options.innerRadius);

  let arcs = selection.selectAll(".js-chart-series").data((d) => d);

  arcs.exit().remove();
  arcs
    .enter()
    .append("path")
    .attr("d", arcShape)
    .attr("class", function (datum, index) {
      return `js-chart-series chart__series ${CLASS_NAME_SHAPE} ${CLASS_NAME_SHAPE + "-" + ++index}`;
    });

  if (hasInheritedTransition) {
    arcs = arcs
      .transition(context)
      .duration(DEFAULT_TRANSITION_DURATION_SERIES);
  }

  arcs.attr("d", arcShape);
}

/**
 * Draws a pie shape.
 *
 * TODO Make it work with the rest of the charting framework.
 * Note, this is an initial implementation of pie shape for the charting framework.
 *
 * @param {Object}          options         Initialization options.
 * @param {Function|Number} options.value   If `value` is specified, sets the value accessor to the specified function or number.
 * @param {Function|Number} options.sort    If `sort` is specified, sets the data comparator to the specified function.
 *                                          If the data comparator is null, then arcs are positioned in the original input order.
 *                                          Otherwise, the data is sorted according to the data comparator,
 *                                          and the resulting order is used.
 * @param {Number} options.innerRadius specifies the inner radius of the pie chart
 * @param {Number} options.padAngle specifies padding between the arcs.
 *
 * @returns {Function}                      A new pie.
 */
export default function (options) {
  options = options || {};

  const pieGenerator = pie();

  // null is a valid value
  if (options.sort !== undefined) {
    pieGenerator.sort(options.sort);
  }

  if (options.value) {
    pieGenerator.value(options.value);
  }

  if (options.padAngle) {
    pieGenerator.padAngle(options.padAngle);
  }

  function series(context) {
    var hasInheritedTransition = context instanceof transition;
    var selection = hasInheritedTransition ? context.selection() : context;
    const rect = selection.node().getBoundingClientRect();
    const radius = Math.min(rect.width, rect.height) / HALF;
    const arcOptions = {
      outerRadius: radius,
      innerRadius: options.innerRadius || 0,
    };

    var series = selection
      .selectAll(".js-chart-pie")
      .data((d) => [pieGenerator(d)]);

    series
      .enter()
      .append("g")
      .attr(
        "transform",
        "translate(" + rect.width / HALF + "," + rect.height / HALF + ")"
      )
      .attr("class", function (datum, index) {
        return (
          "js-chart-pie " +
          CLASS_NAME_SERIES +
          " " +
          CLASS_NAME_SERIES +
          "-" +
          ++index
        );
      })
      .transition(context)
      .call(shape, arcOptions);

    series.exit().remove();

    if (hasInheritedTransition) {
      series = series.transition(context);
    }
    series.call(shape, arcOptions);
  }

  return series;
}
