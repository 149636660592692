import React from "react";
import PropTypes from "prop-types";
import Row from "libs/pcap/table/Row";
import Column from "libs/pcap/table/Column";

const GroupHeaderRow = (props) => {
  if (props.hasCustomColumns) {
    return (
      <div
        role="rowgroup"
        className={`table__row table__row-group-header ${props.className}`}
      >
        {props.columns.map((col, i) => {
          return (
            <Column
              key={"group_col" + i}
              data={props.data}
              accessor={col.groupRowAccessor}
              formatter={col.groupRowFormatter}
              className={col.className || ""}
              style={col.style}
            />
          );
        })}
      </div>
    );
  }

  return (
    <Row
      className={`table__row-group-header ${props.className}`}
      columns={props.columns}
      data={{ text: props.text, value: props.value }}
      index={props.index}
    />
  );
};

GroupHeaderRow.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  text: PropTypes.string,
  value: PropTypes.string,
  index: PropTypes.number.isRequired,
  data: PropTypes.PropTypes.array,
  hasCustomColumns: PropTypes.bool,
};

GroupHeaderRow.defaultProps = {
  className: "",
  columns: [
    {
      accessor: (d) => d.text,
      style: { width: "50%" },
      className: "u-text-left",
    },
    {
      accessor: (d) => d.value,
      style: { width: "50%" },
      className: "u-text-right",
    },
  ],
  text: "",
  data: undefined,
  value: undefined,
  hasCustomColumns: false,
};

export default GroupHeaderRow;
