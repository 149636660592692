import parseResponseErrors from "libs/pcap/utils/response";
import { Events } from "backbone";

/**
 * Wraps the supplied `service` in a promise.
 *
 * @example
    const fetchProjection = promisify(Services.SponsorProjection.get);

    fetchProjection({ userAccountIds: [123] })
      .then(response => {
        // process response
      })
      .catch(errors => {
        // process errors
      });
 *
 * @param {Function} service a service function to wrap
 * @param {Boolean} includeFullResponse whether the full response {spData, spHeader} should be returned.
 * @param {Boolean} forgiveErrors whether the API failure needs to be forgiven.
 * @param {Boolean} defaultResponse default response when API errors is forgiven.
 *
 * @returns {Function}  a function which when executed, makes the service call
 *                      and returns a promise to handle the result. The function
 *                      accepts `params` to send along with the API call.
 *                      The promise is resolved with `spData` content.
 *                      The promise is rejects with an array of string errors.
 */
export function promisify(
  service,
  includeFullResponse,
  forgiveErrors = false,
  defaultResponse = {}
) {
  return function (params = {}) {
    return new Promise((resolve, reject) => {
      service(params, (err, response) => {
        const errors = parseResponseErrors(err, response);
        if (errors) {
          if (forgiveErrors) {
            resolve(defaultResponse);
            return;
          }

          reject(
            includeFullResponse
              ? Object.assign({}, response, { errors })
              : errors
          );
          return;
        }
        resolve(includeFullResponse ? response : response.spData);
      });
    });
  };
}

/**
 * Wraps the supplied `service` in a promise and watches for server changes.
 *
 * @example
    const fetchAccountSubscription = subscribify(Services.Accounts.get);

    fetchAccountSubscription.promise
      .then(response => {
        // process response
      })
      .catch(errors => {
        // process errors
      });
    
    fetchAccountSubscription.on("change", function(){})
    fetchAccountSubscription.off("change")
    fetchAccountSubscription.unwatch()
 *
 * @param {Function} service a service function to wrap
 * @param {Boolean} includeFullResponse whether the full response {spData, spHeader} should be returned.
 *
 * @returns {Object}  an extend of Backbone events along with promise and unwatch.
 */
export function subscribify(service, includeFullResponse) {
  return function (params = {}) {
    const result = Object.assign({}, Events);
    let watchedServiceId;
    let isInitialFetchCompleted;
    result.unwatch = () => {
      if (watchedServiceId) {
        service.unwatch(watchedServiceId);
        watchedServiceId = null;
      }
    };
    result.promise = new Promise((resolve, reject) => {
      watchedServiceId = service.watch(params, (err, response) => {
        const errors = parseResponseErrors(err, response);
        if (errors) {
          reject(errors);
          return;
        }

        const returnResponse = includeFullResponse ? response : response.spData;
        if (isInitialFetchCompleted) {
          result.trigger("change", returnResponse);
        } else {
          isInitialFetchCompleted = true;
          resolve(returnResponse);
        }
      });
    });
    return result;
  };
}

export function fetchJsonPromise(jsonUrl) {
  return new Promise((resolve, reject) => {
    $.getJSON(jsonUrl)
      .then((parsed) => {
        resolve(parsed);
      })
      .fail((err) => {
        reject(err);
      });
  });
}
