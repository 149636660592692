import PropTypes from "prop-types";
import React from "react";
import BaseInput from "../BaseInput";

export default class Textarea extends BaseInput {
  componentWillUnmount() {
    this.unmounted = true;
  }
  render() {
    const className = `input input--full ${this.props.className} ${
      this.state.valid ? "" : "input--error"
    }`;

    return (
      <div>
        <textarea
          id={this.props.id || this.id}
          className={className}
          placeholder={this.props.placeholder}
          rows={this.props.rows}
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          aria-label={this.props.ariaLabel || ""}
        />

        {this.props.helpText && (
          <label className="pc-help-block pc-help-block--tiny u-text-left">
            {this.props.helpText}
          </label>
        )}
        {this.getErrorBlock({ placeholder: this.props.errorPlaceholder })}
      </div>
    );
  }
}

Textarea.propTypes = Object.assign({}, BaseInput.propTypes, {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  rows: PropTypes.number,
  errorPlaceholder: PropTypes.bool,
  ariaLabel: PropTypes.string,
});
