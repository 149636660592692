import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import Textarea from "components/common/form/Textarea";
import { FIRST_NAME } from "utils/patterns";
import deepCopy from "deep-copy";
import objectPath from "object-path";
import { isEqual, noop } from "underscore";

const MAXLENGTH_FIRST_NAME = 20;
const MAXLENGTH_LAST_NAME = 29;

class ContactUsForm extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { emailSubject, emailBody } = this.props;
    this.focus();
    this.setState({
      model: {
        subject: emailSubject,
        description: emailBody,
      },
    });
  }

  componentDidUpdate(prevProps) {
    const oldFirstName = objectPath.get(prevProps.person, "name.firstName");
    const firstName = objectPath.get(this.props.person, "name.firstName");
    const oldLastName = objectPath.get(prevProps.person, "name.lastName");
    const lastName = objectPath.get(this.props.person, "name.lastName");
    const oldEmail = objectPath.get(prevProps.person, "emailAddress");
    const email = objectPath.get(this.props.person, "emailAddress");
    const phoneNumber = objectPath.get(this.props.person, "phone");
    const oldPhoneNumber = objectPath.get(prevProps.person, "phone");
    const model = {};
    let modelChanged = false;
    if (!isEqual(oldFirstName, firstName)) {
      Object.assign(model, this.state.model, { firstName });
      modelChanged = true;
    }
    if (!isEqual(oldLastName, lastName)) {
      Object.assign(model, this.state.model, { lastName });
      modelChanged = true;
    }
    if (!isEqual(oldEmail, email)) {
      Object.assign(model, this.state.model, { email });
      modelChanged = true;
    }
    if (!isEqual(oldPhoneNumber, phoneNumber)) {
      Object.assign(model, this.state.model, { phoneNumber });
      modelChanged = true;
    }
    if (modelChanged) {
      this.setState({ model });
    }
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    const { model } = this.state;
    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.handleSend(model);
    }
  }

  render() {
    const { loading, person, emailSubject, emailBody } = this.props;
    const firstName = objectPath.get(person, "name.firstName");
    const lastName = objectPath.get(person, "name.lastName");
    const validator = ContactUsForm.validationSchema;
    return (
      <form onSubmit={this.handleSubmit}>
        <section>
          <LoadingOverlay active={loading} />
          <div>
            <label
              className="pc-label js-contact-us-form-first-name-label"
              htmlFor="firstName"
            >
              First Name
            </label>
            <Input
              ref={this.storeInputRef}
              type="text"
              id="firstName"
              sizeVariation="full"
              name="firstName"
              onChange={this.handleInputChange}
              validator={validator.firstName}
              maxLength={MAXLENGTH_FIRST_NAME}
              value={firstName}
            />
          </div>
          <div>
            <label
              className="pc-label js-contact-us-form-last-name-label"
              htmlFor="lastName"
            >
              Last Name
            </label>
            <Input
              ref={this.storeInputRef}
              type="text"
              id="lastName"
              sizeVariation="full"
              name="lastName"
              onChange={this.handleInputChange}
              validator={validator.lastName}
              maxLength={MAXLENGTH_LAST_NAME}
              value={lastName}
            />
          </div>
          <div>
            <label
              className="pc-label js-contact-us-form-email-label"
              htmlFor="email"
            >
              Email
            </label>
            <Input
              ref={this.storeInputRef}
              type="text"
              id="email"
              sizeVariation="full"
              name="email"
              onChange={this.handleInputChange}
              validator={validator.email}
              value={person.emailAddress}
            />
          </div>
          <div>
            <label
              className="pc-label js-contact-us-form-phone-label"
              htmlFor="phoneNumber"
            >
              Phone
            </label>
            <Input
              ref={this.storeInputRef}
              type="text"
              id="phoneNumber"
              sizeVariation="full"
              name="phoneNumber"
              onChange={this.handleInputChange}
              validator={validator.phone}
              value={person.phone}
            />
          </div>
          <div>
            <label
              className="pc-label js-contact-us-form-subject-label"
              htmlFor="subject"
            >
              Subject
            </label>
            <Input
              ref={this.storeInputRef}
              type="text"
              id="subject"
              sizeVariation="full"
              name="subject"
              onChange={this.handleInputChange}
              validator={validator.subject}
              value={emailSubject}
            />
          </div>
          <div>
            <label
              className="pc-label js-contact-us-form-description-label"
              htmlFor="description"
            >
              Description
            </label>
            <Textarea
              ref={this.storeInputRef}
              id={"description"}
              rows={5}
              errorPlaceholder={true}
              name={"description"}
              validator={validator.description}
              onChange={this.handleInputChange}
              value={emailBody}
            />
          </div>
          <div className="u-text-right">
            <button
              type="button"
              className="js-contact-us-form-cancel pc-btn js-cancel"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="js-contact-us-form-send pc-btn pc-btn--primary"
            >
              Send
            </button>
          </div>
        </section>
      </form>
    );
  }
}

ContactUsForm.propTypes = {
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  person: PropTypes.any,
};

ContactUsForm.defaultProps = {
  loading: false,
  errors: undefined,
  person: {},
  onCancel: noop,
};

const EMPTY_VALIDATION_ERROR_MESSAGE = "this field is required";
const VALIDATION_REQUIRED_STRING = {
  allowEmpty: false,
  type: "string",
  messages: { allowEmpty: EMPTY_VALIDATION_ERROR_MESSAGE },
};

const VALIDATION_REQUIRED_STRING_NO_NUMBERS = {
  allowEmpty: false,
  type: "string",
  pattern: FIRST_NAME.source,
  messages: {
    allowEmpty: EMPTY_VALIDATION_ERROR_MESSAGE,
    pattern: "Name may not include special characters or numbers.",
  },
};

ContactUsForm.validationSchema = {
  type: "object",
  firstName: Object.assign(deepCopy(VALIDATION_REQUIRED_STRING_NO_NUMBERS), {
    maxLength: MAXLENGTH_FIRST_NAME,
  }),
  lastName: Object.assign(deepCopy(VALIDATION_REQUIRED_STRING_NO_NUMBERS), {
    maxLength: MAXLENGTH_LAST_NAME,
  }),
  email: { format: "email" },
  phone: Object.assign(deepCopy(VALIDATION_REQUIRED_STRING), { minLength: 10 }),
  subject: VALIDATION_REQUIRED_STRING,
  description: VALIDATION_REQUIRED_STRING,
};

export default ContactUsForm;
