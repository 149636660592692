export default function (object, ignore) {
  var empty = true;
  ignore = ignore || [];
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      if (
        object[key] != null &&
        object[key] !== "" &&
        ignore.indexOf(key) === -1
      ) {
        empty = false;
        break;
      }
    }
  }

  return empty;
}
