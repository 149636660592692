import _ from "underscore";
import Backbone from "backbone";
import "backbone.queryparams";
var globals = {
  csrf: window.csrf,
  userGuid: window.userGuid,
  baseUrl: window.baseUrl,
  cmsUrl: window.cmsUrl,
  gaApiKey: window.gaApiKey,
  domainName: window.domainName,
};
// turns globals into an injectable event bus
_.extend(globals, Backbone.Events);
export default globals;
