import PropTypes from "prop-types";
import React from "react";

const formatSentenceCase = (data) => {
  let formattedData = data;

  if ((typeof data === "string" || data instanceof String) && data.length > 1) {
    formattedData = data[0].toUpperCase() + data.toLowerCase().substring(1);
  }

  return formattedData;
};

export default function RadioButton({
  name,
  value,
  label,
  ariaLabel,
  checked,
  disabled,
  inputClassName,
  labelClassName,
  onChange,
  onBlur,
  shouldFormatLabel,
}) {
  const optionalProps = {};

  if (ariaLabel) {
    optionalProps["aria-label"] = ariaLabel;
  }

  const handleKeyDown = (event) => {
    // eslint-disable-next-line no-magic-numbers
    if (event.key === "Enter" || event.keyCode === 13) {
      onChange({
        target: {
          name,
          type: "radio",
          value,
        },
      });
    }
  };

  return (
    <label
      className={`pc-label ${labelClassName} u-preserve-case${
        checked ? " selected" : ""
      }`}
    >
      <input
        className={inputClassName}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        {...optionalProps}
      />
      {shouldFormatLabel ? formatSentenceCase(label) : label}
    </label>
  );
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  checked: PropTypes.bool,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  shouldFormatLabel: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

RadioButton.defaultProps = {
  checked: undefined,
  inputClassName: "",
  labelClassName: "u-text-bold pc-u-mb--",
  disabled: undefined,
  shouldFormatLabel: true,
  ariaLabel: undefined,
};
