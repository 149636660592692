import PropTypes from "prop-types";
import React from "react";

function Column(props) {
  const { data, accessor, formatter, className, titleAccessor, style } = props;
  let value = accessor ? accessor(data) : "";
  if (formatter) {
    value = formatter(value);
  }
  let title;
  if (titleAccessor) {
    title = titleAccessor(data);
  }
  return (
    <div
      role="gridcell"
      className={`table__column ${className ? className : ""}`}
      title={title}
      style={style}
    >
      {value}
    </div>
  );
}

Column.propTypes = {
  accessor: PropTypes.func,
  formatter: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  titleAccessor: PropTypes.func,
  style: PropTypes.object,
};

Column.defaultProps = {
  accessor: undefined,
  formatter: undefined,
  className: "",
  titleAccessor: undefined,
  style: {},
};
export default Column;
