/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* cSpell:disable */
import globals from "globals";
import Services from "libs/pcap/utils/service";
import log from "log";
import analytics from "analytics";
import Mixpanel from "mixpanel";
import fireGoogleTagManagerEvent from "libs/pcap/utils/fireGoogleTagManagerEvent";
const USER_MSG_TYPE = {
  APP_WIDE_BANNER: "APP_WIDE_BANNER",
  INTERJECTION: "INTERJECTION",
};

var cache = Services.cache({
  ignoreParameters: [
    "lastServerChangeId",
    "csrf",
    "apiClient",
    "FROMBARGRAPH",
    "incomeExpenseMode",
    "sort_cols",
    "sort_rev",
    "page",
    "rows_per_page",
    "search",
    "credit_debit",
    "component",
    "includeInCashManager",
    "transactionType",
    "accountType",
  ],
});

var accountServerChanges = [
  "USER_SITE_ADDED",
  "USER_SITE_UPDATED",
  "USER_SITE_REMOVED",
  "USER_PRODUCT_ADDED",
  "USER_PRODUCT_UPDATED",
  "USER_PRODUCT_REMOVED",
  "USER_ACCOUNT_ADDED",
  "USER_ACCOUNT_UPDATED",
  "USER_ACCOUNT_REMOVED",
  "USER_CACHE_REFRESHED",
  "USER_HOLDING_ADDED",
  "USER_HOLDING_UPDATED",
  "USER_HOLDING_REMOVED",
  "USER_ACCOUNT_CLOSED",
  "USER_ACCOUNT_CLOSED_DATE_MOVED",
  "USER_TRANSACTION_CATEGORY_UPDATED",
  "USER_TRANSACTION_CATEGORY_DELETED",
  "USER_ACCOUNT_REOPENED",
  "SHOW_INVESTMENT_INCOME_CHANGE",
];

var uiPreferencesServerChanges = [
  "SHOW_INVESTMENT_INCOME_CHANGE",
  "ONUS_RETIREMENT_PAYMENT_TYPE_UPDATED",
];
var adviceServerChanges = ["USER_MESSAGE_UPDATED", "USER_MESSAGE_DELETED"];

var advisorServerChanges = [
  "USER_APPOINTMENT_SET",
  "USER_APPOINTMENT_UPDATED",
  "USER_APPOINTMENT_CANCELLED",
  "UPDATE_ADVISOR",
];

var transactionCategoriesServerChanges = [
  "USER_TRANSACTION_CATEGORY_UPDATED",
  "USER_TRANSACTION_CATEGORY_CREATED",
  "USER_TRANSACTION_CATEGORY_DELETED",
];

var plannerWhatIfServerChanges = ["UPDATED_RETIREMENT_PLAN"];

var completenessMeterServerChanges = ["COMPLETENESS_METER_UPDATED"];

var transactionTagsServerChanges = [
  "USER_TRANSACTION_TAG_CREATED",
  "USER_TRANSACTION_TAG_UPDATED",
  "USER_TRANSACTION_TAG_DELETED",
];

var transactionChanges = ["USER_TRANSACTION_UPDATED"].concat(
  accountServerChanges,
  transactionTagsServerChanges
);

const fpTopicServerChanges = ["USER_FP_TOPIC_UPDATED"];

const MIXPANEL_POLL_TIME = 30;
const MIXPANEL_POLL_TIMEOUT = 2000;

function getGACookie() {
  try {
    var docCookie = document.cookie,
      utmzIndex = docCookie.indexOf("__utmz");
    return utmzIndex >= 0 ? docCookie.substring(utmzIndex).split(";")[0] : "";
  } catch (e) {
    return "";
  }
}

var ensureMixpanelIsDefined = function (timeout) {
  var start = Date.now();

  function waitForMixpanel(resolve, reject) {
    if (window.mixpanel && window.mixpanel.get_distinct_id) resolve(true);
    else if (timeout && Date.now() - start >= timeout)
      reject(
        new Error("Mixpanel failed to load prior to calling trackEvent()")
      );
    else
      setTimeout(() => {
        waitForMixpanel(resolve, reject);
      }, MIXPANEL_POLL_TIME);
  }

  return new Promise(waitForMixpanel);
};

var marketingServerChanges = function () {
  return {
    USER_REGISTERED: 1,
    USER_AGGREGATED_100K_UBER: 2,
    USER_AGGREGATED_FIRST_ACCOUNT: 3,
    response: function (req, res) {
      if (res.data && res.data.spHeader && res.data.spHeader.SP_DATA_CHANGES) {
        var serverChanges = res.data.spHeader.SP_DATA_CHANGES;
        let plaUtils;
        if (IS_EMPOWER) {
          plaUtils = window.postLoginActions?.utils;
        } else {
          plaUtils =
            window.AppRouter &&
            window.AppRouter.postLoginActions &&
            window.AppRouter.postLoginActions.utils;
        }

        for (var i = 0; i < serverChanges.length; i++) {
          switch (serverChanges[i].eventType) {
            case "USER_REGISTERED":
              analytics.sendPageView("/completed-registration");
              /*
                The event 'Registration' fails to log intermittently because Mixpanel might not be defined.
                Added polling promise to check if window.mixpanel and window.mixpanel.get_distinct_id is defined.
              */
              ensureMixpanelIsDefined(MIXPANEL_POLL_TIMEOUT)
                .then(() => {
                  Mixpanel.trackEvent(
                    "Registration",
                    {},
                    {
                      // eslint-disable-next-line camelcase
                      send_immediately: true,
                    }
                  );
                  Mixpanel.createProfile();
                })
                .catch((error) => {
                  analytics.sendEngineeringEvent("Error", error.message);
                });
              break;
            case "USER_AGGREGATED_100K_UBER":
              analytics.sendPageView("/user-aggregated-100k");
              break;
            case "USER_135K_IAV_ACHIEVED":
              analytics.sendPageView("/user-135k");
              break;
            case "USER_135K_QQ_ACHIEVED":
              analytics.sendPageView("/user-135k");
              break;
            case "USER_100K_PAV_ACHIEVED":
              analytics.sendPageView("/user-pav-100k");
              break;
            case "USER_150K_PAV_ACHIEVED":
              analytics.sendPageView("/user-aggregated-150k");
              break;
            case "USER_AGGREGATED_FIRST_ACCOUNT":
              analytics.sendPageView("/user-aggregated-first-account");
              break;
            case "USER_25K_PAV_ACHIEVED":
              analytics.sendPageView("/user-aggregated-25k");
              break;
            case "FIRST_USER_MEETING_SET":
              analytics.sendPageView("/first-user-meeting-set");
              break;
            case "USER_APPOINTMENT_SET":
              analytics.sendPageView("/user-appointment-set");
              if (plaUtils) {
                plaUtils.getLatestUserMessages({
                  screenFor: [USER_MSG_TYPE.APP_WIDE_BANNER],
                  isFromServerChangeEvent: true,
                });
              }
              break;
            case "COMPLETENESS_METER_UPDATED":
              // Call completeness meter api and update widget UI accordingly
              break;
            case "USER_MESSAGE_UPDATED":
              if (plaUtils) {
                plaUtils.getLatestUserMessages({
                  screenFor: [USER_MSG_TYPE.INTERJECTION],
                  showBlankDisplayLocationInterjection: true,
                  isFromServerChangeEvent: true,
                });
              }
              break;
            case "USER_QUALIFIED":
              fireGoogleTagManagerEvent(
                [
                  serverChanges[i].eventType,
                  serverChanges[i].details.qualifiedThreshold,
                ].join("_"),
                {
                  highWaterMarkRange:
                    serverChanges[i].details.highWaterMarkRange,
                  type: serverChanges[i].details.type,
                }
              );
              break;
            default:
          }
        }
      }
    },
    recordPixel: function (number) {
      try {
        var cookieString = getGACookie(),
          cookieValues = cookieString ? cookieString.split("|") : [],
          localUserGuid = globals.userGuid,
          source,
          medium,
          campaign,
          cookieValue,
          valueIndex;

        for (var i = 0; i < cookieValues.length; i++) {
          cookieValue = cookieValues[i];
          valueIndex = cookieValue.indexOf("utmscr=");
          if (valueIndex !== -1) {
            source = cookieValue.slice(
              valueIndex + 7 /* disable preexisting warning */ // eslint-disable-line no-magic-numbers
            );
          }
          valueIndex = cookieValue.indexOf("utmcmd=");
          if (valueIndex !== -1) {
            medium = cookieValue.slice(
              valueIndex + 7 /* disable preexisting warning */ // eslint-disable-line no-magic-numbers
            );
          }
          valueIndex = cookieValue.indexOf("utmccn=");
          if (valueIndex !== -1) {
            campaign = cookieValue.slice(
              valueIndex + 7 /* disable preexisting warning */ // eslint-disable-line no-magic-numbers
            );
          }
        }

        var rand = String(Math.random());
        rand = rand * 100000; /* disable preexisting warning */ // eslint-disable-line no-magic-numbers
        var html =
          '<iframe src="//ad.wsod.com/activity/3d6bcab09d0adfe7fd69dcc5d841e73a/' +
          number +
          ".iframe.activity/" +
          rand +
          ";userGuid=" +
          localUserGuid +
          ";tpid1=" +
          source +
          "_" +
          medium +
          "_" +
          campaign +
          '" width="1" height="1" frameborder="0"></iframe>';
        var trackingdiv = document.createElement("div");
        trackingdiv.innerHTML = html;
        document.body.appendChild(trackingdiv);
      } catch (err) {
        log("Could not track " + number + " through wsod");
        log(err);
      }
    },
  };
};

var MainServices = new Services.Service()
  .use(Services.ensureParams())
  .use(cache)
  .use(Services.serverChanges(cache))
  .use(Services.csrfToken(globals.csrf))
  .use(Services.clientId())
  .use(marketingServerChanges())
  .use(Services.unauthRedirect())
  .map({
    Accounts: {
      get: {
        url: "/api/newaccount/getAccounts2",
        cache: true,
        serverChanges: accountServerChanges,
      },
      getAccountsBySite: {
        url: "/api/newaccount/getAccountsBySite",
      },
      refresh: "/api/newaccount/refreshAccounts",
      update: "/api/newaccount/updateAggregatedAccount",
      types: {
        url: "/api/account/getAccountTypes",
        cache: true,
      },
      close: "/api/newaccount/closeAccount",
      remove: "/api/newaccount/removeAccounts",
      removeManualAccounts: "/api/newaccount/removeManualAccounts",
      removeDisabledAccount: "/api/newaccount/removeDisabledAccount",
      updateManual: "/api/newaccount/updateAccount",
      updateCredentials: "/api/newaccount/updateCredentials",
      getRetirementCashFlow: "/api/account/getRetirementCashFlow",
      setRetirementCashFlow: "/api/account/setRetirementCashFlow",
      updateRetirementCashFlow: "/api/account/updateRetirementCashFlow",
      getAnnualCashFlow: "/api/account/getAnnualCashFlow",
      getAnnualCashFlowWithWhatIf: "/api/account/getAnnualCashFlowWithWhatIf",
      getProductAccounts: {
        url: "/api/newaccount/getProductAccounts",
        cache: true,
        serverChanges: accountServerChanges,
      },
      create: "/api/newaccount/createAccounts2",
      refreshAccountsWithExtendedDetails:
        "/api/newaccount/refreshAccountsWithExtendedDetails",
      updateAccountOverrides: "/api/newaccount/updateAccountOverrides",
      reopen: "/api/newaccount/reopenAccount",
      getContributionLimits: {
        url: "/api/account/getAccountContributionLimits",
        cache: true,
      },
      getAssociatedAccounts: "/api/newaccount/getAssociatedAccounts",
    },
    PersonAccounts: {
      get: "/api/newaccount/getPersonAccounts",
      save: "/api/newaccount/savePersonAccounts",
      assign: "/api/newaccount/assignAccountsToPersons",
      unassign: "/api/newaccount/unassignAccountsFromPersons",
    },
    AccountTypes: {
      get: "/api/account/getAccountTypes2",
    },
    BillReminders: {
      get: {
        url: "/api/account/getBillReminders",
        cache: true,
        serverChanges: accountServerChanges,
      },
      update: "/api/account/updateBillReminder",
    },
    CompletenessMeter: {
      get: {
        url: "/api/completeness/getMeterInfo2",
        cache: true,
        serverChanges: completenessMeterServerChanges,
      },
      update: {
        url: "/api/completeness/updateMeterInfo",
        cache: true,
        serverChanges: completenessMeterServerChanges,
      },
    },
    UserMessages: {
      get: {
        url: "/api/message/getUserMessages",
        cache: true,
        serverChanges: adviceServerChanges,
      },
      getInsights: {
        url: "/api/message/getInsights",
        cache: true,
        serverChanges: adviceServerChanges,
      },
      getV2: {
        url: "/api/message/getUserMessages",
        cache: true,
        serverChanges: adviceServerChanges,
      },
      getUncache: {
        url: "/api/message/getUserMessages",
        cache: false,
      },
      read: {
        url: "/api/message/getUserMessages",
        cache: true,
        serverChanges: adviceServerChanges,
      },
      updateV2: {
        url: "/api/message/updateUserMessages",
        cache: false,
      },
      addComment: {
        url: "/api/message/addComment",
        cache: false,
      },
      update: "/api/message/updateUserMessages",
    },
    UserMessage: {
      markViewed: "/api/message/updateUserMessages",
    },
    UserResponse: {
      add: "/api/userresponse/add",
      getByQuestionGroupName: "/api/userresponse/getByQuestionGroupName",
    },
    Transactions: {
      get: {
        url: "/api/transaction/getUserTransactions",
        cache: true,
        serverChanges: transactionChanges,
      },
      getTopByCashFlow: "/api/transaction/getTopCashFlowTransactions",
      update: "/api/transaction/updateUserTransaction",
      update2: "/api/transaction/updateUserTransaction2",
      updates: "/api/transaction/updateUserTransactions",
      updates2: "/api/transaction/updateUserTransactions2",
      updateTransactionSplits: "/api/transaction/updateTransactionSplits",
      cancel: "/api/transaction/cancelTransaction",
      removeTransactionImage: "/api/transaction/removeTransactionImage",
      delete: "/api/transaction/deleteManualTransaction",
      add: "/api/transaction/createUserTransaction",
    },
    Tags: {
      get: {
        url: "/api/transactiontag/getTags",
        cache: true,
        serverChanges: transactionTagsServerChanges,
      },
      create: "/api/transactiontag/addTag",
      update: "/api/transactiontag/updateTag",
      delete: "/api/transactiontag/removeTag",
    },
    TransactionCategories: {
      get: {
        url: "/api/transactioncategory/getCategories",
        cache: true,
        serverChanges: transactionCategoriesServerChanges,
      },
      create: {
        url: "/api/transactioncategory/addCategory",
      },
      remove: {
        url: "/api/transactioncategory/removeCategory",
      },
      update: {
        url: "/api/transactioncategory/updateCategory",
      },
    },
    TransactionTypes: {
      get: {
        url: "/api/transaction/getTransactionTypes",
        cache: true,
      },
    },
    Histories: {
      get: {
        url: "/api/account/getHistories",
        cache: true,
        serverChanges: accountServerChanges,
      },
      getV2: {
        url: "/api/account/getHistoriesForOneYear",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    SponsorPlanHistories: {
      get: {
        url: "/api/account/getSponsorPlanHistories",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    PerformanceHistories: {
      get: {
        url: "/api/account/getPerformanceHistories",
        cache: true,
        serverChanges: accountServerChanges,
      },
      getV2: {
        url: "/api/account/getPerformanceHistoriesForOneYear",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },

    GrantHistories: {
      get: {
        url: "/api/esog/getGrantHistories",
        cache: true,
        serverChanges: accountServerChanges,
      },
      update: "/api/esog/updateGrant",
      remove: "/api/esog/removeGrant",
    },

    Companies: {
      get: {
        url: "/api/esog/getCompanies",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },

    MarketMovers: {
      get: {
        url: "/api/invest/getMarketMovers",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },

    Quotes: {
      get: {
        url: "/api/invest/getQuotes",
        cache: true,
        serverChanges: accountServerChanges,
      },
      getV2: {
        url: "/api/invest/getQuotesForOneYear",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    NetWorth: {
      get: {
        url: "/api/account/getNetworthHistories",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    Advisor: {
      get: {
        url: "/api/profile/getAdvisor",
        cache: true,
        serverChanges: advisorServerChanges,
      },
    },
    AdvisorInteraction: {
      getEmailReplies: "/api/advisorinteraction/getEmailReplies",
    },
    ClassifiedHoldings: {
      get: {
        url: "/api/invest/getClassifiedHoldings",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    GetSite: {
      get: "/api/site/getSite",
    },
    SearchSites: {
      get: "/api/search/searchSites",
    },
    AddAccount: {
      post: "/api/newaccount/createAggregatedAccount",
      postByProductId: "/api/newaccount/createAccounts",
    },
    Feedback: {
      send: "/api/feedback/logFeedback",
    },
    EmailSupport: {
      send: "/api/profile/emailUs",
    },
    SubmitSupportTicket: {
      send: "/api/profile/submitSupportTicket",
    },
    InvestmentProfile: {
      get: "/api/profile/getAdvisorInterviewInfo",
      update: "/api/profile/updateAdvisorInterviewInfo",
    },
    GetPopular401kProducts: {
      get: {
        url: "/api/search/getPopular401Kproducts",
        cache: true,
      },
    },
    Get401kFees: {
      get: "/api/fundfee/get401kFundFees",
    },
    _401kQuestionnaire: {
      get: "/api/profile/getQuestionnaire",
      post: "/api/profile/updateQuestionnaire",
    },
    Holdings: {
      get: {
        url: "/api/invest/getHoldings",
        cache: true,
        serverChanges: accountServerChanges,
      },
      post: "/api/invest/updateSecurityInfo",
      update: "/api/account/updateHolding",
      create: "/api/account/addHolding",
      remove: "/api/account/removeHolding",
    },
    Crypto: {
      getCryptoCoins: "/api/invest/getCryptoCoins",
    },
    ManualClassification: {
      getAssetClassification: {
        url: "/api/invest/getAssetClassification",
      },
      updateAssetClassification: {
        url: "/api/invest/updateAssetClassification",
      },
    },
    Grants: {
      create: "/api/esog/addGrant",
      remove: "/api/esog/removeGrant",
    },

    Securities: {
      get: "/api/invest/searchSecurity",
    },
    InvestmentCheckup: {
      get: "/api/invest/getInvestmentCheckup",
      getCached: {
        url: "/api/invest/getInvestmentCheckup",
        cache: true,
      },
      getV2: "/api/invest/getInvestmentCheckup2",
      submitAction: "/api/invest/submitInvestmentCheckupAction",
      update: "/api/invest/updateInvestmentCheckup",
      projectedFundFees: "/api/fundfee/getFeeProjectionData",
    },
    myLife: {
      get: "/api/invest/getMyLife",
      getInput: "/api/invest/getMyLifeInput",
      savePlan: "/api/invest/saveMyLifePlan",
      deletePlan: "/api/invest/deleteMyLifePlan",
      getPlan: "/api/invest/getMyLifePlan",
      getPlans: {
        url: "/api/invest/getMyLifePlans",
        cache: true,
        serverChanges: plannerWhatIfServerChanges,
      },
      saveGoals: "/api/invest/saveMyLifeGoals",
      removeGoals: "/api/invest/removeMyLifeGoals",
      getGoals: "/api/invest/getMyLifeGoals",
      compareInputsWithMaster: "/api/invest/compareMyLifePlanInputsWithMaster",
      clonePlan: "/api/invest/cloneMyLifePlan",
      clonePlanByAdvisor: "/api/invest/cloneMyLifePlanByAdvisor",
      changePlanStatus: "/api/invest/changeMyLifePlanStatus",
      getV2: "/api/invest/getMyLife2",
      clonePlanMarket: "/api/invest/cloneMyLifePlanMarketScenario",
    },
    Recommendations: {
      get: "/api/invest/getRecommendation",
    },
    Person: {
      get: "/api/person/getPerson",
      getUncache: {
        url: "/api/person/getPerson",
        cache: false,
      },
      getAll: "/api/person/getPersons",
      create: "/api/person/createPerson",
      update: "/api/person/updatePerson",
      delete: "/api/person/deletePerson",
      savingsDetail: "/api/mtr/getSavingsDetail",
    },
    Enrollment: {
      get: "/api/enrollment/getEnrollments",
      save: "/api/enrollment/saveEnrollment",
      saveFunding: "/api/enrollment/saveFunding",
      submit: "/api/enrollment/submitEnrollment",
    },
    HoldingsByType: {
      get: {
        url: "/api/invest/getHoldingsByType",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    Payment: {
      getStripePaymentUrl: {
        url: "/api/payment/getStripePaymentUrl",
      },
      getStripeUpdatePaymentUrl: {
        url: "/api/payment/getStripeUpdatePaymentUrl",
      },
      updateOnUsRetirementPaymentType: {
        url: "/api/payment/updateOnUsRetirementPaymentType",
      },
    },
    Profile: {
      get: {
        url: "/api/profile/getUserProfile",
        cache: true,
        serverChanges: [
          "PROFILE_UPDATED",
          "DEVICE_UNREGISTERED_TOTP",
          "DEVICE_REGISTERED_TOTP",
        ],
      },
      getUncache: {
        url: "/api/profile/getUserProfile",
        cache: false,
      },
      updateUsername: "/api/profile/updateUserEmail", // {username: oldUsername, email: newUserName}
      updatePreferences: "/api/profile/updatePreferences",
      getUserPreferences: {
        url: "/api/profile/getUserPreferences",
        cache: true,
        serverChanges: ["PROFILE_UPDATED"],
      },
      updateUserEmailPreferences: "/api/profile/updateUserEmailPreferences", // {daily: daily, weekly: weekly}
      updateUserSitePreferences: {
        url: "/api/profile/updateUserSitePreferences", // {: userSiteId: userSiteId, subscribeForNotification: true or false}
      },
      updateUserFeatureByAdvisor: "/api/profile/updateUserFeatureByAdvisor",
      updateUserContact: "/api/profile/updateUserContact", // {contactId: contactId, flags:"v", phoneNumber: phoneNumber}
      removeUser: "/api/profile/deleteUser", // {email: username}
      unbindDevice: "/api/profile/unbindDevice", // {deviceId: deviceId}
      updateUserProfile: "/api/profile/updateUserProfile", // {flags: flags, passwd: passwd, oldPasswd: oldPasswd}
      getBoundDevices: {
        url: "/api/profile/getBoundDevices",
        cache: true,
        serverChanges: ["PROFILE_UPDATED"],
      },
      getFunnelAttributes: "/api/profile/getFunnelAttributes",
      getDeviceDetails: "/api/profile/getDeviceDetails",
      bindDevice: "/api/profile/bindDevice",
      getUIPreferences: {
        url: "/api/profile/getUIPreferences",
        cache: true,
        serverChanges: uiPreferencesServerChanges,
      },
      getUIPreferencesNoCache: {
        url: "/api/profile/getUIPreferences",
        cache: false,
        serverChanges: uiPreferencesServerChanges,
      },
      getSponsorUIPreferences: {
        url: "/api/sponsor/getUIPreferences",
      },
      updateUIPreferences: "/api/profile/updateUIPreferences",
      updateDevice: "/api/profile/updateDevice", // {deviceId: deviceId, deviceName: deviceName}
      updateTwoWayImage: "/api/image/updateTwoWayImage",
      getReferredUsers: "/api/profile/getReferredUsers",
      getPcbRates: "/api/profile/getPCBRateData",
      isUserDeletionEligible: "/api/profile/isUserDeletionEligible",
    },
    PostLoginAction: {
      get: {
        url: "/api/profile/getPostLoginAction",
        cache: false,
      },
    },
    _401kVerification: {
      challengeEmail: {
        url: "/api/credential/challengeEmail",
        cache: false,
      },
      verifyEmail: {
        url: "/api/credential/authenticateEmailByCode",
        cache: false,
      },
      verifySSN: {
        url: "/api/credential/authenticateSsn",
        cache: false,
      },
    },
    DailyCapitalFeeds: {
      getWeeklyCommentary: {
        url:
          document.location.protocol +
          "//ajax.googleapis.com/ajax/services/feed/load?v=1.0&num=10&callback=?&q=" +
          encodeURIComponent(
            "https://www.personalcapital.com/blog/feed/?cat=3,891,890,68,284"
          ),
        cache: false,
      },
      getWhitePapers: {
        url:
          document.location.protocol +
          "//ajax.googleapis.com/ajax/services/feed/load?v=1.0&num=40&callback=?&q=" +
          encodeURIComponent(
            "https://www.personalcapital.com/blog/category/whitepapers/feed/"
          ),
        cache: false,
      },
    },
    MarketingNumbers: {
      get: {
        url:
          document.location.protocol +
          "//www.personalcapital.com/api/data/marketing-numbers?callback=?",
        cache: false,
      },
    },
    OnUs401KSiteCredential: {
      get: "/api/site/getOnUs401KSiteCredential",
    },
    OnUs401kPartnerSSO: {
      get: "/api/ltlogin/ltDoSsoGatekeeper",
    },
    Documents: {
      get: "/api/filecabinet/getEdocuments",
      delete: "/api/filecabinet/removeDocument",
      update: "/api/filecabinet/updateDocument",
    },
    CustomProducts: {
      get: "/api/search/getCustomProducts",
    },
    PopularSitesAndProducts: {
      get: {
        url: "/api/search/getPopularSitesAndProducts",
        cache: true,
      },
    },
    PopularSites: {
      get: "/api/search/getPopularSites",
    },
    Credentials: {
      authenticatePassword: "/api/credential/authenticatePassword",
      enrollTotp: "/api/credential/enrollTotp",
      getTotpRecoveryCodes: {
        url: "/api/credential/getTotpRecoveryCodes",
        cache: true,
        serverChanges: ["DEVICE_REGISTERED_TOTP"],
      },
    },
    SecurityImage: {
      getCategories: {
        url: "/api/image/browseTwoWayImageCategories",
        cache: true,
      },
      getImagesForCategory: "/api/image/browseTwoWayImages",
    },
    Proposal: {
      get: "/api/proposal/getProposal",
      updateStats: "/api/proposal/updateProposalViewStats",
    },
    Proposals: {
      get: "/api/proposal/getProposals",
    },
    Signout: {
      get: "/api/login/switchUser",
    },
    Login: {
      keepAlive: "/api/login/keepalive",
    },
    Appointment: {
      get: "/api/appointment/getAppointments",
      getAvailabilities: "/api/appointment/getAvailabilities",
      create: "/api/appointment/createAppointment",
      cancel: "/api/appointment/cancelAppointment",
      getAvailabilitiesForDate: "/api/appointment/getAppointmentsForDate",
      update: "/api/appointment/updateAppointment",
      getEligibleMeetings: "/api/appointment/getEligibleMeetings",
      getAllUserAppointments: "/api/appointment/getAllUserAppointments",
      getAllAppointmentTypes: {
        url: "/api/appointment/getAllAppointmentTypes",
        cache: true,
      },
      getFpTopics: "/api/appointment/getFPTopicsForAppointment",
      addPromoInSalesforce: "/api/appointment/addPromoInSalesforce",
    },
    UserEvent: {
      createUserEvent: "/api/userevent/createUserEvent",
      createTrackingEvent: "/api/userevent/createTrackingEvent",
    },
    Limited: {
      getProfile: {
        url: "/api/limited/getUserProfile",
        cache: true,
        serverChanges: ["PROFILE_UPDATED"],
      },
      getAppointments: "/api/limited/getAppointments",
      getAvailabilities: "/api/limited/getAvailabilities",
      createAppointment: "/api/limited/createAppointment",
      updateAppointment: "/api/limited/updateAppointment",
      cancelAppointment: "/api/limited/cancelAppointment",
      getEligibleMeetings: "/api/limited/getEligibleMeetings",
      getAdvisor: {
        url: "/api/limited/getAdvisor",
        cache: true,
        serverChanges: advisorServerChanges,
      },
      updateUserEmailPreferences: "/api/limited/updateUserEmailPreferences",
    },
    FinancialPlanner: {
      get: "/api/financialplanner/getFinancialPlanner",
    },
    CollegePlanner: {
      getInput: "/api/financialplanner/getCollegePlannerInput",
      getAverageTuition: "/api/financialplanner/getStateAverageTuitions",
      run: "/api/financialplanner/runCollegePlanner",
      save: "/api/financialplanner/saveCollegePlanner",
      search: "/api/financialplanner/searchCollege",
      getStateAverage: "/api/financialplanner/getStateAverageTuitions",
    },
    InsurancePlanner: {
      getInput: "/api/financialplanner/getInsurancePlannerInput",
      run: "/api/financialplanner/runInsurancePlanner",
      save: "/api/financialplanner/saveInsurancePlanner",
    },
    EstatePlanner: {
      getInput: "/api/financialplanner/getEstatePlannerInput",
      run: "/api/financialplanner/runEstatePlanner",
      save: "/api/financialplanner/saveEstatePlanner",
    },
    SavingsManager: {
      getInput: "/api/financialplanner/getCashFlowPlannerInput",
      run: {
        url: "/api/financialplanner/runCashFlowPlanner",
        cache: true,
        serverChanges: accountServerChanges,
      },
      save: "/api/financialplanner/saveCashFlowPlanner",
    },
    EmployerSponsoredRetirementPlanner: {
      getInput: "/api/financialplanner/get401kPlannerInput",
      save: "/api/financialplanner/save401kPlanner",
    },
    RetirementIncome: {
      get: "/api/invest/getRetirementIncome",
    },
    Spending: {
      get: "/api/account/getUserSpending",
      setTarget: "/api/account/setTargetSpending",
    },
    Strategy: {
      get: {
        url: "/api/invest/getMyStrategy",
        cache: true,
      },
      getStrategies: {
        url: "/api/sponsor/getStrategies",
      },
      getHouseholdStrategies: {
        url: "/api/strategy/getHouseholdStrategies",
      },
      generate: {
        url: "/api/sponsor/generateStrategy",
      },
      accept: {
        url: "/api/sponsor/acceptStrategy",
      },
      reject: {
        url: "/api/sponsor/rejectStrategy",
      },
      getRecommendation: {
        url: "/api/sponsor/getStrategyRecommendation",
      },
      getMarketCommentary: {
        url: "/api/invest/marketCommentaryContent",
        cache: true,
      },
      getTaxSavings: {
        url: "/api/invest/getTaxSavings",
      },
      saveStrategyReason: {
        url: "/api/strategy/strategyReason",
      },
      getTaxBenefit: {
        url: "/api/invest/getTaxBenefit",
      },
    },
    StrategyReview: {
      getRecommendation: {
        url: "/api/strategy/getStrategyRecommendation",
      },
      generateRecommendation: {
        url: "/api/strategy/generateStrategyRecommendation",
      },
      getInput: {
        url: "/api/strategy/getStrategyReviewInput",
      },
      selectNewStrategy: {
        url: "/api/strategy/selectStrategy",
      },
      strategyReview: {
        url: "/api/strategy/strategyReview",
      },
    },
    RiskTolerance: {
      getInput: {
        url: "/api/strategy/getRiskToleranceInput",
      },
      run: {
        url: "/api/strategy/runRiskToleranceRec",
      },
      save: {
        url: "/api/strategy/saveRiskToleranceRec",
      },
    },
    SocialSecurity: {
      getRetirementPlanCheck: {
        url: "/api/invest/getRetirementPlanCheckData",
      },
    },
    StandingFundingInstructions: {
      get: {
        url: "/api/newaccount/getStandingFundingInstructions",
        cache: true,
      },
    },
    StrategyProjection: {
      get: {
        url: "/api/invest/getStrategyProjection",
      },
    },
    SponsorProjection: {
      get: {
        url: "/api/sponsor/getProjection",
      },
    },
    RecurringContribution: {
      update: "/api/invest/updateRecurringContribution",
    },
    Contribution: {
      get: "/api/sponsor/getCurrentContribution",
    },
    PlanElections: {
      get: "/api/sponsor/getPlanElections",
      update: "/api/sponsor/updatePlanElections",
      createMoneyTransfer: "/api/sponsor/createMoneyTransfer",
      getMoneyTransferStatus: "/api/sponsor/getMoneyTransferStatus",
    },
    SponsorUIPreferences: {
      get: {
        url: "/api/sponsor/getUIPreferences",
      },
    },
    SponsorPerformance: {
      get: {
        url: "/api/sponsor/getPartnerPerformance",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    SponsorFundAllocations: {
      get: {
        url: "/api/sponsor/getFundAllocation",
      },
    },
    SponsorEnrollmentStatus: {
      update: {
        url: "/api/sponsor/updateEnrollmentStatus",
      },
    },
    PendingActivity: {
      cancel: {
        url: "/api/sponsor/cancelPendingActivity",
      },
    },
    PCBEnrollment: {
      create: "/api/pcbenrollment/createEnrollment",
      update: "/api/pcbenrollment/updateEnrollment",
      updateV2: "/api/pcbenrollment/updateEnrollmentV2",
      submit: "/api/pcbenrollment/submitEnrollment",
      getAll: "/api/pcbenrollment/getEnrollments",
      get: {
        url: "/api/pcbenrollment/getEnrollment",
        cache: true,
        serverChanges: ["PCB_ENROLLMENT_UPDATED", "PCB_ENROLLMENT_RETRY"],
      },
      verifyIdentity: "/api/pcbenrollment/verifyIdentity",
      cancel: "/api/pcbenrollment/cancelEnrollment",
    },
    PCBMicroDeposit: {
      initiate: "/api/pcbmicrodeposit/initiate",
      verify: "/api/pcbmicrodeposit/verify",
    },

    PCBAccount: {
      captureAccountDetails: "/api/pcbaccount/captureAccountDetails2",
      sweepDetails: "/api/pcbaccount/sweepDetails",
      accountInfo: "/api/pcbaccount/accountInfo",
      closeAccount: "/api/pcbaccount/closeManagedCashUserAccount",
      getCloseAccountReadiness:
        "/api/pcbaccount/getCashAccountCloseEventReadiness",
    },
    Transfer: {
      getTransferAdditionalFieldInfo:
        "/api/transfer/getTransferAdditionalFieldInfo",
      captureAccountDetails: "/api/transfer/captureAccountDetails",
      getStateForTransfer: {
        url: "/api/transfer/getAccountStateForTransfer",
        cache: true,
        serverChanges: [
          "USER_SITE_UPDATED",
          "USER_ACCOUNT_TRANSFER_STATE_UPDATED",
          "USER_ACCOUNT_CLOSED",
          "PCB_ENROLLMENT_UPDATED",
        ],
      },
      submitTransfer: "/api/transfer/submitTransfer",
      updateTransferInstruction: "/api/transfer/updateTransferInstruction",
      getTransferInstruction: "/api/transfer/getTransferInstructions",
      getRecentTransfers: "/api/transfer/getRecentTransfers",
      deleteTransferInstruction: "/api/transfer/deleteTransferInstruction",
      getTaxWithholdingsMinimum: "/api/taxWithholding/getTaxWithholdingMinimum",
      getTotalAmountAfterTaxForWithdrawal:
        "/api/taxWithholding/getTotalAmountAfterTaxForWithdrawal",
      getRequiredMinimumDistributions: "/api/getRequiredMinimumDistributions",
    },
    TransferManager: {
      getTransfers: "/api/transfer/getTransfers",
      getTransfersV3: "/api/transfer/getTransfersV3",
    },
    EmergencySavings: {
      get: {
        url: "/api/financialplanner/getEmergencySavingsHistory",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    DebtHistory: {
      get: {
        url: "/api/financialplanner/getDebtPaymentHistory",
        cache: true,
        serverChanges: accountServerChanges,
      },
    },
    AccountInfo: {
      update: "/api/account/updateAccountInfo",
    },
    TargetSavings: {
      get: "/api/account/getTargetSaving",
      update: "/api/account/setTargetSaving",
    },
    PlanningTopics: {
      get: "/api/fptopic/getTopics",
    },
    UserPlanningTopics: {
      get: "/api/fptopicuser/getUserTopics",
      getTarget: "/api/fptopicuser/getTargetTopics",
      update: "/api/fptopicuser/updateUserTopics",
      updateUserTopicFeatures: "/api/fptopicuser/updateUserTopicFeatures",
    },
    Delegate: {
      add: "/api/delegate/addPersonDelegate",
      remove: "/api/delegate/deletePersonDelegate",
      resend: "/api/delegate/resendInvite",
      get: "/api/delegate/getPersonDelegates",
    },
    Milestones: {
      get: {
        url: "/api/milestone/getUserMilestones2",
        cache: true,
        serverChanges: fpTopicServerChanges,
      },
      update: "/api/milestone/updateUserMilestoneStatus",
      updateMilestone: "/api/milestone/updateUserMilestone",
      getHistory: "/api/milestone/getUserMilestonesHistory",
      submitMessage: "/api/financialplanner/submitMilestoneMessage",
      getDocuments: "/api/filecabinet/getMilestoneDocument",
      getSuggestions: "/api/milestone/getMilestoneSuggestions",
    },
    EquityCompensation: {
      getInput: "/api/milestone/getEquityCompensationInput",
      getTypes: "/api/milestone/getEquityCompensationTypes",
    },
    Privacy: {
      requestReport: "/api/privacy/createRequest",
      getRequests: "/api/privacy/getRequests",
      getRequest: "/api/privacy/getRequest",
    },
    SponsorMatchInfo: {
      update: "/api/financialplanner/updateSponsorMatchInfo",
    },
    Sponsor: {
      update: "/api/financialplanner/updateSponsor",
    },
    Referral: {
      shareViaEmail: "/api/referral/share/email",
    },
    PartnerSSO: {
      signOnHelper: "/api/empower/sso/ssoTestHelper",
      signOn: "/api/empower/sso/saml2",
    },
    Invest: {
      getGains: "/api/invest/getGains",
    },
    Investment: {
      get: "/api/empower/investment/profile/account/get",
      save: "/api/empower/investment/profile/account/save",
    },
    SendMessage: {
      dolProspectLastDeliveryDate:
        "/api/advisorSendMessage/dolProspectEmail/lastDeliveryDate",

      dolClientLastDeliveryDate:
        "/api/advisorSendMessage/dolClientEmail/lastDeliveryDate",

      dolProspectEmail: "/api/advisorSendMessage/dolProspectEmail",
      dolClientEmail: "/api/advisorSendMessage/dolClientEmail",
    },
    MyTotalRetirement: {
      get: "/api/mtr/getMyTotalRetirement",
    },
    AdviceProfileParams: {
      get: "/api/mtr/getAdviceProfileParams",
    },
    InvestmentStrategy: {
      get: "/api/mtr/getInvestmentStrategy",
    },
    Gmwb: {
      getGMWBData: "/api/mtr/getGMWBData",
      updateGMWBData: "/api/mtr/updateGMWBData",
    },
    UserMtrService: {
      getEnrollmentConfirmationBySponsor:
        "/api/mtr/getEnrollmentConfirmationBySponsor",
      getEnrollmentConfirmation: "/api/mtr/getEnrollmentConfirmation",
      getEnrolledConfirmation: "/api/mtr/getEnrolledConfirmation",
      updateEnrollmentStatus: "/api/mtr/updateEnrollmentStatus",
      updateUnenrollmentReason: "/api/mtr/updateUnenrollmentReason",
      getEnrolledConfirmationBySponsor:
        "/api/mtr/getEnrolledConfirmationBySponsor",
      getEnrollmentStatusBySponsor: "/api/mtr/getEnrollmentStatusBySponsor",
      getEnrollmentFees: "/api/mtr/getEnrollmentFees",
      getParticipantAccountManagementHistory:
        "/api/mtr/getParticipantAccountManagementHistory",
    },
    EmpowerAccounts: {
      get: "/api/empower/enrollment/account/get",
    },
    EmpowerAdvisor: {
      get: "/api/empower/advisor/getAdvisor",
    },
    EmpowerEnrollment: {
      save: "/api/empower/enrollment/save",
      submit: "/api/empower/enrollment/submit",
      cip: "/api/empower/enrollment/cip2",
      get: "/api/empower/enrollment/get",
      verify: "/api/empower/enrollment/verify",
    },
    EmpowerAgreement: {
      sign: "/api/empower/enrollment/agreement/sign",
    },
    EmpowerPerson: {
      get: "/api/empower/investment/profile/person/get",
      save: "/api/empower/investment/profile/person/save",
    },
    EmpowerInvestment: {
      fundTransfer: "/api/empower/investment/fundtofundtransfer",
      fundOptions: "/api/empower/investment/fundOptions",
      save: "/api/empower/investment/save",
      getInvestmentSelection: "/api/empower/investment/getinvestmentselection",
      advice: "/api/empower/investment/advice/getinvestmentadvice",
      rebalance: "/api/empower/investment/rebalance",
      prevalidateTransaction: "/api/empower/investment/prevalidateTransaction",
      getTradingBlackoutPeriod:
        "/api/empower/investment/getTradingBlackoutPeriod",
      getInvestmentTransfers: "/api/empower/investment/getInvestmentTransfers",
      getFeatureHealthAvailabilityInfo:
        "/api/empower/featureHealth/getAvailabilityInfo",
      getInvestmentHoldingAndSecurityInfo:
        "/api/empower/investment/getInvestmentHoldingAndSecurityInfo",
    },
    EmpowerUserInterfaceSetup: {
      preferences: "/api/empower/enrollment/uisetup/preferences",
      prompts: "/api/empower/enrollment/uisetup/prompts",
      preferencesv2: "/api/empower/enrollment/uisetup/preferencesv2",
    },
    Retirement: {
      getEnrollmentUrl: "/api/retirement/getEnrollmentUrl",
      getFixAccountUrl: "/api/retirement/getFixAccountUrl",
      createEnrollment: "/api/retirement/createEnrollment",
      updateEnrollment: "/api/retirement/updateEnrollment",
      getAccountEligibility: "/api/retirement/getAccountEligibility",
      actionTakenByClient: "/api/retirement/actionTakenByClient",
      updateToReestablishingConnection:
        "/api/retirement/updateToReestablishingConnection",
    },
    EmpowerReforecastHistory: {
      getReforecastHistories: "/api/mtr/getReforecastHistories",
      getMtrProfileChangeHistories: "/api/mtr/getMtrProfileChangeHistories",
    },
    EmpowerFunding: {
      save: "/api/empower/enrollment/fund/save",
      get: "/api/empower/enrollment/fund/get",
    },
    EmpowerContextProfile: {
      update: "/api/epcxs/participantProfile/updateUserContextProfile",
    },
    EmpowerUserContact: {
      update: "/api/epcxs/updateUserContact",
    },
    EmpowerRetailProduct: {
      getOptions: "/api/empower/retailproduct/getOptions",
      captureRetailOnboardingInsights:
        "/api/empower/retailproduct/captureRetailOnboardingInsights",
    },
    RefreshEmpowerLiat: {
      update: "/api/epcxs/refreshEmpowerData",
    },
    AdviceProfile: {
      getMaModelPortfolios: {
        url: "/api/mtr/getMaModelPortfolios",
        cache: true,
      },
      updateUserAdviceProfile: "/api/mtr/updateUserAdviceProfile",
    },
    Reforecast: {
      reforecast: "/api/mtr/reforecast",
    },
    DocumentType: {
      get: "/api/empower/onbase/document/getDocumentType",
    },
    Oauthaccount: {
      getConsent: "/api/oauthaccount/getConsent",
      acknowledgeConsent: "/api/oauthaccount/acknowledgeConsent",
    },
    SpecificAdvice: {
      executeTransaction: "/api/mtr/executeTransaction",
    },
    Stripe: {
      getCustomerPortalUrl: "/api/payment/getStripeCustomerPortalUrl",
    },
    Verify: {
      transfer: "/api/verify/verifyTransfer",
    },
    Fastlink: {
      getAuthenticationDetails: "/api/fastlink/getAuthenticationDetails",
      linkAccount: "/api/fastlink/linkAccount",
      events: "/api/fastlink/events",
    },
    edelivery: {
      get: "/api/empower/account/edelivery/getEdeliveryPreferences",
      update: "/api/empower/account/edelivery/updateEdeliveryPreference",
    },
    UserEventsByDateRange: {
      get: "/api/events/getUserEventsByDateRange",
    },
    AccountActions: {
      getAccountActions: "/api/empower/accountActions/getAccountActions",
      resendEmail: "/api/empower/docusign/resendEmail",
      getDocumentUrl: "/api/empower/docusign/getDocumentUrl",
    },
    CipVerificationState: {
      get: "/api/empower/cip/getVerificationState",
      update: "/api/empower/cip/updateVerificationState",
    },
    MarketingPreferences: {
      get: "/api/empower/account/marketingPreferences/getCustomerMarketingPreferences",
      update:
        "/api/empower/account/marketingPreferences/updateCustomerMarketingPreferences",
    },
    ManualAccountRecapture: {
      create: "/api/newaccount/createUnifiedManualAccounts", // TODO: waiting on backend to provide endpoint
    },
    AccountRoles: {
      save: "/api/empower/retailAccountRole/savePersonAccounts2",
      get: "/api/empower/retailAccountRole/getPersonAccounts2",
    },
  });

MainServices.cache = cache;

export default MainServices;
