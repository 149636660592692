/* eslint-disable react/require-default-props */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, once, noop } from "underscore";
import Header from "components/scheduling/Header";
import AppointmentTypeSelector from "components/scheduling/AppointmentTypeSelector";
import { AppointmentTypeSelectorBlank } from "components/scheduling/AppointmentTypeSelector";
import DateTimeSelector from "components/scheduling/DateTimeSelector";
import ThreeTimeSlotsAppointmentSelector from "components/scheduling/ThreeTimeSlotsAppointmentSelector";
import {
  FORMAT_DAY,
  FORMAT_TIME,
  FORMAT_TIME_TZ,
} from "components/scheduling/utils";
import AdviceDisclaimerModal from "components/modal/AdviceDisclaimerModal";
import { trackView, trackClick } from "components/common/ComponentAnalytics";
import FallingConfetti from "./animations/FallingConfetti";
import markUserMessageAsClicked from "libs/pcap/utils/markUserMessageAsClicked";
import eventBus from "eventBus";
import objectPath from "object-path";
import { getTimeRangeSegment } from "utils/appointmentUtils";

const RESTRICTED_TYPES = ["TAX_ANALYSIS", "SERVICE_WELCOME_CALL"];

const ADVISOR_APPOINTMENT_TYPES = [
  {
    value: "TAX_IMPLEMENTATION",
    label: "Tax Implementation Call",
    duration: 30,
    durations: [
      {
        label: "30 min",
        value: 30,
      },
      {
        label: "60 min",
        value: 60,
      },
    ],
  },
  {
    value: "SALES_FOLLOW_UP_CALL",
    label: "Sales Follow Up",
    duration: 30,
    durations: [
      {
        label: "30 min",
        value: 30,
      },
      {
        label: "60 min",
        value: 60,
      },
    ],
  },
  {
    value: "FOLLOW_UP",
    label: "Follow Up Call",
    duration: 30,
    durations: [
      {
        label: "30 min",
        value: 30,
      },
      {
        label: "60 min",
        value: 60,
      },
    ],
  },
  {
    value: "ENROLLMENT",
    label: "Enrollment",
    duration: 30,
    durations: [
      {
        label: "30 min",
        value: 30,
      },
      {
        label: "60 min",
        value: 60,
      },
    ],
  },
  {
    value: "ENROLLMENT_FOLLOW_UP",
    label: "Enrollment Follow Up",
    duration: 30,
    durations: [
      {
        label: "30 min",
        value: 30,
      },
      {
        label: "60 min",
        value: 60,
      },
    ],
  },
  {
    value: "DECISION_CALL",
    label: "Decision",
    duration: 30,
    durations: [
      {
        label: "30 min",
        value: 30,
      },
      {
        label: "60 min",
        value: 60,
      },
    ],
  },
  {
    value: "SA_OUTREACH",
    label: "SA Outreach",
    duration: 30,
    durations: [
      {
        label: "30 min",
        value: 30,
      },
    ],
  },
];

function getRescheduleHeaderCopy(startTime, endTime, timezone) {
  startTime = startTime.clone().tz(timezone);
  endTime = endTime.clone().tz(timezone);
  return (
    <span>
      Please select an appointment type, day and time to reschedule your
      appointment. Current appointment:
      <br />
      <strong>
        {startTime.format(FORMAT_DAY)} {startTime.format(FORMAT_TIME)} –{" "}
        {endTime.format(FORMAT_TIME_TZ)}
      </strong>
    </span>
  );
}

export default class AppointmentTime extends Component {
  static getDerivedStateFromProps(props, state) {
    const result = {};

    const { appointmentId } = props;
    if (props.appointmentId !== state.prevPropsAppointmentId) {
      return {
        appointmentId,
        prevPropsAppointmentId: appointmentId,
      };
    }
    return result;
  }

  constructor(props) {
    super(props);
    this.state = {
      appointmentType: props.appointmentType,
      duration: props.duration,
      timezone: props.timezone,
      isDoneLoading: !props.loading,
    };
    this.handleInteractedWithScheduler = once(() => {
      const { customContent } = this.props;
      const customContentData = (customContent && customContent.data) || {};
      if (customContentData.userMessageId) {
        markUserMessageAsClicked(customContentData.userMessageId);
      }
      eventBus.trigger("ThreeTimeSlotOrViewMoreTimesClicked");
    });
    this.handleNext = this.handleNext.bind(this);
    this.handleShowMoreTimes = this.handleShowMoreTimes.bind(this);
    this.handleAppointmentTypeChange =
      this.handleAppointmentTypeChange.bind(this);
    this.handleAppointmentTimeChange =
      this.handleAppointmentTimeChange.bind(this);
    this.showDisclaimerModal = this.showDisclaimerModal.bind(this);
    this.hideDisclaimerModal = this.hideDisclaimerModal.bind(this);
  }

  componentDidMount() {
    const { eventTrackingSharedProperties, isThreeTimeSlotsMode, loading } =
      this.props;
    if (!loading) {
      this.startHeaderAnimation();
    }
    trackView(
      "Appointment Time",
      Object.assign({}, eventTrackingSharedProperties, { isThreeTimeSlotsMode })
    );
  }

  componentDidUpdate() {
    const { availabilities } = this.props;
    const { appointmentId } = this.state;
    const wasDoneLoading = !this.props.loading;
    if (wasDoneLoading && !this.state.isDoneLoading) {
      this.setState({ isDoneLoading: true }, () => {
        this.startHeaderAnimation();
      });
    }
    if (availabilities?.length > 3 && appointmentId) {
      const selectedSlot = availabilities.find(
        (a) =>
          getTimeRangeSegment(a.appointmentId) ===
          getTimeRangeSegment(appointmentId)
      );
      if (!selectedSlot) {
        this.setState({ appointmentId: null });
      }
    }
  }

  startHeaderAnimation() {
    if (this.headerAnimationWasStarted) {
      return;
    }
    const { customContent } = this.props;
    const customContentData = (customContent && customContent.data) || {};
    if (customContentData.headerAnimation === "FallingConfetti") {
      FallingConfetti();
    }
    this.headerAnimationWasStarted = true;
  }

  handleNext(e) {
    const {
      appointmentId,
      appointmentType,
      duration,
      timezone,
      endTime,
      startTime,
    } = this.state;
    const { componentName, eventTrackingSharedProperties } = this.props;
    this.props.onNext({
      appointmentId,
      appointmentType,
      endTime,
      startTime,
      duration,
      timezone,
    });
    trackClick(
      e,
      componentName,
      "Time Select Next Button",
      eventTrackingSharedProperties
    );
  }

  handleShowMoreTimes() {
    const { onShowMoreTimes, customContent } = this.props;
    const customContentData = (customContent && customContent.data) || {};
    this.handleInteractedWithScheduler();
    if (customContentData.userMessageId) {
      markUserMessageAsClicked(customContentData.userMessageId);
    }
    if (onShowMoreTimes) {
      onShowMoreTimes(...arguments);
    }
  }

  handleAppointmentTypeChange({ type, duration }) {
    this.setState({
      appointmentType: type,
      duration,
      appointmentId: undefined,
    });
    this.props.onAppointmentTypeChange.apply(null, arguments);
  }

  handleAppointmentTimeChange({ appointmentId, timezone, endTime, startTime }) {
    this.handleInteractedWithScheduler();
    this.setState({ appointmentId, timezone, endTime, startTime });
  }

  renderCustomHeader() {
    const { customContent } = this.props;
    const customContentData = (customContent && customContent.data) || {};
    const hasHeaderAnimation =
      customContentData.headerAnimation === "FallingConfetti";

    if (customContentData.topContent) {
      return (
        <div
          className="appointment__header"
          dangerouslySetInnerHTML={{ __html: customContentData.topContent }}
        />
      );
    }
    let title = customContentData.title;
    let teaser = customContentData.teaser;
    let description = customContentData.description;
    let headerClassName = customContentData.headerClassName || "";
    let titleClassName = customContentData.titleClassName || "";
    let showTypeSelector = customContentData.showTypeSelector;

    if (customContentData.isNew2019Version) {
      const topRightContent = (
        <div
          className={`appt-custom-content__top-right-content${
            customContentData.titleIcon ? "" : " u-text-center"
          }`}
        >
          {title && (
            <div
              className={`js-appt-custom-content__title appt-custom-content__title ${titleClassName}`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {teaser && (
            <div
              className="appt-custom-content__teaser"
              dangerouslySetInnerHTML={{ __html: teaser }}
            />
          )}
        </div>
      );
      return (
        <>
          <div className="appointment__header">
            <div
              className={`appt-custom-content__top js-appt-custom-content__top ${headerClassName}`}
              style={{
                backgroundImage:
                  hasHeaderAnimation || !customContentData.titleBackground
                    ? "none"
                    : `url(${customContentData.titleBackground})`,
              }}
            >
              {customContentData.titleIcon && (
                <div className="appt-custom-content__top-left">
                  <img
                    className="appt-custom-content__title-icon"
                    src={customContentData.titleIcon}
                  />
                </div>
              )}
              {customContentData.titleIcon && (
                <div className="appt-custom-content__top-right">
                  {topRightContent}
                </div>
              )}
              {!customContentData.titleIcon && topRightContent}
              {hasHeaderAnimation && (
                <canvas
                  id="FallingConfettiCanvas"
                  className="appt-custom-content__canvas"
                />
              )}
            </div>
            {description && (
              <div
                className="appt-custom-content__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
          {showTypeSelector && (
            <AppointmentTypeSelectorBlank selectorClassName="pc-u-p- pc-u-mb" />
          )}
        </>
      );
    }

    if (customContentData.isMTREnrollment) {
      return (
        <>
          <div className="appointment__header">
            {title && (
              <div
                className={`js-appt-custom-content__title appt-custom-content__title pc-u-ph ${titleClassName}`}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            <div
              className={`appt-custom-content__top js-appt-custom-content__top ${headerClassName}`}
              style={{
                backgroundImage:
                  hasHeaderAnimation || !customContentData.titleBackground
                    ? "none"
                    : `url(${customContentData.titleBackground})`,
              }}
            >
              {customContentData.titleIcon && (
                <div className="appt-custom-content__top-left">
                  <img
                    className="appt-custom-content__title-icon"
                    src={customContentData.titleIcon}
                  />
                </div>
              )}
              {teaser && (
                <div
                  className="appt-custom-content__teaser pc-u-mt0"
                  dangerouslySetInnerHTML={{ __html: teaser }}
                />
              )}
              {hasHeaderAnimation && (
                <canvas
                  id="FallingConfettiCanvas"
                  className="appt-custom-content__canvas"
                />
              )}
            </div>
            {description && (
              <div
                className="appt-custom-content__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
          {showTypeSelector && (
            <AppointmentTypeSelectorBlank selectorClassName="pc-u-p- pc-u-mb" />
          )}
        </>
      );
    }

    return (
      <div className="appointment__header">
        <div
          className="appointment-custom-content__top"
          style={{ background: `url(${customContentData.image}) no-repeat` }}
        >
          <div className="appointment-custom-content__title">{title}</div>
          <div className="appointment-custom-content__teaser">{teaser}</div>
        </div>
        <div className="appointment-custom-content__description">
          {description}
        </div>
      </div>
    );
  }

  renderNormalHeader() {
    const {
      appointmentTypes,
      advisorImgURL,
      advisorName,
      reschedule,
      oldAppointmentStartTime,
      oldAppointmentEndTime,
      isClient,
      eventTrackingSharedProperties,
      componentName,
      onAppointmentTypeChange,
      isTopicPreselected,
      is401kEnrollment,
    } = this.props;
    const { appointmentType, duration, timezone } = this.state;
    let filteredApptTypes = appointmentTypes.filter(
      (t) => !RESTRICTED_TYPES.includes(t.value)
    );

    if (!is401kEnrollment)
      filteredApptTypes = filteredApptTypes.filter(
        (t) => t.value !== "ENROLLMENT_401K"
      );

    let headerCopy;
    if (isClient) {
      headerCopy =
        "We're always here to answer questions, discuss your portfolio strategy, or advise on important financial planning topics.";
    }
    if (reschedule) {
      headerCopy = getRescheduleHeaderCopy(
        oldAppointmentStartTime,
        oldAppointmentEndTime,
        timezone
      );
    }

    return (
      <div className="appointment__header">
        <Header
          advisorImgURL={advisorImgURL}
          advisorName={advisorName}
          body={headerCopy}
        />
        {isClient && onAppointmentTypeChange ? (
          <AppointmentTypeSelector
            appointmentTypes={
              reschedule
                ? ADVISOR_APPOINTMENT_TYPES.concat(appointmentTypes)
                : filteredApptTypes
            }
            appointmentType={appointmentType}
            duration={duration}
            onChange={this.handleAppointmentTypeChange}
            typeDisabled={reschedule || isTopicPreselected || is401kEnrollment}
            durationDisabled={
              !appointmentType || reschedule || isTopicPreselected
            }
            eventTrackingSharedProperties={eventTrackingSharedProperties}
            componentName={componentName}
          />
        ) : (
          <AppointmentTypeSelectorBlank />
        )}
      </div>
    );
  }

  showDisclaimerModal(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      showDisclaimerModal: true,
    });
  }

  hideDisclaimerModal() {
    this.setState({
      showDisclaimerModal: false,
    });
  }

  render() {
    const {
      isThreeTimeSlotsMode,
      availabilities,
      onMonthChange,
      errors,
      month,
      reschedule,
      onKeep,
      customContent,
      eventTrackingSharedProperties,
      componentName,
      isClient,
      loading,
      hasOnboardingAppointmentPage,
      referralOnboardingTest,
    } = this.props;
    const {
      appointmentType,
      duration,
      appointmentId,
      timezone,
      showDisclaimerModal,
    } = this.state;
    const isDateTimeSelectorDisabled = !appointmentType || !duration;
    const customContentData = (customContent && customContent.data) || {};
    const customDisclaimerLink =
      objectPath.get(customContentData, "disclaimer.link") || "";
    const customDisclaimerTitle =
      objectPath.get(customContentData, "disclaimer.title") ||
      "Advice Disclaimer";
    const viewMoreTimesButtonLabel =
      (customContent && customContent.viewMoreTimesCustomLabel) ||
      "See More Times";
    let footerClassName = customContentData.footerClassName || "";
    if (showDisclaimerModal) {
      return (
        <AdviceDisclaimerModal
          isOpen={true}
          customDisclaimerData={customContentData.disclaimer}
          onClosed={this.hideDisclaimerModal}
          title={customDisclaimerTitle.replace("*", "")}
        />
      );
    }

    return (
      <div className="appointment qa-appointment appointment-time qa-appointment-time">
        {isEmpty(customContentData)
          ? this.renderNormalHeader()
          : this.renderCustomHeader()}
        <div className="appointment__body pc-u-mb+">
          {isThreeTimeSlotsMode ? (
            <ThreeTimeSlotsAppointmentSelector
              availabilities={availabilities}
              appointmentId={appointmentId}
              timezone={timezone}
              onChange={this.handleAppointmentTimeChange}
              eventTrackingSharedProperties={eventTrackingSharedProperties}
              componentName={componentName}
              loading={loading}
              hasOnboardingAppointmentPage={hasOnboardingAppointmentPage}
              referralOnboardingTest={referralOnboardingTest}
            />
          ) : (
            <DateTimeSelector
              errors={errors}
              appointmentId={appointmentId}
              availabilities={availabilities}
              duration={duration}
              disabled={isDateTimeSelectorDisabled}
              timezone={timezone}
              month={month}
              onChange={this.handleAppointmentTimeChange}
              onMonthChange={onMonthChange}
              componentName={componentName}
              isClient={isClient}
            />
          )}
        </div>
        <div className="appointment__footer">
          <div className="pc-u-mb-">
            {reschedule && (
              <button
                type="button"
                className="js-appointment-time-keep qa-appointment-time-keep pc-btn pc-btn--appointment-action"
                onClick={onKeep}
              >
                Keep Appointment
              </button>
            )}
            <button
              type="button"
              className="js-appointment-time-next qa-appointment-time-next pc-btn pc-btn--primary pc-btn--appointment-action"
              disabled={!appointmentId}
              onClick={this.handleNext}
            >
              Continue
            </button>
          </div>
          {isThreeTimeSlotsMode && (
            <button
              type="button"
              className="js-appointment-time-switch-mode qa-appointment-time-switch-mode pc-btn pc-btn--bare pc-btn--appointment-action"
              onClick={this.handleShowMoreTimes}
            >
              {viewMoreTimesButtonLabel}
            </button>
          )}
        </div>
        {Boolean(customContentData.bottomContent) && (
          <div
            className={`js-appointment-custom-content__bottom appointment-custom-content__bottom ${footerClassName}`}
            dangerouslySetInnerHTML={{
              __html: customContentData.bottomContent,
            }}
          />
        )}
        {Boolean(customContentData.showDisclaimerLink) && (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className="appointment__advice-disclaimer-link micro"
            href={customDisclaimerLink || "#"}
            target={customDisclaimerLink ? "_blank" : "_self"}
            onClick={customDisclaimerLink ? noop : this.showDisclaimerModal}
          >
            {customDisclaimerTitle}
          </a>
        )}
      </div>
    );
  }
}

AppointmentTime.propTypes = {
  is401kEnrollment: PropTypes.bool,
  errors: PropTypes.array,
  appointmentId: PropTypes.string,
  advisorImgURL: PropTypes.string,
  isThreeTimeSlotsMode: PropTypes.bool,
  advisorName: PropTypes.string,
  appointmentTypes: PropTypes.array,
  availabilities: PropTypes.array,
  appointmentType: PropTypes.string,
  timezone: PropTypes.string,
  duration: PropTypes.number,
  month: PropTypes.object,
  oldAppointmentStartTime: PropTypes.object,
  oldAppointmentEndTime: PropTypes.object,
  onAppointmentTypeChange: PropTypes.func,
  onMonthChange: PropTypes.func,
  onNext: PropTypes.func.isRequired,
  onKeep: PropTypes.func,
  onShowMoreTimes: PropTypes.func,
  reschedule: PropTypes.bool,
  isClient: PropTypes.bool,
  customContent: PropTypes.object,
  loading: PropTypes.bool,
  eventTrackingSharedProperties: PropTypes.object,
  componentName: PropTypes.string,
  isTopicPreselected: PropTypes.bool,
  hasOnboardingAppointmentPage: PropTypes.bool,
  referralOnboardingTest: PropTypes.string,
};

AppointmentTime.defaultProps = {
  is401kEnrollment: false,
  isTopicPreselected: false,
  hasOnboardingAppointmentPage: false,
  referralOnboardingTest: "",
};
