const ORIGIN_ANY = "*";
const EVENT_ALL = "all";

/**
 * `IframeBus` handles cross-domain communications between the iframe and the host window.
 * The host window URL is passed via `allowedTargetOrigin` option.
 */
export default class IframeBus {
  constructor({
    sourceWindow,
    targetWindow,
    allowedTargetOrigin,
    allowedSourceOrigin,
  }) {
    this.window = window;
    this.targetWindow = targetWindow;
    this.sourceWindow = sourceWindow;
    this.allowedTargetOrigin = allowedTargetOrigin;
    this.allowedSourceOrigin = allowedSourceOrigin;
    this.handlers = new WeakMap();
  }

  trigger(event, data) {
    this.targetWindow.postMessage({ event, data }, this.allowedTargetOrigin);
  }

  /**
   * Posts the message as-is as oppose to `trigger()` which creates a `{ event, data }` structure.
   * @param {Any} message the message to send
   */
  postMessage(message) {
    this.targetWindow.postMessage(message, this.allowedTargetOrigin);
  }

  on(event, handler) {
    this.sourceWindow.addEventListener(
      "message",
      this.createHandler(event, handler)
    );
  }

  off(_, handler) {
    const messageHandler = this.handlers.get(handler);
    if (messageHandler) {
      this.sourceWindow.removeEventListener("message", messageHandler);
      this.handlers.delete(handler);
    }
  }

  createHandler(eventName, handler) {
    const messageHandler = function (event) {
      if (
        this.allowedSourceOrigin !== ORIGIN_ANY &&
        event.origin !== this.allowedSourceOrigin
      ) {
        return;
      }

      if (event.data.event === eventName) {
        handler(event.data.data);
      }
      if (eventName === EVENT_ALL) {
        handler(event.data);
      }
    }.bind(this);

    this.handlers.set(handler, messageHandler);
    return messageHandler;
  }
}
