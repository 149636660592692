import React from "react";
import PropTypes from "prop-types";
import eventBus from "eventBus";
import AbstractForm from "components/common/form/AbstractForm";
import Input from "components/common/form/Input";
import Textarea from "components/common/form/Textarea";
import AppointmentDetails from "components/scheduling/AppointmentDetails";
import { first } from "underscore";
import Message from "components/common/Message";
import {
  trackView,
  trackClick,
  trackEvent,
} from "components/common/ComponentAnalytics";
import {
  MASKED_PHONE_NUMBER_FORMAT,
  PHONE_NUMBER_FORMAT,
} from "components/common/form/formattingOptions";
import {
  AB_TEST_GROUP_A,
  AB_TEST_GROUP_B,
} from "libs/pcap/utils/getABTestGroupForCurrentUser";

const INVALID_PHONE_MESSAGE = "Please enter a 10-digit phone number.";

class AppointmentReview extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    trackView("Appointment Review", props.eventTrackingSharedProperties);
  }

  componentDidMount() {
    this.focus();
    eventBus.on("firstUseSignOut:clicked", () => {
      if (window.referralOnboardingTest === AB_TEST_GROUP_A) {
        trackEvent("Review Appointment Page Ref_On", "Sign Out");
      } else if (window.referralOnboardingTest === AB_TEST_GROUP_B) {
        trackEvent("Review Appointment Page Ref_Ont", "Sign Out");
      } else {
        trackEvent("Review Appointment Page", "Sign Out");
      }
    });
  }

  componentWillUnmount() {
    eventBus.off("firstUseSignOut:clicked");
  }

  handleSubmit(event) {
    const { eventTrackingSharedProperties, componentName, onConfirm } =
      this.props;
    const validateResult = this.validate();
    if (validateResult.valid) {
      onConfirm(this.state.model);
    }
    event.preventDefault();
    trackClick(
      event,
      componentName,
      "Confirm Appointment",
      eventTrackingSharedProperties
    );
  }

  handleBack(e) {
    const { eventTrackingSharedProperties, componentName, onBack } = this.props;
    onBack();
    trackClick(
      e,
      componentName,
      "Review Back Button",
      eventTrackingSharedProperties
    );
  }

  render() {
    const {
      advisorName,
      appointmentStartTime,
      appointmentEndTime,
      validator,
      errors,
      timezone,
      reschedule,
      collectFirstName,
      isLimited,
    } = this.props;

    const { model } = this.state;
    const guest = first(model.invitees);

    return (
      <form onSubmit={this.handleSubmit}>
        <section className="appointment qa-appointment appointment-review appointment-review--responsive qa-appointment-review">
          <div className="appointment__header">
            <div className="appointment__sub-header">
              <h2 className="pc-delta pc-u-mb0 qa-appointment__sub-header--text">
                Please confirm your appointment information
              </h2>
            </div>
          </div>
          <div className="appointment__body appointment__body--padded">
            <Message className="pc-u-mb+" severity="error" messages={errors} />
            <AppointmentDetails
              className="pc-u-mb+"
              advisorName={advisorName}
              startTime={appointmentStartTime}
              endTime={appointmentEndTime}
              timezone={timezone}
            />
            <div>
              <h3 className="u-text-center">Please verify your information</h3>
              {collectFirstName && (
                <div className="pc-layout pc-layout--center pc-layout--top pc-form-group--small">
                  <div className="pc-layout__item pc-u-1/5">
                    <label className="pc-label pc-u-mt--" htmlFor="apptrvfname">
                      First Name
                    </label>
                  </div>
                  <div className="pc-layout__item pc-u-2/5">
                    <Input
                      id="apptrvfname"
                      type="text"
                      name="firstName"
                      value={model.firstName}
                      ref={this.storeInputRef}
                      sizeVariation="full"
                      onChange={this.handleInputChange}
                      validator={validator.firstName}
                    />
                  </div>
                </div>
              )}
              <div className="pc-layout pc-layout--center pc-layout--top pc-form-group--small appointment__input-container appointment__input-container--responsive">
                <div className="pc-layout__item pc-u-1/5">
                  <label className="pc-label pc-u-mt--" htmlFor="apptrvemail">
                    Email
                  </label>
                </div>
                <div className="pc-layout__item pc-u-2/5">
                  <Input
                    id="apptrvemail"
                    type="text"
                    name="userEmail"
                    value={model.userEmail}
                    ref={this.storeInputRef}
                    sizeVariation="full"
                    onChange={this.handleInputChange}
                    validator={validator.userEmail}
                  />
                </div>
              </div>
              <div className="pc-layout pc-layout--center pc-layout--top pc-form-group--large appointment__input-container appointment__input-container--responsive">
                <div className="pc-layout__item pc-u-1/5">
                  <label
                    className={`pc-label pc-u-mt-- u-sentence-case`}
                    htmlFor="apptrvph"
                  >
                    Phone Number
                  </label>
                </div>
                <div className="pc-layout__item pc-u-2/5">
                  <Input
                    id="apptrvph"
                    type="text"
                    name="userPhoneNumber"
                    formattingOptions={
                      isLimited
                        ? MASKED_PHONE_NUMBER_FORMAT
                        : PHONE_NUMBER_FORMAT
                    }
                    maxLength={14}
                    value={model.userPhoneNumber}
                    ref={this.storeInputRef}
                    sizeVariation="full"
                    onChange={this.handleInputChange}
                    validator={validator.userPhoneNumber}
                  />
                </div>
              </div>
            </div>
            {Boolean(!reschedule || (reschedule && guest)) && (
              <div className="pc-layout pc-layout--center pc-layout--top pc-form-group--large appointment-review__guest-details qa-appointment-review__guest-details">
                <div className="pc-layout__item pc-u-2/8">
                  {reschedule ? (
                    <label className="pc-label pc-u-mt--" htmlFor="apptrvgname">
                      Guest
                    </label>
                  ) : (
                    <label className="pc-label" htmlFor="apptrvgname">
                      Add a guest to your appointment
                    </label>
                  )}
                </div>
                <div className="pc-layout__item pc-u-2/8">
                  <Input
                    id="apptrvgname"
                    type="text"
                    name="invitees.0.name"
                    value={guest && guest.name}
                    placeholder="First Name"
                    ariaLabel="First Name"
                    ref={this.storeInputRef}
                    sizeVariation="full"
                    onChange={this.handleInputChange}
                    disabled={reschedule}
                  />
                </div>
                <div className="pc-layout__item pc-u-4/8">
                  <Input
                    type="text"
                    name="invitees.0.email"
                    value={guest && guest.email}
                    placeholder="Email Address"
                    ariaLabel="Email Address"
                    ref={this.storeInputRef}
                    sizeVariation="full"
                    onChange={this.handleInputChange}
                    validator={validator.guestEmail}
                    disabled={reschedule}
                  />
                </div>
              </div>
            )}
            <Textarea
              ref={this.storeInputRef}
              rows={5}
              placeholder="Meeting notes for your advisor"
              ariaLabel="Meeting notes for your advisor"
              name="clientNotesToAdvisor"
              value={model.clientNotesToAdvisor}
              onChange={this.handleInputChange}
              className="textarea--no-resize appointment-review__meeting-notes"
            />
          </div>
          <div className="appointment__footer">
            <button
              type="button"
              className="js-appointment-review-back pc-btn pc-btn--appointment-action"
              onClick={this.handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="js-appointment-review-next qa-appointment-review-next pc-btn pc-btn--primary pc-btn--appointment-action"
            >
              Confirm
            </button>
          </div>
        </section>
      </form>
    );
  }
}

AppointmentReview.propTypes = {
  model: PropTypes.object,
  advisorName: PropTypes.string,
  timezone: PropTypes.string,
  appointmentStartTime: PropTypes.object.isRequired,
  appointmentEndTime: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  isOpen: PropTypes.bool,
  errors: PropTypes.array,
  reschedule: PropTypes.bool,
  collectFirstName: PropTypes.bool,
  eventTrackingSharedProperties: PropTypes.object,
  componentName: PropTypes.string,
  isLimited: PropTypes.bool,
};

AppointmentReview.defaultProps = {
  model: {},
  validator: {
    firstName: { type: "string", allowEmpty: false },
    userEmail: { type: "string", allowEmpty: false, format: "email" },
    userPhoneNumber: {
      type: "string",
      pattern: /^[\d*]{10}$/,
      allowEmpty: false,
      messages: { type: INVALID_PHONE_MESSAGE, pattern: INVALID_PHONE_MESSAGE },
    },
    guestEmail: { type: "string", allowEmpty: true, format: "email" },
  },
};

export default AppointmentReview;
