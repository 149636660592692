import { select, mouse, event, transition } from "d3";
import { isEmpty } from "underscore";

const TOOLTIP_OFFSET = 12;
const HALF = 2;

/**
 * Defines the continuous hover area covering the complete chart body.
 *
 * @export the tooltip factory function
 * @param {Object}    options           the options object
 * @param {Function}  options.x         the accessor function which is called on each datum object and returns the X value.
 * @param {Function}  options.y         the accessor function which is called on each datum object and returns the XYvalue.
 * @param {String}    options.width     the width of the hover area
 * @param {String}    options.height    the height of the hover area
 * @param {String}    options.className the class name
 * @param {String}    options.template  the template function to generate HTML content for the tooltip.
 *                                      Receives the dataset bound to the chart and the index associated
 *                                      with the currently focused datum `function(data, index) {}`.
 * @param {Function} options.onChartMouseMove   f
 *
 * @returns {Function}            the tooltip factory
 */
export default function (options) {
  let overlay;
  let bars;
  let tooltipEl;
  const isStackBarChart = !isEmpty(options.stacks);

  function attachMouseEvents(selection, tooltipEl) {
    let bars = selection
      .selectAll(".js-chart-bar")
      .on("mouseover.discrete", function () {
        // When one of react charts is destroyed
        // tooltip component is also destroyed (which is shared by all charts on the page)
        // recreating the tooltip component if it is empty on chart hover.
        tooltipEl = select("body").select(".js-chart-tooltip");
        if (tooltipEl.empty()) {
          tooltipEl = select("body")
            .append("div")
            .style("display", "none")
            .classed("chart-tooltip js-chart-tooltip", true);
        }

        tooltipEl.style("display", null);

        if (options.className) {
          tooltipEl.classed(options.className, true);
        }
      })
      .on("mouseout.discrete", function () {
        tooltipEl.style("display", "none");

        // Tooltip is a global element reused for all the charts.
        // Cleanup the custom class name after the user done hovering over the chart.
        if (options.className) {
          tooltipEl.classed(options.className, false);
        }
      })
      .on("mousemove.discrete", function (data, index) {
        const el = this; /* `this` is the target element */ // eslint-disable-line no-invalid-this, consistent-this
        const chartCoords = mouse(el);
        const offset = el.getBoundingClientRect().left + window.pageXOffset;
        let tooltipTop = event.pageY;
        let tooltipLeft = offset;

        let transformX;
        if (options.width / HALF > chartCoords[0]) {
          transformX = options.xScale.step
            ? options.xScale.step() / HALF + "px"
            : "0px";
          tooltipLeft += TOOLTIP_OFFSET;
        } else {
          transformX = "-100%";
          tooltipLeft -= TOOLTIP_OFFSET;
        }
        let transformY;
        if (options.height / HALF > chartCoords[1]) {
          transformY = TOOLTIP_OFFSET * HALF + "px";
        } else {
          transformY = "-100%";
          tooltipTop -= TOOLTIP_OFFSET;
        }
        const key =
          options.stacks && options.stacks.length ? options.stacks[index] : "";

        // If user hovers on remaining region of the chart
        // hide tooltip.
        if (options.hideTooltipKey && key === options.hideTooltipKey) {
          tooltipEl.style("display", "none");
          return;
        }

        const templateData = isStackBarChart ? data.data : data;
        tooltipEl
          .style("left", tooltipLeft + "px")
          .style("top", tooltipTop + "px")
          .style("transform", `translate(${transformX}, ${transformY})`)
          .html(options.template(templateData, 0, key));
      });

    select(selection.node().parentNode)
      .on("mouseenter.discrete", function () {
        bars.classed("chart__bar--active", true);
      })
      .on("mouseleave.discrete", function () {
        bars.classed("chart__bar--active", false);
      });
  }

  const tooltip = function (context) {
    overlay = context instanceof transition ? context.selection() : context;

    tooltipEl = select("body").select(".js-chart-tooltip");
    if (tooltipEl.empty()) {
      tooltipEl = select("body")
        .append("div")
        .style("display", "none")
        .classed("chart-tooltip js-chart-tooltip", true);
    }
    attachMouseEvents(overlay, tooltipEl);
  };

  /**
   * Removes the tooltip and detaches the events
   *
   */
  tooltip.destroy = function () {
    select(overlay.node().parentNode).on("mouseover mouseleave", null);
    if (bars) {
      bars.on("mouseover mouseout mousemove", null);
    }
    tooltipEl.remove();
  };

  /**
   * Specifies the accessor function for `x` value on the datum object.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {chart|Function}       If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  tooltip.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    return tooltip;
  };

  /**
   * Specifies the accessor function for `y` value on the datum object.
   * By defaults uses `datum.y`.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {tooltip|Function}     If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  tooltip.y = function (accessor) {
    if (options.stacks) {
      return (d) => d[options.stacks[0]];
    }
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    return tooltip;
  };

  /**
   * Set/get the hover area width.
   *
   * @param  {String} [width]   The `width` of the hover area.
   * @return {tooltip|String}   If `width` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `width` value.
   */
  tooltip.width = function (width) {
    if (!arguments.length) {
      return options.width;
    }

    options.width = width;
    return tooltip;
  };

  /**
   * Set/get the hover area height.
   *
   * @param  {String} [height]   The `height` of the hover area.
   * @return {tooltip|String}   If `height` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `height` value.
   */
  tooltip.height = function (height) {
    if (!arguments.length) {
      return options.height;
    }

    options.height = height;
    return tooltip;
  };

  /**
   * Set/get the xScale.
   *
   * @param  {String} [xScale]  The `xScale` of the hover area.
   * @return {tooltip|String}   If `xScale` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `xScale` value.
   */
  tooltip.xScale = function (xScale) {
    if (!arguments.length) {
      return options.xScale;
    }

    options.xScale = xScale;
    return tooltip;
  };

  /**
   * Set/get the yScale.
   *
   * @param  {String} [yScale]  The `yScale` of the hover area.
   * @return {tooltip|String}   If `yScale` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `yScale` value.
   */
  tooltip.yScale = function (yScale) {
    if (!arguments.length) {
      return options.yScale;
    }

    options.yScale = yScale;
    return tooltip;
  };

  /**
   * Set/get the className.
   *
   * @param  {String} [className]   The `className` for the tooltip.
   * @return {tooltip|String}       If `className` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `className` value.
   */
  tooltip.className = function (className) {
    if (!arguments.length) {
      return options.className;
    }

    options.className = className;
    return tooltip;
  };

  /**
   * Set/get the template function.
   *
   * @param  {Function} [template]  The `template` for the tooltip.
   * @return {tooltip|Function}     If `template` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `template` value.
   */
  tooltip.template = function (template) {
    if (!arguments.length) {
      return options.template;
    }

    options.template = template;
    return tooltip;
  };

  return tooltip;
}
