import IframeClient from "partner/iframeClient";

const REGEX_SAFARI = /^((?!chrome|android).)*safari/i;
const iframeClient = IframeClient.getInstance();

// for mocking purposes only
export function isSafari() {
  return REGEX_SAFARI.test(navigator.userAgent);
}

/**
 * This is a proxy for `Element.scrollIntoView()` API
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView.
 *
 * This wrapper was created as workaround for an issue where the window cannot
 * be scrolled to an element inside of an iframe with cross-origin content in Safari
 * using the standard methods.
 *
 * @param {Element} el an element to scroll into view
 * @param {Boolean|Object} options refer to https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 */
export default function scrollIntoView(el, options) {
  if (IS_IFRAMED && isSafari()) {
    const clientRect = el.getBoundingClientRect();
    const scrollToOptions = { block: "start", left: 0, ...options };
    switch (scrollToOptions.block) {
      case "start":
        scrollToOptions.top = clientRect.top;
        break;
      case "center": {
        const elCenter = (clientRect.bottom - clientRect.top) / 2;
        scrollToOptions.top = clientRect.top + elCenter;
        break;
      }
      case "end":
        scrollToOptions.top = clientRect.bottom;
        break;
      default:
        scrollToOptions.top = clientRect.top;
        break;
    }
    iframeClient.triggerScrollTo(scrollToOptions);
  } else {
    el.scrollIntoView(options);
  }
}
