// TODO Fix bad design.
// This module implicitly updates `Backbone.View` class when imported by the client.
// We import it in the `router` module so that all subsequent view instantiations
// will have the following methods mixed in to the prototype of `Backbone.View`.
//
// The proper way of doing that is to convert this code to a conventional AMD definition
// which exports the module. Then, mix the module in implicitly or inherit from where needed.
//
// That will help to avoid confusion and enable the same code to be executed within
// unit tests and on runtime.


/* eslint-disable valid-jsdoc */
import $ from 'jquery';

import _ from 'underscore';
import Backbone from 'backbone';
import PERSONALCAPITAL from 'PERSONALCAPITAL';
import { replaceHash } from "libs/pcap/utils/location";

Backbone.View.prototype.close = function(){
    this.remove();
    this.unbind();
    if (this.onClose) {
        this.onClose();
    }
}

/**
 * If the state of this view is driven by an external change,
 * then it must be done through this method.
 *
 * @param {Object} newViewState Use or define a new viewStateModel located in the models/states folder, such as AccountDetailState
 */
Backbone.View.prototype.updateView = function(newViewState) {
if(this.options == null){
 this.options = {};
}
   this.previousOptions = _.clone(this.options);
   $.pcap.replaceObject(newViewState, this.options);
}

/**
 * trackViewState performs the following functions:
 *	- stores state management data for a specific view, like Account Details
 *	- if necessary, update the url to reflect the changes to the view state
 *
 * @param {String}  moduleName  A string that specifies the name of the module that is using state tracking
 * @param  {Function} modelClass  Reference to a constructor for a viewStateModel class located models/states
 * @param  {Boolean} hasMultipleIds  A Boolean value that specifies whether a unique key is needed to store the state model.
 *						   Account Details is a good example where each account has it's own state and a key is needed.
 *						   Whereas All Transactions only needs one state model and a key is not needed.
 * @param {String} idAttribute (optional) Used only if hasMultipleIds is true.  A string used specify the
 *								  name of the property in the state model to be used as a look-up key.
 *
 * @return {Object} viewStateResult	An object that contains the following properties:
 *							- viewState A clone of the ViewState model
 *							- newlyCreated A boolean value to indicate if the ViewState is new to the state management system
 */

Backbone.View.prototype.trackViewState = function(moduleName, modelClass, hasMultipleIds, idAttribute) {
    var module
        , viewState
        , updateUrl = true
        , i
        , stateProperty
        , key
        , newlyCreated = false;

    if (hasMultipleIds) {
        key = (_.isNumber(this.options[idAttribute])) ? '_' + this.options[idAttribute] : this.options[idAttribute]
    }
    const isFirstLoad = !PERSONALCAPITAL.has(moduleName);
    // On 1st time, initialize dictionary for module
    if (isFirstLoad) {
        module = {};
        viewState = _.extend(new modelClass, this.options);

        // exclude el proporty from viewState, to prevent view from attaching to parent DOM element
        delete viewState.el;

        if (hasMultipleIds) {
            module[key] = viewState;
        } else {
            module = viewState;
        }

        PERSONALCAPITAL.set(moduleName, module);

        // If view contain subviews and this.options.path is undefined or '', then set a default value for this.options.path
        // based on the first element in the pathableSubViews array.
        if (this.pathableSubViews.length > 0 && (_.isUndefined(this.options.path) || this.options.path === '')) {
            if (Object.prototype.hasOwnProperty.call(this.pathableSubViews[0], 'pathNode')) {
                viewState.path = this.options.path = this.pathableSubViews[0].pathNode;
            } else {
                viewState.path = this.options.path = this.pathableSubViews[0].nodeName;
            }
        }

        this.options.pathNodes = viewState.pathNodes = viewState.path.split('/');
        newlyCreated = true;
    } else {
        module = PERSONALCAPITAL.get(moduleName);

        if (!hasMultipleIds || Object.prototype.hasOwnProperty.call(module, key)) {
            viewState = hasMultipleIds ? module[key] : module;

            for (i = 0; i < viewState.optionalUrlParams.length; i++) {
                stateProperty = viewState.optionalUrlParams[i];

                // If this.options has a property that is defined, it indicates that
                // the change is driven by url and the change needs to be reflected
                // in viewState
                if (!_.isUndefined(this.options[stateProperty]) && this.options[stateProperty] !== '') {
                    viewState[stateProperty] = this.options[stateProperty];
                    updateUrl = false;
                }
            }

            // If view has subviews and path is '', then default state of path
            if (this.pathableSubViews.length > 0 && (_.isUndefined(this.options.path) || this.options.path === '')) {
                if (Object.prototype.hasOwnProperty.call(this.pathableSubViews[0], 'pathNode')) {
                    this.options.path = this.pathableSubViews[0].pathNode;
                } else {
                    this.options.path = this.pathableSubViews[0].nodeName;
                }
            }

            if (!_.isUndefined(this.options.path) && this.options.path !== '') {
                if (!_.isUndefined(viewState.path) && viewState.path !== this.options.path) {
                    updateUrl = false;
                } else if (this.options.path !== '') {
                    updateUrl = false;
                }
                viewState.path = this.options.path;
                viewState.pathNodes = this.options.pathNodes = this.options.path.split('/');
            } else if (viewState.path !== this.options.path) {
                viewState.path = this.options.path;
                updateUrl = false;
            }

            // If the view state is set by module and not by url params,
            // then only the url needs to be updated to reflect intended state of
            // the view
            if (updateUrl) {
                var urlString = (hasMultipleIds) ? viewState.baseUrl + this.options[idAttribute] : viewState.baseUrl;

                for (i = 0; i < viewState.optionalUrlParams.length; i++) {
                    stateProperty = viewState.optionalUrlParams[i];

                    if (!_.isUndefined(viewState[stateProperty]) && viewState[stateProperty] !== '') {
                        urlString = urlString + '&' + stateProperty + '=' + viewState[stateProperty];
                    }
                }
                replaceHash(urlString)
            }
        } else {
            viewState = _.extend(new modelClass, this.options);
            // exclude el proporty from viewState, to prevent view from attaching to parent DOM element
            delete viewState.el;
            module[key] = viewState;
        }
    }

    return {viewState: viewState, newlyCreated: newlyCreated};
}

/**
 * Unlike trackViewState, saveInternalState save the state of a view only within the application so it can
 * be later retrieved to restore state when the user returns to the view. It does not expose the view state in the url.
 *
 * @param {String} moduleName  A string that specifies the name of the module that is using state tracking
 * @param  {Object} newParams A generic object that contain values used to update the corresponding viewState
 * @param  {Boolean} hasMultipleIds  A Boolean value that specifies whether a unique key is needed to store the state model.
 *						   Account Details is a good example where each account has it's own state and a key is needed.
 *						   Whereas All Transactions only needs one state model and a key is not needed.
 * @param  {String} idAttribute (optional) Used only if hasMultipleIds is true.  A string used specify the
 *								  name of the property in the state model to be used as a look-up key.
 * @param  {String} idValue (optional) Used only if hasMultipleIds is true.  The value of the idAttribute that is used
 *							  to look up the corresponding state model.
 *
 * @return {Object} viewState	A clone of the ViewState model.
*/
Backbone.View.prototype.saveInternalState = function(moduleName, newParams, hasMultipleIds, idAttribute, idValue) {
    var module = PERSONALCAPITAL.get(moduleName)
        , foundViewState, key;

    if (hasMultipleIds) {
        key = (_.isNumber(idValue)) ? '_' + idValue : idValue
    }

    // eslint-disable-next-line block-scoped-var
    foundViewState = (hasMultipleIds) ? module[key] : module;

    if (_.isUndefined(foundViewState)) {
        throw new Error('Attempted to call saveInternalState() without having called trackViewState() at least once to initialize the tracking mechanism for this module.'
            + '\nAdding the call to this.trackViewState() in initialize() should remedy this problem.');
    } else {
        // eslint-disable-next-line guard-for-in
        for (var prop in newParams) {
            if (Object.prototype.hasOwnProperty.call(newParams, prop)) {

            if (_.isUndefined(foundViewState[prop])) {
                foundViewState.internalStateParams.push(prop);
            }
            foundViewState[prop] = newParams[prop];
        }
        }

        return _.clone(foundViewState);
    }
}

/**
 * pathableSubViews is an array used to register this view's subviews that are react to changes to url paths.
 * Each element in the array is an object with the following properties:
 * 		pathNode - A string representing the node in the path for the corresponding subview.  NOTE -
 *				   pathNode uses hyphen naming convention per our URL coding standard.
 *		nodeName - A string representing a unique name given to the corresponding subview.  We use
 *				   camelCase format to id the subView as part of our naming convention.
 *		subView - The backboe subview
 *
 * Example - this.pathableSubView[0] = {subviewName: 'subView1', subview: SubView}
 * So when the path string is 'subView1/subSubView1', we can see that 'subView1' has a mapping to SubView
*/
Backbone.View.prototype.pathableSubViews = [];

/**
 * processPathForSubView clones the viewState, removes the 1st element in the path string and places it in viewState.upstreamPath, and returns a
 *  @param {Object} viewState object that can be passed down to the subview.
 *  @return {Object} viewState
*/
Backbone.View.prototype.processPathForSubView = function(viewState) {
    if (!viewState) {
        return {};
    }

    var clonedViewState = _.clone(viewState);
    clonedViewState.pathNodes = clonedViewState.path.split('/');

    // If path is not empty, then it can be passed down to the subview. Else throw an error.
    if (clonedViewState.pathNodes.length > 0) {
        var pathForSubView = clonedViewState.pathNodes.slice(1);

        if(this.pathableSubViews.length === 0) {
            throw new Error('The registry of pathableSubViews is empty.  You cannot process path for subView because none exists.');
        } else {
            var foundMatchingNode = '';

            for (var i = 0; i < this.pathableSubViews.length; i++) {
                if (Object.prototype.hasOwnProperty.call(this.pathableSubViews[i], 'pathNode') && this.pathableSubViews[i].pathNode === clonedViewState.pathNodes[0]) {
                    foundMatchingNode = this.pathableSubViews[i].pathNode;
                    break;
                } else if (this.pathableSubViews[i].nodeName === clonedViewState.pathNodes[0]) {
                    foundMatchingNode = this.pathableSubViews[i].nodeName;
                    break;
                }
            }

            if (foundMatchingNode === '') {
                console.log('Backbone.View.prototype.processPathForSubView() - The path \'' + clonedViewState.path + '\' is not valid for: ', this);
            } else {
                clonedViewState.path = pathForSubView.join('/');
                var upStreamPath = clonedViewState.pathNodes.slice(0, 1);
                clonedViewState.upStreamPath = upStreamPath.join('/');
            }
        }
    } else {
        throw new Error('You cannot process path for subView when the viewState\'s path property is empty.');
    }

    return clonedViewState;
}

/**
 * updateSubViews displays the subView that is found in the routing path and sends view state date to the subView.
 *  @param {Object} newViewState object that can be passed down to the subview.
*/
Backbone.View.prototype.updateSubViews = function(newViewState) {
    var i = 0;
    var subViewState;
    // set default state for module, which is when path is ''
    if (_.isUndefined(newViewState.path) || newViewState.path === '') {
        for (i = 0; i < this.pathableSubViews.length; i++) {
            // show 1st pathableSubView
            if (i === 0) {
                // initialize subview if needed
                if (_.isFunction(this.pathableSubViews[i].subView)) {
                    this.pathableSubViews[i].subView = new this.pathableSubViews[i].subView(_.clone(newViewState));
                }
                this.$el.find(this.pathableSubViews[i].subView.el).show();
      this.activeSubView = this.pathableSubViews[i].subView;
            } else if (!_.isFunction(this.pathableSubViews[i].subView)) {
                this.$el.find(this.pathableSubViews[i].subView.el).hide();
            // if preinitialize is true for a pathableSubView, then initialize the subview immediately
            } else if (_.isFunction(this.pathableSubViews[i].subView) && this.pathableSubViews[i].preInitialize) {
                // eslint-disable-next-line no-use-before-define, block-scoped-var
                this.pathableSubViews[i].subView = new this.pathableSubViews[i].subView(_.clone(subViewState));
                this.$el.find(this.pathableSubViews[i].subView.el).hide();
            }
        }
    } else {
        var pathArray = newViewState.path.split('/');
        subViewState = this.processPathForSubView(newViewState);

        // Find the subview that will be displayed
        var matchingPathableSubView = _.find(this.pathableSubViews, function(arrayElement) {
            if (Object.prototype.hasOwnProperty.call((arrayElement,"pathNode"))) {
                return arrayElement.pathNode === pathArray[0];
            } else if (_.isUndefined(arrayElement.nodeName)) {
                return arrayElement.nodeName === pathArray[0];
            }
                return arrayElement.pathNode === pathArray[0];

        })

        for (i = 0; i < this.pathableSubViews.length; i++) {
            if (this.pathableSubViews[i] === matchingPathableSubView) {
                if (_.isFunction(this.pathableSubViews[i].subView)) {
                    this.pathableSubViews[i].subView = new this.pathableSubViews[i].subView(_.clone(subViewState));
                    this.$el.find(this.pathableSubViews[i].subView.el).show();
        this.activeSubView = this.pathableSubViews[i].subView;
                } else {
                    this.$el.find(this.pathableSubViews[i].subView.el).show();
        this.activeSubView = this.pathableSubViews[i].subView;
                    this.pathableSubViews[i].subView.updateView(_.clone(subViewState));
                }
            } else if (!_.isFunction(this.pathableSubViews[i].subView)) {
                this.$el.find(this.pathableSubViews[i].subView.el).hide();
                // if backgroundUpdate is true, then inject the subViewState into the hidden subview
                if (this.pathableSubViews[i].backgroundUpdate) {
                    this.pathableSubViews[i].subView.updateView(_.clone(subViewState));
                }
            } else if (_.isFunction(this.pathableSubViews[i].subView) && this.pathableSubViews[i].preInitialize) {
                this.pathableSubViews[i].subView = new this.pathableSubViews[i].subView(_.clone(subViewState));
                this.$el.find(this.pathableSubViews[i].subView.el).hide();
            }
        }
    }
}
