import PropTypes from "prop-types";
import React from "react";
import ContactUsFormContainer from "components/ContactUsForm/ContactUsFormContainer";
import Modal from "components/modal/Modal";
import Mixpanel from "mixpanel";

const ContactUsFormModal = ({
  onCancel,
  isOpen,
  destination,
  emailSubject,
  emailBody,
}) => {
  const handleCancel = () => {
    onCancel();
    Mixpanel.trackEvent("Click on Cancel Button", {
      component: "Email Us Modal",
    });
  };

  const handleClose = () => {
    onCancel();
    Mixpanel.trackEvent("Close Email Us Modal", {
      component: "Email Us Modal",
    });
  };

  return (
    <Modal
      componentName="Email Us Modal"
      title="Email Us"
      isOpen={isOpen}
      onClosed={onCancel}
      className="pc-modal pc-modal--small"
      shouldCloseOnOverlayClick={false}
      aria={{
        label: "Email Us",
      }}
    >
      <ContactUsFormContainer
        onCancel={handleCancel}
        handleClose={handleClose}
        destination={destination}
        emailBody={emailBody}
        emailSubject={emailSubject}
      />
    </Modal>
  );
};

ContactUsFormModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  destination: PropTypes.string,
  emailSubject: PropTypes.string,
  emailBody: PropTypes.string,
};

ContactUsFormModal.defaultProps = {
  destination: "SUPPORT",
  emailSubject: "",
  emailBody: "",
  isOpen: true,
};

export default ContactUsFormModal;
