import {
  transition,
  select,
  mouse,
  bisector as bisectorD3,
  scan,
  event,
} from "d3";

const TOOLTIP_OFFSET = 12;
const HOVER_CIRCLE_RADIUS = 3;
const HALF = 2;

/**
 * Defines the continuous hover area covering the complete chart body.
 *
 * @export the tooltip factory function
 * @param {Object}    options           the options object
 * @param {Function}  options.x         the accessor function which is called on each datum object and returns the X value.
 * @param {Function}  options.y         the accessor function which is called on each datum object and returns the XYvalue.
 * @param {String}    options.width     the width of the hover area
 * @param {String}    options.height    the height of the hover area
 * @param {String}    options.className the class name
 * @param {String}    options.template  the template function to generate HTML content for the tooltip.
 *                                      Receives the dataset bound to the chart and the index associated
 *                                      with the currently focused datum `function(data, index) {}`.
 * @returns {Function}            the tooltip factory
 */
export default function (options) {
  let overlay;
  let tooltipEl;
  let circle;

  const tooltip = function (context) {
    const selection =
      context instanceof transition ? context.selection() : context;

    let line;
    let hover = selection.select(".js-chart-hover");
    if (hover.empty()) {
      hover = selection
        .append("g")
        .classed("js-chart-hover", true)
        .style("display", "none");

      line = hover
        .append("line")
        .classed("chart__hover-line", true)
        .style("stroke", "#000")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", options.height);
    }

    circle = hover.selectAll(".js-chart-series-highlight").data((d) => d);

    circle.exit().remove();

    // create as many hover circles as many data points there are
    circle = circle
      .enter()
      .append("circle")
      .attr("class", function (datum, index) {
        return `js-chart-series-highlight chart__hover-circle chart__hover-circle-${++index}`;
      })
      .attr("r", HOVER_CIRCLE_RADIUS)
      .merge(circle);

    tooltipEl = select("body").select(".js-chart-tooltip");
    if (tooltipEl.empty()) {
      tooltipEl = select("body")
        .append("div")
        .style("display", "none")
        .classed("chart-tooltip js-chart-tooltip", true);
    }

    overlay = selection.select(".js-chart-overlay");
    if (overlay.empty()) {
      overlay = selection
        .append("rect")
        .classed("js-chart-overlay", true)
        .attr("width", options.width)
        .attr("height", options.height)
        .style("fill", "none")
        .style("pointer-events", "all")
        .on("mouseover", function () {
          hover.style("display", null);

          // When one of react charts is destroyed
          // tooltip component is also destroyed (which is shared by all charts on the page)
          // recreating the tooltip component if it is empty on chart hover.
          tooltipEl = select("body").select(".js-chart-tooltip");
          if (tooltipEl.empty()) {
            tooltipEl = select("body")
              .append("div")
              .style("display", "none")
              .classed("chart-tooltip js-chart-tooltip", true);
          }

          tooltipEl.style("display", null);

          if (options.className) {
            tooltipEl.classed(options.className, true);
          }
        })
        .on("mouseout", function () {
          hover.style("display", "none");
          tooltipEl.style("display", "none");

          // Tooltip is a global element reused for all the charts.
          // Cleanup the custom class name after the user done hovering over the chart.
          if (options.className) {
            tooltipEl.classed(options.className, false);
          }
        })
        .on("mousemove", function (data) {
          const el = this; /* `this` is the target element */ // eslint-disable-line no-invalid-this, consistent-this
          const chartCoords = mouse(el);
          var dataX = options.xScale.invert(chartCoords[0]);

          // index of the closest datapoint from the left
          const bisector = bisectorD3(options.x).left;
          const longestSeries = data[scan(data, (a, b) => b.length - a.length)];
          const indexLeft = bisector(longestSeries, dataX, 1);
          const d0 = longestSeries[indexLeft - 1];
          const d1 = longestSeries[indexLeft];

          // find the closest data point from the left or from the right
          let index;
          let d;
          if (d1 && dataX - options.x(d0) > options.x(d1) - dataX) {
            d = d1;
            index = indexLeft;
          } else {
            d = d0;
            index = indexLeft - 1;
          }

          const snappedX = options.xScale(options.x(d));
          const offset = el.getBoundingClientRect().left + window.pageXOffset;
          let tooltipTop = event.pageY;
          let tooltipLeft = offset + snappedX;

          line.attr("x1", snappedX).attr("x2", snappedX);

          circle
            .style("display", (d) => {
              // one of the series could be shorter, so check if the datapoint exists at this index
              if (index > d.length - 1) {
                return "none";
              }
              // `null` removes the style
              return null;
            })
            .attr("cx", snappedX)
            .attr("cy", (d) => {
              // one of the series could be shorter, so check if the datapoint exists at this index
              if (index <= d.length - 1) {
                return options.yScale(options.y(d[index]));
              }
            });

          let transformX;
          if (options.width / HALF > chartCoords[0]) {
            transformX = TOOLTIP_OFFSET + "px";
          } else {
            transformX = "-100%";
            tooltipLeft -= TOOLTIP_OFFSET;
          }
          let transformY;
          if (options.height / HALF > chartCoords[1]) {
            transformY = TOOLTIP_OFFSET * HALF + "px";
          } else {
            transformY = "-100%";
            tooltipTop -= TOOLTIP_OFFSET;
          }
          tooltipEl
            .style("left", tooltipLeft + "px")
            .style("top", tooltipTop + "px")
            .style("transform", `translate(${transformX}, ${transformY})`)
            .html(options.template(data, index));
        });
    }
  };

  /**
   * Removes the tooltip and detaches the events
   *
   */
  tooltip.destroy = function () {
    overlay.on("mouseover mouseout mousemove", null);
    tooltipEl.remove();
  };

  /**
   * Specifies the accessor function for `x` value on the datum object.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {chart|Function}       If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  tooltip.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    return tooltip;
  };

  /**
   * Specifies the accessor function for `y` value on the datum object.
   * By defaults uses `datum.y`.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {tooltip|Function}     If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  tooltip.y = function (accessor) {
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    return tooltip;
  };

  /**
   * Set/get the hover area width.
   *
   * @param  {String} [width]   The `width` of the hover area.
   * @return {tooltip|String}   If `width` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `width` value.
   */
  tooltip.width = function (width) {
    if (!arguments.length) {
      return options.width;
    }

    options.width = width;
    return tooltip;
  };

  /**
   * Set/get the hover area height.
   *
   * @param  {String} [height]   The `height` of the hover area.
   * @return {tooltip|String}   If `height` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `height` value.
   */
  tooltip.height = function (height) {
    if (!arguments.length) {
      return options.height;
    }

    options.height = height;
    return tooltip;
  };

  /**
   * Set/get the xScale.
   *
   * @param  {String} [xScale]  The `xScale` of the hover area.
   * @return {tooltip|String}   If `xScale` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `xScale` value.
   */
  tooltip.xScale = function (xScale) {
    if (!arguments.length) {
      return options.xScale;
    }

    options.xScale = xScale;
    return tooltip;
  };

  /**
   * Set/get the yScale.
   *
   * @param  {String} [yScale]  The `yScale` of the hover area.
   * @return {tooltip|String}   If `yScale` is specified, returns `this` reference for chaining,
   *                            otherwise returns the current `yScale` value.
   */
  tooltip.yScale = function (yScale) {
    if (!arguments.length) {
      return options.yScale;
    }

    options.yScale = yScale;
    return tooltip;
  };

  /**
   * Set/get the className.
   *
   * @param  {String} [className]   The `className` for the tooltip.
   * @return {tooltip|String}       If `className` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `className` value.
   */
  tooltip.className = function (className) {
    if (!arguments.length) {
      return options.className;
    }

    options.className = className;
    return tooltip;
  };

  /**
   * Set/get the template function.
   *
   * @param  {Function} [template]  The `template` for the tooltip.
   * @return {tooltip|Function}     If `template` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `template` value.
   */
  tooltip.template = function (template) {
    if (!arguments.length) {
      return options.template;
    }

    options.template = template;
    return tooltip;
  };

  return tooltip;
}
