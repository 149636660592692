/* eslint-disable camelcase */
const convertMaskedNumber = (phone) => {
  if (phone && phone.indexOf("*") !== -1) {
    return phone.replace(/\*/g, ".");
  }

  return phone;
};

export default function toClient(appointment) {
  const { invitees } = appointment;
  return {
    appointmentId: appointment.appointmentId,
    newAppointmentId: appointment.newAppointmentId,
    isReschedule: appointment.isReschedule,
    timezone: appointment.timezone,
    firstName: appointment.firstName,
    fpTopics: JSON.stringify(appointment.selectedFpTopics),
    email: appointment.userEmail,
    phoneNumber: convertMaskedNumber(appointment.userPhoneNumber),
    invitees: invitees ? JSON.stringify(invitees) : null,
    clientNotesToAdvisor: appointment.clientNotesToAdvisor,
    source: appointment.source,
  };
}

export function toPublicServer(
  appointment,
  person,
  marketingSource,
  marketingParam,
  empowerAdvisorName
) {
  const { appointmentId } = appointment;
  const { invitees } = appointment;

  const advisorId = appointmentId ? appointmentId.split("-")[0] : "Unknown";

  return {
    appointmentId,
    newAppointmentId: appointment.newAppointmentId,
    appointmentTimeZone: appointment.timezone,
    email: appointment.userEmail,
    phoneNumber: convertMaskedNumber(appointment.userPhoneNumber),
    invitees: invitees ? JSON.stringify(invitees) : null,
    clientNotesToAdvisor: appointment.clientNotesToAdvisor,
    salesforceId: advisorId,
    person: JSON.stringify({ personInfo: person }),
    marketing_source: marketingSource,
    marketing_param: marketingParam,
    empowerAdvisorName: empowerAdvisorName,
    skipEmailVerification: "true",
  };
}
