import moment from "moment";

const MINUTE = 60000;

function millisecondsToMinutes(milliseconds) {
  return milliseconds / MINUTE;
}

export default function toClient(appointments) {
  return appointments.map((a) => {
    return Object.assign({}, a, {
      duration: millisecondsToMinutes(a.toTime - a.fromTime),
      appointmentType: a.meetingType,
      startTime: moment.utc(a.fromTime),
      endTime: moment.utc(a.toTime),
      userPhoneNumber: a.userPhoneNumber
        ? a.userPhoneNumber.replace(/\./g, "*")
        : a.userPhoneNumber,
    });
  });
}
