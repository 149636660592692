/**
 * Decimal adjustment of a number. Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/ceil
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === "undefined" || Number(exp) === 0) {
    return Math[type](value);
  }
  value = Number(value);
  exp = Number(exp);
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split("e");
  value = Math[type](
    Number(value[0] + "e" + (value[1] ? Number(value[1]) - exp : -exp))
  );
  // Shift back
  value = value.toString().split("e");
  return Number(value[0] + "e" + (value[1] ? Number(value[1]) + exp : exp));
}

export function roundDecimal(value, precision) {
  return decimalAdjust("round", value, -precision);
}

export function floorDecimal(value, precision) {
  return decimalAdjust("floor", value, -precision);
}

export function ceilDecimal(value, precision) {
  return decimalAdjust("ceil", value, -precision);
}
