import React from "react";
import $ from "jquery";
import "tooltip";

/**
 * Higher order component that wraps around another component to provide
 * commonly used tooltip initialization and destruction methods.
 *
 * Usage: const FormPageWithTooltips = withTooltips(FormPage);
 * Then use <FormPageWithTooltips /> instead of <FormPage />
 *
 * Inside FormPage, set the `ref` property of the tooltip icon element to `this.props.storeTooltipRef`:
 * <svg ref={this.props.storeTooltipRef} className="info-gathering-form__tip" viewBox="0 0 16 16" data-toggle="tooltip" data-placement="auto"
 *   title="Most people spend 80-100% of their current spending in retirement.">
 *   <use xlinkHref="#pc-icon__help-outline"></use>
 * </svg>
 *
 * @param {Component} WrappedComponent  The component to provide tooltip features for
 * @returns {Component} The wrapped component that will be able use these tooltip features
 */
function withTooltips(WrappedComponent) {
  return class WrappedComponentWithTooltips extends React.Component {
    constructor() {
      super(...arguments);
      this.tooltipElements = [];
    }

    componentWillUnmount() {
      $(this.tooltipElements).tooltip("destroy");
    }

    storeTooltipRef(el, tooltipOptions = {}) {
      if (!el) {
        return;
      }

      const $tooltipIcon = $(el);
      this.tooltipElements.push(el);

      // Used when you have a link in the tooltip you would like the user to be able to click on
      if (tooltipOptions.trigger === "tooltipClick") {
        tooltipOptions.container = $tooltipIcon; // In this flow, $tooltipIcon must be wrapped in a non-svg element (e.g. span)
        tooltipOptions.trigger = "hover";
      }

      $tooltipIcon.tooltip("destroy");
      $tooltipIcon.tooltip(tooltipOptions);
    }

    render() {
      return (
        <WrappedComponent
          ref={(el) => {
            this.wrappedComponent = el;
          }}
          storeTooltipRef={this.storeTooltipRef.bind(this)}
          {...this.props}
        />
      );
    }
  };
}

export default withTooltips;
