import React from "react";
import PropTypes from "prop-types";
import AbstractForm from "components/common/form/AbstractForm";
//import Input from 'components/common/form/Input';
import Message from "components/common/Message";

const MAX_SELECTIONS = 3;

const isValueChecked = (model) => {
  return Object.values(model).some((v) => v === true);
};

const selectedValues = (model) => {
  return Object.values(model).filter((v) => v === true).length;
};
class AppointmentFPTopics extends AbstractForm {
  constructor(props) {
    super(props);
    this.state = {
      model: props.model,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.focus();
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    const validateResult = this.validate();
    if (validateResult.valid) {
      this.props.onContinue(this.state.model);
    }
  }

  render() {
    const { errors, onBack } = this.props;

    const { model } = this.state;
    const maxValuesSelected = selectedValues(model) >= MAX_SELECTIONS;

    return (
      <form onSubmit={this.handleSubmit}>
        <section className="appointment">
          <div className="appointment__header">
            <h1 className="pc-beta pc-u-m pc-u-ml+">
              Financial Planning Topics
            </h1>
            <div className="appointment__sub-header">
              <h2 className="pc-delta pc-u-mb0 pc-u-pl- pc-u-pr-">
                In order to make your appointment as productive as possible,
                please select up to three topics you wish to cover:
              </h2>
            </div>
          </div>
          <div className="appointment__body appointment__body--padded">
            <Message className="pc-u-mb+" severity="error" messages={errors} />
            <div>
              <div className="pc-layout">
                {Object.keys(model).map((topic) => {
                  return (
                    <div
                      className="pc-layout__item pc-u-1/2 pc-u-mb-"
                      key={topic}
                    >
                      <label
                        className={`pc-label appointment-fp-topics__label${
                          maxValuesSelected && !model[topic]
                            ? " appointment-fp-topics__label--disabled"
                            : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          name={topic}
                          ref={this.storeInputRef}
                          onChange={this.handleInputChange}
                          checked={model[topic]}
                          disabled={maxValuesSelected && !model[topic]}
                        />
                        {topic}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="appointment__footer">
            <button
              type="button"
              className="js-appointment-review-back pc-btn pc-btn--appointment-action"
              onClick={onBack}
            >
              Back
            </button>
            <button
              type="submit"
              className="js-appointment-fp-topics-continue pc-btn pc-btn--primary pc-btn--appointment-action"
              disabled={!isValueChecked(model)}
            >
              Continue
            </button>
          </div>
        </section>
      </form>
    );
  }
}

AppointmentFPTopics.propTypes = {
  model: PropTypes.object,
  onContinue: PropTypes.func,
  onBack: PropTypes.func,
  errors: PropTypes.array,
};

AppointmentFPTopics.defaultProps = {
  model: {},
  validator: {},
};

export default AppointmentFPTopics;
