import React from "react";
import PropTypes from "prop-types";

function AppointmentActions({ onReschedule, onKeep, onCancel }) {
  return (
    <div>
      <button
        type="button"
        className="pc-btn pc-btn--appointment-action qa-appointment-action-cancel"
        onClick={onCancel}
      >
        Cancel Appointment
      </button>
      <button
        type="button"
        className="pc-btn pc-btn--appointment-action qa-appointment-action-reschedule"
        onClick={onReschedule}
      >
        Reschedule
      </button>
      <button
        type="button"
        className="pc-btn  pc-btn--primary pc-btn--appointment-action qa-appointment-action-keep"
        onClick={onKeep}
      >
        Keep Appointment
      </button>
    </div>
  );
}

AppointmentActions.propTypes = {
  onReschedule: PropTypes.func,
  onKeep: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AppointmentActions;
