import { isEmpty } from "underscore";

function defaultFormat(d) {
  return d;
}

function buildStackedLegendItem(datum, options) {
  const stacks = options.stacks;
  const yFormat = options.yFormat;

  return stacks
    .map((stack, i) => {
      if (
        typeof options.shouldHideValue === "function" &&
        options.shouldHideValue(datum[stack])
      ) {
        return "";
      }
      return `<div class="chart-legend__item chart-legend__item--${
        i + 1
      }">${yFormat(datum[stack])}</div>`;
    })
    .reverse()
    .join("");
}

function buildLegendItems(datum, options) {
  const stacks = options.stacks;
  if (datum && stacks) {
    return buildStackedLegendItem(datum, options);
  }
  return "";
}

/**
 * Returns a generator function for the tooltip template.
 * The template uses the supplied formatters for displaying
 * the focused values.
 * The shape of the legend (box or line) can be specified via
 * `legendClassName` parameter.
 *
 * @param {Object}    options                 options object
 * @param {Function}  options.x               x accessor function
 * @param {Function}  options.y               y accessor function
 * @param {Function}  options.xFormat         x formatter function
 * @param {Function}  options.yFormat         y formatter function
 * @param {Array}     options.stacks          stacks array passed to chart
 * @param {Function}  options.shouldHideValue takes in value of a stack and returns if the tooltip should display legend item for that point
 * @param {String}    options.legendClassName the class name to set on the legend list.
 *                                            `chart-legend--line` and `chart-legend--box` are available to
 *                                            control how the legends look like (line vs box).
 *                                            Size modifiers as `chart-legend--small` are also available.
 *
 * @returns {Function} the generator function which returns a template function to be used with the tooltip
 */
export default function (options) {
  const template = function (datum) {
    const { x, legendClassName } = options;
    const xFormat = options.xFormat || defaultFormat;
    const xValue = xFormat(x(datum));
    const isStackBarChart = !isEmpty(options.stacks);

    if (isStackBarChart) {
      const legendItemsHtml = buildLegendItems(datum, options);
      return `<div class="chart-tooltip__header">${xValue}</div>
    <ul class="chart-legend chart-legend--vertical ${
      legendClassName || ""
    }">${legendItemsHtml}</ul>`;
    }

    const yFormat = options.yFormat || defaultFormat;
    const formatedYValue = yFormat(options.y(datum));

    return `<div>
              <div class="chart-tooltip__header">${xValue}</div>
              <div>${formatedYValue}</div>
            </div>`;
  };

  /**
   * Specifies the accessor function for `x` value on the datum object.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {chart|Function}       If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  template.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    return template;
  };

  /**
   * Specifies the accessor function for `y` value on the datum object.
   * By defaults uses `datum.y`.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {template|Function}    If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  template.y = function (accessor) {
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    return template;
  };

  /**
   * Specifies the accessor function for `xFormat` value on the datum object.
   * By defaults uses `datum.xFormat`.
   *
   * @param  {Function} [xFormat]  the xFormat function.
   * @return {template|Function}    If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  template.xFormat = function (xFormat) {
    if (!arguments.length) {
      return options.xFormat;
    }

    options.xFormat = xFormat;
    return template;
  };

  /**
   * Specifies the accessor function for `yFormat` value on the datum object.
   * By defaults uses `datum.yFormat`.
   *
   * @param  {Function} [yFormat]  the yFormat function.
   * @return {template|Function}    If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  template.yFormat = function (yFormat) {
    if (!arguments.length) {
      return options.yFormat;
    }

    options.yFormat = yFormat;
    return template;
  };

  /**
   * Specifies the accessor function for `y` value on the datum object.
   * By defaults uses `datum.y`.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {template|Function}    If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  template.stacks = function (accessor) {
    if (!arguments.length) {
      return options.stacks;
    }

    options.stacks = accessor;
    return template;
  };

  return template;
}
