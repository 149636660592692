import $ from "jquery";
import analytics from "analytics";
var DEFAULT_MESSAGES = [
  {
    time: 7500,
    message: "Loading...",
  },
  {
    time: 7500,
    message: "Still loading...",
  },
  {
    message: "This seems to be taking a while...",
  },
];

var LOADER_BASE = `<div class="loader__container">
    <div class="loader__circle loader__full-circle"></div>
    <div class="loader__circle loader__spinning-circle"></div>
    <div class="loader__circle loader__spinning-circle"></div>
    </div><div class="js-loading-message" style="display: block">Loading...</div>`;

var TIMEOUT_FAIL = 20000;

var ALWAYS_CENTERED_CLASS_NAME = "pc-overlay__loader--always-centered";

/**
 * This is the overlay component to cover an area in the dom.
 * The area to cover is defined by the target element
 * which should be provided when `Overlay` component is instatiated.
 * NOTE: the only requirement for the target element is
 * to have either `relative` or `absolute` position.
 *
 * @example
 * var overlay = new Overlay({
 *     target: document.getElementById('target')
 *   });
 * // later in the code
 * overlay.show();
 *
 * NOTE: A scrollable element stays scrollable if the overlay is applied
 * directly on it. Instead, wrap it with an `inline-block` element having
 * the relative position and use the wrapper as the target for the overlay.
 *
 * @param {Object}          options             The options object
 * @param {Element|jQuery}  options.target      The target element to cover with overlay
 * @param {Array}           options.messages    The array of time-to-message pairs to dynamically
 *                                              change the message on the loader element.
 *                                              NOTE: `time` value represents the amount of time the current
 *                                              `message` will be displayed.
 *                                              Example: ```[
 *                                                { time: 3000, message: 'Loading...' },
 *                                                { time: 5000, message: 'Still loading...' }
 *                                              ]```
 * @param {String}          [options.className] The optional `className` to assign to the overlay
 */
function Overlay(options) {
  if (options.target instanceof jQuery) {
    options.target = options.target[0];
  }

  this.options = options;

  var overlay = document.createElement("div");
  var className = "pc-overlay";
  if (options.className) {
    className += " " + options.className;
  }
  overlay.className = className;

  const loader = document.createElement("div");
  this.$loader = $(loader);
  loader.className = "pc-overlay__loader";
  loader.innerHTML = LOADER_BASE;
  overlay.appendChild(loader);

  this.element = overlay;
  this.$element = $(overlay);
  this.active = false;
}

Overlay.prototype.displayMessage = function () {
  if (this.messages.length) {
    var messagePair = this.messages.shift();
    $(".js-loading-message").html(messagePair.message);
    if (messagePair.time) {
      this.messageTimeout = setTimeout(
        function () {
          this.displayMessage();
        }.bind(this),
        messagePair.time
      );
    }
  }
};

/**
 * Displays the overlay.
 *
 * @param  {Array} [messages] An optional array of messages.
 *                            If not provided, the instance messages or the default ones will be used.
 * @param  {Boolean} alwaysCentered   Optional flag to indicated whether loader should always be centered
 */
Overlay.prototype.show = function (messages, { alwaysCentered = false } = {}) {
  // clone messages
  this.messages = (
    messages ||
    this.options.messages ||
    Overlay.DEFAULT_MESSAGES
  ).slice();
  if (this.messageTimeout) {
    clearTimeout(this.messageTimeout);
  }

  // Attach to the DOM if necessary
  this.$element.addClass("pc-overlay--active");
  if (alwaysCentered) {
    this.$loader.addClass(ALWAYS_CENTERED_CLASS_NAME);
  }
  if (!this.element.parentNode) {
    this.options.target.appendChild(this.element);

    // force re-layout for the initial animation to kick in
    this.element.clientWidth; // eslint-disable-line no-unused-expressions
  }
  this.displayMessage();

  this.active = true;

  // temporary solution to report that the overlay has not been removed after 20s
  // It will be moved out to a separate module in a later sprint.
  if (this.failTimeout) {
    clearTimeout(this.failTimeout);
  }
  this.failTimeout = setTimeout(function () {
    analytics.sendEngineeringEvent("Warning", "App loading overlay timeout");
  }, TIMEOUT_FAIL);
};

/**
 * Hides the overlay.
 */
Overlay.prototype.hide = function () {
  this.$element.removeClass("pc-overlay--active");
  this.$loader.removeClass(ALWAYS_CENTERED_CLASS_NAME);
  this.active = false;

  if (this.messageTimeout) {
    clearTimeout(this.messageTimeout);
  }
  if (this.failTimeout) {
    clearTimeout(this.failTimeout);
  }
};

/**
 * Whether the overlay is currently active.
 * @return {Boolean} is active
 */
Overlay.prototype.isActive = function () {
  return this.active;
};

Overlay.prototype.remove = function () {
  this.element.parentNode?.removeChild(this.element);
  this.element = undefined;
  this.$element = undefined;
  this.$loader = undefined;
};

Overlay.DEFAULT_MESSAGES = DEFAULT_MESSAGES;

export default Overlay;
