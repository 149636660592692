import React from "react";
import PropTypes from "prop-types";
import objectPath from "object-path";
import { isEqual, isEmpty } from "underscore";
import deepCopy from "deep-copy";

/**
 * AbstractForm provides methods and event handlers to manage the input elements
 * on the form and to validate user input.
 *
 * ### Setup
 * To wire a form input, the extending component needs to do some setup:
 * ```
    <Input type="text" name="firstName"
      ref={this.storeInputRef}                    // 1. Store the reference to the field
      onChange={this.handleInputChange}           // 2. Configure the change listener
      validator={schema.properties.firstName} />  // 3. Optionally provide a validation schema
 * ```
 *
 * ### State
 * The state of the controlled form inputs is captured in `this.state.model`.
 * As the user enters information, the value is captured under the key matching the `name`
 * attribute of the input.
 *
 * ### Validation
 * Call `this.validate()` to validate the form before the submission. The call triggers the validation
 * on all inputs which have `validator` attribute provided.
 * The validator format should conform to https://github.com/flatiron/revalidator#schema
 *
 * @export AbstractForm
 * @class AbstractForm
 * @extends {React.Component}
 */
export default class AbstractForm extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      model: {},
    };

    this.inputElements = [];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.checkIfFormHasChanged = this.checkIfFormHasChanged.bind(this);
    this.updateModel = this.updateModel.bind(this);
    this.storeInputRef = this.storeInputRef.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.saveOriginalModel();
  }

  componentWillUnmount() {
    this.inputElements = null;
  }

  focus() {
    if (this.inputElements[0] && this.inputElements[0].focus) {
      this.inputElements[0].focus();
    }
  }

  /**
   * Input change handler (`onChange`) which captures the value in `this.state.model`
   * under the key matching the `name` attribute value.
   *
   * This method is normally used only by `Input` components that has a single input field.
   * Composite input components should use `updateModel()` below as `onChange` handler.
   *
   * @param {Event} ev the change event
   * @param {any} value Input field value
   * @memberOf AbstractForm
   */
  handleInputChange(ev, value) {
    const inputField = ev.target ? ev.target : ev;
    const inputFieldName = inputField.name ? inputField.name : ev;

    // FIXME!!! the value should always come from `ev.target.value`.
    // ^ The problem is ev.target.value can only hold strings and sometimes we need to pass in numbers or objects
    // This is a temporary fix to make the handler compatible with standard input fields.
    let inputFieldValue = value == null ? ev.target.value : value;
    switch (inputField.type) {
      case "checkbox": {
        // If the value attribute was omitted, the default value for the checkbox is "on".
        // Fallback to the checked state (boolean) in that case.
        if (inputFieldValue === "on") {
          inputFieldValue = inputField.checked;
        } else {
          inputFieldValue = inputField.checked ? inputFieldValue : undefined;
        }

        // If there are more than one `input[type=checkbox]` or Checkbox component with the same `name` in the current form
        const checkboxArrayValue = [];
        const checkboxes = this.inputElements.filter((inputOrCheckbox) => {
          if (inputOrCheckbox.unmounted) {
            return false;
          }

          const isInputField =
            objectPath.get(inputOrCheckbox, "props.name") === inputFieldName ||
            inputOrCheckbox.name === inputFieldName;
          if (
            isInputField &&
            (objectPath.get(inputOrCheckbox, "ref.checked") ||
              inputOrCheckbox.checked)
          ) {
            checkboxArrayValue.push(
              inputOrCheckbox.originalValue || inputOrCheckbox.value
            );
          }
          return isInputField;
        });

        if (checkboxes.length > 1) {
          inputFieldValue = checkboxArrayValue;
        }
        break;
      }
      case "radio":
      case "text":
      default:
      // No-op
    }

    this.updateModel(inputFieldName, inputFieldValue);
  }

  /**
   * Update model with passed in form field name and value.
   *
   * @param {String} name         Model attribute name or key path. By default, the `name` works as a path to a deep
   *                              property. The path uses `.` to indicate multiple object branch levels
   *                              (e.g. `name.firstName`, `spouseDetails.socialSecurityIncome.socialSecurityStartAge`).
   *                              This behavior can be turned off by initializing the component with `shallowModelPropertyPath: true`.
   * @param {any} value           Model attribute value, non-boolean falsy values ('', null, undefined, NaN) will
   *                              be converted to an empty string
   * @return {Object}             The updated model
   */
  updateModel(name, value) {
    if (!name || typeof name !== "string") {
      throw new Error("`name` must be a non-empty string");
    }
    const marketingItem = name.replace(
      "marketingPreferences.",
      "marketingPreferences[0]."
    );
    const model = this.state.model || {};
    if (this.props.shallowModelPropertyPath) {
      model[name] = value;
    }
    if (model.marketingPreferences) {
      objectPath.set(model, name, value);
    } else {
      objectPath.set(model, name, value);
    }
    this.setState({ model });
    return model;
  }

  /**
   * Validates the controlled form inputs and returns the validation result.
   *
   * @returns {Object} the validation result
   * ```
      {
        valid: true // or false
        errors: []  // Array of errors if valid is false
      }
   * ```
   *
   * @memberOf AbstractForm
   */
  validate() {
    const errors = this.inputElements
      .map((field) => {
        if (field.unmounted || typeof field.validate !== "function") {
          return null;
        }

        let errors;
        if (field.props.validator) {
          let validateResult = field.validate();
          if (!validateResult.valid) {
            errors = validateResult.errors;
          }
        }
        return errors;
      })
      .filter((e) => e) // remove holes
      .reduce((acc, cur) => (acc ? acc.concat(cur) : cur), []);

    if (errors.length) {
      return {
        valid: false,
        errors: errors,
      };
    }

    return { valid: true };
  }

  /**
   * Stores the reference to the provided DOM element.
   * Use as a `ref` callback `ref={this.storeInputRef}`
   *
   * @param {Element} el the form element
   *
   * @memberOf AbstractForm
   */
  storeInputRef(el) {
    if (!el) {
      return;
    }
    this.inputElements.push(el);
  }

  /**
   * Stores original model, so that it can be used to compare later.
   * this method is called in componentDidMount method
   * Incase where the child component has a different implementation for componentDidMount
   * this method must be called in componentDidMount of child method
   * to be able to use checkIfFormHasChanged.
   */
  saveOriginalModel() {
    if (isEmpty(this.state.originalModel)) {
      const originalModel = deepCopy(this.state.model);
      this.setState({ originalModel });
    }
  }

  /**
   * Check if the current model is modified when compared to original
   * @return {Boolean} specifies if the model that is bound to the form has changed
   */
  checkIfFormHasChanged() {
    return !isEqual(this.state.model, this.state.originalModel);
  }
}

AbstractForm.defaultProps = {
  // When `true`, turns off the default behavior where the input names on the form
  // are treated as the path to the deep properties on the model.
  shallowModelPropertyPath: false,
};

AbstractForm.propTypes = {
  shallowModelPropertyPath: PropTypes.bool,
};
