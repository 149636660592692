/* eslint-disable no-invalid-this */
import { extent as extentD3, merge, area as areaD3, transition } from "d3";

function includesZero(data, accessor) {
  var extent = extentD3(merge(data), accessor);
  if (extent[0] === 0 || extent[1] === 0 || (extent[0] < 0 && extent[1] > 0)) {
    return true;
  }
  return false;
}

// TODO think how to reuse code in `shape/area` and `shape/line`

// TODO the transition time should match the transition for axes (500ms) in case the area
// has negativ values. Otherwise the animation of the 0 axis doesn't match the transitioning
// area and overall experience is bad. However, we don't have instances with negative values yet,
// but do need 1000ms transition in first use retirement planner.
// Leaving 1000ms here till I figure out how to propogate transition configuration to modify it
// on the shape instance.
var DEFAULT_TRANSITION_DURATION_SERIES = 1000;
var CLASS_NAME = "chart__series--area";

/**
 * Draws an area shape.
 *
 * @param {Object}          options         Initialization options.
 * @param {Function|Number} options.x       If `x` is specified, sets the x accessor to the specified function or number.
 * @param {Function|Number} options.y       If `y` is specified, sets the y accessor to the specified function or number.
 * @param {Function}        options.xScale  The X scale.
 * @param {Function}        options.yScale  The Y scale.
 *
 * @returns {Function}                      A new area series.
 */
function area(options) {
  options = options || {};

  var xScaledAccessor = function (d) {
    return options.xScale(options.x(d));
  };
  var yScaledAccessor = function (d) {
    return options.yScale(options.y(d));
  };

  // All options that should be accessible to caller
  var shape = areaD3().x(xScaledAccessor).y1(yScaledAccessor);

  if (options.curve) {
    shape.curve(options.curve);
  }

  function series(context, opts) {
    options = Object.assign(options, opts);
    const { areaClassName, y, yScale, height, key } = options;
    var hasInheritedTransition = context instanceof transition;
    var selection = hasInheritedTransition ? context.selection() : context;

    // Detect where to position the baseline of the area:
    // 1. If data series includes or crosses 0 value match the baseline with 0 value.
    // 2. Otherwise, set the baseline at the X axis.
    var data = selection.data();
    if (data.length !== 0) {
      data = data[0]; // data is always wrapped in an extra array here
      shape.y0(includesZero(data, y) ? yScale(0) : height);
    }

    var keyFunction;
    if (key) {
      keyFunction = function (d) {
        return d ? key(d) : this.getAttribute("data-key");
      };
    }

    var areas = selection.selectAll(".js-chart-series").data(function (d) {
      return d;
    }, keyFunction);

    areas
      .enter()
      .append("path")
      .attr("data-key", function (d) {
        return key ? key(d) : undefined;
      })
      .attr("d", shape)
      .attr("class", function (datum, index) {
        let className =
          "js-chart-series chart__series " +
          CLASS_NAME +
          " " +
          CLASS_NAME +
          "-" +
          ++index;
        if (areaClassName) {
          const classNameToAppend = areaClassName(datum);
          if (classNameToAppend) {
            className += ` ${classNameToAppend}`;
          }
        }

        return className;
      });

    areas.exit().remove();

    if (hasInheritedTransition) {
      areas = areas
        .transition(context)
        .duration(DEFAULT_TRANSITION_DURATION_SERIES);
    }
    areas.attr("d", shape);
  }

  // ------------------------------------------------------------
  //  PUBLIC
  // ------------------------------------------------------------

  /**
   * Set/get x value accessor.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {series|Function}      If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  series.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    return series;
  };

  /**
   * Set/get y value accessor.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {series|Function}      If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  series.y = function (accessor) {
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    return series;
  };

  /**
   * Set/get the scale of Y axis.
   *
   * @param  {Array} [scale=null]  the scale.
   * @return {series|Function}      If `scale` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `xScale` value.
   */
  series.xScale = function (scale) {
    if (!arguments.length) {
      return options.xScale;
    }

    options.xScale = scale;
    return series;
  };

  /**
   * Set/get the scale of Y axis.
   *
   * @param  {Array} [scale=null]   the Y scale
   * @return {series|Function}      If `scale` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `yScale` value.
   */
  series.yScale = function (scale) {
    if (!arguments.length) {
      return options.yScale;
    }

    options.yScale = scale;
    return series;
  };

  return series;
}

export default area;
