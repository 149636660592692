import React from "react";
import PropTypes from "prop-types";

export default function AppointmentTypeSelectorBlank({ selectorClassName }) {
  return (
    <div
      className={`appointment-type-selector appointment-type-selector--blank qa-appointment-type-selector ${selectorClassName}`}
    >
      Please select from available dates and times
    </div>
  );
}

AppointmentTypeSelectorBlank.propTypes = {
  selectorClassName: PropTypes.string,
};

AppointmentTypeSelectorBlank.defaultProps = {
  selectorClassName: "",
};
