import PropTypes from "prop-types";
import React from "react";
import BaseInput from "components/common/form/BaseInput";
import Select, { createFilter } from "react-select";
import FancySelectUtils, {
  datasetFromProps,
  equalityFnReactComp,
} from "./FancySelectUtils";
import { pluck } from "underscore";
import { ModalContext } from "components/modal/ModalContext";
import memoizeOne from "memoize-one";

const getInlineProps = () => ({
  isSearchable: false,
  isClearable: false,
});

/**
 * Returns a set of props for the wrapped `react-select` to fix an issue
 * with rendering of the select menu inside of a modal window. The current
 * implementation of `react-select` doesn't recalculate the position
 * of the menu on scroll when it's attached to the body element.
 *
 * This makes the select menu:
 * 1. To be attached to the body element.
 *    This prevents the dropdown menu from being cut off by the modal window.
 * 2. To be flipped up or down depending on the available space.
 *    This prevents unexpected scroll events when the menu opens.
 * 3. To not propagate scroll events from the menu to body.
 *    This helps with keeping the menu open when the menu content is being scrolled.
 * 4. To be closed when the page is scrolled.
 * @param {Boolean} inModal is rendered in a modal?
 * @returns {Object} props
 */
const getModalProps = (inModal) => {
  if (inModal) {
    return {
      menuPortalTarget: document.body,
      menuPlacement: "auto",
      captureMenuScroll: true,
      closeMenuOnScroll: (ev) =>
        ev.target.nodeType === Node.DOCUMENT_NODE ||
        !ev.target.classList.contains("Select__menu-list"),
    };
  }
};

/**
 * This is a `react-select` component wrapper with the validation support.
 * Validator should be provided via `validator` attribute in the format
 * https://github.com/flatiron/revalidator#schema
 *
 * Example:
 * ```
    {
      allowEmpty: false
    }
 * ```
 *
 * Visit (react-select)[http://jedwatson.github.io/react-select/] for more details.
 *
 * @export FancySelect
 * @class FancySelect
 * @extends {BaseInput}
 */
export default class FancySelect extends BaseInput {
  constructor() {
    super(...arguments);

    this.buildMenu = memoizeOne((className) =>
      FancySelectUtils.Menu({
        className,
      })
    );
    this.buildMenuList = memoizeOne(
      (menuFooterComponent) =>
        FancySelectUtils.MenuList({
          menuFooterComponent,
        }),
      equalityFnReactComp
    );

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (this.props.name === "selectTransactionTags") {
      this.ref.select.focus();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.handleChange = this.handleChange.bind();
  }

  focus() {
    this.ref.focus();
  }

  handleChange(selectedOptions) {
    const { name, isMulti } = this.props;
    const value = isMulti
      ? pluck(selectedOptions || [], "value")
      : (selectedOptions || {}).value;

    super.handleChange({
      target: {
        name,
        value,
        dataset: datasetFromProps(this.props),
      },
    });
  }

  handleInputChange(input, data) {
    // Filter out events not related to the actual search input change
    if (data.action !== FancySelectUtils.REACT_SELECT_ACTION__INPUT_CHANGE) {
      return;
    }
    const { onInputChange, onSearch, matchFromStart, options } = this.props;
    if (onInputChange) {
      onInputChange(...arguments);
    }
    // Used when the parent component needs to know whether the current search query has any results (e.g. to dynamically set below-menu button captions)
    if (onSearch) {
      onSearch({
        searchInput: input,
        resultCount: FancySelectUtils.getSearchResultCount({
          input,
          options,
          matchFromStart,
        }),
      });
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const props = this.props;
    const { valid, value } = this.state;
    const {
      helpText,
      inline,
      containerClassName,
      menuFooterComponent,
      menuClassName,
      helpBlockClassName,
      isMulti,
      inputId,
      ariaLabel = "",
      selectInputId,
      isRequired,
      selectLabel,
    } = props;
    const selectedOptionObject = isMulti
      ? FancySelectUtils.getOptionObjectsFromValues(props.options, value)
      : FancySelectUtils.getOptionObjectFromValue(props.options, value);
    const className = `Select ${props.className || ""} ${
      valid ? "" : "Select--error"
    }  ${inline ? "Select--inline" : ""} ${isMulti ? "Select--multiple" : ""}`;

    let inlineProps = {};
    if (inline) {
      inlineProps = getInlineProps();
    }

    let componentsProp = {
      Menu: this.buildMenu(menuClassName),
      MenuList: this.buildMenuList(menuFooterComponent),
      ClearIndicator: FancySelectUtils.ClearIndicator,
      DropdownIndicator: FancySelectUtils.DropdownIndicator,
      NoOptionsMessage: FancySelectUtils.NoOptionsMessage,
      ...(isRequired
        ? {
            Input: (props) =>
              FancySelectUtils.RequiredInput({
                ...props,
                "aria-label": ariaLabel,
              }),
          }
        : {}),
    };

    /**
     * There are two ways to override the `value` and `option` renderer components
     * 1) Pass them in as `valueRenderer` and `optionRendered` props, or
     * 2) Pass them in via the `components` prop
     * See https://react-select.com/components#replacing-components for more details.
     */

    if (props.valueRenderer) {
      componentsProp.SingleValue = FancySelectUtils.SingleValue(
        props.valueRenderer
      );
    }

    if (props.optionRenderer) {
      componentsProp.Option = FancySelectUtils.Option(props.optionRenderer);
    }

    if (props.matchFromStart) {
      inlineProps.filterOption = createFilter({
        matchFrom: props.matchFromStart ? "start" : "any",
      });
    }

    return (
      <ModalContext.Consumer>
        {(inModal) => (
          <div className={containerClassName}>
            {selectLabel && (
              <label htmlFor={inputId} className="pc-help-block u-text-left">
                {selectLabel}
              </label>
            )}
            <Select
              aria-labelledby={inputId}
              aria-label={ariaLabel}
              {...getModalProps(inModal)}
              {...props}
              {...inlineProps}
              ref={(el) => {
                this.ref = el;
              }}
              classNamePrefix="Select"
              className={className}
              value={selectedOptionObject || null} // Set to null to clear the previously selected option
              onChange={this.handleChange}
              onInputChange={this.handleInputChange}
              onBlur={this.handleBlur}
              styles={FancySelectUtils.FancySelectStyles}
              components={Object.assign(
                {},
                componentsProp,
                props.components || {}
              )}
              inputId={selectInputId}
            />
            {helpText && (
              <label
                className={`pc-help-block pc-help-block--tiny u-text-left ${helpBlockClassName}`}
              >
                {helpText}
              </label>
            )}
            {this.getErrorBlock(this.props.errorProps)}
          </div>
        )}
      </ModalContext.Consumer>
    );
  }
}

FancySelect.defaultProps = Object.assign(
  {},
  BaseInput.defaultProps,
  Select.defaultProps,
  {
    maxMenuHeight: FancySelectUtils.DEFAULT_MAX_MENU_HEIGHT,
    helpBlockClassName: "",
  }
);

FancySelect.propTypes = Object.assign(
  {},
  BaseInput.propTypes,
  Select.propTypes,
  {
    onSearch: PropTypes.func,
    helpText: PropTypes.string,
    selectLabel: PropTypes.string,
    errorProps: PropTypes.object,
    containerClassName: PropTypes.string,
    helpBlockClassName: PropTypes.string,
    matchFromStart: PropTypes.bool,
    menuFooterComponent: PropTypes.element,
    menuClassName: PropTypes.string,
    tabIndex: PropTypes.number,
  }
);
