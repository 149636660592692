/* eslint-disable camelcase */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "components/common/LoadingOverlay";
import Message from "components/common/Message";
import FileDropZone from "components/common/FileDropZone";
import { isEmpty, isString } from "underscore";
import {
  FILE_UPLOAD_STATUS_MESSAGE,
  FILE_UPLOAD_STATUS_MESSAGE_REBRAND,
  INPUT_FILE_TYPES,
} from "components/common/DocumentUploader/utils/constants";

// eslint-disable-next-line sonarjs/cognitive-complexity
const DocumentUploaderView = (props) => {
  const {
    loading,
    errors,
    onBack,
    onDone,
    onFilesAdded,
    files,
    multiple,
    acceptingFiles,
    uploadIconSet,
    uploadStatusMessages,
    footerUploadList,
    onInputClick,
    btnClassName,
  } = props;

  const handleChange = useCallback(
    (e) => {
      if (IS_EMPOWER) {
        window.dashboardUtils?.eventBus.dispatch(
          "funding_account_details.doc_upload_manual_button.click"
        );
        window.dashboardUtils?.eventBus.dispatchAmplitude({
          event_type:
            window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_BUTTON,
          event_properties: {
            selection: "funding_account_details.doc_upload_manual_button.click",
          },
        });
      }

      if (e.target && e.target.files) {
        onFilesAdded(Array.from(e.target.files));
      }
    },
    [onFilesAdded]
  );

  const isUploadingFiles = files.some((file) => !file.complete);
  const isAcceptingImages = acceptingFiles === INPUT_FILE_TYPES.IMAGES;
  const multiFileTypeString = isAcceptingImages ? "images" : "files";
  const singleFileTypeString = isAcceptingImages ? "an image" : "a file";
  const multiSelectButtonLabel = isAcceptingImages ? "Images" : "Files";
  const singleSelectButtonLabel = isAcceptingImages ? "an Image" : "a File";

  const getBodyIcon = () => {
    let icon = (
      <svg className="icon-svg icon--large">
        <use
          href={
            isAcceptingImages ? "#icon-image--colored" : "#icon__file-upload"
          }
        ></use>
      </svg>
    );

    if (uploadIconSet?.BODY_ICON) {
      if (isString(uploadIconSet?.BODY_ICON)) {
        icon = (
          <svg className="icon-svg icon--large">
            <use href={uploadIconSet.BODY_ICON}></use>
          </svg>
        );
      } else {
        icon = uploadIconSet.BODY_ICON;
      }
    }

    return icon;
  };

  const bodyIcon = getBodyIcon();

  return (
    <FileDropZone onDrop={onFilesAdded} multiple={multiple}>
      <Message className="pc-u-mb" severity="error" messages={errors} />
      <LoadingOverlay active={loading} />
      <div className="document-uploader">
        <div className="document-uploader__body">
          <div className="document-uploader__icon-text-section pc-u-mb--">
            <span className="document-uploader__body-icon">{bodyIcon}</span>
            <span className="document-uploader__body-text">
              To upload, drag{" "}
              {multiple ? multiFileTypeString : singleFileTypeString} onto this
              page or
            </span>
          </div>
          <span className="document-uploader__body-action pc-u-mb--">
            <label className="pc-btn" htmlFor="supportingDocuments">
              <span
                role="button"
                aria-controls="supportingDocuments"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (["Enter", " "].includes(e.key)) {
                    e.preventDefault();
                    document.getElementsByClassName(btnClassName)[0].click();
                  }
                }}
              >
                {`Select ${
                  multiple ? multiSelectButtonLabel : singleSelectButtonLabel
                }`}
              </span>
            </label>
            <input
              id="supportingDocuments"
              name="supportingDocuments"
              className={`qa-document-upload-btn ${btnClassName} is-hidden`}
              type="file"
              onChange={handleChange}
              multiple={multiple}
              accept={acceptingFiles}
              onClick={onInputClick}
            />
          </span>
        </div>
        {!isEmpty(files) && errors.length === 0 && (
          <ul className="document-uploader__list pc-list-bare">
            {!footerUploadList &&
              files.map((f) => (
                <ListItem
                  key={f.file.name}
                  file={f}
                  uploadStatusMessages={uploadStatusMessages}
                  uploadIconSet={uploadIconSet}
                />
              ))}

            {footerUploadList &&
              files
                .filter((f) => f.complete)
                // eslint-disable-next-line sonarjs/no-identical-functions
                .map((f) => (
                  <ListItem
                    key={f.file.name}
                    file={f}
                    uploadStatusMessages={uploadStatusMessages}
                    uploadIconSet={uploadIconSet}
                  />
                ))}
          </ul>
        )}
      </div>

      {footerUploadList && !isEmpty(files) && isUploadingFiles && (
        <>
          <div className="document-uploader__footerList pc-u-mt">
            <div className="document-uploader__subtitle">
              <h3 className="pc-u-mb-">Uploading ...</h3>
            </div>
            <ul className="document-uploader__list pc-list-bare">
              {files
                .filter((f) => !f.complete)
                .map((f) => (
                  <ListItem
                    key={f.file.name}
                    file={f}
                    uploadStatusMessages={uploadStatusMessages}
                  />
                ))}
            </ul>
          </div>
          <hr className="document-uploader__divider pc-u-mv" />
        </>
      )}

      {onDone && (
        <div
          className={`u-text-right ${
            !footerUploadList || (footerUploadList && !isUploadingFiles)
              ? "pc-u-mt+"
              : "pc-u-mt"
          }`}
        >
          {onBack && isUploadingFiles && (
            <button
              className="pc-btn pc-btn--link pc-u-mr js-document-upload-back"
              onClick={onBack}
            >
              Back
            </button>
          )}

          <button
            type="button"
            className="pc-btn pc-btn--primary js-document-upload-done qa-document-vault-upload-done qa-document-upload-done"
            onClick={onDone}
            disabled={isUploadingFiles}
          >
            {!onBack || (onBack && isUploadingFiles) ? "Continue" : "Done"}
          </button>
        </div>
      )}
    </FileDropZone>
  );
};

DocumentUploaderView.propTypes = {
  loading: PropTypes.bool,
  errors: PropTypes.array,
  onBack: PropTypes.func,
  onDone: PropTypes.func,
  onFilesAdded: PropTypes.func.isRequired,
  files: PropTypes.array,
  multiple: PropTypes.bool,
  acceptingFiles: PropTypes.string,
  uploadIconSet: PropTypes.object,
  uploadStatusMessages: PropTypes.object,
  footerUploadList: PropTypes.bool,
  onInputClick: PropTypes.func,
  btnClassName: PropTypes.string,
};

DocumentUploaderView.defaultProps = {
  loading: false,
  errors: undefined,
  onBack: undefined,
  onDone: undefined,
  files: [],
  multiple: true,
  acceptingFiles: INPUT_FILE_TYPES.ALL,
  uploadIconSet: undefined,
  uploadStatusMessages: undefined,
  footerUploadList: undefined,
  onInputClick: undefined,
  btnClassName: "js-document-upload-btn",
};

function ListItem({ file: f, uploadStatusMessages, uploadIconSet }) {
  return (
    <li
      className={`document-uploader__item qa-document-upload-list-item ${
        f.error ? "document-uploader__item--error" : ""
      }`}
    >
      <UploadStatusIcon file={f} uploadIconSet={uploadIconSet} />
      <span>
        <i>{f.file.name}</i>{" "}
        <UploadStatusDescription
          file={f}
          statusMessages={uploadStatusMessages}
        />
      </span>
    </li>
  );
}

ListItem.propTypes = {
  file: PropTypes.object.isRequired,
  uploadStatusMessages: PropTypes.object,
  uploadIconSet: PropTypes.object,
};

ListItem.defaultProps = {
  uploadStatusMessages: undefined,
  uploadIconSet: undefined,
};

function UploadStatusIcon({ file, uploadIconSet }) {
  const fullCircleClass = "loader__full-circle--rebrand";
  const loaderClass = "loader__spinning-circle--rebrand";

  if (!file.complete) {
    return (
      <span className="document-uploader__item-status loader__container">
        <span
          className={`loader__circle loader__circle--document-uploader ${fullCircleClass}`}
        ></span>
        <span
          className={`loader__circle loader__circle--document-uploader ${loaderClass}`}
        ></span>
      </span>
    );
  }

  const errorIcon = "#icon__fail-upload";
  const successIcon = "#icon__success-upload";
  let iconRef = file.error ? errorIcon : successIcon;

  if (uploadIconSet && uploadIconSet?.SUCCESS && uploadIconSet?.FAIL) {
    iconRef = file.error ? uploadIconSet.FAIL : uploadIconSet?.SUCCESS;
  }

  const iconClassSet = iconRef.replace("#", "");
  const iconClass = file.error
    ? "document-uploader__item-status--fail"
    : "document-uploader__item-status--success";
  return (
    <svg
      className={`document-uploader__item-status ${iconClass} ${iconClassSet}`}
    >
      <use href={iconRef}></use>
    </svg>
  );
}

UploadStatusIcon.propTypes = {
  file: PropTypes.object.isRequired,
  uploadIconSet: PropTypes.object,
};

UploadStatusIcon.defaultProps = {
  uploadIconSet: undefined,
};

function UploadStatusDescription({
  file,
  statusMessages,
  statusMessagesRebrand,
}) {
  /**
   * @param {Object} statusMessages (optional) Object that returns the messages for the different status of uploading files {UPLOADING, FAIL, SUCCESS}
   **/

  statusMessages = statusMessagesRebrand;

  if (!file.complete) {
    return <b>{statusMessages.UPLOADING}</b>;
  }
  if (!file.complete) {
    return statusMessages.UPLOADING;
  }
  if (file.error) {
    return (
      <>
        {statusMessages.FAIL} {file.error}
      </>
    );
  }
  if (file.complete) {
    return (
      <span className="document-uploader__item--sucess">
        <b>{statusMessages.SUCCESS}</b>
      </span>
    );
  }
}

UploadStatusDescription.propTypes = {
  file: PropTypes.object.isRequired,
  statusMessages: PropTypes.object,
  statusMessagesRebrand: PropTypes.object,
};

UploadStatusDescription.defaultProps = {
  statusMessages: FILE_UPLOAD_STATUS_MESSAGE,
  statusMessagesRebrand: FILE_UPLOAD_STATUS_MESSAGE_REBRAND,
};

export default DocumentUploaderView;
