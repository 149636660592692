/* eslint-disable camelcase */

/**
 * Dispatch event to Amplitude
 *
 * @param {('CHANGE_FIELD'|'SELECT_BUTTON'|'SELECT_EVENT'|'SELECT_FIELD'|'SELECT_LINK'|'SELECT_SLIDER'|'SELECT_VIEW'|'TOGGLE_EVENT'|'VIEW_PAGE'
 * )} type - Amplitude event type
 * @param {string} selection - Amplitude 'selection' event property
 * @param {Object.<string,any>=} payload - Object with additional event properties
 */
export const handleAmplitudeEvent = (type, selection, payload = {}) => {
  const eventBus = window.empower?.eventBus;
  const amplitudeEvents = window.integratedSharedData?.AMPLITUDE_EVENTS || {};

  if (amplitudeEvents) {
    eventBus?.dispatchAmplitude({
      event_type: amplitudeEvents[type],
      event_properties: {
        selection,
        ...payload,
      },
    });
  }
};
