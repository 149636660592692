import { isEmpty } from "underscore";
import { isEqualTimeSlots } from "components/scheduling/utils";

const SLOT_INCREMENT = 30;
const START_HOUR = 8;
const END_HOUR = 17;

function getStartHour(day, availabilities) {
  const defaultDayStart = day.clone();
  defaultDayStart.hour(START_HOUR).startOf("hour");

  if (!isEmpty(availabilities)) {
    const earliestAvailability = availabilities.reduce(
      (earliest, availability) => {
        if (availability.startTime.isBefore(earliest)) {
          return availability.startTime;
        }
        return earliest;
      },
      defaultDayStart
    );
    return earliestAvailability.clone();
  }
  return defaultDayStart;
}

function getEndHour(day, availabilities) {
  const defaultDayEnd = day.clone();
  defaultDayEnd.hour(END_HOUR).startOf("hour");

  if (!isEmpty(availabilities)) {
    const latestAvailability = availabilities.reduce((latest, availability) => {
      if (availability.endTime.isAfter(latest)) {
        return availability.endTime;
      }
      return latest;
    }, defaultDayEnd);
    return latestAvailability.clone();
  }
  return defaultDayEnd;
}

function populateAvailability(slot, availabilities) {
  const availability = availabilities.find((a) => isEqualTimeSlots(a, slot));
  if (availability) {
    slot.isAvailable = true;
    slot.appointmentId = availability.appointmentId;
  }
}

/**
 * Generates an array of time slots based on the provided input.
 * The time slots are generated for the standard 8-5 work day.
 * This window will be expanded if the provided availabilities
 * fall outside of the standard time.
 *
 * @param {String} day the day to generate the time slots for
 * @param {Number} duration the duration of the time slots
 * @param {Array} availabilities the availabilities of the advisor. Must be in the same timezone as `day`
 * @returns {Array} the array of time slots
 */
export default function timeSlotsGenerator({ day, duration, availabilities }) {
  if (
    !isEmpty(availabilities) &&
    day.tz() !== availabilities[0].startTime.tz()
  ) {
    throw new Error("Availabilities must be in the same timezone as day.");
  }

  const dayStart = getStartHour(day, availabilities);

  const dayEnd = getEndHour(day, availabilities);

  const slots = [];
  let slotStartTime = dayStart;
  let slotEndTime = slotStartTime.clone().add(duration, "minutes");
  do {
    const slot = {
      startTime: slotStartTime,
      endTime: slotEndTime,
    };

    if (!isEmpty(availabilities)) {
      populateAvailability(slot, availabilities);
    }
    slots.push(slot);

    // next appointment
    slotStartTime = slotStartTime.clone().add(SLOT_INCREMENT, "minutes");
    slotEndTime = slotStartTime.clone().add(duration, "minutes");
  } while (slotEndTime.isSameOrBefore(dayEnd));

  return slots;
}
