export const US_TIMES = [
  { value: "America/New_York", label: "Eastern Time" },
  { value: "America/Chicago", label: "Central Time" },
  { value: "America/Denver", label: "Mountain Time" },
  { value: "America/Los_Angeles", label: "Pacific Time" },
  { value: "America/Anchorage", label: "Alaska Time" },
  { value: "America/Adak", label: "Hawaii–Aleutian Time - Adak" },
  { value: "Pacific/Honolulu", label: "Hawaii–Aleutian Time - Honolulu" },
];

export const DEFAULT_TIMEZONE = "America/Los_Angeles";
