import React from "react";
import AbstractForm from "components/common/form/AbstractForm";
import splunkLog from "libs/pcap/utils/splunkLog";
import Services from "services";
import parseResponseErrors from "libs/pcap/utils/response";
import { trackView, trackClick } from "components/common/ComponentAnalytics";

export const COMPONENT_NAME = "Appointment Confirmation Form";
export const FIELD_NAME = "importantTopics";
export const ERROR_NONE_CHECKED = "Please select an option";
export const OPTIONS = [
  "Preparing for retirement",
  "Managing the risk and expected return of my investments",
  "Financial decisions support (e.g. Insurance, Stock Options etc.)",
  "Managing my 401k",
  "Estate and legacy planning",
];

export default class AppointmentConfirmationForm extends AbstractForm {
  constructor() {
    super(...arguments);
    this.state = Object.assign(this.state, { error: null, done: false });
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.getAdvisorData();
    trackView(COMPONENT_NAME);
  }

  getAdvisorData() {
    Services.Advisor.get((err, response) => {
      const errors = parseResponseErrors(err, response);
      if (!errors && response && response.spData) {
        const advisorData = response.spData || {};
        if (advisorData.email) {
          this.advisorEmail = advisorData.email;
        }
      }
    });
  }

  logSubmittedFormData(data) {
    splunkLog({
      type: "appointment_confirmation_form",
      advisor: this.advisorEmail,
      email: window.username,
      data: data.join(" | "),
    });
    this.setState({ done: true, error: null });
  }

  onSubmit(ev) {
    ev.preventDefault();
    const answers = this.state.model[FIELD_NAME];
    if (!answers || !answers.length) {
      this.setState({ error: ERROR_NONE_CHECKED });
    } else {
      this.logSubmittedFormData(answers);
      trackClick(null, COMPONENT_NAME, "Submit Button");
    }
  }

  render() {
    const { error, done } = this.state;
    const value = this.state.model[FIELD_NAME] || [];

    if (done) {
      return <div className="u-text-center pc-u-mb">Thank you!</div>;
    }

    return (
      <form
        className="appt-confirmation-form u-text-left"
        onSubmit={this.onSubmit}
      >
        <label className="pc-label pc-u-mb-">
          Which of the following topics are most important to you so we can best
          prepare for the call? Don&apos;t worry, you can still change your mind
          after you submit.
        </label>
        {OPTIONS.map((answer, index) => (
          <label key={index} className="pc-label">
            <input
              type="checkbox"
              name={FIELD_NAME}
              ref={this.storeInputRef}
              value={answer}
              checked={value.includes(answer)}
              onChange={this.handleInputChange}
            />{" "}
            {answer}
          </label>
        ))}
        <div className="pc-u-mt- pc-u-mb- u-text-center">
          {Boolean(error) && (
            <div className="pc-help-block pc-help-block--small pc-help-block--error pc-u-mb-">
              {error}
            </div>
          )}
          <button
            className="pc-btn pc-btn--primary pc-btn--small"
            onClick={this.onSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}

AppointmentConfirmationForm.defaultProps = {
  schema: {},
};

AppointmentConfirmationForm.OPTIONS = OPTIONS;
