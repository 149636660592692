import $ from "jquery";
import analytics from "analytics";
var DEBUG = false;
if (DEBUG && window.mixpanel) {
  window.mixpanel.set_config({ debug: true });
}

var REGISTRATION_EVENT_NAME = "Registration";

var REGEX_TEST_USER = /.*test.*@personalcapital.com$/g;
var IS_PRODUCTION_ENVIRONMENT =
  window.location.host.toLowerCase() === "home.personalcapital.com";

var isNewlyRegisteredUser = false;
let registeredSuperProperties = {};

const IS_ADVISOR_APP = window.location.pathname.indexOf("/advisor/page") === 0;

// eslint-disable-next-line valid-jsdoc
/**
 * Given an email address, determine whether to create Mixpanel user profile.
 * We need to create them selectively, as each new one costs us money.
 *
 * The criteria is do not create user profiles for users whose email contains `test` before
 * @ and `personalcapital.com` after.
 *
 * @param {String} email  Current logged in user's email address
 * @return {Boolean}      Returns true or false
 */
function skipUserProfileCreation(email) {
  return REGEX_TEST_USER.test(email);
}

export default {
  ignoreNextEventName: null,

  /**
   * Prepare the data of a custom event to Mixpanel
   * API Doc: https://mixpanel.com/help/reference/tracking-an-event
   * @param {String}  eventName  Name of event
   * @param {Object}  data       Optional key/value pairs of data related to said event
   * @param {Object}  options    Optional key/value pairs of options data
   * @param {Boolean} options.force Force track in advisor app
   * @param {String}  options.ignoreNextEventName Name of subsequent event to ignore,
   *                             used to prevent default tab events when we visit index page
   */
  trackEvent: function (eventName, data, options = {}) {
    if (
      (IS_ADVISOR_APP && !options.trackAdvisorApp) ||
      IS_EMPOWER ||
      IS_ADVISOR
    ) {
      return; // Do not track Advisor App interactions or Empower app
    }

    if (eventName === REGISTRATION_EVENT_NAME) {
      isNewlyRegisteredUser = true;
    }

    if (
      this.ignoreNextEventName &&
      eventName.toLowerCase() === this.ignoreNextEventName.toLowerCase()
    ) {
      this.ignoreNextEventName = null;
      return;
    }

    if (options && options.ignoreNextEventName) {
      this.ignoreNextEventName = options.ignoreNextEventName;
    }

    if (!eventName) {
      throw new Error("`eventName` should not be empty");
    }
    this.executeMixpanelTracker(eventName, data, options, options.callback);
  },

  /**
   * Send a custom event to Mixpanel from TrackEvent()
   * API Doc: https://mixpanel.com/help/reference/tracking-an-event
   * @param {String}  eventName  Name of event
   * @param {Object}  data       Optional key/value pairs of data related to said event
   * @param {Object}  options    Optional key/value pairs of options data
   * @param {Function} callback  function to be executed if the event succeeds or fails.
   */
  executeMixpanelTracker: function (eventName, data, options, callback) {
    try {
      // get_distinct_id will only be defined if the mixpanel.js file is loaded
      if (window.mixpanel && window.mixpanel.get_distinct_id) {
        window.mixpanel.track(
          eventName,
          data || {},
          options || {},
          callback || null
        );
      } else {
        throw new Error(
          "Mixpanel failed to load prior to calling trackEvent()"
        );
      }
    } catch (error) {
      analytics.sendEngineeringEvent("Error", error.message);
      if (callback) {
        callback();
      }
      if (DEBUG) {
        console.log("MIXPANEL REQUEST (Mixpanel failed to load!):");
        console.log({ event: eventName, properties: data || {} });
      }
    }
  },

  /**
   * Creates a user profile on Mixpanel with data from getPerson API
   * API Doc: https://mixpanel.com/help/reference/creating-a-profile
   *
   * The following user data will be passed in if available:
   * - Email
   * - Registration Timestamp
   * - First Name
   */
  createProfile: function () {
    if (
      !IS_PRODUCTION_ENVIRONMENT &&
      skipUserProfileCreation(window.username)
    ) {
      return;
    }

    if (window.mixpanel) {
      var profileData = {
        $email: window.username,
        $created: new Date().toISOString(),
        user_guid: window.userGuid, // eslint-disable-line
        tier: "nq",
      };
      window.mixpanel.people?.set(profileData);
    } else {
      analytics.sendEngineeringEvent(
        "Error",
        "Mixpanel failed to load prior to calling `createProfile()`"
      );
    }
  },

  /**
   * Updates a user profile on Mixpanel with profileData hash object
   * @param {Object} profileData  Name/value pairs of profile data
   * @param {Object} options      Object containing options
   * @param {Boolean} appendData  set or append data
   */
  updateProfile: function (profileData, options, appendData = false) {
    if (options && options.newUsersOnly && !isNewlyRegisteredUser) {
      return;
    }
    if (window.mixpanel) {
      profileData = profileData || {};
      if (appendData) {
        window?.mixpanel?.people?.union(profileData);
      } else {
        window?.mixpanel?.people?.set(profileData);
      }
    } else {
      analytics.sendEngineeringEvent(
        "Error",
        "Mixpanel failed to load prior to calling `updateProfile()`"
      );
    }
  },

  // Register as super property so all subsequent track calls will include it
  registerSuperProperties: function (props = {}) {
    if (window.mixpanel) {
      window.mixpanel.register(props);
      Object.assign(registeredSuperProperties, props);
    }
  },

  // Unregister all super properties registered via Mixpanel.registerSuperProperties() in current page load session
  unregisterSuperProperties: function (keys = []) {
    const superPropertyNames = Object.keys(registeredSuperProperties).concat(
      keys
    );
    if (superPropertyNames.length > 0 && window.mixpanel) {
      superPropertyNames.forEach((prop) => window.mixpanel.unregister(prop));
    }
  },

  trackZendeskHelpButtonClicks: function () {
    var customHelpButton = $(".custom-zendesk-help-button");
    var onButtonClick = function () {
      this.trackEvent("Click on Zendesk Help Button", {
        component: "Zendesk Support Button",
      });
    }.bind(this);

    if (customHelpButton.length) {
      customHelpButton.on("click", onButtonClick);
    } else {
      var $window = $(window);
      var isHoveringOverButtonIframe = false;

      // iFrames do not support on-click events, so we do the following to get around that issue
      $("iframe.zEWidget-launcher")
        .on("mouseover", function () {
          isHoveringOverButtonIframe = true;
          $window.focus(); // Subsequent button clicks do not trigger the blur event on $window unless it is in focus
        })
        .on("mouseout", function () {
          isHoveringOverButtonIframe = false;
        });

      $window.on("blur.zendesk", function () {
        if (isHoveringOverButtonIframe) {
          onButtonClick();
        }
      });
    }
  },
};
