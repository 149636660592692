/* eslint-disable react/require-default-props */
/* cSpell:disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import AppointmentDetails from "components/scheduling/AppointmentDetails";
import AppointmentActions from "components/scheduling/AppointmentActions";
import Message from "components/common/Message";
import AppointmentConfirmationForm from "components/appointmentConfirmationForm/AppointmentConfirmationForm";
import { trackView, trackClick } from "components/common/ComponentAnalytics";
import getUrlParameterByName from "libs/pcap/utils/getUrlParameterByName";
import AppointmentGoogle from "svg-icons/AppointmentGoogle.svg";
import AppointmentIcal from "svg-icons/AppointmentIcal.svg";
import AppointmentOutlook from "svg-icons/AppointmentOutlook.svg";

class AppointmentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.passwordField = React.createRef();
    this.handleRequestAppointmentChange =
      this.handleRequestAppointmentChange.bind(this);
    this.handleAppointmentKeep = this.handleAppointmentKeep.bind(this);
  }

  componentDidMount() {
    const { eventTrackingSharedProperties } = this.props;
    trackView("Appointment Confirmation", eventTrackingSharedProperties);

    const twoMinutes = 2;
    const secondsPerMinute = 60;
    const millisecondsPerSecond = 1000;
    let timeInRescheduleFlow = window.sessionStorage.getItem(
      "timeInRescheduleFlow"
    );
    let twoMinutesSinceRescheduleFlow =
      Date.now() - timeInRescheduleFlow >
      twoMinutes * secondsPerMinute * millisecondsPerSecond;
    if (
      getUrlParameterByName("reschedule") === "true" &&
      twoMinutesSinceRescheduleFlow
    ) {
      this.props.onReschedule();

      window.sessionStorage.setItem("timeInRescheduleFlow", Date.now());
    }
  }

  handleRequestAppointmentChange(ev) {
    const { eventTrackingSharedProperties, componentName } = this.props;
    ev.preventDefault();
    this.setState({ appointmentChangeMode: true });
    trackClick(
      ev,
      componentName,
      "Cancel or Reschedule Appointment?",
      eventTrackingSharedProperties
    );
  }

  handleAppointmentKeep(e) {
    const { eventTrackingSharedProperties, componentName } = this.props;
    this.setState({ appointmentChangeMode: false });
    trackClick(
      e,
      componentName,
      "Keep Appointment Button",
      eventTrackingSharedProperties
    );
  }

  renderAppointmentChangeFooter() {
    const { appointmentChangeMode } = this.state;

    return appointmentChangeMode ? (
      <div className="appointment__footer">
        <AppointmentActions
          onReschedule={this.props.onReschedule}
          onKeep={this.handleAppointmentKeep}
          onCancel={this.props.onCancel}
        />
      </div>
    ) : (
      <div className="appointment__footer appointment__footer--link u-text-light">
        Need to cancel or reschedule your appointment?&nbsp;
        <button
          className="qa-appointment-request-change pc-btn pc-btn--link u-text-light"
          onClick={this.handleRequestAppointmentChange}
        >
          Click here
        </button>
      </div>
    );
  }

  renderPasswordField() {
    const { userEmail, userPhoneNumber, captchaResponse, person } = this.props;

    return (
      <form
        className="appointment__registration"
        id="apptconfform"
        action={window.registerUrl}
        method="post"
      >
        <input
          id="apptconfemail"
          type="hidden"
          name="username"
          value={userEmail}
        />
        <input
          id="apptconfphone"
          type="hidden"
          name="phoneNumber"
          value={userPhoneNumber}
        />
        <input type="hidden" name="termsVersion" value={1} />
        <input
          id="apptconfcapresp"
          type="hidden"
          name="g-recaptcha-response"
          value={captchaResponse}
        />
        <input
          id="apptconfperson"
          type="hidden"
          name="person"
          value={JSON.stringify(person)}
        />
        <input
          id="skipFirstUse"
          type="hidden"
          name="skipFirstUse"
          value="true"
        />
        <input type="hidden" name="pin" value="" />
        <input type="hidden" name="marketing_param" value="" />
        <h3 className="appointment__registration__header u-text-center">
          Create a password to save your profile and use our free tools.
        </h3>
        <div className="pc-layout pc-layout--center pc-form-group--small">
          <div className="pc-input-group pc-input-group--full pc-u-mb pc-layout__item pc-u-2/5">
            <label htmlFor="apptconfpass">Choose A Password</label>
            <input
              type="password"
              id="apptconfpass"
              className="input input--full pc-u-mt--"
              name="passwd"
              ref={this.passwordField}
              autoComplete="off"
              data-lpignore="true"
            />
          </div>
        </div>
        <div className="pc-layout pc-layout--center pc-u-mb">
          <div className="pc-layout__item pc-u-2/5 appointment__registration__save">
            <button
              type="submit"
              className="js-appointment__registration__save-btn qa-appointment__registration__save-btn pc-btn pc-btn--primary pc-btn--appointment-action u-text-center"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    );
  }

  render() {
    const {
      errors,
      appointmentCanceled,
      customContent,
      onStartOver,
      disableChanges,
      showPasswordField,
    } = this.props;
    const showAppointmentConfirmationForm =
      customContent && customContent.showAppointmentConfirmationForm;

    return (
      <section className="appointment qa-appointment appointment-confirmation qa-appointment-confirmation">
        <div className="appointment__header">
          <div className="appointment__sub-header">
            <h2 className="pc-delta pc-u-mb0 qa-appointment-confirmation__header--text">
              {this.props.userFirstName
                ? `${this.props.userFirstName}, your`
                : "Your"}{" "}
              appointment{" "}
              {appointmentCanceled ? "has been canceled" : "is ready!"}
            </h2>
          </div>
        </div>
        {!appointmentCanceled && (
          <>
            <div className="appointment__body appointment__body--padded">
              <Message severity="error" messages={errors} />
              <AppointmentDetails
                className="pc-u-mb+"
                advisorName={this.props.advisorName}
                advisorImgURL={this.props.advisorImgURL}
                startTime={this.props.appointmentStartTime}
                endTime={this.props.appointmentEndTime}
                timezone={this.props.timezone}
              />
              <div>
                <h3 className="u-text-center appointment__centered-header">
                  A confirmation email has been sent to
                </h3>
                <ul className="appointment__centered-copy pc-list-bare u-text-bold pc-u-mb">
                  <li>
                    {this.props.userEmail}
                    {this.props.guestEmail && ","}
                  </li>
                  {this.props.guestEmail && <li>{this.props.guestEmail}</li>}
                </ul>
              </div>
              {showAppointmentConfirmationForm && (
                <AppointmentConfirmationForm />
              )}
              <div>
                <h3 className="u-text-center appointment__centered-header">
                  Export it to your calendar
                </h3>
                <div className="appointment__centered-copy l-spaced">
                  <a
                    className="appointment-confirmation__calendar-link qa-appointment-confirmation-gcal-link"
                    href={this.props.gcalURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AppointmentGoogle className="appointment-confirmation__calendar-icon"></AppointmentGoogle>
                    <br />
                    Google
                  </a>
                  <a
                    className="appointment-confirmation__calendar-link qa-appointment-confirmation-ical-link"
                    href={this.props.icalURL}
                  >
                    <AppointmentIcal className="appointment-confirmation__calendar-icon"></AppointmentIcal>
                    <br />
                    Ical
                  </a>
                  <a
                    className="appointment-confirmation__calendar-link qa-appointment-confirmation-ocal-link"
                    href={this.props.outlookURL}
                  >
                    <AppointmentOutlook className="appointment-confirmation__calendar-icon"></AppointmentOutlook>
                    <br />
                    Outlook
                  </a>
                </div>
              </div>
            </div>
            {disableChanges ? null : this.renderAppointmentChangeFooter()}
          </>
        )}
        {showPasswordField ? this.renderPasswordField() : null}
        {appointmentCanceled && (
          <div className="appointment__footer">
            <button
              type="button"
              className="pc-btn pc-btn--appointment-action qa-appointment-action-new pc-u-mt"
              onClick={onStartOver}
            >
              Schedule a New Appointment
            </button>
          </div>
        )}
      </section>
    );
  }
}

AppointmentConfirmation.propTypes = {
  errors: PropTypes.array,
  onStartOver: PropTypes.func,
  onReschedule: PropTypes.func,
  onCancel: PropTypes.func,
  disableChanges: PropTypes.bool,
  advisorName: PropTypes.string,
  advisorImgURL: PropTypes.string,
  userFirstName: PropTypes.string,
  userEmail: PropTypes.string,
  guestEmail: PropTypes.string,
  timezone: PropTypes.string,
  appointmentStartTime: PropTypes.object,
  appointmentEndTime: PropTypes.object,
  gcalURL: PropTypes.string,
  icalURL: PropTypes.string,
  outlookURL: PropTypes.string,
  appointmentCanceled: PropTypes.bool,
  showPasswordField: PropTypes.bool,
  userPhoneNumber: PropTypes.string,
  eventTrackingSharedProperties: PropTypes.object,
  customContent: PropTypes.object,
  componentName: PropTypes.string,
  captchaResponse: PropTypes.string,
  person: PropTypes.object,
};

AppointmentConfirmation.defaultProps = {
  person: {},
};

export default AppointmentConfirmation;
