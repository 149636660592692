import React from "react";
import PropTypes from "prop-types";
import { getTimeRangeSegment } from "utils/appointmentUtils";

const FORMAT_TIME = "h:mm";

export default function TimeSelectorColumn({
  header,
  slots,
  tightSpacing,
  appointmentId,
  onSelect,
}) {
  const handleClick = function (ev) {
    onSelect(slots[parseInt(ev.currentTarget.dataset.index, 10)]);
  };

  return (
    <div className="time-selector__column qa-time-selector__column">
      <h2 className="time-selector__column-header">{header}</h2>
      <div className="time-selector__column-inner time-selector__column-inner--responsive">
        {slots.map((s, i) => {
          const isSelected =
            s.appointmentId != null &&
            getTimeRangeSegment(s.appointmentId) ===
              getTimeRangeSegment(appointmentId);
          return (
            <button
              key={String(s.startTime.valueOf()) + String(s.endTime.valueOf())}
              data-index={i}
              type="button"
              disabled={!s.isAvailable}
              onClick={handleClick}
              className={`pc-btn pc-btn--time-slot pc-btn--time-slot--responsive js-time-slot qa-time-slot ${
                tightSpacing ? "pc-btn--time-slot-tight" : ""
              }  ${isSelected ? "is-active" : ""}`}
            >
              {s.startTime.format(FORMAT_TIME)}{" "}
              <span className="pc-btn__time-slot-to">TO</span>{" "}
              {s.endTime.format(FORMAT_TIME)}
            </button>
          );
        })}
      </div>
    </div>
  );
}

TimeSelectorColumn.propTypes = {
  header: PropTypes.string,
  slots: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  appointmentId: PropTypes.string,
  tightSpacing: PropTypes.bool,
};
