/* eslint-disable no-invalid-this */
import { line as lineD3, transition } from "d3";

// TODO think how to reuse code in `shape/area` and `shape/line`

var DEFAULT_TRANSITION_DURATION_SERIES = 1000;
var CLASS_NAME = "chart__series--line";

/**
 * Draws a line shape.
 *
 * @param {Object}          options         Initialization options.
 * @param {Function|Number} options.x       If `x` is specified, sets the x accessor to the specified function or number.
 * @param {Function|Number} options.y       If `y` is specified, sets the y accessor to the specified function or number.
 * @param {Function}        options.xScale  The X scale.
 * @param {Function}        options.yScale  The Y scale.
 *
 * @returns {Function}                      A new line series.
 */
function line(options) {
  options = options || {};

  var xScaledAccessor = function (d) {
    return options.xScale(options.x(d));
  };
  var yScaledAccessor = function (d) {
    return options.yScale(options.y(d));
  };

  // All options that should be accessible to caller
  var shape = lineD3().x(xScaledAccessor).y(yScaledAccessor);

  if (options.curve) {
    shape.curve(options.curve);
  }

  function series(context, opts) {
    options = Object.assign(options, opts);
    var hasInheritedTransition = context instanceof transition;
    var selection = hasInheritedTransition ? context.selection() : context;

    var lines = selection.selectAll(".js-chart-series").data(function (d) {
      return d;
    });

    lines.exit().remove();

    lines
      .enter()
      .append("path")
      .attr("d", shape)
      .attr("class", function (datum, index) {
        return (
          "js-chart-series chart__series " +
          CLASS_NAME +
          " " +
          CLASS_NAME +
          "-" +
          ++index
        );
      });

    if (hasInheritedTransition) {
      lines = lines
        .transition(context)
        .duration(DEFAULT_TRANSITION_DURATION_SERIES);
    }

    lines.attr("d", shape);
  }

  //------------------------------------------------------------
  //  PUBLIC
  //------------------------------------------------------------

  /**
   * Set/get x value accessor.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {series|Function}      If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  series.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    return series;
  };

  /**
   * Set/get y value accessor.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {series|Function}      If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  series.y = function (accessor) {
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    return series;
  };

  /**
   * Set/get the scale of Y axis.
   *
   * @param  {Array} [scale=null]  the scale.
   * @return {series|Function}      If `scale` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `xScale` value.
   */
  series.xScale = function (scale) {
    if (!arguments.length) {
      return options.xScale;
    }

    options.xScale = scale;
    return series;
  };

  /**
   * Set/get the scale of Y axis.
   *
   * @param  {Array} [scale=null]   the Y scale
   * @return {series|Function}      If `scale` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `yScale` value.
   */
  series.yScale = function (scale) {
    if (!arguments.length) {
      return options.yScale;
    }

    options.yScale = scale;
    return series;
  };

  return series;
}

export default line;
