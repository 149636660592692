import PropTypes from "prop-types";
import React, { Component } from "react";
import TableColumnFilter from "components/common/TableColumnFilter/TableColumnFilter";
import { noop } from "underscore";

class HeaderColumn extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  getAriaSort(sortOrder) {
    let ariaSort;
    switch (sortOrder) {
      case "asc":
        ariaSort = "ascending";
        break;
      case "desc":
        ariaSort = "descending";
        break;
      default:
        ariaSort = "none";
    }
    return ariaSort;
  }

  getSortButtonLabel(sortOrder) {
    return `Sort by ${this.props.header} ${
      !sortOrder || sortOrder === "asc" ? "descending" : "ascending"
    }`;
  }

  handleClick(ev) {
    ev.preventDefault();

    const { onCellClick } = this.props;
    const sortOrder = this.toggleSortOrder();

    if (onCellClick) {
      onCellClick(this.props, sortOrder);
    }
  }

  toggleSortOrder() {
    let sortOrder;
    if (this.props.sortOrder === "asc") {
      sortOrder = "desc";
    } else if (this.props.sortOrder === "desc") {
      sortOrder = "asc";
    } else {
      sortOrder = "desc";
    }

    return sortOrder;
  }

  handleFilterChange(value) {
    this.props.onFilterChange(this.props.header, value);
  }

  handleKeyDown(event) {
    // eslint-disable-next-line no-magic-numbers
    if (event.key === "Enter" || event.keyCode === 13) {
      this.handleClick(event);
    }
  }

  render() {
    const {
      header,
      headerPluralityOverrides,
      className,
      isSortable,
      sortOrder,
      style,
      isFilterable,
      filterOptions,
      activeFilter,
      isFilterAlignedToTheRight,
      disableTransactionsFilter,
      onColumnFilterOpen,
      enableTabIndex,
      tabIndex,
      enableOnKeyDown,
    } = this.props;
    const tableColumnFilterComponent =
      isFilterable && filterOptions && filterOptions.length ? (
        <TableColumnFilter
          options={filterOptions}
          value={activeFilter}
          onExpand={onColumnFilterOpen}
          onChange={this.handleFilterChange}
          isFilterAlignedToTheRight={isFilterAlignedToTheRight}
          disableTransactionsFilter={disableTransactionsFilter}
          columnHeader={header}
          columnHeaderPluralityOverrides={headerPluralityOverrides}
        />
      ) : null;

    if (isSortable) {
      let orderClassName = "";
      if (sortOrder) {
        orderClassName =
          "table__sort-trigger--sorted table__sort-trigger--sorted-" +
          sortOrder;
      }
      return (
        <div
          role="columnheader"
          aria-sort={this.getAriaSort(sortOrder)}
          className={`${className} table__row--header table__column`}
          style={style}
        >
          <button
            className={`table__column table__sort-trigger  ${orderClassName} centi`}
            onClick={this.handleClick}
            tabIndex={enableTabIndex ? tabIndex : null}
            onKeyDown={enableOnKeyDown ? this.handleKeyDown : noop}
            aria-label={this.getSortButtonLabel(sortOrder)}
          >
            {header}
            <svg className="table__column-sort-icon" alt="" aria-hidden>
              <use xlinkHref="#pc-sorting-icon" />
            </svg>
          </button>
          {tableColumnFilterComponent}
        </div>
      );
    }

    return (
      <div
        role="columnheader"
        className={`table__column table__column--header ${className}`}
        style={style}
      >
        {header}
        {tableColumnFilterComponent}
      </div>
    );
  }
}

HeaderColumn.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  headerPluralityOverrides: PropTypes.object,
  className: PropTypes.string,
  onCellClick: PropTypes.func,
  onColumnFilterOpen: PropTypes.func,
  onFilterChange: PropTypes.func,
  isSortable: PropTypes.bool,
  sortOrder: PropTypes.oneOf(["asc", "desc"]),
  style: PropTypes.object,
  isFilterable: PropTypes.bool,
  isFilterAlignedToTheRight: PropTypes.bool,
  disableTransactionsFilter: PropTypes.bool,
  filterOptions: PropTypes.array,
  activeFilter: PropTypes.array,
  enableTabIndex: PropTypes.bool,
  tabIndex: PropTypes.number,
  enableOnKeyDown: PropTypes.bool,
};

HeaderColumn.defaultProps = {
  header: "",
  headerPluralityOverrides: undefined,
  className: "",
  onCellClick: noop,
  onColumnFilterOpen: undefined,
  onFilterChange: noop,
  isSortable: false,
  sortOrder: undefined,
  style: {},
  isFilterable: false,
  isFilterAlignedToTheRight: false,
  disableTransactionsFilter: false,
  filterOptions: undefined,
  activeFilter: undefined,
  enableTabIndex: false,
  tabIndex: 0,
  enableOnKeyDown: false,
};

export default HeaderColumn;
