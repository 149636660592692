import React from "react";
import PropTypes from "prop-types";
import DocumentUploaderView from "components/common/DocumentUploader/DocumentUploaderView";
import {
  MAX_FILE_SIZE,
  TYPE_VALIDATORS,
} from "components/common/DocumentUploader/utils/constants";
import { isEqual, first } from "underscore";
import parseResponseErrors, { ERROR_GENERIC } from "libs/pcap/utils/response";
const MEGA_BYTE = 1048576;

function getOnFileUploadError(f, maxFileSize, fileErrors, onFileUploadError) {
  if (f.size > maxFileSize) {
    let error = `${f.name} is too large to upload. Files must be less than ${
      maxFileSize ? maxFileSize / MEGA_BYTE : MAX_FILE_SIZE / MEGA_BYTE
    }MB.`;
    if (IS_EMPOWER) {
      error = `This file exceeds the maximum allowed file size.`;
    }
    fileErrors.push(error);
    if (onFileUploadError) {
      onFileUploadError(undefined, [f], error);
    }
  }
}
/**
 *
 *  Generic document uploader container and view. This component is used to upload documents for UI requiring drop zone or multiple file upload functionality
 *
 *  For default functionality,
 *  - must specify `requestParams` and `uploadAPIEndpoint` together
 *  - component will call API and upload on file select
 *
 *  For custom onFileSelect events and handling upload API call,
 *  - must specify `onFileSelect`
 *
 *  @param {String} uploadAPIEndpoint (required when using default file select) API endpoint string which expects a relative path
 *  @param {Object} requestParams (required when using default file select) Object which specifies API `request parameter` and `value` through the object key and value pair
 *  @param {Number} maxFileSize (optional) Number specifying the maximum upload file size in MB
 *  @param {Func} onFileSelect (optional) custom file select handler
 *  @param {Func} onFileUpload (optional) an optional callback executed when the file upload begins
 *  @param {Func} onFileUploaded (optional) an optional callback executed when the file upload completes
 *  @param {Func} onFileUploadError (optional) an optional callback executed when the file upload fails
 *  @param {Func} onDone (optional) Passed to view `onDone` prop, exclude if you wish to hide button
 *  @param {Func} fileTypeValidator (optional) Function that should return true or false given `filename`, by default fileTypeValidator will accept image or document files
 *  @param {Func} typeErrorMessage (optional) Function that formats error message string given `filename`, by default error message is formatted for default `fileTypeValidator` prop results
 *  @param {Func} fileNameValidator (optional) Function that should return true or false given `filename`, by default it will accept on Alpha-numerical chars, and () - _., and spaces if not the only space.
 *  @param {Func} fileNameErrorMessage (optional) Function that formats error message string given `filename`, by default error message is formatted for default `fileNameValidator` prop results
 *  @param {Object} uploadStatusMessages (optional) Object that returns the messages for the different status of uploading files {UPLOADING, FAIL, SUCCESS}
 *
 **/

export default class DocumentUploaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      files: [],
      errors: [],
    };

    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleFileUploaded = this.handleFileUploaded.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleFileError = this.handleFileError.bind(this);
    this.postFile = this.postFile.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.files, prevState.prevPropsFiles)) {
      if (nextProps.files === undefined) return null;
      return {
        files: nextProps.files,
        prevPropsFiles: nextProps.files,
      };
    }

    return null;
  }

  handleFileUploaded(file, res) {
    let files = this.state.files.slice();
    const fileIndex = files.findIndex((f) => f.file.name === file.name);
    const fileState = { file, complete: true };
    fileState.document = res.spData;
    files[fileIndex] = fileState;
    this.setState({
      files,
    });

    const { onFileUploaded } = this.props;
    if (onFileUploaded) {
      onFileUploaded(fileState, files);
    }
  }

  handleFileError(file, error) {
    let files = this.state.files.slice();
    const fileIndex = files.findIndex((f) => f.file.name === file.name);
    const fileState = { file, complete: true, failed: true, error };
    files[fileIndex] = fileState;
    this.setState({
      files,
    });

    const { onFileUploadError } = this.props;
    if (onFileUploadError) {
      onFileUploadError(fileState, files);
    }
  }

  handleDone() {
    if (this.props.onDone) {
      this.props.onDone(this.state.files);
    }
  }

  postFile(file) {
    const { uploadAPIEndpoint, requestParams, onFileUpload, binaryParamName } =
      this.props;
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    const files = this.state.files.slice();

    if (!file.type && file.name.indexOf(".xls") !== -1) {
      let newFile = new Blob([file], { type: "application/vnd.ms-excel" });
      newFile.name = file.name;
      newFile.lastModifiedDate = file.lastModifiedDate;
      file = newFile;
    }

    xhr.addEventListener("load", (event) => {
      const res =
        event.target && event.target.response
          ? JSON.parse(event.target.response)
          : {};

      const errors = parseResponseErrors(null, res);
      if (errors) {
        this.handleFileError(file, first(errors));
        return;
      }

      this.handleFileUploaded(file, res);
    });

    xhr.addEventListener("error", () => {
      this.handleFileError(file, ERROR_GENERIC);
    });

    formData.append(binaryParamName, file, file.name);
    formData.append("csrf", window.csrf);

    Object.entries(requestParams).forEach(([param, value]) => {
      formData.append(param, value);
    });

    xhr.open("POST", uploadAPIEndpoint, true);
    if (IS_EMPOWER) {
      xhr.withCredentials = true;
    }
    xhr.send(formData);

    this.setState({
      files,
    });

    if (onFileUpload) {
      onFileUpload({ file, complete: false }, files);
    }
  }

  handleFileSelect(selectedFiles) {
    const {
      fileTypeValidator,
      typeErrorMessage,
      fileNameValidator,
      fileNameErrorMessage,
      maxFileSize,
      onFileSelect,
      multiple,
      onFileUploadError,
    } = this.props;
    let errors = [];

    selectedFiles.forEach((f) => {
      const fileErrors = [];
      const fileTypeIsNotValid = !fileTypeValidator(f.name);
      if (fileTypeIsNotValid) {
        fileErrors.push(typeErrorMessage(f.name));
      }
      const fileNameIsNotValid = !fileNameValidator(f.name);
      if (fileNameIsNotValid) {
        fileErrors.push(fileNameErrorMessage(f.name));
      }
      getOnFileUploadError(f, maxFileSize, fileErrors, onFileUploadError);
      if (fileErrors.length === 0) {
        const { files } = this.state;
        // set `complete` to true if handling file upload in a parent component
        const file = { file: f, complete: Boolean(onFileSelect) };
        if (multiple) {
          files.push(file);
        } else {
          files[0] = file;
        }
        this.setState({ files });

        if (onFileSelect) {
          onFileSelect(f, this.postFile);
        } else {
          this.postFile(f);
        }
      } else {
        errors = errors.concat(fileErrors);
      }
    });

    this.setState({
      errors,
    });
  }

  render() {
    const { errors, loading, files } = this.state;
    const {
      onBack,
      onDone,
      multiple,
      acceptingFiles,
      uploadIconSet,
      uploadStatusMessages,
      footerUploadList,
      onSelectButtonClick,
      btnClassName,
    } = this.props;

    return (
      <DocumentUploaderView
        errors={errors}
        loading={loading}
        onBack={onBack}
        onDone={onDone ? this.handleDone : undefined}
        files={files}
        onFilesAdded={this.handleFileSelect}
        multiple={multiple}
        acceptingFiles={acceptingFiles}
        uploadIconSet={uploadIconSet}
        uploadStatusMessages={uploadStatusMessages}
        footerUploadList={footerUploadList}
        onInputClick={onSelectButtonClick}
        btnClassName={btnClassName}
      />
    );
  }
}

DocumentUploaderContainer.propTypes = {
  files: PropTypes.array,
  requestParams: PropTypes.object,
  maxFileSize: PropTypes.number,
  uploadAPIEndpoint: PropTypes.string,
  binaryParamName: PropTypes.string,
  fileTypeValidator: PropTypes.func,
  typeErrorMessage: PropTypes.func,
  fileNameValidator: PropTypes.func,
  fileNameErrorMessage: PropTypes.func,
  multiple: PropTypes.bool,
  onBack: PropTypes.func,
  onDone: PropTypes.func,
  onFileSelect: PropTypes.func,
  onFileUpload: PropTypes.func,
  onFileUploaded: PropTypes.func,
  onFileUploadError: PropTypes.func,
  acceptingFiles: PropTypes.string,
  uploadIconSet: PropTypes.object,
  uploadStatusMessages: PropTypes.object,
  footerUploadList: PropTypes.bool,
  onSelectButtonClick: PropTypes.func,
  btnClassName: PropTypes.string,
};

DocumentUploaderContainer.defaultProps = {
  files: undefined,
  binaryParamName: "uploadDocument",
  acceptingFiles: undefined,
  requestParams: {},
  maxFileSize: MAX_FILE_SIZE,
  uploadAPIEndpoint: "",
  fileTypeValidator: TYPE_VALIDATORS.IMAGE_DOCUMENTS.validate,
  typeErrorMessage: TYPE_VALIDATORS.IMAGE_DOCUMENTS.errorFormatter,
  fileNameValidator: TYPE_VALIDATORS.DOCUMENT_NAME.validate,
  fileNameErrorMessage: TYPE_VALIDATORS.DOCUMENT_NAME.errorFormatter,
  multiple: true,
  onBack: undefined,
  onDone: undefined,
  onFileSelect: undefined,
  onFileUpload: undefined,
  onFileUploaded: undefined,
  onFileUploadError: undefined,
  uploadIconSet: undefined,
  uploadStatusMessages: undefined,
  footerUploadList: undefined,
  onSelectButtonClick: undefined,
  btnClassName: undefined,
};
