import React from "react";
import PropTypes from "prop-types";
const LimitedAppHeader = ({ cmsUrl, baseUrl, noMargin, loginUrl }) => {
  return (
    <div className={noMargin ? "" : "pc-u-mb+"}>
      <div className="limited-app-header">
        <div className="col-sm-6">
          {
            <a className="limited-app-header__logo-rebrand" href={cmsUrl}>
              <svg
                width="188"
                height="20"
                viewBox="0 0 188 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_105_42)">
                  <path
                    d="M68.5867 2.73413H80.4134V5.86263H72.0137V9.10558H79.4055V12.2343H72.0137V15.5911H80.5254V18.7198H68.5867V2.73413Z"
                    fill="white"
                  />
                  <path
                    d="M83.0648 2.73413H86.7833L90.9051 9.49382L95.0269 2.73413H98.7454V18.7199H95.3181V8.28333L90.9051 15.1117H90.8154L86.4472 8.35201V18.7199H83.0648V2.73413Z"
                    fill="white"
                  />
                  <path
                    d="M101.886 2.73413H108.292C112.033 2.73413 114.295 4.99493 114.295 8.26058V8.3063C114.295 12.0059 111.473 13.9242 107.956 13.9242H105.335V18.7199H101.886L101.886 2.73413ZM108.068 10.7955C108.407 10.8392 108.752 10.8096 109.079 10.7084C109.405 10.6072 109.708 10.4368 109.966 10.2083C110.224 9.97982 110.431 9.6984 110.575 9.38244C110.719 9.06649 110.796 8.72311 110.801 8.37477V8.32904C110.801 6.75331 109.726 5.90835 108.001 5.90835H105.335V10.7955H108.068Z"
                    fill="white"
                  />
                  <path
                    d="M115.468 10.7722V10.7265C115.527 8.51736 116.428 6.4186 117.98 4.87528C119.532 3.33196 121.612 2.46545 123.78 2.4595C125.947 2.45356 128.032 3.30864 129.592 4.84342C131.152 6.37821 132.064 8.47199 132.135 10.6808V10.7265C132.075 12.9356 131.174 15.0344 129.623 16.5777C128.071 18.121 125.991 18.9875 123.823 18.9935C121.655 18.9994 119.571 18.1443 118.011 16.6096C116.451 15.0748 115.539 12.981 115.468 10.7722V10.7722ZM128.528 10.7722V10.7265C128.55 10.076 128.444 9.42768 128.215 8.81998C127.986 8.21228 127.64 7.65762 127.197 7.18895C126.754 6.72029 126.223 6.34718 125.636 6.09179C125.049 5.83639 124.417 5.70394 123.779 5.70228C123.145 5.69972 122.517 5.82814 121.934 6.07978C121.35 6.33141 120.822 6.70104 120.383 7.16635C119.943 7.63165 119.601 8.18297 119.376 8.78701C119.151 9.39106 119.049 10.0353 119.075 10.6808V10.7265C119.053 11.377 119.159 12.0253 119.388 12.633C119.616 13.2407 119.962 13.7953 120.405 14.2639C120.848 14.7326 121.379 15.1057 121.967 15.3611C122.554 15.6164 123.185 15.7489 123.824 15.7506C124.457 15.7531 125.085 15.6247 125.669 15.3731C126.253 15.1215 126.78 14.7519 127.22 14.2866C127.659 13.8213 128.002 13.27 128.227 12.666C128.452 12.062 128.554 11.4177 128.528 10.7722V10.7722Z"
                    fill="white"
                  />
                  <path
                    d="M132.345 2.73417H136.041L139.289 13.4446L142.806 2.73413H145.763L149.28 13.4446L152.528 2.73417H156.134L150.781 18.7199H147.779L144.24 8.42047L140.7 18.7199H137.698L132.345 2.73417Z"
                    fill="white"
                  />
                  <path
                    d="M157.717 2.73413H169.544V5.86263H161.144V9.10558H168.536V12.2343H161.144V15.5911H169.656V18.7198H157.717V2.73413Z"
                    fill="white"
                  />
                  <path
                    d="M172.191 2.73419H179.359C180.188 2.68114 181.019 2.7972 181.804 3.07557C182.588 3.35395 183.311 3.78904 183.928 4.35541C184.38 4.84208 184.732 5.41614 184.963 6.0437C185.194 6.67127 185.299 7.33962 185.273 8.00932V8.05503C185.323 9.14235 185.021 10.2164 184.414 11.1118C183.807 12.0072 182.928 12.6744 181.913 13.0106L185.743 18.7199H181.711L178.351 13.6045H175.641V18.7199H172.191L172.191 2.73419ZM179.135 10.4987C180.815 10.4987 181.778 9.58506 181.778 8.23789V8.19218C181.778 6.6849 180.748 5.9084 179.068 5.9084H175.641V10.4987H179.135Z"
                    fill="white"
                  />
                  <path
                    d="M63.4646 2.77531C47.5352 6.60375 47.6622 -1.44911 34.1658 0.246227L27.2543 3.51916C41.4599 0.947626 41.76 10.7978 60.9567 5.2584C61.8312 5.00606 62.6607 4.76301 63.4646 4.5542L63.4646 2.77531Z"
                    fill="white"
                  />
                  <path
                    d="M63.4645 6.58458C60.6381 6.94267 57.843 7.5221 55.1039 8.31771C34.897 14.1071 35.4408 4.52624 20.5213 6.72728L13.621 10.0002C28.2217 7.9892 28.8217 17.3007 48.6639 11.7394C55.1327 9.92642 59.7154 9.42321 63.4645 9.6549V6.58458Z"
                    fill="white"
                  />
                  <path
                    d="M63.4641 13.7027C58.5329 12.571 52.8176 11.899 42.4619 14.7953C21.9585 20.5296 22.2848 11.1411 6.89905 13.2049L0 16.4778C14.9082 14.5271 15.6582 23.7233 35.8828 18.217C50.8817 14.1335 56.0571 17.1063 63.4642 18.6761L63.4641 13.7027Z"
                    fill="white"
                  />
                  <path
                    d="M186.651 5.48588C186.384 5.4858 186.123 5.40505 185.902 5.25383C185.68 5.10262 185.507 4.88773 185.405 4.63634C185.303 4.38495 185.276 4.10834 185.328 3.84149C185.38 3.57463 185.509 3.32951 185.697 3.13712C185.886 2.94472 186.126 2.81369 186.388 2.76059C186.65 2.70749 186.921 2.7347 187.168 2.83878C187.414 2.94287 187.625 3.11915 187.773 3.34535C187.922 3.57155 188.001 3.8375 188.001 4.10959C188.001 4.47447 187.858 4.82426 187.605 5.08227C187.352 5.34027 187.009 5.48542 186.651 5.48588ZM186.651 2.94678C186.425 2.94686 186.205 3.01516 186.017 3.14305C185.83 3.27093 185.684 3.45266 185.597 3.66524C185.511 3.87783 185.488 4.11172 185.533 4.33735C185.577 4.56298 185.685 4.77021 185.845 4.93283C186.005 5.09545 186.208 5.20617 186.429 5.25097C186.651 5.29577 186.88 5.27265 187.089 5.18453C187.297 5.09642 187.475 4.94726 187.6 4.75592C187.726 4.56458 187.793 4.33966 187.792 4.10959C187.792 3.80116 187.672 3.50546 187.458 3.28744C187.244 3.06942 186.954 2.9469 186.651 2.94678V2.94678Z"
                    fill="white"
                  />
                  <path
                    d="M186.167 3.40917H186.776C186.92 3.40108 187.062 3.45131 187.17 3.54898C187.207 3.58887 187.236 3.63588 187.256 3.68731C187.275 3.73874 187.285 3.79358 187.283 3.84868V3.85285C187.286 3.95068 187.255 4.04644 187.196 4.12351C187.137 4.20057 187.053 4.25408 186.959 4.27473L187.328 4.8013H187.043L186.708 4.31657H186.408V4.8013H186.167L186.167 3.40917ZM186.759 4.09983C186.93 4.09983 187.039 4.00774 187.039 3.8671V3.86292C187.039 3.7139 186.934 3.63186 186.757 3.63186H186.407V4.09983H186.759Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_105_42">
                    <rect width="188" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          }
        </div>
        {
          <div className="col-sm-6 limited-app-header__btn-col u-text-center">
            <a
              className="qa-limited-app-header-btn pc-btn pc-btn--small limited-app-header__btn-rebrand"
              href={loginUrl || baseUrl}
            >
              Sign in
            </a>
          </div>
        }
      </div>
    </div>
  );
};

LimitedAppHeader.propTypes = {
  cmsUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  noMargin: PropTypes.bool,
  loginUrl: PropTypes.string,
};

LimitedAppHeader.defaultProps = {
  cmsUrl: "",
  baseUrl: "",
  noMargin: false,
  loginUrl: undefined,
};

export default LimitedAppHeader;
