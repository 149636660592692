import accounting from "accounting";
import { floorDecimal } from "libs/pcap/utils/decimalAdjust";

const REGEX_ALL_COMMAS = /,/g;

const PositiveCurrencyFormatter = {
  // Format `1000` to `1,000`
  format(value, precision) {
    // `accounting.formatNumber` formats an empty string `` to `0`.
    // That makes it impossible to clear out the field.
    if (value === "") {
      return value;
    }
    return accounting.formatNumber(Math.abs(value), precision);
  },
  unformat(value, precision) {
    value = parseFloat(String(value).replace(REGEX_ALL_COMMAS, "")); // Removes comma
    if (isNaN(value)) {
      value = "";
    }
    if (precision) {
      value = floorDecimal(value, precision);
    }
    return value;
  },
};

export default PositiveCurrencyFormatter;
