const KEY_ATTRIBUTION_SOURCE = "attributionSource";

export function setSource(attributionSource) {
  sessionStorage.setItem(KEY_ATTRIBUTION_SOURCE, attributionSource);
}

export function getSource() {
  return sessionStorage.getItem(KEY_ATTRIBUTION_SOURCE);
}

export function clear() {
  sessionStorage.removeItem(KEY_ATTRIBUTION_SOURCE);
}
