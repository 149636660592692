import _ from "underscore";
import Analytics from "analytics";
import Mixpanel from "mixpanel";
import { getSource } from "components/common/attributionStore";

const tagToClickTargetTypeMap = { a: "Link", button: "Button" };

const getClickTargetElementInfo = (event) => {
  if (!event) {
    return {};
  }
  const clickTarget = (event && event.currentTarget) || {};
  const targetLabel = clickTarget.innerText; // E.g. Schedule a Call

  const targetClassList = _.isEmpty(clickTarget)
    ? []
    : (
        (clickTarget.getAttribute && clickTarget.getAttribute("class")) ||
        ""
      ).split(" "); // E.g. "pc-btn pc-btn--tiny"
  const targetTagName = (clickTarget.tagName || "").toLowerCase();
  const isInputButton =
    targetTagName === "input" &&
    ["button", "submit"].includes(clickTarget.type);
  let targetType = tagToClickTargetTypeMap[targetTagName] || targetTagName;
  if (targetClassList.includes("pc-btn") || isInputButton) {
    targetType = "Button";
  }
  return { targetLabel, targetType };
};

/**
 * Track a component view event
 * @param {String} componentName      Name of the component being tracked
 * @param {Object} additionalData     Name value pairs of extra event data to send
 * @param {Object} [options]          Options for `mixpanel` utility.
 */
export function trackView(componentName, additionalData = {}, options) {
  Mixpanel.trackEvent(
    `View ${componentName}`,
    _.extend(
      {
        component: componentName,
        source: getSource(),
      },
      additionalData
    ),
    options
  );
}

/**
 * Track a custom event
 * @param {String} componentName      Name of the component being tracked
 * @param {String} eventDescription   A short event description
 * @param {Object} additionalData     Name value pairs of extra event data to send
 * @param {Object} [options]          Options for `mixpanel` utility
 */
export function trackEvent(
  componentName,
  eventDescription,
  additionalData = {},
  options
) {
  Mixpanel.trackEvent(
    eventDescription,
    _.extend(
      {
        component: componentName,
        source: getSource(),
      },
      additionalData
    ),
    options
  );
}

/**
 * Track an engineering event
 * @param {String} componentName      Name of the component being tracked
 * @param {String} eventType          The engineering event type (e.g. Error)
 * @param {String} eventDescription   A short event description
 */
export function trackEngineeringEvent(
  componentName,
  eventType,
  eventDescription
) {
  Analytics.sendEngineeringEvent(
    eventType,
    `${componentName}: ${eventDescription}`
  );
}

/**
 * Track a component click event
 * @param {SyntheticEvent} event          Event passed to a component's event handler
 * @param {String} componentName      Name of the component being tracked
 * @param {String} label              Optional button or link label
 * @param {Object} additionalData     Name value pairs of extra event data to send
 */
export function trackClick(event, componentName, label, additionalData = {}) {
  const { targetLabel, targetType } = getClickTargetElementInfo(event);
  label = label || targetLabel;
  Analytics.sendEngagementEvent(`${componentName} Action Click`, label);
  Mixpanel.trackEvent(
    `Click on ${label}`,
    _.extend(
      {
        // Click on Schedule a Call Button
        component: componentName,
        type: targetType,
        label: label,
        source: getSource(),
      },
      additionalData
    )
  );
}

/**
 * Send an event to Google Tag Manager
 * @param {String} eventName  Name of the event being tracked
 */
export function sendEventToGTM(eventName) {
  Analytics.sendGoogleTagManagerEvent(eventName);
}

/**
 * @Deprecated Since September 2017, please use trackClick()
 *
 * Track a component button click event
 * @param {SyntheticEvent} event          Event passed to a component's event handler
 * @param {String} componentName      Name of the component being tracked
 * @param {String} buttonLabel        Button label
 * @param {Object} additionalData     Name value pairs of extra event data to send
 */
export function trackButtonClick(
  event,
  componentName,
  buttonLabel,
  additionalData = {}
) {
  buttonLabel =
    buttonLabel ||
    (event && event.currentTarget && event.currentTarget.innerText);

  Analytics.sendEngagementEvent(`${componentName} Action Click`, buttonLabel);
  Mixpanel.trackEvent(
    `Click on ${componentName} Action Button`,
    _.extend(
      {
        component: componentName,
        button_label: buttonLabel, // eslint-disable-line camelcase
        source: getSource(),
      },
      additionalData
    )
  );
}

/**
 * @Deprecated Since September 2017, please use trackClick()
 *
 * Track a component link click event
 * @param {SyntheticEvent} event          Event passed to a component's event handler
 * @param {String} componentName      Name of the component being tracked
 * @param {String} linkLabel          Link label
 * @param {Object} additionalData     Name value pairs of extra event data to send
 */
export function trackLinkClick(
  event,
  componentName,
  linkLabel,
  additionalData = {}
) {
  linkLabel =
    linkLabel ||
    (event && event.currentTarget && event.currentTarget.innerText);

  Analytics.sendEngagementEvent(`${componentName} Link Click`, linkLabel);
  Mixpanel.trackEvent(
    `Click on ${componentName} Link`,
    _.extend(
      {
        component: componentName,
        link_label: linkLabel, // eslint-disable-line camelcase
        source: getSource(),
      },
      additionalData
    )
  );
}

export function prepareDataForTracking(data) {
  const result = {};
  Object.keys(data).forEach((key) => {
    result[key] =
      typeof data[key] === "string" ? data[key] : JSON.stringify(data[key]);
  });
  return result;
}

export default {
  trackView,
  trackEvent,
  trackEngineeringEvent,
  trackClick,
  sendEventToGTM,
  trackButtonClick,
  trackLinkClick,
  prepareDataForTracking,
};
