import PropTypes from "prop-types";
import React, { Component } from "react";
import Column from "libs/pcap/table/Column";
import { noop } from "underscore";

class Row extends Component {
  constructor(props) {
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
    this.keyHandler = this.keyHandler.bind(this);
  }

  clickHandler(ev) {
    const { data, index, onClick } = this.props;
    // Dispatch event bus
    if (onClick) {
      onClick(ev, data, index);
    }
  }

  keyHandler(ev) {
    const { data, index, onClick } = this.props;
    // Dispatch event bus
    if (["Enter", " "].includes(ev.key) && onClick) {
      ev.preventDefault();
      onClick(ev, data, index);
    }
  }

  render() {
    const {
      columns,
      data,
      className,
      dataAttributes,
      index,
      title,
      storeTooltipRef,
      onClick,
    } = this.props;
    const transactionClassName = data.userTransactionId
      ? ` qa-transaction-${data.userTransactionId}${
          data.isEditable
            ? " qa-datagrid-row--editable"
            : " qa-datagrid-row--read-only"
        }`
      : "";
    const rowClassName = `table__row qa-dategrid-row-${index} ${
      className || ""
    }${transactionClassName}`;
    let tooltipParams = {};

    if (title && storeTooltipRef) {
      tooltipParams = {
        ref: (el) => {
          storeTooltipRef(
            el,
            Object.assign({ html: false, container: "body", placement: "auto" })
          );
        },
        "data-toggle": "tooltip",
        title,
      };
    }

    return (
      <div
        role="row"
        {...(onClick !== noop && {
          tabIndex: "0",
          onClick: this.clickHandler,
          onKeyDown: this.keyHandler,
        })}
        className={rowClassName}
        {...dataAttributes}
        data-user-transaction-id={data.userTransactionId}
        {...tooltipParams}
      >
        {columns.map((col, i) => {
          var uniqueKey = `row_${index}_col_${i}`;
          return (
            <Column key={`row_${uniqueKey}_col_${i}`} data={data} {...col} />
          );
        })}
      </div>
    );
  }
}

Row.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  dataAttributes: PropTypes.object,
  title: PropTypes.string,
  storeTooltipRef: PropTypes.func,
};

Row.defaultProps = {
  className: "",
  onClick: noop,
  dataAttributes: undefined,
  title: undefined,
  storeTooltipRef: noop,
};

export default Row;
