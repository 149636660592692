import PropTypes from "prop-types";
import React, { Component } from "react";
import HeaderColumn from "libs/pcap/table/HeaderColumn";
import { noop } from "underscore";

// The default width of the container, this isn't content width (930px) because the 12px margin is already applied
const MAIN_CONTAINER_WIDTH = 954;
class HeaderRow extends Component {
  constructor(props) {
    super(props);
    this.handleCellClick = this.handleCellClick.bind(this);
  }

  handleCellClick(column, sortOrder) {
    const { columns } = this.props;

    // apply new sort state
    const clickedColumn = columns.find((c) => c.header === column.header);
    clickedColumn.sortOrder = sortOrder;

    // clear previous sort state
    columns
      .filter((c) => c !== clickedColumn)
      .forEach((c) => {
        if (c.sortOrder) {
          c.sortOrder = null;
        }
      });

    const { onCellClick } = this.props;
    if (onCellClick) {
      onCellClick.apply(this.props, arguments);
    }
  }
  render() {
    const {
      offset,
      width,
      className,
      onColumnFilterOpen,
      onColumnFilterChange,
      isSticky,
      columns,
    } = this.props;
    return (
      <div role="rowgroup">
        <div
          role="row"
          className={`table__row table__row--header ${
            isSticky ? "is-stuck" : ""
          } ${className}`}
          style={isSticky ? { top: offset, width } : {}}
        >
          {columns.map((col, i) => {
            return (
              <HeaderColumn
                key={`hcol_${i}`}
                onCellClick={this.handleCellClick}
                onColumnFilterOpen={onColumnFilterOpen}
                onFilterChange={onColumnFilterChange}
                {...col}
                className={col.headerClassName || col.className || ""}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

HeaderRow.propTypes = {
  isSticky: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  onCellClick: PropTypes.func,
  onColumnFilterOpen: PropTypes.func,
  onColumnFilterChange: PropTypes.func,
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

HeaderRow.defaultProps = {
  isSticky: false,
  onColumnFilterOpen: undefined,
  onColumnFilterChange: noop,
  onCellClick: noop,
  offset: undefined,
  width: MAIN_CONTAINER_WIDTH,
  className: "",
};

export default HeaderRow;
