import accounting from "accounting";
import { floorDecimal } from "libs/pcap/utils/decimalAdjust";

const REGEX_ALL_CHARS_EXCEPT_FULL_STOP_AND_NUMBERS = /[^\d.]/g;
const REGEX_ALL_FULL_STOPS = /\./g;
const REGEX_FULL_STOP = /\./;
const DECIMAL_CHAR = ".";
const LAST_INDEX = -1;

const CurrencyWithCents = (precision) => {
  return {
    format(value) {
      value = value.toString();

      // If the value is empty return empty
      // so that user is able to clear the value in the input.
      if (value === "") {
        return value;
      }

      // If the input contains a decimal
      // and precision is passed
      if (precision && value.includes(DECIMAL_CHAR)) {
        const numberOfDecimalNumbers = value.split(DECIMAL_CHAR)[1].length;

        // When input is `12345.` return `12,345.`
        if (value.endsWith(DECIMAL_CHAR)) {
          return `${accounting.formatNumber(
            Math.abs(value.slice(0, LAST_INDEX))
          )}.`;
        }

        // When input is `12345.67` return `12,345.67`
        return accounting.formatNumber(
          Math.abs(value),
          numberOfDecimalNumbers < precision
            ? numberOfDecimalNumbers
            : precision
        );
      }

      // If there is no precision and no decimal in the input
      // return the formatted number.
      return accounting.formatNumber(Math.abs(value), 0);
    },

    unformat(value) {
      // Removes All characters except numbers and decimal from the value.
      value = value
        .toString()
        .replace(REGEX_ALL_CHARS_EXCEPT_FULL_STOP_AND_NUMBERS, "");

      // If the value is empty return empty
      // so that user is able to clear the value in the input.
      if (value === "") {
        return value;
      }

      // If the input contains a decimal
      // and precision is passed
      if (precision && value.includes(DECIMAL_CHAR)) {
        // If the input has more than one decimal (Full Stop)
        // Replace the first full stop with empty string
        // This avoids user to enter multiple full stops
        if (value.match(REGEX_ALL_FULL_STOPS).length > 1) {
          value = value.replace(REGEX_FULL_STOP, "");
        }

        const decimalValue = value.split(DECIMAL_CHAR)[1];
        const intValue = value.split(DECIMAL_CHAR)[0];

        // Based on number of decimals and precision rounds the value
        // ex: for precision 2
        // `12,345.` is converted to 12345.
        // `12,345.6` is converted to 12345.6
        // `12,345.67` is converted to 12345.67
        // `12,345.678` is converted to 12345.67
        // Avoiding user to enter more decimals than the precision.
        const adjustedIntValue = floorDecimal(intValue, 0);
        const adjustedDecimalValue = decimalValue.slice(0, precision);
        return `${adjustedIntValue}.${adjustedDecimalValue}`;
      }

      // If there is no precision and no decimal in the input
      // return the number.
      return floorDecimal(value, 0);
    },
  };
};

export default CurrencyWithCents;
