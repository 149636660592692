/**
 * Sometimes, the display string is derived from the URL path or some random key
 * @param {String} s The string to break apart.
 * @param {String} delimiter The character to split by.
 * @returns {String} a transformed string
 */
export function normalizeForDisplay(s, delimiter = "-") {
  if (s == null) {
    return "";
  }

  return s
    .toLowerCase()
    .split(delimiter)
    .map((val) => {
      if (val.toLowerCase() === "us") {
        return val.toUpperCase();
      }
      return val.charAt(0).toUpperCase() + val.slice(1);
    })
    .join(" ");
}
