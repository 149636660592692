import React from "react";
import PropTypes from "prop-types";
import Error from "svg-icons/Error.svg";
import Warning from "svg-icons/Warning.svg";
import InfoHelpCircle from "svg-icons/InfoHelpCircle.svg";

const ICON_DETAILS = {
  ERROR: {
    IconComponent: Error,
    iconWidth: "45",
    iconHeight: "38",
    iconViewBox: "0 0 45 38",
    iconImageLabel: "Error",
  },
  WARNING: {
    IconComponent: Warning,
    iconWidth: "45",
    iconHeight: "38",
    iconViewBox: "0 0 45 38",
    iconImageLabel: "Warning",
  },
  DANGER: {
    IconComponent: Warning,
    iconWidth: "45",
    iconHeight: "38",
    iconViewBox: "0 0 45 38",
    iconImageLabel: "Danger",
  },
  INFO: {
    IconComponent: InfoHelpCircle,
    iconWidth: "44",
    iconHeight: "44",
    iconViewBox: "0 0 44 44",
    iconImageLabel: "Information",
  },
  SUCCESS: {
    IconComponent: InfoHelpCircle,
    iconWidth: "44",
    iconHeight: "44",
    iconViewBox: "0 0 44 44",
    iconImageLabel: "Success",
  },
};

const WarningMessage = ({
  containerClassName,
  iconClassName,
  messageClassName,
  message,
  icon,
  iconSize,
}) => {
  if (!icon) {
    return (
      <div className={`${containerClassName}`} role={"alert"}>
        <span
          dangerouslySetInnerHTML={{ __html: message }}
          className={messageClassName}
        ></span>
      </div>
    );
  }

  const { IconComponent, iconWidth, iconHeight, iconViewBox, iconImageLabel } =
    ICON_DETAILS[icon];

  return (
    <div
      className={`warning-notification-message__container ${containerClassName}`}
      role={"alert"}
    >
      <span>
        <IconComponent
          aria-label={`${iconImageLabel} icon`}
          width={iconSize ? iconSize.width : iconWidth}
          height={iconSize ? iconSize.height : iconHeight}
          className={iconClassName}
          viewBox={
            iconSize ? `0 0 ${iconSize.width} ${iconSize.height}` : iconViewBox
          }
        />
      </span>
      <span
        dangerouslySetInnerHTML={{ __html: message }}
        className={`pc-u-pl- ${messageClassName}`}
      ></span>
    </div>
  );
};

WarningMessage.propTypes = {
  containerClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  messageClassName: PropTypes.string,
  iconSize: PropTypes.object,
  message: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(["ERROR", "WARNING", "INFO", "", "DANGER", "SUCCESS"]),
};

WarningMessage.defaultProps = {
  containerClassName: "",
  iconClassName: "",
  messageClassName: "",
  icon: "WARNING",
  iconSize: undefined,
};

export default WarningMessage;
