import { US_TIMES, DEFAULT_TIMEZONE } from "enums/usTimes";
import moment from "moment-timezone";

const NOON_HOUR = 12;

export const FORMAT_DAY = "dddd, MMMM Do";
export const FORMAT_TIME = "h:mm a";
export const FORMAT_TIME_TZ = FORMAT_TIME + " z";

export function filterMorningTimeSlots(slots) {
  return slots.filter((s) => {
    return (
      s.endTime.hour() < NOON_HOUR ||
      (s.endTime.hour() === NOON_HOUR && s.endTime.minute() === 0)
    );
  });
}

export function filterAfternoonTimeSlots(slots) {
  return slots.filter((s) => s.startTime.hour() >= NOON_HOUR);
}

export function convertAppointmentsToTimezone(appointments, timezone) {
  return appointments.map((a) => {
    return {
      startTime: a.startTime.clone().tz(timezone),
      endTime: a.endTime.clone().tz(timezone),
      appointmentId: a.appointmentId,
    };
  });
}

export function isEqualTimeSlots(a, b) {
  if (!a || !b) {
    return false;
  }

  if (a === b) {
    return true;
  }

  return a.startTime.isSame(b.startTime) && a.endTime.isSame(b.endTime);
}

export function guessUserTimezone() {
  const timezone = moment.tz.guess();
  const isSupported = US_TIMES.some((t) => t.value === timezone);
  if (isSupported) {
    return timezone;
  }
  return DEFAULT_TIMEZONE;
}
