import PropTypes from "prop-types";
import React from "react";
import BaseInput from "../BaseInput";
import MaskedInput from "react-text-mask";
import { isEmpty } from "underscore";
import Cleave from "cleave.js/react";
import DefaultFormatter from "components/common/form/formatters/default";

/**
 * `input` element wrapper with the validation support.
 * Validator should be provided via `validator` attribute in the format
 * https://github.com/flatiron/revalidator#schema
 *
 * `formattingOptions`: Pass formattingOptions to the Input component to format input field value
 * Example: For Date formatting MM/DD/YYYY <Input formattingOptions={US_FULL_DATE_FORMAT}/>
 * Some standard formatters are defined in `components/common/form/formattingOptions`
 *
 * Example:
 * ```
    {
      type: 'string',
      format: 'email',
      allowEmpty: false
    }
 * ```
 *
 * @export Input
 * @class Input
 * @extends {React.Component}
 */

export default class Input extends BaseInput {
  constructor() {
    super(...arguments);
    // When formatting options are passed, We want to set the initial value to the input and
    // do not want to update the value of the input field for subsequent updates to avoid
    // cursor being pushed to the end of the input.
    this.state = Object.assign({}, this.state, {
      initialValue: this.props.value,
    });
    this.isCleaveInstance = !isEmpty(this.props.formattingOptions);
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    // To set up the proper initial value for the state updates.
    this.retainCleaveCursorPosition();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    // To set up the proper initial value for the prop updates.
    this.retainCleaveCursorPosition();
  }

  retainCleaveCursorPosition() {
    // When the input is cleave instance.
    // we have to set initial value to undefined if it is not already undefined.
    // Otherwise the Cleave input will be updated with the initial value instead of the latest input.
    // We are adding this fix as a workaround for a cleave issue https://github.com/nosir/cleave.js/issues/499
    // where cleave started listening to value prop updates to update the input value.
    if (this.isCleaveInstance && this.state.initialValue != null) {
      this.setState({ initialValue: undefined });
    }
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== "production") {
      //eslint-disable-line no-undef
      if (this.props.type === "checkbox" && this.props.value) {
        /*eslint-disable no-console*/
        console.warn(`Alert: The input type="checkbox" name="${this.props.name}" is being instantiated with a prop.value. This behavior is currently not supported
        due to limitations with our "form" and "input" framework. The value will be overwritten every time by "true" or "false" depending on the "checking" attribute.
        If your value is not a "boolean", you have to set it manually in the "model" depending on the "boolean" in the checkbox "value" or "checked" property.`);
        /*eslint-enable no-console*/
      }

      if (this.props.formatter !== DefaultFormatter) {
        /*eslint-disable no-console*/
        console.warn(`Alert: The input type="${this.props.type}" name="${this.props.name}" is being instantiated with a prop.formatter.
        As the current formatter implementation has some drawbacks, we are deprecating formatter prop.
        Using Cleave we have a better implementation to achieve formatting.
        Example: For Date formatting MM/DD/YYYY <Input formattingOptions={{ date: true, datePattern: ['m', 'd', 'Y'] }}
        Some commonly used formats are defined in 'components/common/form/formattingOptions'`);
        /*eslint-enable no-console*/
      }
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  focus() {
    // `ref` is not available when rendered by "react-test-renderer"
    if (this.ref) {
      this.ref.focus();
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      formattingOptions,
      prefix,
      prefixClassName,
      suffix,
      helpText,
      sizeVariation,
      onFocus,
      errorBlockClassName,
      autoFocus,
      autoComplete,
      checked,
      mask,
      showMaskGuide,
      alwaysShowMaskGuide,
      onInit,
    } = this.props;
    const { initialValue } = this.state;
    const inputGroupClassName =
      (sizeVariation ? `pc-input-group--${sizeVariation}` : "") +
      " " +
      (prefix ? "pc-input-group--with-prefix" : "");
    const inputSizeClassName = sizeVariation ? `input--${sizeVariation}` : "";
    const className = `input ${
      this.props.className || ""
    } ${inputSizeClassName} ${this.state.valid ? "" : "input--error"} ${
      prefix || suffix ? "pc-input-group__field" : ""
    }`;
    const containerClassName = this.props.containerClassName || "";
    const disabled = this.props.disabled || false;
    const readOnly = this.props.readOnly || false;
    const inputProps = {
      ref: (el) => {
        this.ref = el;
      },
    };
    let InputComponent = "input";
    let value;

    if (mask) {
      inputProps.mask = mask;
      inputProps.guide = showMaskGuide;
      inputProps.showMask = alwaysShowMaskGuide;
      InputComponent = MaskedInput;
    }

    if (onInit) {
      inputProps.onInit = onInit;
    }

    // Building Cleave Input component and props
    if (this.isCleaveInstance) {
      InputComponent = Cleave;
      // Cleave uses htmlRef instead of ref.
      delete inputProps.ref;
      inputProps.htmlRef = (el) => {
        this.ref = el;
      };
      inputProps.options = formattingOptions;
    }

    if (this.props.type === "checkbox") {
      value = this.props.value;
    } else {
      // When options is passed formatting is done in the Cleave component
      value = this.isCleaveInstance
        ? initialValue
        : this.props.formatter.format(this.state.value);
    }

    if (this.props.onPaste) {
      inputProps.onPaste = this.props.onPaste;
    }

    if (this.props.onDrop) {
      inputProps.onDrop = this.props.onDrop;
    }

    if (this.props.ariaRequired) {
      inputProps["aria-required"] = this.props.ariaRequired;
    }

    const inputId = this.props.id || this.id;

    if (this.state?.errors?.length > 0) {
      inputProps["aria-describedby"] = `${inputId}-error`;
    }

    if (!disabled) {
      inputProps["aria-disabled"] = false;
    }

    return (
      <div className={containerClassName}>
        <div className={`pc-input-group ${inputGroupClassName}`}>
          {prefix && (
            <label
              htmlFor={inputId}
              className={`pc-input-group__label pc-input-group__label--prefix qa-input-label ${prefixClassName}`}
            >
              {prefix}
            </label>
          )}
          <InputComponent
            type={this.props.type}
            id={inputId}
            className={className}
            maxLength={this.props.maxLength}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            placeholder={this.props.placeholder}
            disabled={disabled}
            readOnly={readOnly}
            name={this.props.name}
            aria-label={this.props.ariaLabel || this.props.name}
            value={value}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={onFocus}
            autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            checked={checked}
            data-lpignore={true /* Disable LastPass icon */}
            autoComplete={autoComplete}
            aria-labelledby={this.props.labelledby}
            {...inputProps}
          />
          {suffix && (
            <label className="pc-input-group__label pc-input-group__label--suffix">
              {suffix}
            </label>
          )}
        </div>
        {helpText && (
          <label className="pc-help-block pc-help-block--tiny u-text-left">
            {helpText}
          </label>
        )}
        {this.getErrorBlock({
          placeholder: this.props.errorPlaceholder,
          className: errorBlockClassName,
        })}
      </div>
    );
  }
}

Input.defaultProps = Object.assign({}, BaseInput.defaultProps, {
  showMaskGuide: true,
  alwaysShowMaskGuide: false,
  prefixClassName: "",
});

Input.propTypes = Object.assign({}, BaseInput.propTypes, {
  type: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onPaste: PropTypes.func,
  onDrop: PropTypes.func,
  className: PropTypes.string,
  prefixClassName: PropTypes.string,
  errorBlockClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  sizeVariation: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  helpText: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  formattingOptions: PropTypes.object,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  errorPlaceholder: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  mask: PropTypes.array, // Input mask (e.g. [/\d/, /\d/, '/', /\d/, /\d/] will show __/__ as mask)
  showMaskGuide: PropTypes.bool, // Show input mask guide when field is not empty
  alwaysShowMaskGuide: PropTypes.bool, // Show input mask guide even if field is empty
  onInit: PropTypes.func, // Support getting ref to Cleave instance,
  ariaLabel: PropTypes.string,
});
