import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";
import { delay, isFunction } from "underscore";
import ComponentAnalytics from "components/common/ComponentAnalytics";
import LoadingOverlay from "components/common/LoadingOverlay";
import scrollIntoView from "utils/scrollIntoView";
import { ModalContext } from "./ModalContext";

function slugify(str) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

const CloseIcon = () => (
  <svg className="pc-modal__close-button-icon" width="12" height="12">
    <use xlinkHref="#pc-icon__close-x"></use>
  </svg>
);

export default class Modal extends React.Component {
  constructor(props) {
    super(...arguments);
    const { isOpen, delayViewTracking, componentName, fireTrackViewEvent } =
      props;

    this.setAccessibilityLabelID();

    this.state = { isOpen };
    if (isOpen && fireTrackViewEvent) {
      if (delayViewTracking) {
        // Delay is used to get around a Mixpanel bug where if you click on a tracked action button to open
        // a tracked modal (view modal event is fired almost immediately after button click event), the event's
        // `component` custom property of the first event is overridden with that of the second event.
        delay(() => ComponentAnalytics.trackView(componentName), 0);
      } else {
        // By default `delayViewTracking` is true. Set it to false in unit tests so trackView() gets fired immediately.
        ComponentAnalytics.trackView(componentName);
      }
    }

    this.setContentRef = (el) => {
      this.contentRef = el;
    };
  }

  /**
   * The modal is supposed to be scrolled into view when opened. That is accomplished by focusing
   * the modal content on `react-modal` level. Scroll into view doesn't work with the focus
   * in FF if the modal is partially visible (even just a tiny bit).
   * https://bugzilla.mozilla.org/show_bug.cgi?id=1278864
   *
   * Below is a custom solution that works in all browsers that scrolls the modal into view if needed.
   */
  scrollIntoView() {
    if (this.contentRef) {
      scrollIntoView(this.contentRef, { block: "center" });
      this.setState({ scrollIntoViewRequested: false });
    }
  }

  componentDidMount() {
    if (this.props.shouldScrollIntoView && this.state.isOpen) {
      // We have to use an interim state `scrollIntoViewRequested` to scroll the modal into view
      // because `setContentRef()` callback is not being executed before `componentDidMount()` as expected.
      // That is due to a condition in `render()` of `react-modal` which mounts an empty component on the first run.
      this.setState({ scrollIntoViewRequested: true });
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.props.shouldScrollIntoView) {
      const { isOpen, scrollIntoViewRequested } = this.state;
      if (isOpen && isOpen !== prevState.isOpen) {
        this.setState({ scrollIntoViewRequested: true });
      }
      if (
        scrollIntoViewRequested &&
        scrollIntoViewRequested !== prevState.scrollIntoViewRequested
      ) {
        this.scrollIntoView();
      }
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      if (nextProps.isOpen) {
        this.open();
      } else {
        this.close();
      }
    }
  }

  open() {
    this.setState({ isOpen: true });
    ComponentAnalytics.trackView(this.props.componentName);
    window.dashboardUtils?.eventBus.dispatch(
      `modal.open.${this.props.componentName}.view`
    );
  }

  close() {
    // Wait for setState() to finish before continuing
    this.setState({ isOpen: false }, () => {
      const { componentName, onClosed, closeModalEventCustomProps } =
        this.props;
      const appendModalIfNotInComponentName = componentName
        .toLowerCase()
        .endsWith("modal")
        ? ""
        : " Modal";
      ComponentAnalytics.trackEvent(
        componentName,
        `Close ${componentName}${appendModalIfNotInComponentName}`,
        closeModalEventCustomProps
      );
      window.dashboardUtils?.eventBus.dispatch(
        `modal.close.${componentName}.view`
      );

      if (onClosed) {
        onClosed();
      }
    });
  }

  onCloseButtonClick() {
    const { shouldModalClose } = this.props;
    if (!isFunction(shouldModalClose) || shouldModalClose()) {
      this.close();
    } else {
      this.closeButton.blur(); // Modal is still open and `Close` button still has focus
    }
  }

  onRequestClose() {
    this.close();
    const parentOnRequestClose = this.props.onRequestClose;
    if (parentOnRequestClose) {
      parentOnRequestClose();
    }
  }

  setAccessibilityLabelID() {
    if (this.props.title && !this.props.contentLabel) {
      this.labelledById =
        this.props.labelledById ||
        `pc-modal__title-${slugify(this.props.title)}`;
    }
  }

  render() {
    let header;

    const headingID = this.labelledById ? { id: this.labelledById } : {};

    if (this.props.logo || this.props.title === "PERSONAL_CAPITAL_LOGO") {
      header = (
        <section className="pc-modal__header">
          <div className="pc-modal__header-logo-wrap">
            <svg className="icon-logo-text icon-logo-text--white">
              <use xlinkHref="#pc-icon__brand__logo--text" />
            </svg>
          </div>
        </section>
      );
    } else if (this.props.title) {
      header = (
        <section className="pc-modal__header">
          {this.props.icon && (
            <div className="pc-modal__icon-container">{this.props.icon}</div>
          )}
          <div
            className="pc-modal__title qa-modal-title"
            role="heading"
            aria-level={2}
            {...headingID}
          >
            <div
              className={`${
                this.props.isTitleSentenceCase ? "u-sentence-case " : ""
              }pc-modal__title-text-container`}
              dangerouslySetInnerHTML={{
                __html: `${this.props.title}${
                  this.props.isTitleRegisteredMark ? "<sup>®</sup>" : ""
                }`,
              }}
            />
          </div>
        </section>
      );
    }

    let closeModalX;
    if (this.props.showCloseButton) {
      closeModalX = (
        <button
          ref={(el) => {
            this.closeButton = el;
          }}
          type="button"
          className={`pc-btn pc-btn--stripped qa-modal-close-btn pc-modal__close-button ${
            header ? "" : "pc-modal__close-button--light-content"
          }`}
          onClick={this.onCloseButtonClick.bind(this)}
          data-testid="modal-close-icon"
          aria-label={`Close dialog`}
        >
          <CloseIcon />
        </button>
      );
    }

    const ariaProps = this.labelledById && { labelledby: this.labelledById };

    return (
      <ReactModal
        {...this.props}
        aria={ariaProps}
        ariaHideApp={false}
        className={`pc-modal qa-modal ${this.props.className || ""}`}
        overlayClassName={`pc-overlay pc-overlay--viewport pc-overlay--modal pc-overlay--active ${
          this.props.overlayClassName || ""
        }`}
        onRequestClose={this.onRequestClose.bind(this)} // Required for overlay click to close to work
        isOpen={this.state.isOpen}
        contentRef={this.setContentRef}
        contentLabel={this.props.contentLabel}
      >
        {closeModalX}
        {header}
        <section className={`js-modal-content ${this.props.contentClassName}`}>
          <LoadingOverlay active={this.props.loading} />
          <ModalContext.Provider value={this.state.isOpen}>
            {this.props.children}
          </ModalContext.Provider>
        </section>
      </ReactModal>
    );
  }
}

/**
 * For complete react-modal component APIs, visit https://reactcommunity.org/react-modal/
 */
Modal.propTypes = {
  // Component name to use when tracking analytics events
  componentName: PropTypes.string.isRequired,
  // Optional modal title
  title: PropTypes.string,
  // Optional classes to add to the outer most wrapper div
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  // Show X button on modal?
  showCloseButton: PropTypes.bool,
  // Call before modal is about to close, if it returns false, do not close
  shouldModalClose: PropTypes.func,
  // Callback function when modal is closed
  onClosed: PropTypes.func,
  // Block the UI with the overlay
  loading: PropTypes.bool,
  // Custom properties Mixpanel should use when firing the close modal event
  closeModalEventCustomProps: PropTypes.object,
  // JSX block to render an icon in the header (optional)
  icon: PropTypes.object,
  // Renders a ® mark on the side of the title
  isTitleRegisteredMark: PropTypes.bool,
  logo: PropTypes.bool,
  delayViewTracking: PropTypes.bool,
  /* Boolean describing if the modal should be shown or not. Defaults to false. */
  isOpen: PropTypes.bool,
  /* Function that will be run when the modal is requested to be closed, prior to actually closing. */
  onRequestClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  shouldScrollIntoView: PropTypes.bool,
  // Class name for the content container
  contentClassName: PropTypes.string,
  fireTrackViewEvent: PropTypes.bool,
  isTitleSentenceCase: PropTypes.bool,
  contentLabel: PropTypes.string,
  labelledById: PropTypes.string,
};

Modal.defaultProps = {
  delayViewTracking: true,
  showCloseButton: true,
  closeModalEventCustomProps: {},
  isOpen: false,
  title: undefined,
  onRequestClose: undefined,
  className: undefined,
  overlayClassName: undefined,
  shouldModalClose: undefined,
  onClosed: undefined,
  loading: false,
  icon: undefined,
  isTitleRegisteredMark: false,
  logo: false,
  // shouldScrollIntoView is set to `true` in iframe only as the default implementation via focus works by default
  shouldScrollIntoView: IS_IFRAMED,
  contentClassName: "pc-modal__content qa-modal-content",
  fireTrackViewEvent: true,
  isTitleSentenceCase: true,
  contentLabel: undefined,
  labelledById: undefined,
};
