/**
 * Used by Storybook embedded components that require API calls to by pass them in Storybook
 * @returns {boolean}  Whether the calling component was being used in Storybook
 */
const isStorybook = () => {
  const currentUrl = window.location.href;
  return (
    currentUrl.includes("localhost:900") || currentUrl.includes("/storybook")
  );
};

export default isStorybook;
