import $ from "jquery"; // END AMD WRAPPER
import _ from "underscore";
import "globals";
var ONE_SECOND = 1000;
var FIVE_SECONDS = 5000;

function Pinger(config) {
  _.extend(this, config);
  this.numberOfPings = 0;
  this.intervalID = -1;

  var services = this.services;

  // make sure we have the necessary mechanism to ping
  if (!_.isObject(services.Session) || !_.isFunction(services.Session.get)) {
    services.map({
      Session: {
        get: "/api/login/querySession",
      },
    });
  }
}

Pinger.prototype.stop = function () {
  clearTimeout(this.intervalID);
  this.intervalID = -1;
};

Pinger.prototype.ping = function () {
  if (this.intervalID > 0) {
    return;
  }
  this.started = true;
  this.numberOfPings++;
  this.services.Session.get(this.onPong, this);
};

Pinger.prototype.onPong = function (err, response) {
  var self = this;
  if (this.steadyInterval === undefined) {
    this.steadyInterval =
      !err && response.spData && response.spData.interval != null
        ? response.spData.interval * ONE_SECOND
        : FIVE_SECONDS;
  }
  this.intervalID = setTimeout(function () {
    self.intervalID = -1;
    self.ping();
  }, this.steadyInterval);
};

export default Pinger;
