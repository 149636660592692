import PropTypes from "prop-types";
import React from "react";
import { promisify } from "utils/service";
import Services from "services";
import ContactUsForm from "components/ContactUsForm";
import Mixpanel from "mixpanel";
import noop from "../../utils/noop";
import makeCancelablePromise from "libs/pcap/utils/makeCancelablePromise";

const SUCCESS_MESSAGE = "Thank you. Your email has been sent.";
const ERROR_MESSAGE =
  "Sorry. Your email has not been sent. Please try again later.";

export default class ContactUsFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sent: false,
      person: {},
    };

    this.handleFailure = this.handleFailure.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.emailUsPromise = promisify(Services.EmailSupport.send);
    this.getPersonPromise = promisify(Services.Person.get);
  }

  componentDidMount() {
    this.getPersonCancelable = makeCancelablePromise(this.getPersonPromise());
    this.getPersonCancelable.promise.then((data) =>
      this.setState({ person: data })
    );
  }

  handleSuccess() {
    this.setState({
      sent: true,
      loading: false,
      errors: undefined,
    });
    Mixpanel.trackEvent("Click on Send Button", {
      component: "Email Us Modal",
      // eslint-disable-next-line camelcase
      confirm_success: true,
    });
  }

  handleFailure(errors = []) {
    this.setState({
      loading: false,
      errors: errors,
      sent: true,
    });
    Mixpanel.trackEvent("Click on Send Button", {
      component: "Email Us Modal",
      // eslint-disable-next-line camelcase
      confirm_success: false,
    });
  }

  handleSend(model) {
    model.destination = this.props.destination;
    this.setState({ loading: true });
    this.emailUsPromise(model)
      .then((data) => {
        if (data.isSuccess) {
          this.handleSuccess();
        }
      })
      .catch((err) => {
        this.handleFailure(err);
      });
  }

  trackView(destination) {
    Mixpanel.trackEvent("View Email Us Form", {
      component: "Email Us Modal",
      // eslint-disable-next-line camelcase
      to_type: destination,
    });
  }

  render() {
    const { errors, loading, sent, person } = this.state;
    const {
      onCancel,
      destination,
      handleClose,
      emailBody,
      emailSubject,
    } = this.props;
    this.trackView(destination);
    return (
      <div>
        {sent === false ? (
          <ContactUsForm
            errors={errors}
            loading={loading}
            onCancel={onCancel}
            handleSend={this.handleSend}
            person={person}
            emailSubject={emailSubject}
            emailBody={emailBody}
          />
        ) : (
          <ResultMessage errors={errors} handleClose={handleClose} />
        )}
      </div>
    );
  }
}

const ResultMessage = ({ errors, handleClose }) => {
  return (
    <div>
      <div className="pc-u-mb">
        {errors === undefined ? SUCCESS_MESSAGE : ERROR_MESSAGE}
      </div>
      <div className="u-text-right">
        <button
          type="button"
          className="js-contact-us-form-cancel pc-btn"
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

ResultMessage.propTypes = {
  errors: PropTypes.any,
  handleClose: PropTypes.func,
};

ResultMessage.defaultProps = {
  errors: undefined,
  handleClose: noop,
};

ContactUsFormContainer.propTypes = {
  destination: PropTypes.string,
  onCancel: PropTypes.func,
  emailSubject: PropTypes.string,
  emailBody: PropTypes.string,
  handleClose: PropTypes.func,
};

ContactUsFormContainer.defaultProps = {
  destination: "SUPPORT",
  emailSubject: "",
  emailBody: "",
  onCancel: noop,
  handleClose: noop,
};
