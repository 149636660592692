import IframeBus from "../iframeBus";
import scrollWidth from "utils/scrollWidth";

let iframeClientInstance;

function getBodyHeight() {
  const { body } = document;
  const hasHorizontalScroll = body.scrollWidth > body.offsetWidth;
  return body.scrollHeight + (hasHorizontalScroll ? scrollWidth : 0);
}

/**
 * `IframeHost` and `IframeClient` classes define a communication interface between
 * the host window and an iframed Personal Capital dashboard. The primary use of the
 * interface pair is to notify the host window about (1) navigation and (2) content
 * height change from Personal Capital iframe.
 *
 * `IframeHost` is used by the iframed Personal Capital app to send events to the host window.
 *
 * #### Security
 * The client reads the value of `iframeHostWindowUrl` global variable defined
 * on the JSP.  Only that host is allowed to receive events triggered by
 * Personal Capital.
 *
 * #### Usage
 * Personal Capital components should retrieve the singleton instance of the
 * client using `IframeClient.getInstance()`.
 *
 * @example
    import IframeClient from 'partner/iframeClient';

    const iframeClient = IframeClient.getInstance();

    // notify the host window that the iframe needs to be resized
    // when the component has been rendered
    iframeClient.triggerResize();
 */
export default class IframeClient {
  constructor(targetUrl) {
    if (!this.isIframed()) {
      throw new Error("This application is intended to be used in an iframe.");
    }

    this.iframeBus = new IframeBus({
      sourceWindow: window,
      targetWindow: window.parent,
      allowedTargetOrigin: targetUrl,
      allowedSourceOrigin: targetUrl,
    });
  }

  // returns a singleton
  static getInstance() {
    if (!iframeClientInstance) {
      iframeClientInstance = new IframeClient(window.iframeHostWindowUrl);
    }

    return iframeClientInstance;
  }

  trigger() {
    this.iframeBus.trigger.apply(this.iframeBus, arguments);
  }

  triggerResize() {
    // plus the scrollbar width if the horizontal scrollbar appears
    this.trigger("resize", {
      height: getBodyHeight(),
    });
  }

  triggerResizeOnModalUpdate(className = "js-pc-modal") {
    const modalElement = document.getElementsByClassName(className)[0];
    if (!modalElement) {
      return;
    }

    const modalElementRect = modalElement.getBoundingClientRect();
    const modalHeight = modalElementRect.height;
    const modalTop = modalElementRect.top;
    // add top and bottom margin to modal height
    const modalOffsetHeight = modalHeight + modalTop * 2;
    if (modalOffsetHeight > getBodyHeight()) {
      this.trigger("resize", { height: modalOffsetHeight });
    }
  }

  /**
   * Notify the host window about the internal navigation within the iframe.
   * @param {String} url the url iframe navigated to
   */
  triggerNavigate(url) {
    this.trigger("navigate", url);
  }

  isIframed() {
    return Boolean(window.parent);
  }

  /**
   * Trigger a redirect on the host window.
   * @param {String} url the url to send the redirect to
   * @param {Object} params the optional object with additional parameters to pass along with the redirect `url`.
   */
  triggerRedirect(url, params) {
    this.trigger("redirect", { url, params });

    // The following is a custom implementation for iframed applications.
    // Sends a message in the format accepted by iframed applications
    this.iframeBus.postMessage(`redirect:linkNm=${url}`);
  }

  triggerKeepAliveProbe() {
    this.iframeBus.trigger("keep-alive");
  }

  /**
   * Triggers a scroll event on the host window.
   * @param {ScrollToOptions} options scroll to options
   * @param {Number} options.top y coordinate
   * @param {Number} options.left x coordinate
   * @param {String} options.behavior scroll behavior. Either "smooth" or "auto".
   */
  triggerScrollTo(options) {
    this.trigger("scrollTo", options);
  }

  onLogout(cb) {
    this.iframeBus.on("logout", cb);
    return this;
  }
}
