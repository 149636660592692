import React, { Component } from "react";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import moment from "moment";

/**
 * `DayPicker` operates on `Date` objects. Thus, `moment` should be converted
 * to a `Date`. We want to map the day as-is and skip the timezone conversion.
 *
 * Converts the provided moment object to a date by omitting the timezone information.
 *
 * @param {Moment} moment object
 * @returns {Date} the date object.
 */
export function momentToDay(moment) {
  // Used `toArray()` to remove the information about the timezone during the conversion.
  if (!moment) {
    return null;
  }
  return new Date(...moment.toArray());
}

function getDate(mmt) {
  let result = mmt;
  if (moment.isMoment(mmt)) {
    result = momentToDay(mmt);
  }
  return result;
}

/**
 * This is a calendar component customized for scheduling tool.
 *
 * By default, all days are disabled. Pass `enabledDays` prop to
 * enable specific days.
 */
export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.isDayDisabled = this.isDayDisabled.bind(this);
  }

  isDayDisabled(day) {
    const { enabledDays } = this.props;
    return !enabledDays.some((enabledDay) => {
      return enabledDay.isSame(day, "day");
    });
  }

  handleDayClick(day, { selected, disabled }) {
    // `selected` means that the user clicked already selected time slot
    if (disabled || selected) {
      return;
    }

    const { onChange } = this.props;
    if (onChange) {
      onChange(day);
    }
  }

  render() {
    const { fromMonth, month, selectedDay, onMonthChange, toMonth } =
      this.props;
    let fromMonthDate = getDate(fromMonth);
    let monthDate = getDate(month);
    let toMonthDate = getDate(toMonth);

    return (
      <div className="DayPicker-Panel DayPicker-Panel--responsive">
        <DayPicker
          fromMonth={fromMonthDate}
          month={monthDate}
          selectedDays={selectedDay}
          onDayClick={this.handleDayClick}
          disabledDays={this.isDayDisabled}
          onMonthChange={onMonthChange}
          toMonth={toMonthDate}
        />
      </div>
    );
  }
}

Calendar.propTypes = {
  // `Date`
  selectedDay: PropTypes.object,
  // An array of `moment`s
  enabledDays: PropTypes.array,
  // Receives two arguments:
  // - the date object
  // - boolean `selected` state
  onChange: PropTypes.func,
  fromMonth: PropTypes.object,
  toMonth: PropTypes.object,
  month: PropTypes.object,
  onMonthChange: PropTypes.func,
};

Calendar.defaultProps = {
  enabledDays: [],
};
