import _ from "underscore";
import alertBoxTemplate from "templates/modules/enrollmentNew/partials/alertBox.html";

export default {
  /**
   * Display error messages inside of `.js-error-block` container.
   * @param  {String|Array} messages A single message or an array of messages.
   */
  displayErrors: function (messages) {
    this.displayMessages(messages, "error");
  },

  displaySuccess: function (messages) {
    this.displayMessages(messages, "success");
  },

  /**
   * @param {String|Array} messages  A single message or an array of messages.
   * @param {String}       severity 'error' or 'success'
   */
  displayMessages: function (messages, severity) {
    if (!_.isArray(messages)) {
      messages = [messages];
    }
    this.$(".js-" + severity + "-block").html(
      alertBoxTemplate({
        messages: messages,
        severity: severity,
      })
    );
  },
};
