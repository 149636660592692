import PropTypes from "prop-types";
import React from "react";
import BaseInput from "../BaseInput";
import RadioButton from "./RadioButton";

/**
 * `RadioGroup` component renders a list of radio buttons and provides
 * the validation support.
 * Validator should be provided via `validator` attribute in the format
 * https://github.com/flatiron/revalidator#schema
 *
 * Example:
 * ```
    {
      allowEmpty: false
    }
 * ```
 *
 * RadioGroup options can be defined as an array of objects via `options` prop:
 * ```
    <RadioGroup options={[ {value: 'example_value', label: 'Example Option'}, ... ]}>
    </RadioGroup>
 * ```
 *
 * @export RadioGroup
 * @class RadioGroup
 * @extends {React.Component}
 */
export default class RadioGroup extends BaseInput {
  componentWillUnmount() {
    this.unmounted = true;
  }

  focus() {
    this.ref.focus();
  }

  render() {
    const {
      options,
      name,
      value,
      disabled,
      inputClassName,
      labelClassName,
      containerClassName,
      shouldFormatLabel,
      ariaLabelledById,
    } = this.props;
    const helpText = this.props.helpText;
    const className = `radio-group ${this.props.className} ${
      this.state.valid ? "" : "radio-group--error"
    }`;

    return (
      <div
        className={className}
        role="radiogroup"
        aria-labelledby={ariaLabelledById}
      >
        <div
          ref={(el) => {
            this.ref = el;
          }}
          className={containerClassName}
        >
          {options.map((o) => (
            <RadioButton
              name={name}
              value={o.value}
              label={o.label}
              ariaLabel={o?.ariaLabel}
              checked={o.value === value}
              inputClassName={inputClassName}
              labelClassName={labelClassName}
              disabled={disabled}
              key={o.value}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              shouldFormatLabel={shouldFormatLabel}
            />
          ))}
        </div>
        {helpText && (
          <label className="pc-help-block pc-help-block--small u-text-left">
            {helpText}
          </label>
        )}
        {this.getErrorBlock({ placeholder: this.props.errorPlaceholder })}
      </div>
    );
  }
}

RadioGroup.propTypes = Object.assign({}, BaseInput.propTypes, {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  errorPlaceholder: PropTypes.bool,
  shouldFormatLabel: PropTypes.bool,
  ariaLabelledById: PropTypes.string,
});

RadioGroup.defaultProps = Object.assign({}, BaseInput.defaultProps, {
  className: "",
  containerClassName: "",
  options: [],
  shouldFormatLabel: true,
});
