import $ from "jquery";
import _ from "underscore";
import globals from "globals";
import Services from "libs/pcap/utils/service";
import log from "log";
import analytics from "analytics";
import Mixpanel from "mixpanel";

var HTTP_CODE_OK = 200;
var HTTP_CODE_CREATED = 201;
var HTTP_CODE_ACCEPTED = 202;
var HTTP_CODE_NON_AUTH_INFO = 203;
var cache = Services.cache({
  ignoreParameters: [
    "lastServerChangeId",
    "csrf",
    "apiClient",
    "FROMBARGRAPH",
    "incomeExpenseMode",
    "sort_cols",
    "sort_rev",
    "page",
    "rows_per_page",
    "search",
    "credit_debit",
    "component",
    "includeInCashManager",
    "transactionType",
    "accountType",
    "includeCashFlows",
    "includeCategoryCashFlows",
    "includeMerchantCashFlows",
    "includeMerchantCategories",
  ],
});

const enrollmentChanges = ["PS_ENROLLMENT_SUBMITTED"];

function getGACookie() {
  try {
    var docCookie = document.cookie,
      utmzIndex = docCookie.indexOf("__utmz");
    return utmzIndex >= 0 ? docCookie.substring(utmzIndex).split(";")[0] : "";
  } catch (e) {
    return "";
  }
}

var marketingServerChanges = function () {
  return {
    USER_REGISTERED: 1,
    USER_AGGREGATED_100K_UBER: 2,
    USER_AGGREGATED_FIRST_ACCOUNT: 3,
    response: function (req, res) {
      if (res.data && res.data.spHeader && res.data.spHeader.SP_DATA_CHANGES) {
        var serverChanges = res.data.spHeader.SP_DATA_CHANGES;
        for (var i = 0; i < serverChanges.length; i++) {
          switch (serverChanges[i].eventType) {
            case "USER_REGISTERED":
              analytics.sendPageView("/completed-registration");
              Mixpanel.trackEvent("Registration");
              Mixpanel.createProfile();
              break;
            case "USER_AGGREGATED_100K_UBER":
              analytics.sendPageView("/user-aggregated-100k");
              break;
            case "USER_AQ_ACHIEVED":
              analytics.sendPageView("/user-135k");
              break;
            case "USER_QQ_ACHIEVED":
              analytics.sendPageView("/user-135k");
              break;
            case "USER_150K_PAV_ACHIEVED":
              analytics.sendPageView("/user-aggregated-150k");
              break;
            case "USER_AGGREGATED_FIRST_ACCOUNT":
              analytics.sendPageView("/user-aggregated-first-account");
              break;
            case "USER_25K_PAV_ACHIEVED":
              analytics.sendPageView("/user-aggregated-25k");
              break;
            default:
          }
        }
      }
    },
    // 1. Obsolete code: I have no idea what these magic numbers are.
    recordPixel: function (number) {
      try {
        var cookieString = getGACookie(),
          cookieValues = cookieString ? cookieString.split("|") : [],
          localUserGuid = globals.userGuid,
          source,
          medium,
          campaign,
          cookieValue,
          valueIndex;

        for (var i = 0; i < cookieValues.length; i++) {
          cookieValue = cookieValues[i];
          valueIndex = cookieValue.indexOf("utmscr=");
          if (valueIndex !== -1) {
            source = cookieValue.slice(valueIndex + 7); /* [1] */ // eslint-disable-line no-magic-numbers
          }
          valueIndex = cookieValue.indexOf("utmcmd=");
          if (valueIndex !== -1) {
            medium = cookieValue.slice(valueIndex + 7); /* [1] */ // eslint-disable-line no-magic-numbers
          }
          valueIndex = cookieValue.indexOf("utmccn=");
          if (valueIndex !== -1) {
            campaign = cookieValue.slice(valueIndex + 7); /* [1] */ // eslint-disable-line no-magic-numbers
          }
        }

        var rand = Math.random() * 100000; /* [1] */ // eslint-disable-line no-magic-numbers
        var html =
          '<iframe src="//ad.wsod.com/activity/3d6bcab09d0adfe7fd69dcc5d841e73a/' +
          number +
          ".iframe.activity/" +
          rand +
          ";userGuid=" +
          localUserGuid +
          ";tpid1=" +
          source +
          "_" +
          medium +
          "_" +
          campaign +
          '" width="1" height="1" frameborder="0"></iframe>';
        var trackingdiv = document.createElement("div");
        trackingdiv.innerHTML = html;
        document.body.appendChild(trackingdiv);
      } catch (err) {
        log("Could not track " + number + " through wsod");
        log(err);
      }
    },
  };
};

var showServerErrors = function (errors) {
  var displayErrors = _.filter(errors, function (error) {
    return !_.contains(
      [
        HTTP_CODE_OK,
        HTTP_CODE_CREATED,
        HTTP_CODE_ACCEPTED,
        HTTP_CODE_NON_AUTH_INFO,
      ],
      error.code
    );
  });
  var messages = _.map(displayErrors, function (error) {
    return error.message;
  });
  var message = messages.join("<br />");
  $("#serverErrors").html(message).show();
  $("#moduleContainer").scrollTop(0);
};

var spHeaderCapture = function () {
  return {
    init: function (service) {
      this.service = service;
    },
    response: function (req, res) {
      if (res && res.data && res.data.spHeader) {
        this.service.spHeader = res.data.spHeader;
        if (res.data.spHeader.errors) {
          showServerErrors(res.data.spHeader.errors);
        }
      }
    },
  };
};

var EnrollmentServices = new Services.Service()
  .use(Services.ensureParams())
  .use(cache)
  .use(Services.serverChanges(cache))
  .use(Services.csrfToken(globals.csrf))
  .use(Services.clientId())
  .use(marketingServerChanges())
  .use(spHeaderCapture())
  .use(Services.unauthRedirect())
  .map({
    Accounts: {
      get: {
        cache: true,
        url: "/api/newaccount/getAccounts2",
        serverChanges: ["USER_SITE_UPDATED", "STATEMENT_AVAILABLE"],
      },
      create: "/api/newaccount/createAccounts2",
      update: "/api/newaccount/updateAccount",
      refreshAccountsWithExtendedDetails:
        "/api/newaccount/refreshAccountsWithExtendedDetails",
    },
    Account: {
      validateEnrollment: "/api/newaccount/validateEnrollment",
      getTitle: "/api/newaccount/getAccountTitle",
    },
    Feedback: {
      send: "/api/feedback/logFeedback",
    },
    EmailSupport: {
      send: "/api/profile/emailUs",
    },
    Person: {
      get: "/api/person/getPerson",
      create: "/api/person/createPerson",
      update: "/api/person/updatePerson",
      getAll: "/api/person/getPersons",
      signClientAgreement: "/api/person/signClientAgreement",
      delete: "/api/person/deletePerson",
    },
    Enrollments: {
      get: {
        url: "/api/enrollment/getEnrollments",
        serverChanges: enrollmentChanges,
      },
    },
    Enrollment: {
      get: "/api/enrollment/getEnrollment",
      save: "/api/enrollment/saveEnrollment",
      cancel: "/api/enrollment/cancelEnrollment",
      update: "/api/enrollment/saveEnrollment2",
      saveFunding: "/api/enrollment/saveFunding",
      submit: "/api/enrollment/submitEnrollment",
      submit2: "/api/enrollment/submitEnrollment2",
      createNew: "/api/enrollment/createNewEnrollment",
      create: "/api/enrollment/saveEnrollment2",
      getDocusignLink: "/api/enrollment/getDocusignLink",
      delete: "/api/enrollment/deleteEnrollment",
      voidEnvelope: "/api/enrollment/voidAndCorrectEnvelope",
      generateTransferDocument:
        "/api/enrollment/generateOfflineTransferDocument",
    },
    User: {
      register: "/api/registration/registerUser",
      identify: "/api/login/identifyUser",
      clear: "/api/login/switchUser",
    },
    Session: {
      get: "/api/login/querySession",
    },
    Advisor: {
      get: "/api/profile/getAdvisor",
    },
    Holdings: {
      update: "/api/account/updateHolding",
    },
    Statements: {
      delete: "/api/newaccount/removeStatement",
      startStatementDownload: "/api/newaccount/startStatementDownload",
    },
    AccountTypes: {
      get: "/api/account/getAccountTypes2",
    },
    Fundings: {
      get: "/api/enrollment/getFunding",
      create: "/api/enrollment/saveFunding2",
      update: "/api/enrollment/saveFunding2",
      delete: "/api/enrollment/deleteFunding",
    },
    UIPreferences: {
      read: "/api/profile/getUIPreferences",
      create: "/api/profile/updateUIPreferences",
      update: "/api/profile/updateUIPreferences",
    },
    SearchSites: {
      get: "/api/search/searchSites",
    },
    Documents: {
      read: "/api/filecabinet/getEdocuments",
    },
    Document: {
      delete: "/api/filecabinet/removeDocument",
      update: "/api/filecabinet/updateDocument",
    },
    UserMessages: {
      read: "/api/enrollment/getUserMessages",
    },
    UserMessage: {
      markViewed: "/api/enrollment/markUserMessageViewed",
    },
    StandingFundingInstructions: {
      get: "/api/newaccount/getStandingFundingInstructions",
    },
    ContributionLimits: {
      get: "/api/account/getAccountContributionLimits",
    },
    Signout: {
      get: "/api/login/switchUser",
    },
  });

EnrollmentServices.cache = cache;

export default EnrollmentServices;
