import fireGoogleTagManagerEvent from "libs/pcap/utils/fireGoogleTagManagerEvent";

const MARKETING_GOALS = {
  "/user-aggregated-first-account": true,
  "/user-aggregated-25k": true,
  "/user-aggregated-100k": true,
  "/user-135k": true,
  "/user-pav-100k": true,
  "/user-aggregated-150k": true,
};

const ERROR_EMPTY_LABEL = "`label` should not be empty";

function sendEvent(category, action, label, value) {
  if (!category) {
    throw new Error("`category` should not be empty");
  }

  if (!action) {
    throw new Error("`action` should not be empty");
  }

  if (window.ga) {
    window.ga("send", "event", category, action, label, value);
  }
}

export default {
  /**
   * Sends a custom event to Google Analytics.
   *
   * @param  {String} category (required) the name that you supply as a way to group objects that you want to track.
   *                           Typically, you will use the same category name multiple times over related UI elements that you want to group under a given category.
   * @param  {String} action   (required) the type of event or interaction you want to track for a particular web object.
   *                           For example, with a single "Videos" category, you can track a number of specific events with this parameter like "Play", "Stop", etc.
   *                           NOTE:
   *                           - All actions are listed independently from their parent categories.
   *                             This provides you with another useful way to segment the event data for your reports.
   *                           - A unique event is determined by a unique action name. You can use duplicate action names across categories,
   *                             but this can affect how unique events are calculated. See the suggestions below and the Implicit Count section for more details.
   * @param  {String} label    (optional, but recommended) additional information for events that you want to track, such as the movie title or the name of a file when tracking downloads.
   *                           Think of a label as a way to create an additional reporting dimension for user interaction with page objects.
   * @param  {Number} value    (optional) Value differs from the other components in that it is an integer rather than string,
   *                           so use it to assign a numerical value to a tracked page object.
   *                           The value is interpreted as a number and the report adds the total values based on each event count. The report also determines the average value for the category.
   */
  sendEvent: sendEvent,

  /**
   * Sends an engagement event to Google Analytics.
   *
   * @param  {String} action   (required) the type of event or interaction you want to track for a particular web object.
   *                           For example, "Schedule a Call Click", "Enroll Now Click"
   * @param  {String} label    (optional) additional engagement information like button or link label if different from `action`.
   */
  sendEngagementEvent: function (action, label) {
    sendEvent("Engagement", action, label);
  },

  /**
   * Sends an engagement event to Google Analytics.
   *
   * @param  {String} action   (required) the type of event or interaction you want to track for a particular data object.
   *                           For example, "Edit Click", "Remove Click"
   * @param  {String} label    (required) the name of the data object the action being tracked has been applied to.
   *                           For example, "Income Event", "User Profile"
   * @param  {Number} value    (optional) Value differs from the other components in that it is an integer rather than string,
   *                           so use it to assign a numerical value to a tracked page object.
   *                           The value is interpreted as a number and the report adds the total values based on each event count. The report also determines the average value for the category.
   */
  sendDataActionEvent: function (action, label, value) {
    if (!label) {
      throw new Error(ERROR_EMPTY_LABEL);
    }
    sendEvent("Data Action", action, label, value);
  },

  /**
   * Sends a "call for additional information" event to Google Analytics.
   *
   * @param  {String} action   (required) the type of interaction you want to track.
   *                           For example, "Disclaimer Click", "Help Click"
   * @param  {String} label    (required) the name of the information object user wants to view.
   *                           For example, "Advice", "Notification"
   */
  sendInfoEvent: function (action, label) {
    if (!label) {
      throw new Error(ERROR_EMPTY_LABEL);
    }
    sendEvent("Info", action, label);
  },

  /**
   * Sends an engineering event to Google Analytics.
   *
   * Use to track internal errors and warnings to give a better sense
   * of what happens on production.
   *
   * @param  {String} severity (required) the severity of the event.
   *                           For example, "Warning", "Error", "Info".
   * @param  {String} label    (required) the message.
   */
  sendEngineeringEvent: function (severity, label) {
    if (!label) {
      throw new Error(ERROR_EMPTY_LABEL);
    }
    sendEvent("Engineering", severity, label);
  },

  sendPageView: function (pageUrl, pageTitle) {
    // Empower tracks page views themselves, do not send
    if (IS_EMPOWER || !pageUrl) {
      return;
    }

    if (pageTitle) {
      window.ga && // eslint-disable-line no-unused-expressions
        window.ga("send", {
          hitType: "pageview",
          page: pageUrl,
          title: pageTitle,
        }); // eslint-disable-line no-unused-expressions
    } else {
      window.ga && window.ga("send", "pageview", pageUrl); // eslint-disable-line no-unused-expressions
    }

    if (MARKETING_GOALS[pageUrl]) {
      const marketingGoal = pageUrl
        .replace("/", "")
        .replace(/-/g, "_")
        .toLowerCase();

      window.ga && // eslint-disable-line no-unused-expressions
        window.ga("send", "event", "goals", marketingGoal); // eslint-disable-line no-unused-expressions
    }

    fireGoogleTagManagerEvent(pageUrl);
  },

  /**
   * Sends an event to Google Tag Manager.
   *
   * Used to trigger events in Google Tag Manager
   *
   * @param  {String} eventName (required) the event name
   */
  sendGoogleTagManagerEvent: function (eventName) {
    if (!eventName) {
      return;
    }

    fireGoogleTagManagerEvent(eventName);
  },

  /**
   * Sends a Google Analytics event according to Empower specifications.
   *
   * Used to trigger events in Google Tag Manager
   *
   * @param  {String} componentName (required) the component that is tracked by GA
   *                          For example, "Landing page Banner", "Account Aggregation"
   *
   * @param  {String} label (required) the name of the button/link  that is tracked
   *                           For example, "Link an account - BANK", "Add Home Value"
   *
   * @param  {String} action (optional) the type of interaction you want to track.
   *                           For example, "Click", "Add asset"
   */
  sendEmpowerTrackEvent: function (componentName, label, action = "Click") {
    if (!window.dataLayer) {
      return;
    }
    if (!componentName) {
      throw new Error("`componentName` should not be empty");
    }

    if (!label) {
      throw new Error(ERROR_EMPTY_LABEL);
    }
    window.dataLayer.push({
      event: "sendEvent",
      "options.category": componentName,
      "options.action": action,
      "options.label": label,
    });
  },
};
