/* eslint-disable react/require-default-props */
import React, { Component } from "react";
import PropTypes from "prop-types";
import eventBus from "eventBus";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import { US_TIMES } from "enums/usTimes";
import moment from "moment-timezone";
import Calendar, { momentToDay } from "components/scheduling/Calendar";
import TimeSelector from "components/scheduling/TimeSelector";
import timeSlotsGenerator from "../timeSlotsGenerator";
import {
  filterAfternoonTimeSlots,
  filterMorningTimeSlots,
  convertAppointmentsToTimezone,
  guessUserTimezone,
} from "../utils";
import { isEmpty, first } from "underscore";
import memoizeOne from "memoize-one";
import Message from "components/common/Message";
import { trackEvent } from "components/common/ComponentAnalytics";
import { getTimeRangeSegment } from "utils/appointmentUtils";
import {
  AB_TEST_GROUP_A,
  AB_TEST_GROUP_B,
} from "libs/pcap/utils/getABTestGroupForCurrentUser";

const DURATION_HOUR = 60;
const NOW = new Date();
// eslint-disable-next-line no-magic-numbers
const PROSPECT_END_DATE = moment().add(15, "days");

const getAvailableDays = memoizeOne((availabilities) => {
  let enabledDays;
  if (availabilities) {
    const availableDays = {};
    availabilities.forEach((a) => {
      const startTime = a.startTime;
      availableDays[startTime.format("MMDDYYYY")] = a.startTime;
    });
    enabledDays = Object.values(availableDays);
  }
  return enabledDays;
});

function getAvailabilitiesForTimezone(availabilities, timezone) {
  if (timezone) {
    return convertAppointmentsToTimezone(availabilities, timezone);
  }
}

function getEmptyTimeSlots(duration = DURATION_HOUR) {
  return timeSlotsGenerator({ day: moment(), duration });
}

function getTimeSlotsForDay(availabilities, day, duration) {
  if (!day) {
    return null;
  }
  const selectedDayAvailabilities = availabilities.filter((a) =>
    a.startTime.isSame(day, "day")
  );
  return timeSlotsGenerator({
    day: day,
    duration,
    availabilities: selectedDayAvailabilities,
  });
}

// Convert availabilities to user's timezone.
function getDerivedStateFromAvailabilities(props, state = {}) {
  const { availabilities } = props;
  if (availabilities !== state.prevPropsAvailabilities) {
    const availabilitiesInTz = getAvailabilitiesForTimezone(
      availabilities,
      state.timezone
    );
    const firstAvailability = first(availabilitiesInTz);
    let selectedDay, timeSlots;
    if (firstAvailability) {
      const firstAvailabilityStartTime = firstAvailability.startTime;
      selectedDay = momentToDay(firstAvailabilityStartTime);
      timeSlots = getTimeSlotsForDay(
        availabilitiesInTz,
        firstAvailabilityStartTime,
        props.duration
      );
    }
    return {
      availabilities: availabilitiesInTz,
      prevPropsAvailabilities: availabilities,
      selectedDay,
      timeSlots,
    };
  }
  return null;
}

// 1. Select the day on the calendar based on the selected appointment id.
// 2. Populate the availabilities for the selected day
function getDerivedStateFromAppointmentId(
  props,
  state = {},
  availabilitiesInUserTimezone
) {
  const { appointmentId, duration } = props;
  if (appointmentId !== state.prevPropsAppointmentId) {
    if (appointmentId && !isEmpty(availabilitiesInUserTimezone)) {
      const selectedAppointment =
        availabilitiesInUserTimezone.find(
          (a) =>
            getTimeRangeSegment(a.appointmentId) ===
            getTimeRangeSegment(appointmentId)
        ) ?? availabilitiesInUserTimezone[0];
      const selectedAppointmentStartTime = selectedAppointment?.startTime;
      return {
        selectedDay: momentToDay(selectedAppointmentStartTime),
        timeSlots: getTimeSlotsForDay(
          availabilitiesInUserTimezone,
          selectedAppointmentStartTime,
          duration
        ),
      };
    }
    return { prevPropsAppointmentId: appointmentId };
  }
  return null;
}

export default class DateTimeSelector extends Component {
  static getDerivedStateFromProps(props, state) {
    const derivedState = {};
    Object.assign(
      derivedState,
      getDerivedStateFromAvailabilities(props, state)
    );
    const availabilitiesInUserTimezone = derivedState.availabilities;
    Object.assign(
      derivedState,
      getDerivedStateFromAppointmentId(
        props,
        state,
        availabilitiesInUserTimezone
      )
    );

    if (isEmpty(derivedState)) {
      return null;
    }
    return derivedState;
  }

  constructor(props) {
    super(props);

    this.state = {
      timezone: props.timezone || guessUserTimezone(),
    };

    this.handleTimezoneChange = this.handleTimezoneChange.bind(this);
    this.handleCalendarChange = this.handleCalendarChange.bind(this);
    this.handleTimeSlotSelect = this.handleTimeSlotSelect.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  componentDidMount() {
    eventBus.on("firstUseSignOut:clicked", () => {
      if (window.referralOnboardingTest === AB_TEST_GROUP_A) {
        trackEvent("Select Different Time Page Ref_On", "Sign Out");
      } else if (window.referralOnboardingTest === AB_TEST_GROUP_B) {
        trackEvent("Select Different Time Page Ref_Ont", "Sign Out");
      } else {
        trackEvent("Select Different Time Page", "Sign Out");
      }
    });
  }

  componentWillUnmount() {
    eventBus.off("firstUseSignOut:clicked");
  }

  handleCalendarChange(selectedDay) {
    const { availabilities, timezone } = this.state;
    const { duration, eventTrackingSharedProperties, componentName } =
      this.props;

    // `selectedDay` is a `Date` object in system timezone.
    // Need to convert only the day portion here and omit the
    // timezone conversion as the converted day may not match
    // the originally selected one due to the timezone offset.
    const selectedDayInTimezone = moment.tz(
      {
        year: selectedDay.getFullYear(),
        month: selectedDay.getMonth(),
        date: selectedDay.getDate(),
      },
      timezone
    );
    const timeSlots = getTimeSlotsForDay(
      availabilities,
      selectedDayInTimezone,
      duration
    );
    this.setState({ selectedDay, timeSlots });
    trackEvent(
      componentName,
      "Select a Date",
      Object.assign({}, eventTrackingSharedProperties, {
        selectedDay: JSON.stringify(selectedDay),
      })
    );
  }

  triggerChange(appointment) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(appointment);
    }
  }

  handleTimezoneChange({ target }) {
    const timezone = target.value;
    let { selectedDay, timeSlots } = this.state;
    const { duration, componentName, eventTrackingSharedProperties } =
      this.props;
    const availabilities = convertAppointmentsToTimezone(
      this.props.availabilities,
      timezone
    );

    if (isEmpty(availabilities)) {
      this.setState({
        timezone,
      });
    } else {
      if (!selectedDay) {
        const firstAvailability = first(availabilities);
        const firstAvailabilityStartTime = firstAvailability.startTime;
        selectedDay = momentToDay(firstAvailabilityStartTime);
      }

      const selectedDayInTimezone = moment.tz(
        {
          year: selectedDay.getFullYear(),
          month: selectedDay.getMonth(),
          date: selectedDay.getDate(),
        },
        timezone
      );

      timeSlots = getTimeSlotsForDay(
        availabilities,
        selectedDayInTimezone,
        duration
      );

      this.setState({
        timezone,
        availabilities,
        timeSlots,
        selectedDay,
      });
    }

    trackEvent(
      componentName,
      "Select Time Zone",
      Object.assign({}, eventTrackingSharedProperties, {
        timeZone: target.value,
      })
    );
    this.triggerChange({ appointmentId: null, timezone });
  }

  handleTimeSlotSelect({ appointmentId, startTime, endTime }) {
    const { componentName, eventTrackingSharedProperties } = this.props;
    this.triggerChange({
      appointmentId,
      startTime,
      endTime,
      timezone: this.state.timezone,
    });
    trackEvent(
      componentName,
      "Select Time Slot",
      Object.assign({}, eventTrackingSharedProperties, {
        startTime: JSON.stringify(startTime),
        endTime: JSON.stringify(endTime),
      })
    );
  }

  handleMonthChange() {
    this.setState({ selectedDay: null });
    const { onMonthChange } = this.props;
    if (onMonthChange) {
      onMonthChange.apply(null, arguments);
    }
    this.triggerChange({ appointmentId: null, timezone: this.state.timezone });
  }

  render() {
    const { timezone, availabilities, selectedDay } = this.state;
    const {
      disabled,
      appointmentId,
      duration,
      fromMonth,
      errors,
      month,
      isClient,
    } = this.props;
    let { timeSlots } = this.state;

    let enabledDays = getAvailableDays(availabilities);
    let toMonth;

    if (!isClient) {
      toMonth = PROSPECT_END_DATE;
    }

    if (!selectedDay) {
      timeSlots = getEmptyTimeSlots(duration);
    }

    return (
      <div
        className="date-time-selector date-time-selector--responsive qa-date-time-selector"
        data-testid={`date-time-selector--${disabled ? "disabled" : "enabled"}`}
      >
        <Message className="pc-u-mb+" severity="error" messages={errors} />
        <div className="pc-layout pc-layout--stretch date-time-selector-container date-time-selector-container--responsive">
          <div className="pc-layout__item pc-u-1/2 date-time-selector__calendar date-time-selector__calendar--responsive">
            <div
              className={`date-time-selector__calendar-container date-time-selector__calendar-container--responsive qa-date-time-selector__calendar-container`}
            >
              <FancySelect
                name="timezone"
                placeholder="Select your timezone..."
                ariaLabel="Select your timezone..."
                value={timezone}
                options={US_TIMES}
                maxMenuHeight={300}
                onChange={this.handleTimezoneChange}
                className="pc-u-mb qa-date-time-selector__timezone-select"
              />
              <Calendar
                month={month}
                fromMonth={fromMonth}
                selectedDay={selectedDay}
                enabledDays={enabledDays}
                onChange={this.handleCalendarChange}
                onMonthChange={this.handleMonthChange}
                toMonth={toMonth}
              />
            </div>
          </div>
          <div className="pc-layout__item pc-u-1/2 date-time-selector__time date-time-selector__time--responsive">
            <div
              className={`date-time-selector__time-container qa-date-time-selector__time-container date-time-selector__calendar-container--rebranding`}
            >
              <TimeSelector
                amSlots={filterMorningTimeSlots(timeSlots)}
                pmSlots={filterAfternoonTimeSlots(timeSlots)}
                appointmentId={appointmentId}
                onSelect={this.handleTimeSlotSelect.bind(this)}
              />
            </div>
          </div>
        </div>
        <div
          className={`pc-overlay pc-overlay--disabled pc-overlay--date-time-selector ${
            disabled ? "pc-overlay--active" : ""
          }`}
        />
      </div>
    );
  }
}

DateTimeSelector.propTypes = {
  errors: PropTypes.array,
  duration: PropTypes.number,
  // must be sorted
  availabilities: PropTypes.array,
  disabled: PropTypes.bool,
  appointmentId: PropTypes.string,
  // sends the selected `appointmentId`
  onChange: PropTypes.func,
  timezone: PropTypes.string,
  onMonthChange: PropTypes.func,
  fromMonth: PropTypes.object,
  month: PropTypes.object,
  componentName: PropTypes.string,
  eventTrackingSharedProperties: PropTypes.object,
  isClient: PropTypes.bool,
};

DateTimeSelector.defaultProps = {
  availabilities: [],
  duration: DURATION_HOUR,
  fromMonth: NOW,
  month: NOW,
};
