import { scan } from "d3";

function findLongestSeries(series) {
  return series[scan(series, (a, b) => b.length - a.length)];
}

function defaultFormat(d) {
  return d;
}

function buildLegendItems(series, focusedIndex, options) {
  const y = options.y;
  let yFormat = options.yFormat || defaultFormat;
  const getItemClassName = options.getItemClassName;
  // `series` is an array of arrays

  return series
    .map(function (data, i) {
      var datum = data[focusedIndex];
      if (datum) {
        return `<div class="chart-legend__item chart-legend__item--${i + 1} ${
          datum && datum.id ? "chart-legend__item--" + datum.id : ""
        } ${getItemClassName ? getItemClassName(datum) : ""}">${yFormat(
          y(datum)
        )}</div>`;
      }
      return "";
    })
    .join("");
}

/**
 * Returns a generator function for the tooltip template.
 * The template uses the supplied formatters for displaying
 * the focused values.
 * The shape of the legend (box or line) can be specified via
 * `legendClassName` parameter.
 *
 * @param {Object}    options                   options object
 * @param {Function}  options.x                 x accessor function
 * @param {Function}  options.y                 y accessor function
 * @param {Function}  options.xFormat           x formatter function
 * @param {Function}  options.yFormat           y formatter function
 * @param {String}    options.legendClassName   the class name to set on the legend list.
 *                                              `chart-legend--line` and `chart-legend--box` are available to
 *                                              control how the legends look like (line vs box).
 *                                              Size modifiers as `chart-legend--small` are also available.
 * @param {Function}  options.getItemClassName  A function that takes the data point of the current tooltip item and returns
 *                                              any additional class names to add to the item's .chart-legend__item div
 *                                              (e.g. to bold only items that meet certain conditions)
 *
 * @returns {Function} the generator function which returns a template function to be used with the tooltip
 */
export default function (options) {
  const template = function (data, i) {
    const { x, legendClassName, tooltipHeaderClassName } = options;
    const xFormat = options.xFormat || defaultFormat;
    const longestSeries = findLongestSeries(data);
    const xValue = xFormat(x(longestSeries[i]));
    const legendItemsHtml = buildLegendItems(data, i, options);
    return `<div class="chart-tooltip__header ${
      tooltipHeaderClassName || ""
    }">${xValue}</div>
      <ul class="chart-legend chart-legend--vertical ${
        legendClassName || ""
      }">${legendItemsHtml}</ul>`;
  };

  /**
   * Specifies the accessor function for `x` value on the datum object.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {chart|Function}       If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  template.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    return template;
  };

  /**
   * Specifies the accessor function for `y` value on the datum object.
   * By defaults uses `datum.y`.
   *
   * @param  {Function} [accessor]  the accessor function.
   * @return {template|Function}    If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  template.y = function (accessor) {
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    return template;
  };

  /**
   * Specifies the accessor function for `xFormat` value on the datum object.
   * By default uses `datum.xFormat`.
   *
   * @param  {Function} [xFormat]  the xFormat function.
   * @return {template|Function}    If `xFormat` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `xFormat` function.
   */
  template.xFormat = function (xFormat) {
    if (!arguments.length) {
      return options.xFormat;
    }

    options.xFormat = xFormat;
    return template;
  };

  /**
   * Specifies the accessor function for `yFormat` value on the datum object.
   * By default uses `datum.yFormat`.
   *
   * @param  {Function} [yFormat]  the yFormat function.
   * @return {template|Function}    If `yFormat` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `yFormat` function.
   */
  template.yFormat = function (yFormat) {
    if (!arguments.length) {
      return options.yFormat;
    }

    options.yFormat = yFormat;
    return template;
  };

  /**
   * Specifies the `legendClassName` string to use on the tooltip. Useful for updating the value.
   *
   *
   * @param  {String} [legendClassName]  the legendClassName function.
   * @return {template|String}    If `legendClassName` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `legendClassName`.
   */
  template.legendClassName = function (legendClassName) {
    if (!arguments.length) {
      return options.legendClassName;
    }

    options.legendClassName = legendClassName;
    return template;
  };

  return template;
}
