/* eslint-disable no-invalid-this */
import _ from "underscore";

import {
  extent,
  scaleBand,
  scaleLinear,
  axisTop,
  axisBottom,
  axisLeft,
  axisRight,
  transition,
  select,
} from "d3";
import moment from "moment";
import line from "libs/pcap/chart/series/line";
import area from "libs/pcap/chart/series/area";
import bar from "libs/pcap/chart/series/bar";
import formatCurrency from "libs/pcap/chart/format/currency";
import continuousTooltip from "libs/pcap/chart/tooltip/continuous";
import discreteTooltip from "libs/pcap/chart/tooltip/discrete";
import defaultTooltipTemplate from "libs/pcap/chart/tooltip/template/default";
import discreteTooltipTemplate from "libs/pcap/chart/tooltip/template/discrete";

var X_AXIS_HOR_OFFSET = 15;
var X_AXIS_HEIGHT = 20;
var Y_AXIS_VERT_OFFSET = 15;
var Y_AXIS_WIDTH = 60;
// gives some space on the sides of the chart to keep elements like axes, gridlines and hover state from being cut off
var DEFAULT_MARGIN = 4;
var DEFAULT_WIDTH = 640;
var DEFAULT_HEIGHT = 480;
var DEFAULT_TICKS_NUMBER = 6;
var DEFAULT_TRANSITION_DURATION_AXES = 500;

var ZERO_STATE_MAX_VALUE = 20;

function getDefaults() {
  return {
    width: null,
    height: null,
    showXAxis: true,
    showYAxis: true,
    showXGrid: false,
    showYGrid: true,
    showYZeroLine: true,
    xAxisPosition: "bottom",
    yAxisTicks: DEFAULT_TICKS_NUMBER,
    yDomain: null,
    xDomain: null,
    leftAlignYAxis: false,
    horizontalOrientation: false,
    x: function (d) {
      if (d.x === undefined) {
        throw new Error(
          "The default x-accessor couldn't find `x` property on the datum object.\nProvide a custom accessor via `.setX` API.\nProcessed datum object: " +
            JSON.stringify(d)
        );
      }
      return d.x;
    },
    y: function (d) {
      if (d.y === undefined) {
        throw new Error(
          "The default y-accessor couldn't find `y` property on the datum object.\nProvide a custom accessor via `.setY` API.\nProcessed datum object: " +
            JSON.stringify(d)
        );
      }
      return d.y;
    },
  };
}

function buildSeriesOptions(options) {
  return {
    x: options.x,
    y: options.y,
    xScale: options.xScale,
    yScale: options.yScale,
    curve: options.curve,
    key: options.key,
    stacks: options.stacks,
    group: options.group,
    horizontalOrientation: options.horizontalOrientation,
    leftAlignYAxis: options.leftAlignYAxis,
    showBarValue: options.showBarValue,
    barClassName: options.barClassName,
    areaClassName: options.areaClassName,
    barValueFormat: options.barValueFormat,
    groupPaddingInner: options.groupPaddingInner,
  };
}

function processDomain(data, options, dataAccessor) {
  return extent(data, function (d) {
    if (options.stacks) {
      return options.stacks.reduce(function (prev, key) {
        var value = d[key] == null ? 0 : d[key];
        return prev + value;
      }, 0);
    }
    return dataAccessor(d);
  });
}

function getValueDomain(data, dataAccessor, options, axis) {
  if (options.group) {
    // Given a group `['apple', 'banana']` and  data `[{ apple: 1, banana: 2, month: 8 }, { apple: 3:, banana: 4, month: 9 }]`,
    // produce `[1, 2, 3, 4]`
    data = _.chain(data)
      .map(function (d) {
        return Object.values(_.pick(d, options.group));
      })
      .flatten()
      .value();
    dataAccessor = _.identity;
  }

  var domain = processDomain(data, options, dataAccessor);

  if (domain[0] instanceof Date || moment.isMoment(domain[0])) {
    return domain;
  }

  var min = domain[0] || 0,
    max = domain[1] || ZERO_STATE_MAX_VALUE;

  if (
    (axis === "Y" && options.includeYZero) ||
    (axis === "X" && options.includeXZero) ||
    options.type === "bar"
  ) {
    if (min > 0 && max > 0) {
      min = 0;
    } else if (min < 0 && max < 0) {
      max = 0;
    }
  }

  return [min, max];
}

function defineXDomain(data, options) {
  return options.xScale.step
    ? data.map(options.x)
    : getValueDomain(data, options.x, options, "X");
}

function defineYDomain(data, options) {
  return options.yScale.step
    ? data.map(options.y)
    : getValueDomain(data, options.y, options, "Y");
}

function showXAxisModifier(options, margin) {
  if (options.xAxisPosition === "top") {
    margin.top = X_AXIS_HEIGHT;
  } else {
    margin.bottom = X_AXIS_HEIGHT;
  }

  // NOTE: For Bar Charts, the side margins are not required.
  if (options.type !== "bar") {
    margin.left = X_AXIS_HOR_OFFSET;
    margin.right = X_AXIS_HOR_OFFSET;
  }

  return margin;
}

function showYAxisModifier(options, margin, minVertMargin) {
  margin.top = Y_AXIS_VERT_OFFSET;
  margin.bottom = options.showXAxis ? X_AXIS_HEIGHT : minVertMargin;
  if (options.leftAlignYAxis) {
    margin.left = options.yAxisWidth || Y_AXIS_WIDTH;
  } else {
    margin.right = options.yAxisWidth || Y_AXIS_WIDTH;
  }

  return margin;
}

function calculateMargin(options) {
  var minHorMargin =
    options.showYGrid || options.showYZeroLine || options.tooltip
      ? DEFAULT_MARGIN
      : 0;
  var minVertMargin = options.showXGrid || options.tooltip ? DEFAULT_MARGIN : 0;
  var margin = {
    top: minHorMargin,
    right: minVertMargin,
    bottom: minHorMargin,
    left: minVertMargin,
  };

  if (options.showXAxis) {
    margin = showXAxisModifier(options, margin);
  }

  if (options.showYAxis) {
    margin = showYAxisModifier(options, margin, minVertMargin);
  }

  return margin;
}

/**
 * A d3 chart plugin.
 *
 * @param {Object}          options           Initialization options.
 * @param {Function}        [options.x]       The accessor function which is called on each datum object and returns
 *                                            the X value.
 *                                            @example
 *                                            function x(d) {
 *                                              return d.age;
 *                                            }
 *                                            The default accessor looks for `x` property on the datum object.
 * @param {Function}        [options.y]       The accessor function which is called on each datum object and returns
 *                                            the Y value.
 *                                            @example
 *                                            function x(datum) {
 *                                              return d.income;
 *                                            }
 *                                            The default accessor looks for `y` property on the datum object.
 *                                            NOTE: The accessor will not be used for bar chart when `stacks` option is specified.
 *                                            `stacks` serves as an accessor for multiple values to be stacked on top of each other.
 * @param {String}          options.type      The type of the chart.
 *                                            Supported types: `line`, `area`, `bar` and unspecified.
 *                                            NOTE: No shapes will be drawn unless a supported `type` value is specified.
 *                                            Don't specify `type` in case you just want to construct an empty chart.
 * @param {Curve}           options.curve     The interpolation mode for line and area chart.
 *                                            Accepted [built-in]{@link https://github.com/d3/d3-shape/blob/master/README.md#curves}
 *                                            and [custom]{@link https://github.com/d3/d3-shape#custom-curves}
 * @param {String}    options.xAxisPosition   The position of X axis.
 *                                            Supported modes: `top`, `bottom` (default), `zero`.
 *                                            In `zero` mode, the axis will be affixed to 0 tick of Y axis. That means,
 *                                            the axis could be rendered in the middle of the chart canvas, if there are
 *                                            negative values on Y axis.
 * @param {Function}        [options.key]     A key function may be specified to control which datum is assigned to
 *                                            which element, replacing the default join-by-index.
 *                                            This is done to maintain the object constancy, where a graphical element
 *                                            that represents a particular data point can be tracked visually through
 *                                            the transition.
 *                                            Please read [Joining Data]{@link https://github.com/d3/d3-selection/blob/master/README.md#joining-data} for more details.
 *                                            NOTE: Currently it is used for bar shapes only.
 * @param {Function|Array}  [options.stacks]  *Bar* Use this configuration option in order to build a stackable
 *                                            bar graph. `stacks` is an array of keys which map data points to particular stacks.
 *                                            Stack keys are typically strings, but they may be arbitrary values.
 *                                            If `stacks` is a function, it must return an array of stack keys.
 *                                            @example
 *                                            // Given the following data, `stacks` array should be defined as
 *                                            // `['apples', 'bananas', 'cherries', 'dates']`
 *                                            var data = [
 *                                              {month: new Date(2015, 0, 1), apples: 3840, bananas: 1920, cherries: 960, dates: 400},
 *                                              {month: new Date(2015, 1, 1), apples: 1600, bananas: 1440, cherries: 960, dates: 400},
 *                                              {month: new Date(2015, 2, 1), apples:  640, bananas:  960, cherries: 640, dates: 400},
 *                                              {month: new Date(2015, 3, 1), apples:  320, bananas:  480, cherries: 640, dates: 400}
 *                                            ];
 *                                            Please read [D3 Stacks]{@link https://github.com/d3/d3-shape/blob/master/README.md#stack} for more details.
 * @param {Array}           [options.group]   *Bar* Use this configuration option in order to build a grouped
 *                                            bar graph. `group` is an array of keys to be included in the group.
 *                                            `group` array can be updated on runtime to remove a bar from the groups.
 *                                            @example
 *                                            // Given the following data, `group` array should be defined as
 *                                            // `['apples', 'bananas', 'cherries', 'dates']`
 *                                            var data = [
 *                                              {month: new Date(2015, 0, 1), apples: 3840, bananas: 1920, cherries: 960, dates: 400},
 *                                              {month: new Date(2015, 1, 1), apples: 1600, bananas: 1440, cherries: 960, dates: 400},
 *                                              {month: new Date(2015, 2, 1), apples:  640, bananas:  960, cherries: 640, dates: 400},
 *                                              {month: new Date(2015, 3, 1), apples:  320, bananas:  480, cherries: 640, dates: 400}
 *                                            ];
 * @param {Boolean}   options.showBarValue    *Bar* Use this configuration option to render values on top of each bar. This is disabled when
 *                                            horizontalOrientation is enabled.
 * @param {Function}  options.barValueFormat  *Bar* Use this configuration option to specify how to format the value on top of each bar. This only
 *                                            applies when showBarValue is enabled.
 * @param {Boolean}   [options.gridStackPosition] The z position of the chart grid.
 *                                            Supported positions: 'top', 'bottom', undefined
 *                                            If undefined, default is `top` for area charts and 'bottom' for bar charts.
 * @param {Boolean}   [options.includeXZero]   *Line, Area* If true, X scale will include 0 if it is not already included.
 * @param {Boolean}   [options.includeYZero]   *Line, Area* If true, Y scale will include 0 if it is not already included.
 * @param {Boolean}   [options.showYZeroLine] Display the zero line for Y coordinates. Default `true`.
 *                                            NOTE: the line will be automatically hidden when it overlaps with the X axis.
 * @param {Object}   [options.margin]         Sets the chart margin - { top, bottom, left, right }. Not all are required.
 *
 * @param {Object|Boolean}  [options.tooltip]         Enables the tooltip for the chart.
 *                                                    The value can be either a simple boolean to enable the basic tooltip
 *                                                    or an object to provide additional customization.
 *                                                    By default, the chart wouldn't know how to format the values,
 *                                                    so generally `xFormat` and `yFormat` formatters should be provided.
 *                                                    If that is not enough, a custom template can be provided as
 *                                                    `tooltip.template` function.
 * @param {Function}  [options.tooltip.xFormat]       X value formatter function.
 * @param {Function}  [options.tooltip.yFormat]       Y value formatter function.
 * @param {Function}  [options.tooltip.template]      A custom template function which receives the dataset bound
 *                                                    to the chart and the index associated with the currently focused
 *                                                    datum `function(data, index) {}`.
 *                                                    The function should return html to be used in the tooltip.
 * @param {String}  [options.tooltip.className]       Class name to set on the tooltip element.
 * @param {String}  [options.tooltip.legendClassName] Class name to set on the legend list.
 *                                                    Available built-in classes:
 *                                                    - `chart-legend--line` and `chart-legend--box` for controlling
 *                                                      the shape of the legend.
 *                                                    - `chart-legend--{size}` size modifiers.
 * @param {string} [options.tooltip.hideTooltipKey]   Key for which tooltip has to be hidden.
 * @param {Boolean}   [options.horizontalOrientation]  Renders the chart in horizontal direction. Default `false`.
 *
 *                                                   the graph to highlight the importance of each point.
 * @param {Function}  [options.yAxis]                Type of Y Axis with left/right alignment, scale, tick marks and formatter
 * @param {Function}  [options.yGrid]                Type of Y Grid with scale and tick marks.
 * @param {Function}  [options.xAxis]                Type of X Axis with top/bottom alignment, scale, tick marks and formatter
 * @param {Function}  [options.xGrid]                Type of X Grid with scale and tick marks.
 * @param {Number}    [options.yAxisTicks]           Number of Y Axis Ticks to show
 * @param {Number}    [options.yDomainPadding]       Padding for the Y Domain so as to not show base of graph at min value. If padding is large enough to show Y=0, set range minimum to 0.
 * @param {Number}    [options.xScalePadding]       Padding added between items on the x scale
 * @param {Number}    [options.yScalePadding]       Padding added between items on the y scale
 * @param {Function}  [options.barClassName]        Function that returns a class name to set to a bar based on data of the bar.
 * @param {Function}  [options.yAxisTickFormat]         Function that returns a formatted y axis tick.
 * @param {Function}  [options.areaClassName]        Function that returns a class name to set to the chart area series based on data of the area.
 *
 * #Configuration
 * It supports basic configuration available via setters on the `chart` instance:
 *   - showXAxis
 *   - showYAxis
 *   - showXGrid
 *   - showYGrid
 *   - showYZeroLine
 *   - x
 *   - y
 *   - setXDomain
 *   - setYDomain
 *   - key
 *   - margin - { top, bottom, left, right }
 *   - yAxis
 *   - yGrid
 *
 * Additionally, the chart exposes the following d3 objects for further customization:
 *   - area
 *   - xAxis
 *   - yAxis
 *   - xGrid
 *   - yGrid
 *   - xScale
 *   - yScale
 *
 * #Styling
 * Specifying the style of the chart in CSS enables clear separation of concerns.
 * The following selectors are available for visual customizations:
 *   - `.chart__series` - Matches all shapes representing data series.
 *   - `.chart__series--area` - Matches area shapes. Area shapes are used for displaying the filled area
 *     under the line projection.
 *   - `.chart__series--area-{n}` - Matches individual area.
 *   - `.chart__series--line` - Matches line shapes. Lines represent line projections.
 *   - `.chart__series--line-{n}` - Matches individual line.
 *   - `.chart__series--bar` - Matches bar shapes. Bars represent bar projections.
 *   - `.chart__series--bar-{n}` - Matches a series of bars.
 *   - `.chart__stack--bar` - Matches bar stacks on stackable bar chart.
 *   - `.chart__stack--bar-{n}` - Matches a stack of bars.
 *   - `.chart__bar` - Matches an individual bar shape.
 *   - `.chart__bar--negative` - Matches a bar shape indicating a negative data point.
 *   - `.chart__axis` - Matches chart axes.
 *   - `.chart__axis--x` - Matches x-axis.
 *   - `.chart__axis--y` - Matches y-axis.
 *   - `.chart__axis-line` - Mathes axes lines.
 *   - `.chart__axis-tick` - Matches tick lines on axes.
 *   - `.chart__axis-value` - Matches tick labels.
 *     Example: Match x-axis tick lines - `.chart__axis--x .chart__axis-tick`
 *   - `.chart__grid` - Matches chart grids.
 *   - `.chart__grid--x` - Matches x-grid.
 *   - `.chart__grid--y` - Matches y-grid.
 *   - `.chart__grid-line` - Matches grid lines.
 *   - `.chart__zero-line` - Matches the zero line.
 *     Example: Match x-grid lines - `.chart__grid--x .chart__grid-line`
 *   - `.chart-legend__item--{n}` - Matches the n-th legend item.
 *     `.chart-legend__item--{n}::before` pseudo element is either the line or the box legend element.
 *     Example: Target the legend element for the first data series `.chart-tooltip--line-date .chart-legend__item--1::before`
 *
 * #Data
 * The chart works with a matrix data structure (array of arrays). Each array represents a distinct data series on the chart.
 * NOTE: Pass an array consisting of a single array if only one data series is needed to be displayed.
 * @example
 * // Display two data series on the chart
 * .datum([
 *   [{x: 0, y: 0}, {x: 1, y: 3}, {x: 2, y: 3}],  // data series 1
 *   [{x: 0, y: 5}, {x: 1, y: 2}, {x: 2, y: 1}]   // data series 2
 * ])
 * @example
 * // Display one data series on the chart
 * .datum([
 *   [{x: 0, y: 0}, {x: 1, y: 3}, {x: 2, y: 3}]  // data series 1
 * ])
 *
 * #Basic Usage
 * @example
 * // 1. Generate chart function
 * var chart = chart()
 *    // optional configuration calls
 *   .showXGrid(true)
 *   .x(function(d) { return d.age; })
 *   .y(function(d) { return d.value; });
 *
 * // 2. Render chart
 * // select a container
 * d3.select('.js-chart-container')
 *   // optionally setup 'svg' element if it's not in the DOM
 *  .append('svg')
 *    .attr('width', '100%')
 *    .attr('height', '100%')
 *    .classed('svg-chart', true)
 *  // provide data array
 *  .datum(data)
 *    // render chart
 *    .call(chart);
 *
 * // 3. Update chart with new data
 * d3.select('.js-chart-container')
 *  // provide data array
 *  .datum(data)
 *    // render chart
 *    .call(chart);
 *
 * #Responsive Charts
 * The implementation of the responsiveness is pretty basic. The chart image scales as its container scales.
 * By default, the aspect ratio would be preserved. `preserveAspectRatio` attribute can be specified on
 * `svg` element to modify how the chart should be fitted withing the container.
 *
 *
 * @return {Object} the chart instance
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
function chartFactory(options) {
  options = _.defaults(options || {}, getDefaults());
  var defaultMargin = calculateMargin(options);
  var margin = (options.margin = _.defaults(
    options.margin || {},
    defaultMargin
  ));

  if (!options.xScale) {
    options.xScale =
      options.type === "bar" && !options.horizontalOrientation
        ? scaleBand()
        : scaleLinear();
  }

  if (!options.yScale) {
    options.yScale =
      options.type === "bar" && options.horizontalOrientation
        ? scaleBand()
        : scaleLinear();
  }

  if (!options.gridStackPosition) {
    if (options.type === "bar") {
      options.gridStackPosition = "bottom";
    } else if (options.type === "area") {
      options.gridStackPosition = "top";
    }
  }

  var xAxis, xGrid;
  if (options.xAxis) {
    xAxis = options.xAxis;
  } else if (options.xAxisPosition === "top") {
    xAxis = axisTop(options.xScale).tickSizeOuter(0).tickSizeInner(0);
  } else {
    xAxis = axisBottom(options.xScale).tickSizeOuter(0).tickSizeInner(0);
  }

  if (options.xGrid) {
    xGrid = options.xGrid;
  } else {
    xGrid = axisTop(options.xScale).tickSizeOuter(0);
  }

  var yAxis, yGrid;
  if (options.yAxis) {
    yAxis = options.yAxis;
  } else {
    yAxis = options.leftAlignYAxis
      ? axisLeft(options.yScale)
      : axisRight(options.yScale);
    if (options.yTickValues && options.yTickValues.length > 0) {
      yAxis
        .tickValues(options.yTickValues)
        .tickFormat(options.yAxisTickFormat || formatCurrency)
        .tickSizeOuter(0)
        .tickSizeInner(0);
    } else {
      yAxis
        .ticks(options.yAxisTicks)
        .tickFormat(options.yAxisTickFormat || formatCurrency)
        .tickSizeOuter(0)
        .tickSizeInner(0);
    }
  }

  if (options.yGrid) {
    yGrid = options.yGrid;
  } else if (options.yTickValues && options.yTickValues.length > 0) {
    yGrid = axisLeft(options.yScale)
      .tickValues(options.yTickValues)
      .tickSizeOuter(0);
  } else {
    yGrid = axisLeft(options.yScale).ticks(options.yAxisTicks).tickSizeOuter(0);
  }

  var series;
  var seriesOptions = buildSeriesOptions(options);
  switch (options.type) {
    case "area":
      series = area(seriesOptions);
      break;
    case "line":
      series = line(seriesOptions);
      break;
    case "bar":
      series = bar(seriesOptions);
      break;
    default:
  }

  var tooltip;
  var tooltipTemplate;

  function initializeTooltips() {
    if (
      options.tooltip &&
      (options.type === "area" || options.type === "line")
    ) {
      let tooltipOptions = options.tooltip;
      tooltipTemplate =
        tooltipOptions.template ||
        defaultTooltipTemplate({
          x: tooltipOptions.x || options.x,
          y: tooltipOptions.y || options.y,
          xFormat: tooltipOptions.xFormat,
          yFormat: tooltipOptions.yFormat,
          legendClassName: tooltipOptions.legendClassName,
          tooltipHeaderClassName: tooltipOptions.tooltipHeaderClassName,
        });

      tooltip = continuousTooltip({
        xScale: options.xScale,
        yScale: options.yScale,
        x: options.x,
        y: options.y,
        template: tooltipTemplate,
        className: tooltipOptions.className,
      });
    } else if (options.tooltip && options.type === "bar") {
      let tooltipOptions = options.tooltip;
      tooltipTemplate =
        tooltipOptions.template ||
        discreteTooltipTemplate({
          x: tooltipOptions.x || options.x,
          y: tooltipOptions.y || options.y,
          xFormat: tooltipOptions.xFormat,
          yFormat: tooltipOptions.yFormat,
          legendClassName: tooltipOptions.legendClassName,
          stacks: options.stacks,
          stacksDisplay: tooltipOptions.stacksDisplay,
          shouldHideValue: tooltipOptions.shouldHideValue,
        });

      tooltip = discreteTooltip({
        xScale: options.xScale,
        yScale: options.yScale,
        x: options.x,
        stacks: options.stacks,
        template: tooltipTemplate,
        className: tooltipOptions.className,
        hideTooltipKey: tooltipOptions.hideTooltipKey,
      });
    }

    // Storing tooltip on window so that we an call destory
    // when there are route changes that does not unmount chart
    // componets properly
    if (tooltip) {
      window.chartTooltip = tooltip;
    }
  }

  function setupAxisClassNames(axis) {
    axis.selectAll(".tick line").classed("chart__axis-tick", true);

    axis.selectAll(".tick text").classed("chart__axis-value", true);

    axis.select(".domain").classed("chart__axis-line", true);
  }

  function setupGAEvents(body) {
    if (IS_EMPOWER) {
      body
        .selectAll(
          ".debt-paydown-widget__chart-container .chart__axis--x .chart__axis-value"
        )
        .on("mouseover", function () {
            window.dashboardUtils?.eventBus.dispatch(
              "debt_paydown_widget.debt_visualization_specific_date.mouseover"
            );
            window.dashboardUtils?.eventBus.dispatchAmplitude({
              event_type:
                window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_WIDGET,
              event_properties: {
                selection: "debt_paydown_widget.debt_visualization_specific_date.mouseover",
              },
            });
        });

      body
        .selectAll(
          ".debt-paydown__chart-container .chart__axis--x .chart__axis-value"
        )
        .on("mouseover", function () {
            window.dashboardUtils?.eventBus.dispatch(
              `debt_paydown_page.specific_date_debt_visualization.mouseover`
            );
            window.dashboardUtils?.eventBus.dispatchAmplitude({
              event_type:
                window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_TOOLTIP,
              event_properties: {
                selection: "debt_paydown_page.specific_date_debt_visualization.mouseover",
              },
            });
        });
    }
  }

  function chart(context) {
    var hasInheritedTransition = context instanceof transition;
    var selection = hasInheritedTransition ? context.selection() : context;

    selection.each(function (data) {
      var el = select(this);

      // Transition instance used for animating axes and grid lines
      var rootTransition = el.transition(context);
      if (!hasInheritedTransition) {
        rootTransition.duration(DEFAULT_TRANSITION_DURATION_AXES);
      }

      var rect = this.getBoundingClientRect();
      const width = options.width || rect.width;
      const height = options.height || rect.height;

      if (this.tagName.toUpperCase() === "SVG") {
        this.setAttribute("viewBox", "0 0 " + rect.width + " " + rect.height);
      }

      // store innerWidth/innerHeight on the instance
      var innerWidth = (chart.innerWidth =
        (width || DEFAULT_WIDTH) - margin.left - margin.right);
      var innerHeight = (chart.innerHeight =
        (height || DEFAULT_HEIGHT) - margin.top - margin.bottom);

      // data is represented as an array of series (arrays).
      // Flatten to calculate the domain for all series drawn.
      var dataFlat = _.flatten(data);

      // Initialize tooltips once only when data exists.
      if (_.isEmpty(dataFlat)) {
        if (tooltip) {
          tooltip.destroy();
        }
      } else if (!tooltip) {
        initializeTooltips();
      }

      var xScale = options.xScale;
      var xDomain = options.xDomain;
      if (!xDomain) {
        xDomain = defineXDomain(dataFlat, options);
      }
      if (options.xScalePadding) {
        xScale.padding(options.xScalePadding);
      }

      xScale.domain(xDomain).range([0, innerWidth]);

      var yDomain = options.yDomain;
      if (!yDomain) {
        yDomain = defineYDomain(dataFlat, options);
      }
      if (options.yScalePadding) {
        options.yScale.padding(options.yScalePadding);
      }

      // only use yDomainPadding if y=0 is not in the domain
      const yRangeStart =
        yDomain[0] < 0 && yDomain[1] > 0
          ? innerHeight
          : innerHeight - (options.yDomainPadding || 0);

      options.yScale.domain(yDomain).range([yRangeStart, 0]);

      // if min y value is positive and domain is such that min y value is within drawn bounds of chart,
      //    change the domain min to 0 and the range to the full size of the chart
      if (
        !options.horizontalOrientation &&
        options.type !== "bar" &&
        yDomain[0] >= 0 &&
        options.yScale(0) < innerHeight &&
        0 < options.yScale(0)
      ) {
        options.yScale.domain([0, yDomain[1]]).range([innerHeight, 0]);
      }

      var xAxisPosition = options.xAxisPosition;
      var xAxisVertCoordinate;
      if (xAxisPosition === "bottom") {
        xAxisVertCoordinate = innerHeight;
      } else if (xAxisPosition === "zero") {
        xAxisVertCoordinate = options.yScale(0);
      }

      var body = el.select(".chart__body");
      if (body.empty()) {
        body = el
          .append("g")
          .classed("chart__body js-chart-body", true)
          .attr(
            "transform",
            "translate(" + margin.left + "," + margin.top + ")"
          );
      }

      if (options.showXGrid) {
        xGrid.tickSizeInner(-innerHeight);

        var xGridJoin = body.selectAll(".js-x-grid").data([dataFlat]);

        // x-grid - update
        xGridJoin.transition(rootTransition).call(xGrid);

        // x-grid - enter + update
        var xGridEl = xGridJoin
          .enter()
          .append("g")
          .classed("chart__grid chart__grid--x js-x-grid", true)
          .call(xGrid)
          .merge(xGridJoin);

        xGridEl.selectAll("line").classed("chart__grid-line", true);
      }

      if (options.showYGrid) {
        yGrid.tickSizeInner(-innerWidth);

        var yGridJoin = body.selectAll(".js-y-grid").data([dataFlat]);

        // y-grid - update
        yGridJoin.transition(rootTransition).call(yGrid);

        // y-grid - enter + update
        var yGridEl = yGridJoin
          .enter()
          .append("g")
          .classed("chart__grid chart__grid--y js-y-grid", true)
          .call(yGrid)
          .merge(yGridJoin);

        yGridEl
          .selectAll("line")
          .filter(function (d) {
            return d === 0;
          })
          .classed(
            "chart__grid-line--hidden",
            options.showYZeroLine ||
              (options.showXAxis && options.xAxisPosition === "zero")
          );

        yGridEl.selectAll("line").classed("chart__grid-line", true);
      }

      if (series) {
        var seriesOptions = buildSeriesOptions(options);
        seriesOptions.height = innerHeight;

        var seriesGroupClass = "chart__series-group--" + options.type;
        var seriesJoin = body.selectAll(".js-chart-series-group").data([data]);

        seriesJoin.transition(rootTransition).call(series, seriesOptions);

        seriesJoin.exit().remove();

        seriesJoin = seriesJoin
          .enter()
          .append("g")
          .classed(
            "js-chart-series-group chart__series-group " + seriesGroupClass,
            true
          )
          .transition(rootTransition)
          .call(series, seriesOptions)
          .selection();

        if (options.gridStackPosition === "top") {
          seriesJoin.lower();
        } else if (options.gridStackPosition === "bottom") {
          seriesJoin.raise();
        }
      }

      if (options.showYZeroLine) {
        const isZeroInDomain = yDomain[0] <= 0 && yDomain[1] >= 0;
        var yZeroJoin = body
          .selectAll(".js-chart-zero-line--y")
          .data(isZeroInDomain ? [0] : []);
        var zeroLineVertCoordinate = options.yScale(0),
          zeroLineHorizontalCoordinate = options.xScale(0),
          x1 = 0,
          y1 = zeroLineVertCoordinate,
          x2 = innerWidth,
          y2 = zeroLineVertCoordinate;

        if (options.horizontalOrientation) {
          x1 = zeroLineHorizontalCoordinate;
          y1 = 0;
          x2 = zeroLineHorizontalCoordinate;
          y2 = innerHeight;
        }
        yZeroJoin.exit().remove();

        yZeroJoin.transition(rootTransition).attr("y1", y1).attr("y2", y2);

        // create
        yZeroJoin
          .enter()
          .append("line")
          .classed("chart__zero-line js-chart-zero-line--y", true)
          .attr("x1", x1)
          .attr("y1", y1)
          .attr("x2", x2)
          .attr("y2", y2)
          .merge(yZeroJoin)
          // hide when overlaps with X axis OR zero isn't in the domain
          .classed(
            "chart__zero-line--hidden",
            (options.showXAxis &&
              xAxisVertCoordinate === zeroLineVertCoordinate) ||
              !isZeroInDomain
          );
      } else {
        body
          .selectAll(".js-chart-zero-line--y")
          .classed("chart__zero-line--hidden", true);
      }

      if (options.showXAxis) {
        var xAxisJoin = body.selectAll(".js-x-axis").data([dataFlat]);
        let translateStr = "translate(0)";

        if (xAxisVertCoordinate) {
          translateStr = "translate(0," + xAxisVertCoordinate + ")";
        }

        // x-axis - update
        xAxisJoin
          .transition(rootTransition)
          .attr("transform", translateStr)
          .call(xAxis);

        // x-axis - enter
        var xAxisSelection = xAxisJoin
          .enter()
          .append("g")
          .classed("chart__axis chart__axis--x js-x-axis", true)
          .attr("transform", translateStr)
          .call(xAxis)
          .merge(xAxisJoin);

        setupAxisClassNames(xAxisSelection);

        setupGAEvents(body);
      }

      if (options.showYAxis) {
        var yAxisJoin = body.selectAll(".js-y-axis").data([dataFlat]);
        var translateWidth = options.leftAlignYAxis ? 0 : innerWidth;

        // y-axis - update
        yAxisJoin.transition(rootTransition).call(yAxis);

        // y-axis - enter
        var yAxisSelection = yAxisJoin
          .enter()
          .append("g")
          .classed("chart__axis chart__axis--y js-y-axis tabular-numbers", true)
          .attr("transform", "translate(" + translateWidth + ",0)")
          .call(yAxis)
          .merge(yAxisJoin);

        yAxisSelection.selectAll("text").attr("transform", "translate(0,-5)");

        setupAxisClassNames(yAxisSelection);
      }

      if (tooltip) {
        var tooltipContainer = el.select(".js-chart-body");

        tooltip.width(innerWidth).height(innerHeight);

        if (options.tooltip && options.tooltip.template) {
          tooltip.template(options.tooltip.template);
        }
        tooltipContainer.call(tooltip);
      }
    });
  }

  /**
   * Remove tooltips
   *
   */
  chart.destroy = function () {
    if (tooltip) {
      tooltip.destroy();
    }
  };

  /**
   * Specifies whether the x-axis should be displayed.
   *
   * @param  {Boolean} [flag=true]  the flag controlling the visibility of the axis.
   * @return {chart|Boolean}        If `flag` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `flag` value.
   */
  chart.showXAxis = function (flag) {
    if (!arguments.length) {
      return options.showXAxis;
    }

    options.showXAxis = flag;
    margin.bottom = flag ? X_AXIS_HEIGHT : DEFAULT_MARGIN;
    return chart;
  };

  /**
   * Specifies whether the y-axis should be displayed.
   *
   * @param  {Boolean} [flag=true]  the flag controlling the visibility of the axis.
   * @return {chart|Boolean}        If `flag` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `flag` value.
   */
  chart.showYAxis = function (flag) {
    if (!arguments.length) {
      return options.showYAxis;
    }

    options.showYAxis = flag;
    // TODO FIX ME
    // Only margin variable value is updated. Need to update the chart's body element as well.
    var newMargin = flag ? Y_AXIS_WIDTH : DEFAULT_MARGIN;
    if (options.leftAlignYAxis) {
      margin.left = newMargin;
    } else {
      margin.right = newMargin;
    }
    return chart;
  };

  /**
   * Specifies whether the x-grid lines should be displayed.
   *
   * @param  {Boolean} [flag=false] the flag controlling the visibility of the grid.
   * @return {chart|Boolean}        If `flag` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `flag` value.
   */
  chart.showXGrid = function (flag) {
    if (!arguments.length) {
      return options.showXGrid;
    }

    options.showXGrid = flag;
    return chart;
  };

  /**
   * Specifies whether the y-grid lines should be displayed.
   *
   * @param  {Boolean} [flag=true]  the flag controlling the visibility of the grid.
   * @return {chart|Boolean}        If `flag` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `flag` value.
   */
  chart.showYGrid = function (flag) {
    if (!arguments.length) {
      return options.showYGrid;
    }

    options.showYGrid = flag;
    return chart;
  };

  /**
   * Specifies the margin.
   *
   * @param  {Object} [margin]    margin for the grid - { top, bottom, left, right }.
   * @return {chart|Object}       If `margin` is specified, returns `this` reference for chaining,
   *                              otherwise returns the current `margin` value.
   */
  chart.margin = function (margin) {
    if (!arguments.length) {
      return options.margin;
    }

    options.margin = margin;
    return chart;
  };

  /** Specifies whether the zero line for Y coordinates should be displayed.
   *
   * NOTE: the line will be automatically hidden when it overlaps with the X axis.
   *
   * @param  {Boolean} [flag=true]  the flag controlling the visibility of the Y zero line.
   * @return {chart|Boolean}        If `flag` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `flag` value.
   */
  chart.showYZeroLine = function (flag) {
    if (!arguments.length) {
      return options.showYZeroLine;
    }

    options.showYZeroLine = flag;
    return chart;
  };

  /** Specifies the horizontal orientation for chart axes.
   *
   *
   * @param  {Boolean} [flag=true]  the flag controlling the horizontal orientation.
   * @return {chart|Boolean}        If `flag` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `flag` value.
   */
  chart.horizontalOrientation = function (flag) {
    if (!arguments.length) {
      return options.horizontalOrientation;
    }

    options.horizontalOrientation = flag;
    return chart;
  };

  /**
   * Specifies the accessor function for `x` value on the datum object.
   * By defaults uses `datum.x`.
   *
   * @param  {Function} [accessor]  the accessor function.
   *                                Default: `function(d){ return d.x; }`
   * @return {chart|Function}       If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  chart.x = function (accessor) {
    if (!arguments.length) {
      return options.x;
    }

    options.x = accessor;
    series.x(accessor);
    if (tooltip) {
      tooltip.x(accessor);
    }
    if (tooltipTemplate && tooltipTemplate.x) {
      tooltipTemplate.x(accessor);
    }
    return chart;
  };

  /**
   * Specifies the accessor function for `y` value on the datum object.
   * By defaults uses `datum.y`.
   *
   * @param  {Function} [accessor]  the accessor function.
   *                                Default: `function(d){ return d.y; }`
   * @return {chart|Function}       If `accessor` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `accessor` function.
   */
  chart.y = function (accessor) {
    if (!arguments.length) {
      return options.y;
    }

    options.y = accessor;
    series.y(accessor);
    if (tooltip) {
      tooltip.y(accessor);
    }
    if (tooltipTemplate && tooltipTemplate.y) {
      tooltipTemplate.y(accessor);
    }
    return chart;
  };

  /**
   * Defines the whole X scale's domain. Using this will disable calculating the domain based on the data.
   * By default the domain is dynamically calculated based on the data.
   *
   * @param  {Array} [domain=null]  the domain.
   * @return {chart|Function}       If `domain` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `xDomain` value.
   */
  chart.setXDomain = function (domain) {
    if (!arguments.length) {
      return options.xDomain;
    }

    options.xDomain = domain;
    return chart;
  };

  /**
   * Defines the whole Y scale's domain. Using this will disable calculating the domain based on the data.
   * By default the domain is dynamically calculated based on the data.
   *
   * @param  {Array} [domain=null]  the domain
   * @return {chart|Function}       If `domain` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `yDomain` value.
   */
  chart.setYDomain = function (domain) {
    if (!arguments.length) {
      return options.yDomain;
    }

    options.yDomain = domain;
    return chart;
  };

  /**
   * Set/get the key function.
   *
   * @param  {Function} [key=null]  The key function.
   * @return {chart|Function}      If `key` is specified, returns `this` reference for chaining,
   *                                otherwise returns the current `key` value.
   */
  chart.key = function (key) {
    if (!arguments.length) {
      return options.key;
    }

    options.key = key;
    return chart;
  };

  /**
   * Set/get the stacks for bar chart.
   *
   * @param  {Array|Function} [stacks]  The `stacks` accessor function or an array.
   * @return {chart|Function}           If `stacks` is specified, returns `this` reference for chaining,
   *                                    otherwise returns the current `stacks` value.
   */
  chart.stacks = function (stacks) {
    if (!arguments.length) {
      return options.stacks;
    }

    options.stacks = stacks;
    return chart;
  };

  /**
   * Set/get the group for bar chart.
   *
   * @param  {Array} [group]            The `group` array.
   * @return {chart|Array}              If `group` is specified, returns `this` reference for chaining,
   *                                    otherwise returns the current `group` value.
   */
  chart.group = function (group) {
    if (!arguments.length) {
      return options.group;
    }

    options.group = group;
    return chart;
  };

  /**
   * Include 0 value on Y axis.
   * Note: supported by line and area charts only.
   *
   * @param  {Boolean} [includeYZero]  If true, Y scale will include 0 if it is not already included.
   * @return {chart|Boolean}          If `includeYZero` is specified, returns `this` reference for chaining,
   *                                  otherwise returns the current `includeYZero` value.
   */
  chart.includeYZero = function (includeYZero) {
    if (!arguments.length) {
      return options.includeYZero;
    }

    options.includeYZero = includeYZero;
    return chart;
  };

  /**
   * Include 0 value on X axis.
   * Note: supported by line and area charts only.
   *
   * @param  {Boolean} [includeXZero]  If true, X scale will include 0 if it is not already included.
   * @return {chart|Boolean}          If `includeXZero` is specified, returns `this` reference for chaining,
   *                                  otherwise returns the current `includeXZero` value.
   */
  chart.includeXZero = function (includeXZero) {
    if (!arguments.length) {
      return options.includeXZero;
    }

    options.includeXZero = includeXZero;
    return chart;
  };

  /**
   * Set/Get xAxis for chart
   *
   * @param  {function} [axis]   If provided, this will define the `xAxis`.
   * @return {chart|function}     If `axis` is specified, returns `this` reference for chaining,
   *                              otherwise returns the current xAxis.
   */
  chart.xAxis = function (axis) {
    if (!arguments.length) {
      return xAxis;
    }

    options.xAxis = axis;
    return chart;
  };

  /**
   * Set/Get xGrid for chart
   *
   * @param  {function} [grid]   If provided, this will define the `xGrid`.
   * @return {chart|function}     If `grid` is specified, returns `this` reference for chaining,
   *                              otherwise returns the current yGrid.
   */
  chart.xGrid = function (grid) {
    if (!arguments.length) {
      return xGrid;
    }

    options.xGrid = grid;
    return chart;
  };

  /**
   * Set/Get yAxis for chart
   *
   * @param  {function} [axis]   If provided, this will define the `yAxis`.
   * @return {chart|function}     If `axis` is specified, returns `this` reference for chaining,
   *                              otherwise returns the current yAxis.
   */
  chart.yAxis = function (axis) {
    if (!arguments.length) {
      return yAxis;
    }

    options.yAxis = axis;
    return chart;
  };

  /**
   * Set/Get yGrid for chart
   *
   * @param  {function} [grid]   If provided, this will define the `yGrid`.
   * @return {chart|function}     If `grid` is specified, returns `this` reference for chaining,
   *                              otherwise returns the current yGrid.
   */
  chart.yGrid = function (grid) {
    if (!arguments.length) {
      return yGrid;
    }

    options.yGrid = grid;
    return chart;
  };

  /**
   * Sets one or more options for the chart.
   * @param {Object} opts  A map of option-value pairs to set.
   */
  chart.option = function (opts) {
    if (tooltipTemplate) {
      if (tooltipTemplate.xFormat) {
        tooltipTemplate.xFormat(opts.tooltip.xFormat);
      }

      if (tooltipTemplate.yFormat) {
        tooltipTemplate.yFormat(opts.tooltip.yFormat);
      }

      if (tooltipTemplate.legendClassName) {
        tooltipTemplate.legendClassName(opts.tooltip.legendClassName);
      }
    }

    options = Object.assign(options, opts);
  };

  // ------------------------------------------------------------
  //  Expose Public Variables
  // ------------------------------------------------------------

  chart.series = series;
  chart.xAxis = xAxis;
  chart.xGrid = xGrid;
  // TODO remove, available via `options` on the instance
  chart.xScale = options.xScale;
  // TODO remove, available via `options` on the instance
  chart.yScale = options.yScale;

  chart.options = options;

  return chart;
}

export default chartFactory;
