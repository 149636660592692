import isStorybook from "libs/pcap/utils/isStorybook";
import isPostLoginActionPreview from "libs/pcap/utils/isPostLoginActionPreview";
import isCommunicationsPage from "libs/pcap/utils/isCommunicationsPage";
import $ from "jquery";

// Splunk does not like dashes `-` in URL parameter keys, so we will replace them with underscores `_`
const transformParamKeys = (params) => {
  const result = {};
  Object.keys(params).forEach((key) => {
    result[key.replace(/-/g, "_")] = params[key];
  });
  return result;
};

const splunkLog = (dataObject, { encodeQueryString = true, onDone } = {}) => {
  const params = transformParamKeys(dataObject);
  const imgObj = new Image();
  const iterator = (key) =>
    encodeQueryString
      ? encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      : key + "=" + params[key];
  const queryString = Object.keys(params).map(iterator).join("&");
  const source = window.baseUrl + "images/logBeacon.gif?" + queryString;

  // Do not Splunk Log on Advisor App Communications page
  if (isCommunicationsPage()) {
    if (onDone) {
      onDone();
    } else {
      return;
    }
  }

  if (onDone) {
    $(imgObj)
      .load(() => {
        onDone();
      })
      .attr("src", source);
  } else {
    imgObj.src = source;
  }
  if (isStorybook() || isPostLoginActionPreview()) {
    console.log("splunkLog()", params); // eslint-disable-line no-console
  }
};

export default splunkLog;
