/* eslint-disable react/require-default-props */
import React, { Component } from "react";
import PropTypes from "prop-types";
import FancySelect from "components/common/form/FancySelect/FancySelect";
import { first } from "underscore";
import { trackEvent } from "components/common/ComponentAnalytics";

function findAppointmentTypeOption(appointmentType, appointmentTypes) {
  return appointmentTypes.find((at) => at.value === appointmentType);
}

function getDefaultDuration({ duration, durations }) {
  if (!duration && durations.length === 1) {
    duration = first(durations).value;
  }
  return duration;
}

export default class AppointmentTypeSelector extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { appointmentType, appointmentTypes } = nextProps;
    let { duration } = nextProps;
    if (appointmentType !== prevState.prevPropsAppointmentType) {
      const appointmentTypeOption = findAppointmentTypeOption(
        appointmentType,
        appointmentTypes
      );

      duration = duration || getDefaultDuration(appointmentTypeOption);

      return {
        appointmentType,
        prevPropsAppointmentType: appointmentType,
        durations: appointmentTypeOption.durations,
        duration,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      appointmentType: props.appointmentType,
      duration: props.duration,
    };
    this.handleAppointmentTypeChange =
      this.handleAppointmentTypeChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this);
  }

  handleAppointmentTypeChange({ target }) {
    const appointmentType = target.value;
    const {
      onChange,
      eventTrackingSharedProperties,
      appointmentTypes,
      componentName,
    } = this.props;
    const appointmentTypeOption = findAppointmentTypeOption(
      appointmentType,
      appointmentTypes
    );
    const duration = getDefaultDuration(appointmentTypeOption);
    this.setState({
      appointmentType,
      durations: appointmentTypeOption.durations,
      duration,
    });

    if (onChange) {
      onChange({ type: appointmentType, duration });
    }
    trackEvent(
      componentName,
      `Appointment type changed to ${appointmentType}`,
      Object.assign({}, eventTrackingSharedProperties, { appointmentType })
    );
  }

  handleDurationChange({ target }) {
    const duration = target.value;
    this.setState({ duration });
    const { onChange, eventTrackingSharedProperties, componentName } =
      this.props;
    if (onChange) {
      onChange({ type: this.state.appointmentType, duration });
    }
    trackEvent(
      componentName,
      `Appointment duration hanged to ${duration}`,
      Object.assign({}, eventTrackingSharedProperties, { duration })
    );
  }

  getLabel() {
    const { appointmentType, duration } = this.state;
    if (appointmentType && duration) {
      return "Please choose a day to see our availability";
    }
    if (appointmentType) {
      return "Please select the duration";
    }
    return "Please select appointment type";
  }

  customOptionRenderer(option) {
    return (
      <div className={"appointment-type-selector__option"}>
        <span
          className="qa-appointment-type-selector__option"
          data-value={option.value}
        >
          {option.label}
        </span>
      </div>
    );
  }

  render() {
    const { appointmentType, duration, durations } = this.state;
    const { appointmentTypes, typeDisabled, durationDisabled } = this.props;
    return (
      <div className="appointment-type-selector qa-appointment-type-selector">
        <div className="pc-layout pc-layout--middle">
          <div className="pc-layout__item pc-u-1/2 js-appointment-type-selector-label qa-appointment-type-selector-label">
            {this.getLabel()}
          </div>
          <div className="pc-layout__item pc-u-1/2">
            <div className="l-spaced l-spaced--flush">
              <div
                className={`appointment-type-selector__input js-appointment-type-input-type-outline ${
                  appointmentType
                    ? ""
                    : "appointment-type-selector__input--outlined"
                }`}
              >
                <FancySelect
                  name="appointmentType"
                  value={appointmentType}
                  options={appointmentTypes}
                  onChange={this.handleAppointmentTypeChange}
                  optionRenderer={this.customOptionRenderer}
                  className="Select--scheduling-appointment-type-selector appointment-type-selector__type qa-appointment-type-selector__type"
                  placeholder="Appointment Types"
                  ariaLabel="Appointment Types"
                  isDisabled={typeDisabled}
                />
              </div>
              <div
                className={`appointment-type-selector__input js-appointment-type-input-duration-outline ${
                  appointmentType && !duration
                    ? "appointment-type-selector__input--outlined"
                    : ""
                }`}
              >
                <FancySelect
                  name="duration"
                  value={duration}
                  options={durations}
                  onChange={this.handleDurationChange}
                  className="Select--scheduling-appointment-type-selector  appointment-type-selector__duration"
                  placeholder="Duration"
                  ariaLabel="Duration"
                  isDisabled={durationDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AppointmentTypeSelector.propTypes = {
  appointmentTypes: PropTypes.array,
  appointmentType: PropTypes.string,
  duration: PropTypes.number,
  onChange: PropTypes.func,
  typeDisabled: PropTypes.bool,
  durationDisabled: PropTypes.bool,
  eventTrackingSharedProperties: PropTypes.object,
  componentName: PropTypes.string,
};
