/* eslint-disable camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";

const removeDuplicates = (fileCache, selectedFiles) => {
  let files = [];
  selectedFiles.forEach((selectedFile) => {
    // exclude if already seen file name
    const isDuplicate = fileCache.find((f) => f.name === selectedFile.name);
    if (!isDuplicate) {
      files.push(selectedFile);
    }
  });

  return files;
};

/**
 *  FileDropZone is a drag and drop container for file uploading
 *  FileDropZone does not accept duplicate file and matches default behavior of input of type `file`
 *
 *  @param {Boolean} multiple (optional) Flag that specifies whether user can drop multiple files into FileDropZone
 **/

class FileDropZone extends Component {
  constructor() {
    super();

    this.state = {
      drag: false,
      droppedFiles: [], //caches dropped files to prevent duplicate file uploads
    };
    this.dragCounter = 0;
    this.dropRef = React.createRef();
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDragIn(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    const types = Array.from(e.dataTransfer.types || []);
    // Hack to show drag state for Safari/IE11
    if (
      (e.dataTransfer.items && e.dataTransfer.items.length > 0) ||
      types.indexOf("Files") !== -1
    ) {
      this.setState({ drag: true });
    }
  }
  handleDragOut(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  }
  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    if (IS_EMPOWER) {
      window.dashboardUtils?.eventBus.dispatch(
        "funding_account_details.doc_upload_auto.drop"
      );
      window.dashboardUtils?.eventBus.dispatchAmplitude({
        event_type: window.integratedSharedData?.AMPLITUDE_EVENTS?.SELECT_MENU,
        event_properties: {
          selection: "funding_account_details.doc_upload_auto.drop",
        },
      });
    }

    const { multiple } = this.props;
    let { files } = e.dataTransfer;
    this.setState({ drag: false });
    if (files && files.length > 0) {
      const { droppedFiles } = this.state;
      const filteredFiles = removeDuplicates(droppedFiles, Array.from(files));

      if (filteredFiles.length) {
        this.setState({
          droppedFiles: droppedFiles.concat(filteredFiles),
        });
        this.props.onDrop(multiple ? filteredFiles : [filteredFiles[0]]);
      }

      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  render() {
    const { multiple } = this.props;
    const header = multiple ? "Drop Files Here" : "Drop a File Here";
    return (
      <div
        className={`file-drop-zone${
          this.state.drag ? " file-drop-zone--active" : ""
        }`}
        ref={this.dropRef}
      >
        {this.props.children}
        <div className="file-drop-zone__overlay">
          <h1 className="file-drop-zone__overlay-header">{header}</h1>
        </div>
      </div>
    );
  }
}

FileDropZone.propTypes = {
  children: PropTypes.any,
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

FileDropZone.defaultProps = {
  children: [],
  multiple: true,
};

export default FileDropZone;
