/**
 * Utility to make a promise cancelable. This allows us to cancel a promise before its containing
 * React component is unmounted so we can avoid calling setState() on unmounted components. This
 * is the recommended way to handle such cases instead of relying on manual isMounted flags.
 *
 * Note: Please only use on ES6 promises. The jQuery variety will not work here.
 * Reference: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 *
 * Usage:
 *
 * const cancelablePromise = makeCancelablePromise(
 *   new Promise(r => component.setState({...}}))
 * );
 *
 * cancelablePromise
 *   .promise
 *   .then(() => console.log('resolved'))
 *   .catch((reason) => console.log('isCanceled', reason.isCanceled));
 *
 * // In React component's componentWillUnmount()
 * cancelablePromise.cancel(); // Cancel the promise
 *
 * @param {Promise} promise  The ES6 promise to make cancelable
 * @returns {Object} object  Object containing the promise, cancel() and isCanceled()
 */
const makeCancelablePromise = (promise) => {
  let hasCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
    isCanceled() {
      return hasCanceled;
    },
  };
};

export default makeCancelablePromise;
