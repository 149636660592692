import React from "react";
import PropTypes from "prop-types";
import { components, createFilter } from "react-select";
import {
  isUndefined,
  isString,
  isBoolean,
  pluck,
  flatten,
  isEqual,
} from "underscore";
import _ from "lodash";

export const DEFAULT_MAX_MENU_HEIGHT = 200;
export const REACT_SELECT_ACTION__INPUT_CHANGE = "input-change";
const UPSIDE_DOWN_DEGREES = 180;
const BOX_SHADOW_WHEN_OPEN = "inset 0 0 4px 0 #407cca";
const BOX_SHADOW_WHEN_FOCUSED_AND_OPEN =
  "rgba(51, 62, 72, 0.5) 0px 1px 4px 0px";
// Make sure dropdown is not cut off by modal boundary when menuPortalTarget is used
const MODAL_Z_INDEX = 700;

const REGEX_DATA_ATTRIBUTE = /data-(.*)/;

export const FancySelectStyles = {
  control: (base, state) => {
    const isOpen = state.selectProps.menuIsOpen;
    const boxShadowWhenFocused = isOpen
      ? BOX_SHADOW_WHEN_FOCUSED_AND_OPEN
      : BOX_SHADOW_WHEN_OPEN;
    return Object.assign({}, base, {
      boxShadow: state.isFocused ? boxShadowWhenFocused : "none",
      ...(state.isFocused
        ? {
            outline: "2px solid -webkit-focus-ring-color",
            outlineOffset: "-1px",
          }
        : {}),
    });
  },
  dropdownIndicator: (base, state) => {
    const isOpen = state.selectProps.menuIsOpen;
    return Object.assign({}, base, {
      // Flip triangle upside down when dropdown menu is open
      transform: `rotate(${isOpen ? UPSIDE_DOWN_DEGREES : 0}deg) ${
        isOpen ? "translateY(2px)" : ""
      }`,
    });
  },
  menuPortal: (base) => Object.assign({}, base, { zIndex: MODAL_Z_INDEX }),
};

// eslint-disable-next-line react/display-name
export const Menu =
  ({ className = "" } = {}) =>
  (props) => {
    const menu = (
      <components.Menu {...props} className={`qa-select-menu ${className}`}>
        {props.children}
      </components.Menu>
    );
    // eslint-disable-next-line react/prop-types
    return props.selectProps.menuPortalTarget ? (
      <div className="Select">{menu}</div>
    ) : (
      menu
    );
  };

export const MenuList =
  ({ menuFooterComponent } = {}) =>
  (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        {menuFooterComponent && (
          <div className="Select__menu-footer">{menuFooterComponent}</div>
        )}
      </components.MenuList>
    );
  };

export const MenuFooterButton = ({ className, label, onClick } = {}) => (
  <button
    onClick={onClick}
    type="button"
    className={`${
      className || ""
    } input--full pc-btn pc-btn--ghost pc-btn--small`}
  >
    {label}
  </button>
);

MenuFooterButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

MenuFooterButton.defaultProps = {
  className: undefined,
};

export const RequiredInput = (props) => {
  return <components.Input {...props} aria-required="true" />;
};

export const DropdownIndicator = (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <span className="Select__arrow" />
    </components.DropdownIndicator>
  );

export const ClearIndicator = (props) =>
  components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <span className="Select__clear">×</span>
    </components.ClearIndicator>
  );

export const SingleValue = (ValueRenderer) => (innerProps) =>
  components.SingleValue && (
    <components.SingleValue {...innerProps}>
      <ValueRenderer {...innerProps.data} />
    </components.SingleValue>
  );

export const MultiValueContainer = (ValueRenderer) => (innerProps) =>
  components.MultiValueContainer && (
    <components.MultiValueContainer {...innerProps}>
      <div>
        <ValueRenderer {...innerProps.data} />
      </div>
    </components.MultiValueContainer>
  );

export const Option = (OptionRenderer) => (innerProps) =>
  components.Option && (
    <components.Option {...innerProps}>
      <OptionRenderer
        isMulti={innerProps.isMulti}
        isSelected={innerProps.isSelected}
        {...innerProps.data}
      />
    </components.Option>
  );

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props} className={"Select__option"}>
      No matches found
    </components.NoOptionsMessage>
  );
};

export const NoOptionsMessageHidden = () => null;

export const isGroupedOptions = (options) => {
  if (!options || !options.length) {
    return false;
  }
  return options[0].label && options[0].options;
};

export const getMergedGroupedOptions = (groupedOptions) => {
  return flatten(pluck(groupedOptions, "options"));
};

export const getOptionObjectFromValue = (optionsArray, value) => {
  const options = isGroupedOptions(optionsArray)
    ? getMergedGroupedOptions(optionsArray)
    : optionsArray;
  return (options || []).find((option) => {
    let valueToCompare = value;
    if (isString(value) && isBoolean(option.value)) {
      valueToCompare = value.toLowerCase() === "true";
    }
    return !isUndefined(value) && option.value === valueToCompare;
  });
};

// When `isMulti` prop is true, where value is an array of values
export const getOptionObjectsFromValues = (optionsArray, values) => {
  const options = isGroupedOptions(optionsArray)
    ? getMergedGroupedOptions(optionsArray)
    : optionsArray;
  return (options || []).filter((option) =>
    (values || []).includes(option.value)
  );
};

export const getSearchResultCount = ({ input, options, matchFromStart }) => {
  const filterFunction = matchFromStart
    ? createFilter({ matchFrom: "start" })
    : createFilter();
  const optionsArray = isGroupedOptions(options)
    ? getMergedGroupedOptions(options)
    : options;
  return optionsArray.filter((option) => filterFunction(option, input)).length;
};

export const getSelectedOptionLabels = (
  options,
  values,
  { otherOptionWithTextInputName, otherOptionTextInputValue } = {}
) => {
  const selectedOptions = options.filter((option) =>
    (values || []).includes(option.value)
  );
  const labels = pluck(selectedOptions, "label");
  if (otherOptionWithTextInputName) {
    const otherOption = options.find(
      (option) => option.value === otherOptionWithTextInputName
    );
    const otherOptionLabelIndex = labels.indexOf(otherOption.label);
    if (otherOptionLabelIndex > -1 && otherOptionTextInputValue) {
      labels[
        otherOptionLabelIndex
      ] = `${otherOption.label}: ${otherOptionTextInputValue}`;
    }
  }
  return labels.join(", ");
};

/**
 * Converts `data-*` attributes from the supplied object to `dataset` representation.
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLOrForeignElement/dataset
 * @param {Object} props the props object
 * @returns {Object} the object containing `data-*` attributes with the names changed
 *                   to camelCase according to spec.
 */
export function datasetFromProps(props) {
  return Object.entries(props).reduce((res, [prop, value]) => {
    const result = REGEX_DATA_ATTRIBUTE.exec(prop);
    if (result) {
      const name = _.camelCase(result[1]);
      return { ...res, ...{ [name]: String(value) } };
    }
    return res;
  }, {});
}

/**
 * The equality function to check react components in `memoize-one`.
 * https://github.com/alexreardon/memoize-one#custom-equality-function
 * @param {Array} newArgs new arguments
 * @param {Array} oldArgs old arguments
 * @returns {Boolean} `true` if the old and new components are equal, `false` otherwise
 */
export function equalityFnReactComp([component], [prevComponent]) {
  if (Object.is(component, prevComponent)) {
    return true;
  }

  if (component && prevComponent) {
    return (
      component.type === prevComponent.type &&
      isEqual(component.props, prevComponent.props)
    );
  }

  return false;
}

export default {
  DEFAULT_MAX_MENU_HEIGHT,
  REACT_SELECT_ACTION__INPUT_CHANGE,
  FancySelectStyles,
  MenuFooterButton,
  RequiredInput,
  DropdownIndicator,
  ClearIndicator,
  SingleValue,
  MultiValueContainer,
  Menu,
  MenuList,
  Option,
  NoOptionsMessage,
  NoOptionsMessageHidden,
  isGroupedOptions,
  getMergedGroupedOptions,
  getOptionObjectFromValue,
  getOptionObjectsFromValues,
  getSearchResultCount,
  getSelectedOptionLabels,
};
