import React from "react";
import PropTypes from "prop-types";
import TimeSelectorColumn from "./TimeSelectorColumn";

const SPACING_THRESHOLD = 9;

export default function TimeSelector({
  amSlots,
  pmSlots,
  appointmentId,
  onSelect,
}) {
  const tightSpacing =
    amSlots.length > SPACING_THRESHOLD || pmSlots.length > SPACING_THRESHOLD;
  return (
    <div className="l-spaced l-spaced--flush l-spaced--top">
      <TimeSelectorColumn
        header="AM"
        slots={amSlots}
        onSelect={onSelect}
        tightSpacing={tightSpacing}
        appointmentId={appointmentId}
      />
      <TimeSelectorColumn
        header="PM"
        slots={pmSlots}
        onSelect={onSelect}
        tightSpacing={tightSpacing}
        appointmentId={appointmentId}
      />
    </div>
  );
}

TimeSelector.propTypes = {
  amSlots: PropTypes.array,
  pmSlots: PropTypes.array,
  onSelect: PropTypes.func,
  appointmentId: PropTypes.string,
};

TimeSelector.defaultProps = {
  amSlots: [],
  pmSlots: [],
};
