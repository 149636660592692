import PropTypes from "prop-types";
import React from "react";
import { formatCurrency } from "libs/pcap/utils/format";

const PERCENT_HUNDRED = 100;

function renderLabel(name, value, legendClassName) {
  if (!name && !value) {
    return;
  }
  // values betweem -1 and 1 such as 0.05 or -0.01 are displayed along with the decimals.
  // and any other value is truncated for display. hence, the condition below.
  const formatedValue =
    value > -1 && value < 1
      ? formatCurrency(value, 2)
      : formatCurrency(value, 0);
  return (
    <div
      className={
        legendClassName ? "chart-legend chart-legend--box milli" : undefined
      }
    >
      <span
        className={`${
          legendClassName ? "chart-legend__item " + legendClassName : ""
        } pc-progress-bar__name qa-progress-bar-name`}
      >
        {name}{" "}
      </span>
      <span className="u-text-bold pc-progress-bar__value js-progress-bar-value qa-progress-bar-value">
        {formatedValue}
      </span>
    </div>
  );
}

const GenericProgressBar = (props) => {
  const {
    name,
    className,
    fillerClassName,
    value,
    total,
    labelPos,
    isAbsoluteValue,
    legendClassName,
  } = props;
  // Floors value at 0 when isAbsoluteValue is false
  const width = isAbsoluteValue
    ? Math.min((Math.abs(value) * PERCENT_HUNDRED) / total, PERCENT_HUNDRED)
    : Math.min((Math.max(0, value) * PERCENT_HUNDRED) / total, PERCENT_HUNDRED);

  return (
    <div className={`pc-progress-bar ${className}`}>
      {labelPos === "top" && renderLabel(name, total, legendClassName)}
      <div className="pc-progress-bar qa-progress-bar">
        <div className="pc-progress-bar__body">
          <div className="pc-progress-bar__filler-container">
            <div
              className={`pc-progress-bar__filler ${fillerClassName} js-progress-bar-filler`}
              style={{ width: `${width}%` }}
            />
          </div>
        </div>
      </div>
      {labelPos === "bottom" && renderLabel(name, value, legendClassName)}
    </div>
  );
};

GenericProgressBar.defaultProps = {
  name: "",
  labelPos: "bottom",
  className: "",
  fillerClassName: "",
  isAbsoluteValue: false,
  legendClassName: undefined,
};

GenericProgressBar.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  labelPos: PropTypes.string,
  className: PropTypes.string,
  legendClassName: PropTypes.string,
  fillerClassName: PropTypes.string,
  isAbsoluteValue: PropTypes.bool,
};

export default GenericProgressBar;
