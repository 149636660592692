var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog\">\n  <div class=\"modal-content\">\n    <div class=\"modal-header\">\n      <button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span aria-hidden=\"true\">×</span></button>\n      <div class=\"title-row\">\n        <h4 class=\"modal-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":43}}}) : helper)))
    + "</h4>\n      </div>\n    </div>\n    <div class=\"modal-body modal-body--info "
    + alias4(((helper = (helper = lookupProperty(helpers,"bodyClass") || (depth0 != null ? lookupProperty(depth0,"bodyClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bodyClass","hash":{},"data":data,"loc":{"start":{"line":9,"column":44},"end":{"line":9,"column":57}}}) : helper)))
    + "\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"pc-button pc-button--success pc-button--large\" data-dismiss=\"modal\">Close</button>\n    </div>\n  </div>\n</div>\n\n<script type=\"text/javascript\" language=\"javascript\" class=\"init\">\n\n  $(document).ready(function(){\n    $(\".mod-pcap\").modal({\n      show:false,\n      backdrop:'static'\n    });\n  });\n  \n </script>";
},"useData":true});