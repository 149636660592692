import React from "react";
import PropTypes from "prop-types";
import {
  FORMAT_DAY,
  FORMAT_TIME,
  FORMAT_TIME_TZ,
} from "components/scheduling/utils";
import moment from "moment-timezone";

const AppointmentDetails = ({
  advisorImgURL,
  advisorName,
  startTime,
  endTime,
  className,
  timezone,
}) => {
  if (timezone == null || timezone === "") timezone = moment.tz.guess();

  startTime = startTime.clone().tz(timezone);
  endTime = endTime.clone().tz(timezone);

  return (
    <div
      className={`scheduling-appointment-details qa-scheduling-appointment-details appointment__centered-copy ${className}`}
    >
      {advisorImgURL && (
        <img
          className="scheduling-appointment-details__advisor-img"
          src={advisorImgURL}
          alt="Advisor Image"
          width="102"
          height="102"
        />
      )}
      {advisorName} will call you on
      <br />
      <strong className="qa-scheduling-appointment-details__date">
        {startTime.format(FORMAT_DAY)}
      </strong>
      <br />
      <strong className="qa-scheduling-appointment-details__time">
        {startTime.format(FORMAT_TIME)} – {endTime.format(FORMAT_TIME_TZ)}
      </strong>
    </div>
  );
};

export default AppointmentDetails;

AppointmentDetails.propTypes = {
  advisorName: PropTypes.string,
  advisorImgURL: PropTypes.string,
  timezone: PropTypes.string,
  startTime: PropTypes.object.isRequired,
  endTime: PropTypes.object.isRequired,
  className: PropTypes.string,
};

AppointmentDetails.defaultProps = {
  advisorName: "An advisor",
  advisorImgURL: undefined,
  className: "",
  timezone: undefined,
};
