import Services from "services";
import { throttle } from "underscore";

const WAIT = 500;

const keepAlive = throttle(
  () => {
    Services.Login.keepAlive();
  },
  WAIT,
  { leading: false }
);

/**
 * Keeps the session alive by sending a keep-alive probe on every
 * click event.
 */
document.body.addEventListener("click", keepAlive);
